import * as API from '@api/general';
import { Container } from '@components/shared/Container';
import HandleFetchingState from '@components/shared/HandleFetchingState';
import breakpoints from '@lib/common/breakpoints';
import * as qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

const StyledConfirmPostDelete = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.yellow};
  padding: 120px 0;
  text-align: center;
  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 100px 0;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 80px 0;
  }
`;

interface Props {}

export const ConfirmPostDelete = (props: RouteComponentProps<Props>) => {
  const queryString = qs.parse(props.location.search);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      try {
        await API.confirmPostDelete({
          type: `${queryString.type}`,
          token: `${props.location.search.split('token=')[1]}`,
        });
        props.history.push('/');
        setLoading(false);
      } catch (e) {
        //@ts-ignore
        setError(e?.response?.data?.message || e?.response?.data?.data?.message);
        setLoading(false);
      }
    })();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <HandleFetchingState loading={loading}>
      <StyledConfirmPostDelete className="ConfirmPostDelete">
        <Container>
          <h1>{error}</h1>
        </Container>
      </StyledConfirmPostDelete>
    </HandleFetchingState>
  );
};
