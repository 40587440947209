import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';

export interface ResetPasswordFields {
  newPassword: string;
  confirmPassword: string;
}

interface UseResetPasswordOptions {
  initialValues?: ResetPasswordFields;
  onSubmit: (values: ResetPasswordFields, formikHelpers: FormikHelpers<ResetPasswordFields>) => Promise<any>;
}

export const useResetPasswordFormik = (options: UseResetPasswordOptions) => {
  return useFormik<any>({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      newPassword: Yup.string().min(6, 'password.strength').required('field.required'),
      confirmPassword: Yup.string()
        .min(6, 'password.strength')
        .oneOf([Yup.ref('newPassword'), null], 'password.must.match')
        .required('field.required'),
    }),
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};
