import { ReactComponent as ArrowDownIcon } from '@assets/icons/icons_arrow-down_small.svg';
//@ts-ignore
import pdf2013 from '@assets/pdfs/VFC_Jahresbericht_2013.pdf';
//@ts-ignore
import pdf2014 from '@assets/pdfs/VFC_Jahresbericht_2014.pdf';
//@ts-ignore
import pdf2015 from '@assets/pdfs/VFC_Jahresbericht_2015.pdf';
//@ts-ignore
import pdf2016 from '@assets/pdfs/VFC_Jahresbericht_2016.pdf';
//@ts-ignore
import pdf2017 from '@assets/pdfs/VFC_Jahresbericht_2017.pdf';
//@ts-ignore
import pdf2018 from '@assets/pdfs/VFC_Jahresbericht_2018.pdf';
//@ts-ignore
import pdf2019 from '@assets/pdfs/VFC_Jahresbericht_2019.pdf';
//@ts-ignore
import pdf2020 from '@assets/pdfs/VFC_Jahresbericht_2020.pdf';
//@ts-ignore
import pdf2021 from '@assets/pdfs/VFC_Jahresbericht_2021.pdf';
//@ts-ignore
import pdf2022 from '@assets/pdfs/VFC_Jahresbericht_2022.pdf';
//@ts-ignore
import pdf2023 from '@assets/pdfs/VFC_Jahresbericht_2023.pdf';


import AnualReportListItem, { IAnualReport } from '@components/shared/AnualReportListItem';
import { Container } from '@components/shared/Container';
import FullWidthOutlineButton from '@components/shared/FullWidthOutlineButton';
import breakpoints from '@lib/common/breakpoints';
import ModuleType from '@lib/interfaces/Modules';
import React, { FC, useMemo, useState } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import styled, { css, ThemeProvider } from 'styled-components';

interface IWrapperProps {
  color?: string;
}

const Wrapper = styled.div<IWrapperProps>`
  background-color: ${(props: any) => props.data?.background_color};
  padding-top: 110px;
  padding-bottom: 110px;
  width: 100%;
  ${({ color }) =>
    color &&
    css`
      background-color: #${color};
    `}

  @media only screen and ${breakpoints.maxWidth.sm} {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  h3 {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.25em;
    text-transform: uppercase;
  }
`;

const ListWrapper = styled.div`
  position: relative;
  margin-left: -27px;
  margin-right: -27px;

  @media only screen and ${breakpoints.maxWidth.md} {
    margin-left: -16px;
    margin-right: -16px;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    margin-left: -5px;
    margin-right: -5px;
  }
`;

const StyledRow = styled(Row)``;

const StyledCol = styled(Col)`
  margin-bottom: 60px;
  padding: 0 27px;

  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 0 16px;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    padding: 0 5px;
    margin-bottom: 50px;
  }
`;

const Title = styled.p`
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  max-width: 823px;
  font-size: 36px;
  line-height: ${(props) => props.theme.helpers.pxToRem(42)};
  letter-spacing: 0.4px;
  margin-bottom: 40px;

  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 18px;
    line-height: ${(props) => props.theme.helpers.pxToRem(22)};
    letter-spacing: 0.2px;
  }
`;

interface IModule24Props {
  data?: {
    title: string;
    'annual-reports': IAnualReport[];
    button: string;
  };
}

const Module24: FC<IModule24Props> = (props) => {
  const [showMore, setShowMore] = useState(false);

  const theme = useMemo(() => {
    return {
      flexboxgrid: {
        gridSize: 12,
        gutterWidth: 0,
        outerMargin: 0,
        mediaQuery: 'only screen',
        breakpoints: {
          xs: 0,
          sm: 36,
          md: 48,
          lg: 61,
        },
      },
    };
  }, []);

  const getFile = (fileName: string) => {
    if (fileName.includes('2013')) {
      return pdf2013;
    }
    if (fileName.includes('2014')) {
      return pdf2014;
    }
    if (fileName.includes('2015')) {
      return pdf2015;
    }
    if (fileName.includes('2015')) {
      return pdf2015;
    }
    if (fileName.includes('2016')) {
      return pdf2016;
    }
    if (fileName.includes('2017')) {
      return pdf2017;
    }
    if (fileName.includes('2018')) {
      return pdf2018;
    }
    if (fileName.includes('2019')) {
      return pdf2019;
    }
    if (fileName.includes('2020')) {
      return pdf2020;
    }
    if (fileName.includes('2021')) {
      return pdf2021;
    }
    if (fileName.includes('2022')) {
      return pdf2022;
    }
        if (fileName.includes('2023')) {
      return pdf2023;
    }
  };

  const anualReports = props?.data ? props?.data['annual-reports'] : [];

  const dataAnualReports = showMore && anualReports.length > 3 ? anualReports : anualReports.slice(0, 4);

  return (
    <Wrapper data-module={ModuleType.MODULE_24}>
      <Container>
        <Title>{props.data?.title}</Title>

        <ListWrapper>
          <ThemeProvider theme={theme}>
            <StyledRow>
              {dataAnualReports?.map((report) => (
                <StyledCol key={report.ID} xs={6} sm={6} md={6} lg={3}>
                  <AnualReportListItem report={report} file={getFile(report.pdf.filename)} />
                </StyledCol>
              ))}
            </StyledRow>
          </ThemeProvider>
        </ListWrapper>
        {!showMore && (
          <FullWidthOutlineButton title={props?.data?.button || 'Weitere anzeigen'} icon={<ArrowDownIcon />} onClick={() => setShowMore(!showMore)} />
        )}
      </Container>
    </Wrapper>
  );
};

export default Module24;
