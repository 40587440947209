import { ReactComponent as EditIcon } from '@assets/icons/icons_edit.svg';
import LocationsGallery from '@components/locations/LocationsGallery';
import LocationsList from '@components/locations/LocationsList';
import Module00Button from '@components/modules/Module00';
import Module07 from '@components/modules/Module07';
import BookmarkButton from '@components/shared/BookmarkButton';
import { Container } from '@components/shared/Container';
import HandleFetchingState from '@components/shared/HandleFetchingState';
import LoadMoreButton from '@components/shared/LoadMoreButton';
import ShareButton from '@components/shared/ShareButton';
import Tags from '@components/shared/Tags';
import breakpoints from '@lib/common/breakpoints';
import { useAuthContext } from '@lib/context/AuthContext/AuthContext';
import { useLocationBookmarksContext } from '@lib/context/LocationBookmarksContext/LocationBookmarksContext';
import useGetLocationsDetails from '@lib/hooks/queries/useGetLocationsDetails';
import useGetRelatedLocationsDetails from '@lib/hooks/queries/useGetRelatedLocations';
import { useRouter } from '@lib/hooks/useRouter';
import React, { FC, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
`;

const ContentContainer = styled.div`
  padding-top: 80px;
  padding-bottom: 80px;
  width: 100%;
  margin: 0 auto;

  @media only screen and ${breakpoints.maxWidth.lg} {
    padding-top: 50px;
    padding-bottom: 80px;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    padding: 50px 0;
  }
`;

const H1 = styled.h1<{ color?: string }>`
  font-family: 'GTFlexa-ExtendedMedium', sans-serif;
  font-size: 58px;
  font-weight: normal;
  line-height: 1.03;
  color: ${(props) => props.color};
  margin: 0;

  @media only screen and ${breakpoints.maxWidth.lg} {
    font-size: 32px;
    line-height: 0.88;
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 24px;
    line-height: 1.17;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 49px;

  @media only screen and ${breakpoints.maxWidth.md} {
    margin-bottom: 21px;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    flex-direction: column;
  }

  > div:first-of-type {
    max-width: 750px;
  }

  > div:last-of-type {
    flex: 1;
    display: flex;
    justify-content: flex-end;

    @media only screen and ${breakpoints.maxWidth.xs} {
      margin-top: 20px;
      flex-direction: column;
    }
  }

  h1 {
    margin-bottom: 27px;
  }

  p {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 36px;
    font-weight: normal;
    line-height: 1.17;
    letter-spacing: 0.4px;
    color: #000000;

    @media only screen and ${breakpoints.maxWidth.lg} {
      font-size: 18px;
      line-height: 1.22;
    }
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  button:first-of-type {
    margin-right: 20px;
    svg {
      width: 48px;
      height: 48px;
    }
  }
`;

const MobileButtonsWrapper = styled(ButtonsWrapper)`
  margin-bottom: 20px;
  button:first-of-type {
    margin-right: 20px;
  }
`;

const TagsWrapper = styled.div`
  margin-top: 28px;
`;

const ContentWrapper = styled.div`
  margin-top: 28px;
  display: flex;
  justify-content: space-between;
  @media only screen and ${breakpoints.maxWidth.lg} {
    flex-direction: column-reverse;
  }
`;

const LeftContent = styled.div`
  max-width: 725px;
  @media only screen and ${breakpoints.maxWidth.xs} {
    margin-top: 20px;
  }
`;

const RightContent = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media only screen and ${breakpoints.maxWidth.lg} {
    flex-direction: row;
    width: auto;
    div:first-of-type {
      margin-right: 80px;
    }
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    flex-direction: column;
    div:first-of-type {
      margin-bottom: 20px;
    }
  }

  div:first-of-type {
    margin-bottom: 24px;
    h1 {
      font-family: 'GTFlexa-ExtendedMedium', sans-serif;
      font-size: 36px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: 0.4px;
      color: #000;
    }
  }
`;

const TextContent = styled.p`
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: 0.5px;
  color: #000;
  white-space: pre-line;
  a {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    color: #000000;
    &:hover {
      color: #ff3d3d;
    }
  }
`;

const StyledModuleLinkButton = styled(Module00Button)`
  svg {
    transform: rotate(-90deg);
  }
`;
const FiltersToggleButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  cursor: pointer;
  padding: 20px 15px;
  color: #ffffff;
  svg {
    transition: 0.2s;
    width: 28px;
    height: 28px;
    path {
      stroke: white;
    }
  }
  p {
    font-size: 48px;
    font-weight: normal;
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    line-height: 1.25;
    text-align: center;
    margin-left: 8px;
    text-transform: uppercase;
  }
  @media only screen and ${breakpoints.maxWidth.md} {
    p {
      font-size: 25px;
      line-height: 1.25;
    }

    svg {
      width: 19px;
      height: 19px;
    }
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 14px 15px 8px 15px;

    p {
      font-size: 22px;
      line-height: 1.36;
    }
  }
`;
const FiltersWrapper = styled.div`
  background-color: #000000;
  border-top: 1px solid #707070;

  @media only screen and ${breakpoints.maxWidth.md} {
    padding-top: 20px;
  }
`;

const StyledAccordionMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 33px 0 23px;
  transition: all 0.3s ease-in-out;
  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 13px 0 3px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 13px 0 3px;
  }
`;
const StyledAction = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  color: ${(props) => props.theme.colors.white};
  margin-bottom: 45px;
  @media only screen and ${breakpoints.maxWidth.md} {
    margin-bottom: 25px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    margin-bottom: 25px;
  }
  &:hover {
    color: ${(props) => props.theme.colors.red};
  }
`;
const StyledActionTitle = styled.h4`
  width: 100%;
  display: flex;
  font-size: 24px;
  font-family: 'GTFlexa-ExtendedMedium', sans-serif;
  line-height: 1.3em;
  margin-bottom: 8px;
  transition: all 0.3s ease-in-out;
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 20px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 20px;
  }
`;
const StyledActionContent = styled.p`
  width: 100%;
  font-family: 'GTFlexa-StandardLight', sans-serif;
  font-size: 18px;
  line-height: 1.2em;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.colors.white};
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 16px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 16px;
  }
`;
const StyledEditIcon = styled(EditIcon)`
  g {
    stroke: #fff;
  }
`;

const StyledLocationsList = styled(LocationsList)`
  > div:first-of-type {
    padding: 0 0 80px 0 !important;
  }
`;
const StyledLocationsListRow = styled.div`
  margin-left: -27px;
  margin-right: -27px;
  @media only screen and ${breakpoints.maxWidth.md} {
    margin-left: -16px;
    margin-right: -16px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    margin-left: -16px;
    margin-right: -16px;
  }
`;

const RelatedLocations = styled.div`
  padding: 80px;

  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 40px;
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 40px 20px;
  }

  h1 {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 36px;
    font-weight: normal;
    line-height: 1.67;
    letter-spacing: -0.15px;
    color: #000;
    margin-bottom: 53px;

    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 36px;
      line-height: 0.88;
    }
    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 24px;
      line-height: 1.17;
    }
  }
  p {
    display: block;
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.25;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 32px;
  }
`;

interface ILocationDetailsProps {
  locationSlug?: string;
}

const LocationDetails: FC<RouteComponentProps<ILocationDetailsProps>> = (props) => {
  const slug = props.match.params.locationSlug;
  const { user } = useAuthContext();
  const { bookmarks, addBookmark, removeBookmark } = useLocationBookmarksContext();
  const router = useRouter();
  const { formatMessage: t } = useIntl();
  const [open, setOpen] = useState(false);
  const { data, isLoading, error } = useGetLocationsDetails(slug);
  const related = useGetRelatedLocationsDetails({ slug });

  const categories = data?.categories
    ? Object.values(data.categories)
        .map((value) => {
          return value;
        })
        .filter((item: any) => item)
        .flat()
    : [];

  const handleOnBookmarkClick = () => {
    if (!data?.slug) return;
    bookmarks.find((b) => b.includes(data.slug)) ? removeBookmark(data.slug) : addBookmark(data.slug);
  };
  const relatedLocations = related?.data?.pages?.map((item) => item.related).flat() ?? [];

  return (
    <HandleFetchingState loading={isLoading} error={error}>
      <Wrapper>
        <Module07
          withHeader
          data={{
            images: [
              {
                image: data?.thumbnail,
                text: data?.thumbnail_caption,
              },
            ],
          }}
        />
        <Container>
          <ContentContainer>
            <TitleWrapper>
              <H1>{data?.title}</H1>

              <MobileButtonsWrapper>
                {data?.public_private !== 'public' && (
                  <BookmarkButton isfilled={!!bookmarks.find((b) => b.includes(`${data?.slug}`))} onClick={handleOnBookmarkClick} />
                )}
                <ShareButton title={data?.title} />
              </MobileButtonsWrapper>

              <p>{data?.short_description}</p>
              <TagsWrapper>
                <Tags tags={categories} />
              </TagsWrapper>
            </TitleWrapper>

            <ContentWrapper>
              <LeftContent>
                <TextContent dangerouslySetInnerHTML={{ __html: data?.description ?? '' }} />
              </LeftContent>
              <RightContent>
                <div>
                  <TextContent>{t({ id: 'locationnummer' })}</TextContent>
                  <h1>{data?.id}</h1>
                </div>
                {data?.categories?.location_ausstattung && data?.categories?.location_ausstattung.length > 0 && (
                  <div>
                    {data?.categories?.location_ausstattung.map((cat) => {
                      return <TextContent key={`loc-${cat.term_id}`}>+ {cat.name}</TextContent>;
                    })}
                  </div>
                )}
              </RightContent>
            </ContentWrapper>
          </ContentContainer>
        </Container>

        {user?.ID === data?.author_id && (
          <>
            <FiltersToggleButton role="button" onClick={() => setOpen(!open)}>
              <StyledEditIcon /> <p>{t({ id: 'location.bearbeiten' })}</p>
            </FiltersToggleButton>
            {open && (
              <FiltersWrapper>
                <Container>
                  <ContentContainer>
                    <StyledAccordionMain>
                      <StyledAction onClick={() => router.history.push(`/manage-location/${data?.slug}/edit`)}>
                        <StyledActionTitle>→ {t({ id: 'location.edit' })}</StyledActionTitle>
                        <StyledActionContent>{t({ id: 'location.edit.description' })} </StyledActionContent>
                      </StyledAction>
                      <StyledAction onClick={() => router.history.push(`/manage-location/${data?.slug}/delete`)}>
                        <StyledActionTitle>→ {t({ id: 'location.delete' })}</StyledActionTitle>
                        <StyledActionContent>{t({ id: 'location.delete.description' })}</StyledActionContent>
                      </StyledAction>
                    </StyledAccordionMain>
                  </ContentContainer>
                </Container>
              </FiltersWrapper>
            )}
          </>
        )}

        {/* Photo Gallery */}
        <LocationsGallery images={data?.photo_gallery} videos={data?.video_gallery} />

        {data?.public_private === 'public' && user?.ID !== data?.author_id && (
          <Module00Button
            data={{
              link: {
                title: t({ id: 'drehgenehmigung' }),
                url: '/formular',
              },
            }}
          />
        )}

        {data?.public_private === 'private' && user?.ID !== data?.author_id && (
          <Module00Button
            data={{
              link: {
                title: t({ id: 'kontakt.anfragen' }),
                url: `/request-private-location/${data?.slug}`,
              },
            }}
          />
        )}

        <InfiniteScroll
          hasMore={Boolean(related.hasNextPage)}
          dataLength={relatedLocations?.length}
          next={related.fetchNextPage}
          loader={null}
          scrollThreshold={1}
        >
          <RelatedLocations>
            <h1>{t({ id: 'locations.related' })}</h1>
            <StyledLocationsListRow>
              <StyledLocationsList locations={relatedLocations} />
            </StyledLocationsListRow>
            {related.isFetching && <LoadMoreButton isLoading />}
          </RelatedLocations>
        </InfiniteScroll>

        {user?.ID === data?.author_id && (
          <StyledModuleLinkButton
            data={{
              link: {
                title: t({ id: 'zuruck.zu.meinem.profil' }),
                url: '/profile',
              },
            }}
          />
        )}
      </Wrapper>
    </HandleFetchingState>
  );
};

export default LocationDetails;
