import Button from '@components/shared/Button';
import breakpoints from '@lib/common/breakpoints';
import { useRouter } from '@lib/hooks/useRouter';
import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { FILMING_STEP } from './FilmingForm';

const StyledForm = styled.div`
  width: 100%;
`;
const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  @media only screen and ${breakpoints.maxWidth.md} {
    margin-top: 20px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    margin-top: 20px;
  }
`;

const StyledButton = styled(Button)`
  font-size: 24px;
  padding: 24px 36px;
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 8px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 8px;
  }
`;

interface Props {
  setStep: React.Dispatch<React.SetStateAction<FILMING_STEP>>;
}

export const FilmingComplete = (props: Props) => {
  const router = useRouter();
  const { formatMessage: t } = useIntl();

  return (
    <StyledForm className="FilmingComplete">
      <StyledButtonWrapper>
        <StyledButton color="black" onClick={() => router.history.push('/')}>
          {t({ id: 'back_home' })}
        </StyledButton>
      </StyledButtonWrapper>
    </StyledForm>
  );
};
