import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';

export interface BranchDeleteFields {
  id: number;

  other_reason: string;

  no_longer_use: boolean;
  too_much_effort: boolean;
  dont_want_to_show: boolean;
}

interface UseBranchDeleteFormOptions {
  initialValues?: BranchDeleteFields;
  onSubmit: (values: BranchDeleteFields, formikHelpers: FormikHelpers<BranchDeleteFields>) => Promise<any>;
}

export const useBranchDeleteFormik = (options: UseBranchDeleteFormOptions) => {
  const BranchSchema = Yup.object().shape({
    // title: Yup.string().required('Title is required'),

    // no_longer_use: Yup.boolean().oneOf([true], 'Mein Profil wird nicht mehr verwendet is required'),
    // too_much_effort: Yup.boolean().oneOf([true], 'Zu viel Aufwand is required'),
    // dont_want_to_show: Yup.boolean().oneOf([true], 'Möchte ich nicht angeben is required'),

    other_reason: Yup.string().required('Reason is required'),
  });

  return useFormik<BranchDeleteFields>({
    initialValues: {
      id: 0,
      no_longer_use: false,
      too_much_effort: false,
      dont_want_to_show: false,
      other_reason: '',
      ...options.initialValues,
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: BranchSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type BranchDeleteFormik = ReturnType<typeof useBranchDeleteFormik>;
