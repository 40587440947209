import { Container } from '@components/shared/Container';
import breakpoints from '@lib/common/breakpoints';
import ModuleType from '@lib/interfaces/Modules';
import cs from 'classnames';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div<{ hideShadow?: boolean; fullWidth?: boolean }>`
  position: relative;
  width: 100%;
  margin-top: 66px;
  margin-bottom: 120px;
  max-width: ${(props) => (props.fullWidth ? '100%' : '1100px')};

  h1 {
    max-width: ${(props) => (props.fullWidth ? '100%' : '823px')};
  }

  p {
    max-width: ${(props) => (props.fullWidth ? '100%' : null)};
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    margin-top: 40px;
  }

  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 0 20px;
    margin-bottom: 33px;
  }

  &:after {
    content: '';
    width: 100%;
    height: 108px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(to top, rgb(255 255 255), rgba(0, 0, 0, 0));
  }

  &:after {
    display: ${(props) => (props.hideShadow ? 'none' : 'block')};
  }
`;

const Title = styled.h1`
  font-family: 'GTFlexa-ExtendedMedium', sans-serif;
  font-weight: 500;
  font-size: 52px;
  line-height: 1.03;
  margin-bottom: 24px;

  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 20px;
    margin-bottom: 24px;
    line-height: ${(props) => props.theme.helpers.pxToRem(28)};
  }
`;

const Paragraph = styled.p<{ isSmall?: boolean }>`
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  max-width: 823px;
  font-size: ${(props) => (props.isSmall ? '24px' : '30px')};
  line-height: ${(props) => (props.isSmall ? props.theme.helpers.pxToRem(30) : props.theme.helpers.pxToRem(42))};
  letter-spacing: 0.4px;

  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 18px;
    line-height: ${(props) => props.theme.helpers.pxToRem(22)};
    letter-spacing: 0.2px;
  }
`;

const Links = styled.ul`
  margin: 30px 0 0 0;
  padding: 0;
  list-style: none;

  li {
    &:hover {
      a,
      span {
        color: ${(props) => props.theme.colors.red};
      }
    }
    a,
    span {
      font-size: 30px;
      font-family: 'GTFlexa-ExtendedLight', sans-serif;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: 0.4px;
      color: #000;

      @media only screen and ${breakpoints.maxWidth.md} {
        font-size: 18px;
        line-height: ${(props) => props.theme.helpers.pxToRem(22)};
        letter-spacing: 0.2px;
      }
    }
  }
`;

interface Module03Props {
  className?: string;
  hideShadow?: boolean;
  data?: {
    full_width?: boolean;
    smaller_paragraph_font?: boolean;
    show_full_text?: boolean;
    big_text?: string;
    small_text?: string;
    items?: Array<{ to?: string; text?: string }>;
  };
}

export default function Module03(props: Module03Props) {
  const { className, data } = props;

  return (
    <Container>
      <Wrapper data-module={ModuleType.MODULE_03} className={cs(className)} fullWidth={data?.full_width} hideShadow={data?.show_full_text}>
        <Title>{data?.big_text}</Title>
        <Paragraph isSmall={data?.smaller_paragraph_font} dangerouslySetInnerHTML={{ __html: data?.small_text ?? '' }} />

        {data?.items ? (
          <Links>
            {data.items.map((item: any, index: number) => {
              const link = item?.link;
              return (
                <li key={`${link?.url}-${index}`}>
                  <Link to={link?.url ?? ''}>
                    <span>→ </span>
                    {link.title}
                  </Link>
                </li>
              );
            })}
          </Links>
        ) : null}
      </Wrapper>
    </Container>
  );
}
