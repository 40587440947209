import { ReactComponent as IconClose } from '@assets/icons/icons_close_small.svg';
import { ReactComponent as IconEdit } from '@assets/icons/icons_edit.svg';
import { ImageUpload } from '@components/shared/ImageUpload';
import breakpoints from '@lib/common/breakpoints';
import { LocationInfoFormik, LocationUploadFile } from '@lib/hooks/formik/useLocationInfoFormik';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { FormFeedback } from 'reactstrap';
import styled, { css } from 'styled-components';

const StyledLocationUploadFiles = styled.div`
  width: 100%;
  margin-top: 50px;
  @media only screen and ${breakpoints.maxWidth.md} {
    margin-top: 20px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    margin-top: 20px;
  }
`;
interface IStyledTitle {
  noSpace?: boolean;
}
const StyledTitle = styled.h5<IStyledTitle>`
  font-family: 'GTFlexa-ExtendedMedium', sans-serif;
  font-size: 24px;
  line-height: 1.3em;
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 40px;
  ${({ noSpace }) =>
    noSpace &&
    css`
      margin-bottom: 0;
    `}

  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 20px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 20px;
  }
`;

const StyledFeature = styled.div`
  width: 100%;
  max-width: 290px;
  margin-bottom: 30px;
  h4 {
    font-family: 'GTFlexa-StandardMedium', sans-serif;
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.back};
    margin-bottom: 24px;
  }
`;
const StyledSectionTitle = styled.h4`
  font-family: 'GTFlexa-StandardMedium', sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.back};
  margin-bottom: 24px;
`;
const StyledFeatureImage = styled.div`
  width: 100%;
  height: 200px;
  position: relative;
  margin-bottom: 10px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  span {
    width: 48px;
    height: 48px;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 0px 0px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.white};
    svg {
      width: 32px;
      height: 32px;
    }
  }
`;
const StyledFeatureContent = styled.div`
  width: 100%;
  h5 {
    font-family: 'GTFlexa-StandardMedium', sans-serif;
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 1.2em;
    color: ${(props) => props.theme.colors.black};
    margin-bottom: 6px;
  }
  span {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 1.2em;
    color: ${(props) => props.theme.colors.black};
  }
`;
const StyledFileItem = styled.div`
  width: 100%;
  padding: 20px 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #979797;
`;
const StyledFileItemThumb = styled.div`
  width: 104px;
  height: 72px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const StyledFileItemActions = styled.div`
  width: 104px;
  height: 72px;
  display: flex;
  align-items: center;
  svg {
    width: 32px;
    height: 32px;
    margin-left: 40px;
    cursor: pointer;
    &:hover {
      g,
      path {
        stroke: ${(props) => props.theme.colors.red};
      }
    }
    &:first-child {
      margin-left: 0;
    }
  }
`;
const StyledFileItemContent = styled.div`
  width: calc(100% - 208px);
  padding-left: 40px;
  h3 {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 24px;
    line-height: 1.3em;
    color: ${(props) => props.theme.colors.black};
  }
  span {
    display: block;
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 1.2em;
    color: ${(props) => props.theme.colors.black};
    margin-bottom: 10px;
  }
`;
const StyledFormFeedback = styled(FormFeedback)`
  font-family: 'GTFlexa-StandardMedium';
  font-size: 16px;
  letter-spacing: 0.2px;
  color: ${(props) => props.theme.colors.red};
  padding: 10px 24px 0 0;

  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 14px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 14px;
  }
`;

interface Props {
  formik: LocationInfoFormik;
}

export const LocationUploadFiles = ({ formik }: Props) => {
  const { formatMessage: t } = useIntl();
  const [editableImage, setEditableImage] = useState<LocationUploadFile | undefined>(undefined);

  const handleSubmitFile = (value: LocationUploadFile) => {
    if (!formik.values.main_photo_id) {
      formik.setFieldValue('main_photo_id', value);
      formik.setFieldTouched('main_photo_id');
    } else {
      const other_photos = formik.values.other_photos && formik.values.other_photos.length > 0 ? [...formik.values?.other_photos, value] : [value];
      formik.setFieldValue('other_photos', other_photos);
      formik.setFieldTouched('other_photos');
      setEditableImage(undefined);
    }
  };

  const handleImageEdit = (id: number) => {
    const image = formik.values.other_photos?.find((image) => image.id === id);
    setEditableImage(image);
  };

  const handleImageRemove = (id: number) => {
    const other_photos = formik.values.other_photos?.filter((image) => image.id !== id);
    formik.setFieldValue('other_photos', other_photos);
    formik.setFieldTouched('other_photos');
  };

  return (
    <StyledLocationUploadFiles className="LocationUploadFiles">
      <StyledTitle noSpace={!formik.values.main_photo_id}>{t({ id: 'location_foto_upload_text' })}</StyledTitle>
      {/* Feature image file */}
      {formik.values.main_photo_id && (
        <StyledFeature>
          <StyledSectionTitle>{t({ id: 'cover_photo_for_the_location' })}</StyledSectionTitle>
          <StyledFeatureImage>
            <img src={formik.values.main_photo_id?.url} alt={formik.values.main_photo_id?.title} />
            <span
              onClick={() => {
                formik.setFieldValue('main_photo_id', undefined);
                formik.setFieldTouched('main_photo_id');
              }}
            >
              <IconClose />
            </span>
          </StyledFeatureImage>
          <StyledFeatureContent>
            <h5>{formik.values.main_photo_id?.title}</h5>
            <span>{formik.values.main_photo_id?.copyright}</span>
          </StyledFeatureContent>
        </StyledFeature>
      )}
      {/* List of images up to 20 */}
      {formik.values.other_photos && (
        <>
          <StyledSectionTitle>{t({ id: 'location_foto_upload_text' })}</StyledSectionTitle>
          {formik.values.other_photos.map((image) => {
            return (
              <StyledFileItem key={`uploaded-image-${image.id}`}>
                <StyledFileItemThumb>
                  <img src={image.url} alt={image.title} />
                </StyledFileItemThumb>
                <StyledFileItemContent>
                  <span>{image.copyright}</span>
                  <h3>{image.title}</h3>
                </StyledFileItemContent>
                <StyledFileItemActions>
                  <IconEdit onClick={() => handleImageEdit(image.id)} />
                  <IconClose onClick={() => handleImageRemove(image.id)} />
                </StyledFileItemActions>
              </StyledFileItem>
            );
          })}
        </>
      )}

      <ImageUpload onSubmit={handleSubmitFile} value={editableImage} setValue={setEditableImage} />
      {formik.errors.main_photo_id && <StyledFormFeedback>{formik.errors.main_photo_id}</StyledFormFeedback>}
      {formik.errors.other_photos && <StyledFormFeedback>{formik.errors.other_photos}</StyledFormFeedback>}
    </StyledLocationUploadFiles>
  );
};
