import BranchesList from '@components/branches/BranchesList';
import Filters from '@components/shared/Filters';
import FullWidthSearch from '@components/shared/FullWidthSearch';
import LoadMoreButton from '@components/shared/LoadMoreButton';
import objectToStringParams from '@lib/helpers/objectToStringParams';
import removeObjectEmptyProps from '@lib/helpers/removeObjectEmptyProps';
import useGetBranches from '@lib/hooks/queries/useGetBranches';
import useGetBranchFilters from '@lib/hooks/queries/useGetBranchFilters';
import useLocalStorageFilters from '@lib/hooks/useLocalStorageFilters';
import { ObjectAny } from '@lib/interfaces';
import ModuleType from '@lib/interfaces/Modules';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce';

const Wrapper = styled.div``;

const BranchesListWrapper = styled.div`
  padding-bottom: 110px;
`;

interface Module32Props {
  slug?: string;
  data?: {
    title?: string;
  };

  searchProps?: {
    searchValue?: string;
    showResultsHeader?: boolean;
  };
}

const Module32 = (props: Module32Props) => {
  const ref = useRef(null);
  const { getLocalStorageFilterByName, setLocalStorageFilter } = useLocalStorageFilters(ModuleType.MODULE_32);

  const [searchValue, setSearchValue] = useState(props.searchProps?.searchValue ?? '');
  const [debouncedSearchValue] = useDebounce(searchValue, 500);
  const [selectedCategory, setSelectedCategory] = useState<string>(getLocalStorageFilterByName('selectedCategory', ''));
  const [selectedLetter, setSelectedLetter] = useState<string | undefined>(getLocalStorageFilterByName('selectedLetter', 'A'));
  const [selectedFilters, setSelectedFilters] = useState<ObjectAny>(
    getLocalStorageFilterByName('selectedFilters', {
      branch_zusatzqualifikation: {},
      categories: [],
    }),
  );

  useEffect(() => {
    setLocalStorageFilter({
      selectedLetter,
      selectedCategory,
      selectedFilters,
    });
  }, [selectedLetter, selectedCategory, selectedFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSearchValue(props.searchProps?.searchValue ?? '');
  }, [props.searchProps?.searchValue]);

  const branchFilters = useGetBranchFilters(selectedLetter, {
    // onSuccess: (data1) => {
    //   console.log({ data1 });
    //   if (selectedLetter === undefined) {
    //     setSelectedLetter(data1?.letters?.values?.[0] ?? 'A');
    //   }
    // },
  });
  const letters = useMemo(() => branchFilters?.data?.letters?.values ?? [], [branchFilters?.data?.letters]);
  const categories = branchFilters?.data?.categories ?? {};
  const branchZusatzqualifikation = branchFilters?.data?.branch_zusatzqualifikation ?? {};

  // fetch branches
  const { data, isFetching, hasNextPage, fetchNextPage } = useGetBranches(
    removeObjectEmptyProps({
      search: debouncedSearchValue,
      categories: selectedCategory,
      branch_zusatzqualifikation: objectToStringParams(selectedFilters?.branch_zusatzqualifikation),
    }),
  );

  const handleFilterChange = (keyEl: string, el: string) => {
    const isSelected = !!selectedFilters?.[keyEl]?.[el];

    const newFilters = selectedFilters ? { ...selectedFilters[keyEl], [el]: isSelected ? undefined : el } : { [el]: isSelected ? undefined : el };
    setSelectedFilters({
      [keyEl]: { ...newFilters },
    });
  };

  const handleOnCategoriesChange = (category: string) => {
    setSelectedCategory(selectedCategory === category ? '' : category);

    if (!ref.current) {
      return;
    }
    const list = ref.current;

    (list as any).scrollIntoView({
      behavior: 'smooth',
    });
  };

  const handleOnSearchChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchValue(e.target.value);
  };

  const handleOnSelectLetterChange = (letter?: string) => {
    setSelectedLetter(letter);
  };

  const allBranches = data?.pages?.map((item) => item.branches).flat() ?? [];
  const isOnSearchPage = Boolean(props.searchProps);

  return (
    <Wrapper data-module={ModuleType.MODULE_32}>
      <Filters
        isOnSearch={isOnSearchPage}
        selectedLetter={selectedLetter}
        letters={letters}
        categories={{
          label: categories?.label,
          values: categories?.values?.map((item: any) => ({ label: item?.name, value: item?.slug, count: item?.count })) ?? [],
        }}
        selectedCategory={selectedCategory}
        selectedFilters={selectedFilters}
        filters={{
          branch_zusatzqualifikation: {
            label: branchZusatzqualifikation?.label,
            values: branchZusatzqualifikation?.values?.reduce(
              (acc: any, next: any) => ({
                ...acc,
                [next['slug']]: next['name'],
              }),
              {},
            ),
          },
        }}
        onFiltersChange={handleFilterChange}
        onCategoriesChange={handleOnCategoriesChange}
        onSelectLetterChange={handleOnSelectLetterChange}
      />

      {!isOnSearchPage && <FullWidthSearch title={props.data?.title ?? ''} value={searchValue} onChange={handleOnSearchChange} />}

      <InfiniteScroll hasMore={Boolean(hasNextPage)} dataLength={allBranches.length} next={fetchNextPage} loader={null} scrollThreshold={1}>
        <BranchesListWrapper ref={ref}>
          <BranchesList loading={isFetching} branches={allBranches} showResultsHeader={props.searchProps?.showResultsHeader} />
          {isFetching && <LoadMoreButton isLoading />}
        </BranchesListWrapper>
      </InfiniteScroll>
    </Wrapper>
  );
};

export default Module32;
