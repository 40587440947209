import { ReactComponent as LoadingMore } from '@assets/icons/icons_loading-more.svg';
import breakpoints from '@lib/common/breakpoints';
import React, { FC, ForwardedRef } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(-360deg);
      -moz-transform: rotate(-360deg);
      -webkit-transform: rotate(-360deg);
      -o-transform: rotate(-360deg);
      transform: rotate(-360deg);
    }
  }

  button {
    margin: 0;
    padding: 0;
    border: none;
    background: transparent;
    outline: none;
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 48px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -0.2px;
    color: #000;

    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }

    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 24px;
    }
  }

  svg.rotating {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
  }
`;

interface ILoadMoreButtonProps {
  isLoading?: boolean;
  className?: string;
  ref?: ForwardedRef<any>;
}

const LoadMoreButton: FC<ILoadMoreButtonProps> = React.forwardRef((props, ref: ForwardedRef<any>) => {
  const { formatMessage: t } = useIntl();

  return (
    <Wrapper className={props.className ?? ''} ref={ref}>
      <button>
        <LoadingMore className={props.isLoading ? 'rotating' : ''} />
        {t({ id: 'loadingmore' })}
      </button>
    </Wrapper>
  );
});

export default LoadMoreButton;
