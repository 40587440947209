import { get, ISelect } from '@api/axios';
import objectToQueryString from '@lib/helpers/objectToQueryString';
import { PaginatedData, PaginationParams } from '@lib/interfaces';

export interface Production {
  post_title: string | null;
  preview_description: string | null;
  post_name: string | null;
  post_type: string | null;
  post_date: string | null;
  year: string | null;
  production_phase: ISelect[];
  production_categories: ISelect[];
  thumbnail: string | null;
}

export interface GetProductionsResponse extends PaginatedData {
  data: Array<Production>;
}
export interface GetProductionsDetailsResponse {
  fields: Array<any>;
  post_title: string | null;
  post_name: string | null;
  post_type: string | null;
  post_date: string | null;
  year: string | null;
  production_phase: ISelect[];
  produktion: {
    ID: number | null;
    post_author: string | null;
    post_date: string | null;
    post_date_gmt: string | null;
    post_content: string | null;
    post_title: string | null;
    post_excerpt: string | null;
    post_status: string | null;
    comment_status: string | null;
    ping_status: string | null;
    post_password: string | null;
    post_name: string | null;
    to_ping: string | null;
    pinged: string | null;
    post_modified: string | null;
    post_modified_gmt: string | null;
    post_content_filtered: string | null;
    post_parent: number | null;
    guid: string | null;
    menu_order: number | null;
    post_type: string | null;
    post_mime_type: string | null;
    comment_count: string | null;
    filter: string | null;
  }[];

  production_categories: ISelect[];
  thumbnail: string | null;
  preview_description: string | null;
  description: string | null;
  projektart: string | null;
  regie: string | null;
  produzent_innen: string | null;
  drehbuch: string | null;
  kamera: string | null;
  ton: string | null;
  schnitt: string | null;
  land: string | null;
  drehzeit: string | null;
  link: string | null;
  video_title: string | null;
  video_id: string | null;
  bottom_right_text: string | null;
}
interface GetPageInput {
  slug?: string;
}

export interface GetProductionsInput extends PaginationParams {
  production_categories: string;
  veroffentlicht: string;
  year: string;
  search?: string;
}

export const getProductions = (params: GetProductionsInput) => {
  return get<GetProductionsInput, GetProductionsResponse>({ url: `v1/productions/archive?${objectToQueryString(params)}` });
};

export const getProductionsDetails = (slug?: string) => get<GetPageInput, GetProductionsDetailsResponse>({ url: `v1/productions/${slug}` });
