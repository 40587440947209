import * as API from '@api/general';
import { Input } from '@components/user/Input';
import breakpoints from '@lib/common/breakpoints';
import { LocationUploadFile } from '@lib/hooks/formik/useLocationInfoFormik';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import * as Yup from 'yup';
import Button from './Button';

const StyledImageUpload = styled.div`
  width: 100%;
  label {
    background-color: rgb(240 240 240) !important;
  }
  .silver-input {
    background-color: transparent;
  }
`;
const StyledSectionTitle = styled.h4`
  font-family: 'GTFlexa-StandardMedium', sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.back};
  margin-bottom: 24px;
`;
const StyledAddFile = styled.div`
  width: calc(100% + 300px);
  margin-top: 90px;
  margin-left: -150px;
  padding: 50px 150px;
  background-color: rgba(0, 0, 0, 0.06);
  @media only screen and ${breakpoints.maxWidth.md} {
    width: calc(100% + 48px);
    margin-top: 30px;
    margin-left: -24px;
    padding: 30px 20px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    width: calc(100% + 48px);
    margin-top: 30px;
    margin-left: -24px;
    padding: 30px 20px;
  }
  h3 {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 24px;
    line-height: 1.3em;
    color: ${(props) => props.theme.colors.black};
  }
  span {
    display: block;
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 1.2em;
    color: ${(props) => props.theme.colors.black};
    margin-bottom: 10px;
  }
`;
const StyledButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @media only screen and ${breakpoints.maxWidth.md} {
    flex-direction: column;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    flex-direction: column;
  }
`;
const StyledCancelButton = styled(Button)`
  font-size: 24px;
  padding: 25px 90px;
  margin-right: 30px;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.darkRed};
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 20px;
    padding: 10px 18px;
    margin-right: 0;
    margin-bottom: 10px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 20px;
    padding: 10px 18px;
    margin-right: 0;
    margin-bottom: 10px;
  }
`;
const StyledSubmitButton = styled(Button)`
  font-size: 24px;
  padding: 25px 90px;
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 20px;
    padding: 10px 18px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 20px;
    padding: 10px 18px;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      border-color: rgba(0, 0, 0, 0.3) !important;
      color: ${(props) => props.theme.colors.white}!important;
      background-color: rgba(0, 0, 0, 0.3) !important;
    `}
`;
const StyledButton = styled(Button)`
  width: 100%;
  font-size: 24px;
  padding: 24px 36px;
  margin-top: 50px;
  @media only screen and ${breakpoints.maxWidth.md} {
    width: 100%;
    font-size: 20px;
    padding: 10px 18px;
    margin-top: 20px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    width: 100%;
    font-size: 20px;
    padding: 10px 18px;
    margin-top: 20px;
  }
`;

interface Props {
  value: LocationUploadFile | undefined;
  setValue: React.Dispatch<React.SetStateAction<LocationUploadFile | undefined>>;
  onSubmit: (values: LocationUploadFile) => void;
}

export const ImageUpload = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { formatMessage: t } = useIntl();
  const [file, setFile] = useState(t({ id: 'select_data' }));

  const ImageUploadSchema = Yup.object().shape({
    file: props.value ? Yup.mixed() : Yup.mixed().required(t({ id: 'file_is_required' })),
    title: Yup.string().required(t({ id: 'caption_is_required' })),
    copyright: Yup.string().required(t({ id: 'image_copyright_is_required' })),
  });

  useEffect(() => {
    setIsOpen(!!props.value);
    !!props.value &&
      formik.setValues({
        file: '',
        id: props.value?.id || 0,
        url: props.value?.url || '',
        title: props.value?.title || '',
        copyright: props.value?.copyright || '',
      });
  }, [props.value]); // eslint-disable-line react-hooks/exhaustive-deps

  const formik = useFormik({
    initialValues: {
      file: '',
      id: 0,
      url: '',
      title: '',
      copyright: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: ImageUploadSchema,
    onSubmit: async (values, helpers) => {
      if (loading) return;
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append('file', values.file);
        const res = !!values.file && (await API.uploadMedia(formData));
        const image = prepareImageObj(res);
        image && props.onSubmit(image);
        setFile(t({ id: 'select_data' }));
        helpers.resetForm();
        setIsOpen(false);
        props.setValue(undefined);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
  });

  const prepareImageObj = (res: API.UploadImageResponse | false) => {
    if (res) {
      return {
        id: res.id,
        url: res.source_url,
        title: formik.values.title,
        copyright: formik.values.copyright,
      };
    }
    if (props.value) {
      return {
        id: props.value.id,
        url: props.value.url,
        title: formik.values.title,
        copyright: formik.values.copyright,
      };
    }
  };

  const handleCancelUpload = () => {
    formik.resetForm();
    setIsOpen(false);
    setFile(t({ id: 'select_data' }));
    props.setValue(undefined);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files?.length > 0) {
      formik.setFieldValue('file', e.target.files[0]);
      formik.setFieldError('file', undefined);
      setFile(e.target.files[0].name);
    }
  };

  return (
    <StyledImageUpload>
      {isOpen ? (
        <StyledAddFile>
          <StyledSectionTitle>{t({ id: 'add_more_pictures_to_the_location' })}</StyledSectionTitle>
          <Input
            type="file"
            name="file"
            id="file"
            label={t({ id: 'image' })}
            labelInfo={t({ id: 'image' })}
            fileText={file}
            className="form-control-custom silver-input input-image"
            invalid={!!formik.errors.file && !!formik.touched.file}
            onChange={handleFileChange}
            error={formik.errors.file}
          />
          <Input
            type="text"
            name="title"
            id="title"
            label={t({ id: 'caption' })}
            labelInfo={t({ id: 'caption' })}
            className="form-control-custom silver-input"
            value={formik.values.title}
            invalid={!!formik.errors.title && !!formik.touched.title}
            onChange={formik.handleChange}
            error={formik.errors.title}
          />
          <Input
            type="text"
            name="copyright"
            id="copyright"
            label={t({ id: 'image_copyright' })}
            labelInfo={t({ id: 'image_copyright' })}
            className="form-control-custom silver-input"
            value={formik.values.copyright}
            invalid={!!formik.errors.copyright && !!formik.touched.copyright}
            onChange={formik.handleChange}
            error={formik.errors.copyright}
          />
          <StyledButtons>
            <StyledCancelButton color="red" disabled={loading} onClick={handleCancelUpload}>
              {t({ id: 'private_request_button_back' })}
            </StyledCancelButton>
            <StyledSubmitButton color="black" disabled={loading} onClick={(e)=>{
              e?.preventDefault();
              formik.handleSubmit();
            }}>
              {loading ? t({ id: 'loading' }) : t({ id: 'send' })}
            </StyledSubmitButton>
          </StyledButtons>
        </StyledAddFile>
      ) : (
        <StyledButton
          color="black"
          onClick={() => {
            setIsOpen(true);
            props.setValue(undefined);
          }}
        >
          {t({ id: 'location_foto_upload_button' })}
        </StyledButton>
      )}
    </StyledImageUpload>
  );
};
