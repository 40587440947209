import Alert from '@components/shared/Alert';
import FullScreenLoader from '@components/shared/FullScreenLoader';
import { AxiosError } from 'axios';
import { ReactNode } from 'react';

interface HandleLoadingProps {
  loading?: boolean;
  error?: AxiosError | null;
  children?: ReactNode;
}

export default function HandleFetchingState(props: HandleLoadingProps) {
  if (props.loading) {
    return <FullScreenLoader />;
  }

  if (props.error) {
    return <Alert type="error">{props.error?.message}</Alert>;
  }

  return <>{props.children}</>;
}
