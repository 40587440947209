import { ReactComponent as IconLabel } from '@assets/icons/icon_info.svg';
import { Input as InputRS } from '@components/shared/Input/Input';
import React from 'react';
import { FormFeedback, FormGroup, InputProps, Label } from 'reactstrap';
import styled, { css } from 'styled-components';

interface IStyledFormGroup {
  optional?: boolean;
  disabled?: boolean;
  error?: boolean;
}
const StyledFormGroup = styled(FormGroup)<IStyledFormGroup>`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 32px;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;
    `}
  label {
    background-color: ${(props) => props.theme.colors.white};
    display: flex;
    justify-content: space-between;

    position: relative;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    span {
      width: calc(100% - 38px);
      font-family: 'GTFlexa-ExtendedLight', sans-serif;
      font-size: 16px;
      line-height: 1.25em;
      letter-spacing: 0.2px;
      color: ${(props) => props.theme.colors.black};
      /* ${({ error }) =>
        error &&
        css`
          color: ${(props) => props.theme.colors.red};
        `} */
    }
    &:before {
      content: '';
      display: inline-block;
      width: 16px;
      height: 16px;
      border: 1px solid ${(props) => props.theme.colors.black};
      margin-top: 3px;
    }
    &:after {
      content: '';
      opacity: 0;
      width: 10px;
      height: 10px;
      background-image: url('data:image/svg+xml;base64,Cjxzdmcgd2lkdGg9IjEycHgiIGhlaWdodD0iMTFweCIgdmlld0JveD0iMCAwIDEyIDExIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPGcgaWQ9InNwcmludC0yX2hhbmRvdmVyIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iRHJlaGdlbmVobWlndW5nX1VudGVyc2VpdGUtRm9ybXVsYXItUzA0IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMjk2LjAwMDAwMCwgLTIxMTYuMDAwMDAwKSIgZmlsbD0iIzAwMDAwMCIgZmlsbC1ydWxlPSJub256ZXJvIiBzdHJva2U9IiMwMDAwMDAiIHN0cm9rZS13aWR0aD0iMC41Ij4KICAgICAgICAgICAgPGcgaWQ9InBvcC11cCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTQwLjAwMDAwMCwgNTQyLjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9ImZvcm0tZmllbGQtLy1sYXJnZS0vLWRhdGVuc2NodXR6IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxNTQuMDAwMDAwLCAxNTY5LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgICAgIDxnIGlkPSJHcm91cC0yIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjAwMDAwMCwgMi4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICAgICAgPHBvbHlnb24gaWQ9IlYiIHBvaW50cz0iNi4wMzI1Nzk5NyAxMyAxMi44NzI0MjAzIDMuNSAxMi4xODQyMTk4IDMuNSA1LjY2MjAxMDQ2IDEyLjM3MTM1NTMgNS42MDkwNzE5NiAxMi42NDkyODI0IDUuNTE2NDI5NTggMTIuNjQ5MjgyNCA1LjQ3MDEwODM5IDEyLjM3MTM1NTMgMy44MDkxNjI5MiA4LjM2Nzg4MTE1IDMuMTI3NTc5NzIgOC4zNjc4ODExNSA1LjA5OTUzODg4IDEzIj48L3BvbHlnb24+CiAgICAgICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=');
      position: absolute;
      left: 4px;
      top: 7px;
    }
  }

  input {
    display: none;
    &:disabled {
      ~ label {
        opacity: 0.3;
        cursor: not-allowed;
      }
    }
    &:checked {
      ~ label {
        &:after {
          opacity: 1;
        }
      }
    }
  }
`;

const StyledFormFeedback = styled(FormFeedback)`
  font-family: 'GTFlexa-StandardMedium';
  font-size: 16px;
  letter-spacing: 0.2px;
  color: ${(props) => props.theme.colors.red};
  padding: 10px 24px 0 24px;
`;

const StyledInfoIcon = styled.div`
  width: 18px;
  height: 18px;
  margin-left: 10px;
  cursor: pointer;
`;

interface Props extends InputProps {
  label?: string;
  labelInfo?: string;
  optional?: boolean;
  fileText?: string;
}

export const Checkbox = (props: Props) => {
  const { label, labelInfo, error, optional, fileText, ...rest } = props;

  return (
    <StyledFormGroup optional={optional} error={!!error} disabled={props.disabled} className={`custom-input-checkbox`}>
      <InputRS type="checkbox" {...rest} />
      {label && (
        <Label for={props.id} hidden>
          <span dangerouslySetInnerHTML={{ __html: label }}></span>
          {labelInfo && (
            <StyledInfoIcon>
              <IconLabel />
            </StyledInfoIcon>
          )}
        </Label>
      )}
      {error && <StyledFormFeedback>{error}</StyledFormFeedback>}
    </StyledFormGroup>
  );
};
