import { get, post } from './axios';

export interface UploadImageResponse extends UploadImage {
  error?: string;
}

export interface Caption {
  raw: string;
  rendered: string;
}

export interface UploadImageSize {
  file: string;
  width: number;
  height: number;
  mime_type: string;
  source_url: string;
}

export interface UploadImageSizes {
  medium: UploadImageSize;
  large: UploadImageSize;
  thumbnail: UploadImageSize;
  medium_large: UploadImageSize;
  full: UploadImageSize;
}

export interface ImageMeta {
  aperture: string;
  credit: string;
  camera: string;
  caption: string;
  created_timestamp: string;
  copyright: string;
  focal_length: string;
  iso: string;
  shutter_speed: string;
  title: string;
  orientation: string;
  keywords: any[];
}

export interface UploadImage {
  id: number;
  date: Date;
  date_gmt: Date;
  guid: {
    raw: string;
    rendered: string;
  };
  modified: Date;
  modified_gmt: Date;
  slug: string;
  status: string;
  type: string;
  link: string;
  title: {
    raw: string;
    rendered: string;
  };
  author: number;
  comment_status: string;
  ping_status: string;
  template: string;
  meta: any[];
  permalink_template: string;
  generated_slug: string;
  description: {
    raw: string;
    rendered: string;
  };
  caption: Caption;
  alt_text: string;
  media_type: string;
  mime_type: string;
  media_details: {
    width: number;
    height: number;
    file: string;
    sizes: UploadImageSizes;
    image_meta: ImageMeta;
  };
  post?: any;
  source_url: string;
  missing_image_sizes: any[];
}

export interface ConfirmPostDeleteInput {
  type: string;
  token: string;
}

export const uploadMedia = async (data: FormData) =>
  post<FormData, UploadImageResponse>({
    url: 'wp/v2/media',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const getTranslations = async () =>
  get<undefined, any>({
    url: 'v1/general/translations',
  });

export const confirmPostDelete = async (data: ConfirmPostDeleteInput) =>
  post<ConfirmPostDeleteInput, UploadImageResponse>({
    url: `v1/${data.type}/confirmDelete`,
    data,
  });
