import { useUIContext } from '@lib/context/UIContext/UIContext';
import { AppRoute } from '@routes/Routes';
import React from 'react';
import { Route } from 'react-router-dom';

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export const PublicRoute = (props: PartialBy<AppRoute, 'layout'>) => {
  const uiContext = useUIContext();

  const Layout = props?.layout?.component;

  const hideHeader = props.layout?.hideHeader || uiContext.state.removeNavigation;

  return Layout ? (
    <Layout hideFooter={props?.layout?.hideFooter} hideHeader={hideHeader}>
      <Route {...props} />
    </Layout>
  ) : (
    <React.Fragment>
      <Route {...props} />
    </React.Fragment>
  );
};
