import { ReactComponent as Facebook } from '@assets/icons/icons_facebook.svg';
import { ReactComponent as Instagram } from '@assets/icons/icons_instagram.svg';
import { ReactComponent as Youtube } from '@assets/icons/icons_youtube.svg';
import { ReactComponent as Vimeo } from '@assets/icons/icon_vimeo.svg';
import { Address, FooterLinks, MenuLink, SocialLink, SocialLinksWrapper, TextEmail } from '@components/footer/Footer';
import { Container } from '@components/shared/Container';
import useGetPagesGeneralInfo from '@lib/hooks/queries/useGetPagesGeneralInfo';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled(Container)``;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 25px;
  img {
    width: 130px;
    height: 72px;
    object-fit: contain;
  }
`;

const Row = styled.div`
  margin-bottom: 25px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

interface IMobileFooterProps {
  className?: string;
}

const MobileFooter: FC<IMobileFooterProps> = (props) => {
  const { data } = useGetPagesGeneralInfo();

  const footer = data?.footer;

  const address = data?.footer?.contact?.address;
  const socialMedia = data?.footer?.social;
  const firstMenu = footer?.menu?.first_menu ?? [];
  const secondMenu = footer?.menu?.second_menu ?? [];

  const emailData = footer?.contact?.email?.split('@');
  const footerLogo = footer?.logo;

  return (
    <Wrapper>
      <LogoWrapper>
        <Link to="/">
          <img src={footerLogo} alt="VFC" />
        </Link>
      </LogoWrapper>

      <Row>
        <TextEmail>
          <a href={`mailto:${footer?.contact?.email}`}>
            <span>{emailData[0]}@</span>
            <span>{emailData[1]}</span>
          </a>
        </TextEmail>
        <TextEmail>
          <a href={`tel:${footer?.contact?.phone}`}>{footer?.contact?.phone}</a>
        </TextEmail>
      </Row>

      <Row>
        <Address>{address?.line1}</Address>
        <Address>{address?.line2}</Address>
        <Address>{address?.line3}</Address>
      </Row>

      <Row>
        <FooterLinks>
          <ul>
            {firstMenu.map((item: any, index: number) => {
              return (
                <li key={`${item.url}`}>
                  <MenuLink to={item.url} target={item?.target}>
                    {item.title}
                  </MenuLink>
                </li>
              );
            })}
          </ul>
          <ul>
            {secondMenu.map((item: any, index: number) => {
              return (
                <li key={`${item.url}`}>
                  <MenuLink to={item.url} target={item?.target}>
                    {item.title}
                  </MenuLink>
                </li>
              );
            })}
          </ul>
        </FooterLinks>
      </Row>

      <Row>
        <SocialLinksWrapper>
          <SocialLink href={socialMedia?.vimeo}>
            <Vimeo />
          </SocialLink>
          <SocialLink href={socialMedia?.instagram}>
            <Instagram />
          </SocialLink>
          <SocialLink href={socialMedia?.facebook}>
            <Facebook />
          </SocialLink>
          <SocialLink href={socialMedia?.youtube}>
            <Youtube />
          </SocialLink>
        </SocialLinksWrapper>
      </Row>
    </Wrapper>
  );
};

export default MobileFooter;
