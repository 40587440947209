import { getSearchFilters } from '@api/search';
import Module34 from '@components/modules/Module34';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

interface Props {
  searchValue?: string;
}

const ProductionIndexSearch = (props: Props) => {
  const { formatMessage: t } = useIntl();
  const [filters, setFilters] = useState<any>({});

  useEffect(() => {
    (async () => {
      try {
        const res = await getSearchFilters('productions');
        setFilters(res);
      } catch (e) {}
    })();
  }, []);

  return <Module34 data={{ title: t({ id: 'productions' }), filters }} searchProps={{ searchValue: props.searchValue, showResultsHeader: true }} />;
};

export default ProductionIndexSearch;
