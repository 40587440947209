import placeholderImage from '@assets/icons/branche_placeholder.svg';
import arrowLeftWhite from '@assets/icons/icons_arrow-left-white.svg';
import arrowLeft from '@assets/icons/icons_arrow-left.svg';
import arrowRightWhite from '@assets/icons/icons_arrow-right-white.svg';
import arrowRight from '@assets/icons/icons_arrow-right.svg';
import { ReactComponent as DeleteIcon } from '@assets/icons/icons_close_small.svg';
import { ReactComponent as FullScreenIcon } from '@assets/icons/icons_expand.svg';
import { Module05Button } from '@components/modules/Module05';
import Button, { ButtonColor } from '@components/shared/Button';
import FullscreenSlider from '@components/shared/FullscreenSlider';
import Image from '@components/shared/Image';
import FullscreenShowreels from '@components/showreels/FullscreenShowreels';
import { SimpleButton } from '@components/styled-components/StyledComponents';
import breakpoints from '@lib/common/breakpoints';
import prepareLink from '@lib/helpers/prepareLink';
import truncateString from '@lib/helpers/truncateString';
import { ObjectAny } from '@lib/interfaces';
import React, { useEffect, useRef, useState } from 'react';
import Flickity from 'react-flickity-component';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const ContentSliderWrapper = styled.div<{ backgroundColor?: string; noData?: boolean }>`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: ${(props) => props.backgroundColor ?? props.theme.colors.yellow};
  color: ${(props) => (props.backgroundColor === '#9D446E' ? '#ffffff' : null)};

  .flickity-button {
    display: ${(props) => (props.noData ? 'none' : null)};
  }
`;

const ContentSliderContainer = styled.div`
  flex: 1;
  width: 100%;
  position: relative;
  padding-top: 80px;
  padding-bottom: 140px;

  @media only screen and ${breakpoints.maxWidth.md} {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @media only screen and ${breakpoints.maxWidth.md} {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

const ContentSliderType = styled.p`
  font-family: 'GTFlexa-ExtendedMedium', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

const ContentSliderTitle = styled.h1<{ boldTitle?: boolean }>`
  padding-left: 80px;
  font-weight: 300;
  font-family: ${(props) => (props.boldTitle ? 'GTFlexa-ExtendedMedium' : 'GTFlexa-ExtendedLight')}, sans-serif;
  font-size: 48px;
  line-height: 1.25;
  margin-bottom: ${(props) => (props.boldTitle ? '50px' : null)};

  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 24px;
    padding-left: 40px;
  }

  @media only screen and ${breakpoints.maxWidth.md} {
    padding-left: 20px;
  }
`;

const ContentSliderParagraph = styled.p`
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  font-size: 36px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 0.4px;
  margin-top: 30px;
  max-width: 1160px;

  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 18px;
    line-height: ${(props) => props.theme.helpers.pxToRem(22)};
    letter-spacing: 0.2px;
  }
`;

const StyledFlickity = styled(Flickity)<{ whiteIcons?: boolean }>`
  position: relative;

  a {
    color: unset;
  }

  .flickity-slider.start {
    left: 80px !important;
    @media only screen and ${breakpoints.maxWidth.md} {
      left: 23px !important;
    }
  }

  .flickity-button {
    @media only screen and ${breakpoints.maxWidth.md} {
      display: none !important;
    }
  }

  .previous {
    position: absolute;
    top: unset;
    left: 74px;
    bottom: -60px;

    width: 34px;
    height: 36px;
    transition: 0.1s;

    background-image: ${(props) => (props.whiteIcons ? `url(${arrowLeftWhite})` : `url(${arrowLeft})`)};
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent !important;
    transform: none;

    > svg {
      display: none;
    }

    &:hover {
      transform-origin: center;
      transform: scale(0.8);
    }
  }

  .next {
    position: absolute;
    top: unset;
    left: 140px;
    bottom: -60px;

    width: 34px;
    height: 36px;
    transition: 0.1s;

    background-image: ${(props) => (props.whiteIcons ? `url(${arrowRightWhite})` : `url(${arrowRight})`)};
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent !important;
    transform: none;

    > svg {
      display: none;
    }

    &:hover {
      transform-origin: center;
      transform: scale(0.8);
    }
  }
  margin-top: 28px;
  .slick-list {
    padding-left: 80px;
    @media only screen and ${breakpoints.maxWidth.md} {
      padding-left: 40px;
    }
    @media only screen and ${breakpoints.maxWidth.md} {
      padding-left: 20px;
    }
  }
`;

// const Slider = styled.div<{ whiteIcons?: boolean }>`
//   position: relative;
//
//   a {
//     color: unset;
//   }
//
//   .flickity-slider.start {
//     left: 80px !important;
//     @media only screen and ${breakpoints.maxWidth.md} {
//       left: 23px !important;
//     }
//   }
//
//   .flickity-button {
//     @media only screen and ${breakpoints.maxWidth.md} {
//       display: none !important;
//     }
//   }
//
//   .previous {
//     position: absolute;
//     top: unset;
//     left: 74px;
//     bottom: -60px;
//
//     width: 34px;
//     height: 36px;
//     transition: 0.1s;
//
//     background-image: ${(props) => (props.whiteIcons ? `url(${arrowLeftWhite})` : `url(${arrowLeft})`)};
//     background-repeat: no-repeat;
//     background-size: contain;
//     background-color: transparent !important;
//     transform: none;
//
//     > svg {
//       display: none;
//     }
//
//     &:hover {
//       transform-origin: center;
//       transform: scale(0.8);
//     }
//   }
//
//   .next {
//     position: absolute;
//     top: unset;
//     left: 140px;
//     bottom: -60px;
//
//     width: 34px;
//     height: 36px;
//     transition: 0.1s;
//
//     background-image: ${(props) => (props.whiteIcons ? `url(${arrowRightWhite})` : `url(${arrowRight})`)};
//     background-repeat: no-repeat;
//     background-size: contain;
//     background-color: transparent !important;
//     transform: none;
//
//     > svg {
//       display: none;
//     }
//
//     &:hover {
//       transform-origin: center;
//       transform: scale(0.8);
//     }
//   }
//   margin-top: 28px;
//   .slick-list {
//     padding-left: 80px;
//     @media only screen and ${breakpoints.maxWidth.md} {
//       padding-left: 40px;
//     }
//     @media only screen and ${breakpoints.maxWidth.md} {
//       padding-left: 20px;
//     }
//   }
// `;

const SliderItem = styled.div<{ smallImages?: boolean }>`
  width: 391px !important;
  padding-right: 32px;
  transition: 0.2s;
  &:hover {
    opacity: 0.75;
  }
  ${({ smallImages }) =>
    smallImages &&
    css`
      filter: grayscale(100%);
      &:hover {
        filter: none;
        opacity: 1;
      }
    `}

  @media only screen and ${breakpoints.maxWidth.md} {
    width: 208px !important;
  }

  @media only screen and ${breakpoints.maxWidth.md} {
    width: 163px;
  }
`;

const SliderImageWrapper = styled.div<{ showFullscreenButton?: boolean; smallImages?: boolean }>`
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  ${({ smallImages }) =>
    smallImages &&
    css`
      max-width: 210px;
      height: 180px;
      border: 1px solid rgba(0, 0, 0, 0.25);
      img {
        width: 100%;
        height: 100%;
        min-height: 100%;
        object-fit: contain !important;
      }
    `}
  &:hover {
    img {
      opacity: ${(props) => (props.showFullscreenButton ? '0.5' : 1)};
    }
    .m05-full-screen {
      display: flex;
    }
  }
`;

const SliderPlayer = styled(ReactPlayer)`
  width: 100% !important;
  height: 270px !important;
  object-fit: cover;
  transition: 0.2s;

  @media only screen and ${breakpoints.maxWidth.md} {
    height: 144px !important;
  }
  @media only screen and ${breakpoints.maxWidth.xs} {
    height: 113px !important;
  }

  > div {
    width: 100% !important;
    height: 100% !important;
    html {
      height: 100%;
    }
    video {
      top: 0 !important;
      width: 100% !important;
      height: 100% !important;
      object-fit: cover !important;
    }
  }
`;

const SliderImage = styled(Image)`
  width: 100%;
  min-height: 270px;
  height: 270px;
  object-fit: cover;
  transition: 0.2s;
  @media only screen and ${breakpoints.maxWidth.md} {
    height: 144px;
    min-height: 144px;
  }
  @media only screen and ${breakpoints.maxWidth.xs} {
    height: 113px;
    min-height: 113px;
  }
`;

const FullScreenButton = styled.div`
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  align-items: center;
  justify-content: center;
  left: 50%;
  top: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);

  button {
    width: 40px;
    height: 40px;
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    button {
      width: 30px;
      height: 30px;
    }
  }
`;

const SliderContent = styled.div`
  margin-top: 24px;
`;

const SliderTitle = styled.h2`
  font-family: 'GTFlexa-ExtendedMedium', sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  margin-bottom: 8px;
  @media only screen and ${breakpoints.maxWidth.md} {
    line-height: 1.25;
    font-size: 16px;
    max-width: 80%;
  }
`;

const SliderDescription = styled.p`
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  font-size: 16px;
  line-height: 1.25;
  font-weight: 300;

  max-height: 60px;
  overflow: hidden;
  @media only screen and ${breakpoints.maxWidth.md} {
    line-height: 1.14;
    letter-spacing: 0.15px;
    font-size: 14px;
  }
`;

const AllContentWrapper = styled.div`
  width: 100%;
  //margin-top: 65px;
  font-size: 49px;
  text-align: center;
`;

const AllContentLink = styled(Button)`
  border: 2px solid #000000;
  border-right: 0;
  border-left: 0;
  width: 100%;

  &:hover {
    border: 2px solid #000000;
  }

  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 22px;
    line-height: 1.36;
    padding: 10px;
  }
`;

// const FloatingArrowLeft = styled(ArrowLeftIcon)<{ isEnabled?: boolean; iswhite?: string }>`
//   position: absolute;
//   width: 34px;
//   top: 120px;
//   left: 100px;
//   cursor: pointer;
//   opacity: ${(props) => (props.isEnabled ? 1 : 0.5)};
//   z-index: 9;
//   path {
//     stroke: ${(props) => (props.iswhite ? '#ffffff' : null)};
//   }
//
//   @media only screen and ${breakpoints.maxWidth.md} {
//     width: 30px;
//     top: 60px;
//     left: 50px;
//   }
//
//   @media only screen and ${breakpoints.maxWidth.md} {
//     top: 40px;
//   }
// `;
//
// const FloatingArrowRight = styled(ArrowRightIcon)<{ isEnabled?: boolean; iswhite?: string }>`
//   position: absolute;
//   width: 34px;
//   top: 120px;
//   right: 100px;
//   z-index: 9;
//   cursor: pointer;
//   opacity: ${(props) => (props.isEnabled ? 1 : 0.5)};
//
//   path {
//     stroke: ${(props) => (props.iswhite ? '#ffffff' : null)};
//   }
//
//   @media only screen and ${breakpoints.maxWidth.md} {
//     width: 30px;
//     top: 60px;
//     right: 50px;
//   }
//
//   @media only screen and ${breakpoints.maxWidth.md} {
//     top: 40px;
//   }
// `;

const FlickityWrapper = styled.div`
  position: relative;
  &:before {
    content: '';
    width: 120px;
    height: 100%;
    display: block;
    position: absolute;
    z-index: 10;

    display: none;
    @media only screen and ${breakpoints.maxWidth.sm} {
      display: none;
    }
  }
`;

const DeleteButton = styled(SimpleButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 48px;
  height: 48px;

  @media only screen and ${breakpoints.maxWidth.md} {
    width: 30px;
    height: 30px;
  }

  svg {
    width: 30px;
    height: 30px;

    @media only screen and ${breakpoints.maxWidth.md} {
      width: 20px;
      height: 20px;
    }
    path {
      stroke: #000;
    }
  }
`;

interface ButtonData extends Module05Button {}

interface ContentSliderProps {
  pre_title?: string;
  title?: string;
  description?: string;
  posts?: Array<object>;

  autoPlay?: boolean;
  infinite?: boolean;
  buttonColor?: ButtonColor;
  allContentLink?: string;
  button?: ButtonData;
  hideAllButton?: boolean;
  whiteIcons?: boolean;
  fullscreen?: boolean;
  backgroundColor?: string;
  showDeleteIcon?: boolean;
  smallImages?: boolean;

  onDeleteItem?: (post: any) => void;
}

export default function ContentSlider(props: ContentSliderProps) {
  const posts = props.posts ?? [];

  const flickityRef = useRef<any>(null);
  const [showFullscreen, setShowFullscreen] = useState(false);
  const [activeItem, setActiveItem] = useState<ObjectAny | undefined>(undefined);
  const [playing, setPlaying] = useState<number | undefined>(undefined);

  // const [floatingButtons, setFloatingButtons] = useState({
  //   prev: false,
  //   next: false,
  // });

  const startPlaying = (id: number) => {
    setPlaying(id);
  };

  const stopPlaying = () => {
    setPlaying(undefined);
  };

  // const SLIDER_ID = useMemo(() => `slider-${Date.now()}`, []);

  useEffect(() => {
    if (!flickityRef.current || !flickityRef.current.element) {
      return;
    }

    const element = flickityRef.current.element;
    const target = element.querySelector('.flickity-slider');

    flickityRef.current.on('ready', function () {
      (target as any).classList.add('start');

      // setFloatingButtons({
      //   next: flickityRef.current?.nextButton?.isEnabled,
      //   prev: flickityRef.current?.prevButton?.isEnabled,
      // });
    });

    flickityRef.current.on('change', function (index: any) {
      if (!target) return;

      if (index > 0) {
        (target as any).classList.remove('start');
      } else {
        (target as any).classList.add('start');
      }
      // setFloatingButtons({
      //   next: flickityRef.current?.nextButton?.isEnabled,
      //   prev: flickityRef.current?.prevButton?.isEnabled,
      // });
    });

    // const Flickity = (window as any).Flickity;
    // const elem = document.querySelector(`[data-sliderid=${SLIDER_ID}]`);
    //
    // if (!elem) return;
    //
    // const flkty = new Flickity(elem, {
    //   cellAlign: 'left',
    //   contain: true,
    //   groupCells: true,
    //   pageDots: false,
    //   draggable: true,
    //   on: {
    //     ready: function () {
    //       const target = document.querySelector(`[data-sliderid=${SLIDER_ID}] .flickity-slider`);
    //       (target as any).classList.add('start');
    //     },
    //   },
    // });
    //
    // flkty.on('change', function (index: any) {
    //   const target = document.querySelector(`[data-sliderid=${SLIDER_ID}] .flickity-slider`);
    //   if (!target) return;
    //   if (index > 0) {
    //     (target as any).classList.remove('start');
    //   } else {
    //     (target as any).classList.add('start');
    //   }
    // });
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const handleOnNext = () => {
    const currentIndex = posts?.findIndex((x: any) => x.ID === activeItem?.ID);
    if (currentIndex === -1) {
      return;
    }
    const nextItem = posts.find((x: any, index) => index === currentIndex + 1);
    if (!nextItem) {
      setActiveItem(undefined);
      setShowFullscreen(false);
      return;
    }
    setActiveItem(nextItem);
  };

  const handleOnPrev = () => {
    const currentIndex = posts?.findIndex((x: any) => x.ID === activeItem?.ID);
    if (currentIndex === -1) {
      return;
    }
    const nextItem = posts.find((x: any, index) => index === currentIndex - 1);

    if (!nextItem) {
      setActiveItem(undefined);
      setShowFullscreen(false);
      return;
    }

    setActiveItem(nextItem);
  };

  const handleOnFullScreenClick = (item: ObjectAny) => {
    setActiveItem({ ...item, id: item.ID } as any);
    setShowFullscreen(true);
  };

  const isShowreel = (props.posts?.[0] as any)?.['post_type'] === 'showreels';
  const showFullscreenButton = props.fullscreen || isShowreel;

  return (
    <ContentSliderWrapper backgroundColor={props.backgroundColor} noData={posts?.length === 0}>
      {showFullscreen && (
        <>
          {isShowreel ? (
            <FullscreenShowreels
              controls={true}
              items={posts?.map((item: any) => ({ ...item, id: item.ID })) as any}
              activeItem={activeItem ? ({ ...activeItem, id: activeItem.ID } as any) : undefined}
              onClose={() => setShowFullscreen(false)}
              onNext={handleOnNext}
              onPrev={handleOnPrev}
            />
          ) : (
            <FullscreenSlider
              items={posts}
              activeItem={activeItem}
              onNext={handleOnNext}
              onPrev={handleOnPrev}
              onClose={() => setShowFullscreen(false)}
            />
          )}
        </>
      )}

      <ContentSliderContainer>
        <ContentSliderTitle boldTitle={Boolean(props.pre_title || props.description)}>
          {props.pre_title && <ContentSliderType>{props.pre_title}</ContentSliderType>}
          {props.title}
          {props.description && <ContentSliderParagraph>{props.description}</ContentSliderParagraph>}
        </ContentSliderTitle>

        <FlickityWrapper>
          {/*{posts && Array.isArray(posts) && posts.length ? (*/}
          {/*  <>*/}
          {/*    <FloatingArrowLeft*/}
          {/*      isEnabled={floatingButtons.prev}*/}
          {/*      iswhite={props.whiteIcons ? 'white' : undefined}*/}
          {/*      onClick={() => {*/}
          {/*        flickityRef?.current?.previous();*/}
          {/*      }}*/}
          {/*    />*/}
          {/*    <FloatingArrowRight*/}
          {/*      isEnabled={floatingButtons.next}*/}
          {/*      iswhite={props.whiteIcons ? 'white' : undefined}*/}
          {/*      onClick={() => {*/}
          {/*        flickityRef?.current?.next();*/}
          {/*      }}*/}
          {/*    />*/}
          {/*  </>*/}
          {/*) : null}*/}

          <StyledFlickity
            flickityRef={(ref) => {
              flickityRef.current = ref;
            }}
            whiteIcons={props.whiteIcons}
            // reloadOnUpdate={true} // default false
            options={{
              cellSelector: '.content-slider-item',
              cellAlign: 'left',
              contain: true,
              groupCells: true,
              pageDots: false,
              draggable: true,
            }}
          >
            {posts &&
              Array.isArray(posts) &&
              posts.map((post: any, index: number) => {
                return (
                  <Link
                    className="content-slider-item"
                    key={`gallery-el-${post?.ID}-${index}`}
                    to={prepareLink(post?.link)}
                    onClick={(e) => {
                      if (post.post_type === 'locations' && post.link && post?.link?.startsWith('http')) {
                        e.preventDefault();
                        window?.open(post.link, '_blank')?.focus();
                        return;
                      } else if (post.post_type === 'showreels' || props.fullscreen) {
                        e.preventDefault();
                        return;
                      }
                    }}
                  >
                    <SliderItem smallImages={props.smallImages}>
                      <SliderImageWrapper showFullscreenButton={showFullscreenButton}>
                        {props.showDeleteIcon && (
                          <DeleteButton
                            onClick={(e) => {
                              e.preventDefault();
                              props.onDeleteItem?.(post);
                            }}
                          >
                            <DeleteIcon className={props.whiteIcons ? 'delete-icon-white' : ''} />
                          </DeleteButton>
                        )}
                        <SliderImageWrapper smallImages={props.smallImages} showFullscreenButton={showFullscreenButton}>
                          {isShowreel ? (
                            <SliderPlayer
                              muted
                              playing={playing === post.ID}
                              url={post.video_id}
                              onMouseEnter={() => startPlaying(post.ID)}
                              onMouseLeave={stopPlaying}
                            />
                          ) : (
                            <SliderImage
                              src={post?.thumbnail ?? placeholderImage}
                              style={{ objectFit: post?.thumbnail ? 'cover' : 'contain' }}
                              alt={post?.post_title}
                            />
                          )}
                          {showFullscreenButton && (
                            <FullScreenButton className="m05-full-screen" onClick={() => handleOnFullScreenClick(post)}>
                              <SimpleButton>
                                <FullScreenIcon />
                              </SimpleButton>
                            </FullScreenButton>
                          )}
                        </SliderImageWrapper>
                      </SliderImageWrapper>
                      <SliderContent
                        onClick={() => {
                          if (isShowreel) {
                            handleOnFullScreenClick(post);
                          }
                        }}
                      >
                        <SliderTitle>{post?.post_title || post?.title}</SliderTitle>
                        <SliderDescription dangerouslySetInnerHTML={{ __html: truncateString(post?.description, 90) }} />
                      </SliderContent>
                    </SliderItem>
                  </Link>
                );
              })}
          </StyledFlickity>
        </FlickityWrapper>

        {/*<Slider data-sliderid={SLIDER_ID} whiteIcons={props.whiteIcons}>*/}
        {/*  {posts &&*/}
        {/*    Array.isArray(posts) &&*/}
        {/*    posts.map((post: any, index: number) => {*/}
        {/*      return (*/}
        {/*        <Link*/}
        {/*          key={`gallery-el-${post?.ID}-${index}`}*/}
        {/*          to={post?.link}*/}
        {/*          onClick={(e) => {*/}
        {/*            if (post.post_type === 'showreels' || props.fullscreen) {*/}
        {/*              e.preventDefault();*/}
        {/*              return;*/}
        {/*            }*/}
        {/*          }}*/}
        {/*        >*/}
        {/*          <SliderItem>*/}
        {/*            <SliderImageWrapper showFullscreenButton={showFullscreenButton}>*/}
        {/*              <SliderImageWrapper showFullscreenButton={showFullscreenButton}>*/}
        {/*                {isShowreel ? (*/}
        {/*                  <SliderPlayer*/}
        {/*                    muted*/}
        {/*                    playing={playing === post.ID}*/}
        {/*                    url={post.video_id}*/}
        {/*                    onMouseEnter={() => startPlaying(post.ID)}*/}
        {/*                    onMouseLeave={stopPlaying}*/}
        {/*                  />*/}
        {/*                ) : (*/}
        {/*                  <SliderImage src={post?.thumbnail} alt={post?.post_title} />*/}
        {/*                )}*/}
        {/*                {showFullscreenButton && (*/}
        {/*                  <FullScreenButton className="m05-full-screen" onClick={() => handleOnFullScreenClick(post)}>*/}
        {/*                    <SimpleButton>*/}
        {/*                      <FullScreenIcon />*/}
        {/*                    </SimpleButton>*/}
        {/*                  </FullScreenButton>*/}
        {/*                )}*/}
        {/*              </SliderImageWrapper>*/}
        {/*            </SliderImageWrapper>*/}
        {/*            <SliderContent*/}
        {/*              onClick={() => {*/}
        {/*                if (isShowreel) {*/}
        {/*                  handleOnFullScreenClick(post);*/}
        {/*                }*/}
        {/*              }}*/}
        {/*            >*/}
        {/*              <SliderTitle>{post?.post_title || post?.title}</SliderTitle>*/}
        {/*              <SliderDescription dangerouslySetInnerHTML={{ __html: truncateString(post?.description, 140) }} />*/}
        {/*            </SliderContent>*/}
        {/*          </SliderItem>*/}
        {/*        </Link>*/}
        {/*      );*/}
        {/*    })}*/}
        {/*</Slider>*/}
      </ContentSliderContainer>

      {!props.hideAllButton && (
        <AllContentWrapper>
          <AllContentLink color={props.buttonColor} to={props.button?.url ?? ''}>
            {props?.button?.title}
          </AllContentLink>
        </AllContentWrapper>
      )}
    </ContentSliderWrapper>
  );
}
