import { LocationItem } from '@api/locations';
import { ReactComponent as BookmarkIcon } from '@assets/icons/icons_bookmark_small.svg';
import HyphenText from '@components/shared/HyphenText';
import Image from '@components/shared/Image';
import breakpoints from '@lib/common/breakpoints';
import { useLocationBookmarksContext } from '@lib/context/LocationBookmarksContext/LocationBookmarksContext';
import prepareLink from '@lib/helpers/prepareLink';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
  &:hover {
    opacity: 0.7;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 270px;
  margin-bottom: 30px;
  cursor: pointer;

  @media only screen and ${breakpoints.maxWidth.md} {
    height: 226px;
  }
  @media only screen and ${breakpoints.maxWidth.xs} {
    height: 116px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  h3 {
    hyphens: auto;
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #000;
    margin-right: 24px;
    flex: 1;
    cursor: pointer;

    @media only screen and ${breakpoints.maxWidth.xs} {
      font-size: 16px;
      line-height: 1.15;
    }
  }
`;

const StyledBookmarkIcon = styled(BookmarkIcon)<{ isfilled?: string }>`
  min-width: 31px;
  width: 31px;
  cursor: pointer;
  top: 5px;
  position: relative;
  &:hover {
    opacity: 1;
    path {
      stroke: ${(props) => props.theme.colors.red};
    }
  }
  path {
    fill: ${(props) => (props.isfilled === 'true' ? '#000000' : null)};
  }
`;

interface ILocationListItemProps {
  location: LocationItem;
}

const LocationListItem: FC<ILocationListItemProps> = (props) => {
  const { location } = props;

  const { addBookmark, removeBookmark } = useLocationBookmarksContext();

  const handleOnBookmarkClick = (e: any) => {
    e.preventDefault();
    if (!location.bookmarked) {
      addBookmark(location.slug);
      return;
    }

    removeBookmark(location.slug);
  };

  return (
    <Wrapper>
      <Link to={prepareLink(location.link)}>
        <ImageWrapper>
          <Image src={location.thumbnail} />
        </ImageWrapper>
        <TitleWrapper>
          <HyphenText>
            <h3 dangerouslySetInnerHTML={{ __html: location.title }} />
          </HyphenText>
          {location.public_private !== 'public' ? (
            <StyledBookmarkIcon isfilled={location.bookmarked?.toString()} onClick={handleOnBookmarkClick} />
          ) : null}
        </TitleWrapper>
      </Link>
    </Wrapper>
  );
};

export default LocationListItem;
