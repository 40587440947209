import { ReactComponent as IconDownload } from '@assets/icons/icons_download.svg';
import breakpoints from '@lib/common/breakpoints';
import { downloadFileFromUrl } from '@lib/helpers/downloadFileFromUrl';
import { FC } from 'react';
import styled from 'styled-components';
import Image from './Image';

const Wrapper = styled.a`
  cursor: pointer;

  > span {
    width: 100%;
    height: 100%;
    display: block;
  }

  &:hover span {
    opacity: 0.75;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 270px;
  margin-bottom: 20px;
  overflow: hidden;

  @media only screen and ${breakpoints.maxWidth.md} {
    height: 226px;
  }
  @media only screen and ${breakpoints.maxWidth.xs} {
    height: 116px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform ease-in-out 0.5s;
    transform-origin: center center;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;

  h3 {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    text-transform: inherit;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #000;
    /* word-break: break-all; */
    width: calc(100% - 32px);
    padding-right: 15px;

    @media only screen and ${breakpoints.maxWidth.xs} {
      font-size: 16px;
      line-height: 1.15;
    }
  }
  svg {
    width: 32px;
    height: 32px;
  }
`;
interface IDownloadListItemProps {
  title: string;
  image: {
    ID: number;
    title: string;
    filename: string;
    filesize: number;
    url: string;
    caption: string;
    description: string;
    name: string;
  };
  file: {
    ID: number;
    title: string;
    filename: string;
    url: string;
    description: string;
    filesize: number;
    caption: string;
    name: string;
    mime_type: string;
  };
}

const DownloadListItem: FC<IDownloadListItemProps> = (props) => {
  const handleClick = () => downloadFileFromUrl(props.file.url, props.file.filename);
  return (
    <Wrapper onClick={handleClick}>
      <span>
        <ImageWrapper>
          <Image src={props.image.url} />
        </ImageWrapper>
        <TitleWrapper>
          <h3>{props.title}</h3>
          <IconDownload />
        </TitleWrapper>
      </span>
    </Wrapper>
  );
};

export default DownloadListItem;
