import { ReactComponent as ArrowDown } from '@assets/icons/icons_arrow-down.svg';
import { Container } from '@components/shared/Container';
import Image from '@components/shared/Image';
import RenderWordPressContent from '@components/shared/RenderWordPressContent';
import breakpoints from '@lib/common/breakpoints';
import ModuleType from '@lib/interfaces/Modules';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<{ isWhite?: boolean }>`
  background-color: ${(props) => (props.isWhite ? props.theme.colors.white : props.theme.colors.yellow)};
  padding-top: 90px;
  padding-bottom: 106px;

  @media only screen and ${breakpoints.maxWidth.sm} {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    padding-top: 30px;
    padding-bottom: 30px;
  }
`;

const Title = styled.h1`
  max-width: 888px;
  font-family: 'GTFlexa-ExtendedMedium', sans-serif;
  font-size: 58px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.03;
  letter-spacing: normal;
  color: #000;

  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 30px;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    font-size: 20px;
  }
`;

const AccordionsWrapper = styled.div`
  margin-top: 68px;
  @media only screen and ${breakpoints.maxWidth.md} {
    margin-top: 40px;
  }
`;

const List = styled.ul`
  margin: 0;
  padding: 0;

  @media only screen and ${breakpoints.maxWidth.md} {
    margin-bottom: 70px;
  }
`;

const ListItem = styled.li<{ isExpanded?: boolean }>`
  list-style: none;
  background-color: ${(props) => (props.isExpanded ? props.theme.colors.white : null)};
  border-bottom: 2px solid #000000;
  //border-top: 2px solid ${(props) => (props.isExpanded ? props.theme.colors.black : 'transparent')};
`;

const ListItemContent = styled.div`
  padding-top: 40px;
  padding-bottom: 80px;
  background-color: #ffffff;
`;

const ContentWrapper = styled.div`
  display: flex;

  @media only screen and ${breakpoints.maxWidth.md} {
    flex-direction: column-reverse;
  }
`;

const ContentLeft = styled.div`
  flex: 1;
  padding-right: 124px;

  @media only screen and ${breakpoints.maxWidth.md} {
    margin-bottom: 20px;
    padding-right: 0;
  }
`;

const StyledRenderWordPressContent = styled(RenderWordPressContent)`
  * {
    font-family: GTFlexa-ExtendedLight, sans-serif;
    margin-bottom: 0 !important;
  }

  h1,
  h2,
  h3,
  h4 {
    //font-family: 'GTFlexa-ExtendedLight', sans-serif;
    //font-size: 36px;
    //font-weight: normal;
    //font-stretch: normal;
    //font-style: normal;
    //line-height: 1.17;
    //letter-spacing: 0.4px;
    //color: #000;
    //margin-bottom: 30px;

    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 22px;
    }

    @media only screen and ${breakpoints.maxWidth.xs} {
      font-size: 18px;
    }
  }

  h4 {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-weight: normal;

    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 22px;
    }

    @media only screen and ${breakpoints.maxWidth.xs} {
      font-size: 18px;
    }
  }

  p {
    font-size: 18px;
  }
`;

const LinkButton = styled.a`
  padding: 9px 103px;
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: #000;
  border: solid 1.5px #000;
  display: inline-block;
  margin-top: 40px;
  text-align: center;
  text-transform: uppercase;

  &:hover {
    background-color: #000000;
    color: #ffffff;
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 18px;
    padding: 9px 50px;
  }
`;

const ContentRight = styled.div`
  flex-basis: 300px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  img {
    max-width: 100%;
    object-fit: contain;
  }

  @media only screen and ${breakpoints.maxWidth.md} {
    flex-basis: unset;
    margin-bottom: 50px;
  }
`;

const TitleWrapper = styled.div<{ isExpanded?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    h1 {
      color: ${(props) => props.theme.colors.red};
    }

    svg path {
      stroke: ${(props) => props.theme.colors.red};
    }
  }

  h1 {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 36px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.4px;
    padding: 30px 60px 30px 0;
    color: ${(props) => (props.isExpanded ? props.theme.colors.red : props.theme.colors.black)};

    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 20px;
    }

    @media only screen and ${breakpoints.maxWidth.xs} {
      font-size: 16px;
    }
  }

  svg {
    transition: 0.2s;
    margin-left: 10px;
    min-width: 31px;
    height: 31px;

    @media only screen and ${breakpoints.maxWidth.sm} {
      min-width: 21px;
      height: 21px;
    }

    path {
      stroke: ${(props) => (props.isExpanded ? props.theme.colors.red : props.theme.colors.black)};
    }
  }
`;

interface IModule21Props {
  className?: string;
  data?: {
    white_background?: boolean;
    title?: string;
    agencies: any[];
  };
}

const Module21: FC<IModule21Props> = (props) => {
  const { data } = props;
  const [expanded, setExpanded] = useState<number[]>([]);

  const handleOnClick = (id: number) => {
    if (expanded.includes(id)) {
      setExpanded(expanded.filter((x) => x !== id));
      return;
    }

    setExpanded([...expanded, id]);
    return;
  };

  const agencies = data?.agencies ?? [];
  return (
    <Wrapper isWhite={data?.white_background} className={props.className} data-module={ModuleType.MODULE_21}>
      <Container>
        <Title>{data?.title}</Title>
      </Container>
      <AccordionsWrapper>
        <List>
          {agencies
            .map((x, i) => ({ ...x, id: i + 1 }))
            .map((item: any) => {
              const isExpanded = expanded.includes(item.id);
              return (
                <ListItem key={item.id} isExpanded={isExpanded}>
                  <Container>
                    <TitleWrapper onClick={() => handleOnClick(item.id)} isExpanded={isExpanded}>
                      <h1>{item.title}</h1>
                      {isExpanded ? <ArrowDown style={{ transform: 'rotate(180deg)' }} /> : <ArrowDown />}
                    </TitleWrapper>
                  </Container>

                  {isExpanded && (
                    <ListItemContent>
                      <Container>
                        <ContentWrapper>
                          <ContentLeft>
                            <StyledRenderWordPressContent content={item?.description} />
                            <div>
                              <LinkButton href={item.button?.url} target={item?.button?.target ?? '_blank'}>
                                {item?.button?.title}
                              </LinkButton>
                            </div>
                          </ContentLeft>
                          <ContentRight>
                            <Image src={item?.image?.url} />
                          </ContentRight>
                        </ContentWrapper>
                      </Container>
                    </ListItemContent>
                  )}
                </ListItem>
              );
            })}
        </List>
      </AccordionsWrapper>
    </Wrapper>
  );
};

export default Module21;
