import { ReactComponent as DownloadIcon } from '@assets/icons/icons_download.svg';
import { ReactComponent as FullScreenIcon } from '@assets/icons/icons_expand.svg';
import breakpoints from '@lib/common/breakpoints';
import { downloadFileFromUrl } from '@lib/helpers/downloadFileFromUrl';
import { FC, useState } from 'react';
import styled from 'styled-components';
import FullscreenPDF from './FullscreenPDF';

const Wrapper = styled.a`
  cursor: pointer;

  // &:hover {
  //   span,
  //   p,
  //   h6,
  //   h2 {
  //     color: #e20714;
  //   }
  //   svg g {
  //     stroke: ${(props) => props.theme.colors.red};
  //   }
  //
  //   img {
  //     transform: scale(1.1);
  //   }
  // }

  &:hover {
    opacity: 0.75;

    figcaption {
      opacity: 1;
    }

    //
    // span,
    // p,
    // h6,
    // h2 {
    //   color: #e20714;
    // }
    // svg g {
    //   stroke: ${(props) => props.theme.colors.red};
    // }
    //
    // img {
    //   transform: scale(1.1);
    // }
  }
`;

const TitleContentWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: column;

  &:hover {
    opacity: 0.75;
  }

  h6 {
    font-family: 'GTFlexa-Medium', sans-serif;
    font-size: 16px;
    margin-bottom: 10px;
    color: #000;
    flex: 1;
    opacity: 0.5;
    text-transform: uppercase;
  }

  h2 {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    text-transform: inherit;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #000;
    word-break: break-word;
    flex: 1;
    margin-bottom: 16px;

    @media only screen and ${breakpoints.maxWidth.xs} {
      font-size: 16px;
      line-height: 1.15;
    }
  }

  p {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    text-transform: inherit;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.2px;
    color: #000;
    flex: 1;
    margin-bottom: 8px;

    @media only screen and ${breakpoints.maxWidth.xs} {
      font-size: 14px;
      line-height: 1.15;
    }
  }

  span {
    display: block;
    font-family: 'GTFlexa-StandardLight', sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    text-transform: inherit;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.2px;
    color: #000;
    flex: 1;

    @media only screen and ${breakpoints.maxWidth.xs} {
      font-size: 13px;
      line-height: 1.15;
    }
  }
`;

const HoverFigure = styled.figure`
  position: relative;
  width: 220px;
  height: 294px;
  padding: 0;
  margin: 0;
  overflow: hidden;
  margin-bottom: 20px;

  @media only screen and ${breakpoints.maxWidth.md} {
    width: 100%;
    height: 294px;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    width: 100%;
    height: 294px;
  }

  img {
    object-fit: cover;
    object-position: center center;
    width: 100%;
    height: 100%;
    transition: all ease-in-out 0.3s;
    transform-position: center center;
  }

  figcaption {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    //background-color: rgb(255 61 61 / 75%);
    opacity: 0;
    transition: all ease-in-out 0.3s;

    svg {
      width: 32px;
      height: 32px;
    }
  }

  &:hover {
    figcaption {
      opacity: 1;
    }
  }
`;

interface IAnualReportListItemProps {
  report: IAnualReport;
  file: any;
}

export interface IAnualReport {
  ID: number;
  link: string;
  post_author: string;
  post_title: string;
  post_content: string;
  post_name: string;
  post_type: string;
  post_date: string;
  thumbnail: string;
  external_link: string;
  thumbnails: {
    thumbnail: string;
    medium: string;
    medium_large: string;
    large: string;
  };
  pdf: {
    ID: number;
    id: number;
    title: string;
    filename: string;
    filesize: number;
    url: string;
    link: string;
    alt: string;
    author: string;
    description: string;
    caption: string;
    name: string;
    status: string;
    uploaded_to: number;
    date: string;
    modified: string;
    menu_order: number;
    mime_type: string;
    type: string;
    subtype: string;
    icon: string;
  };
}

const AnualReportListItem: FC<IAnualReportListItemProps> = (props) => {
  const [showFullscreen, setShowFullscreen] = useState(false);

  function handleExternalLink(external_link: string | null) {
    console.log("external"+external_link); 
   if (external_link!==null) {
      window.open(props?.report?.external_link, '_blank');
    }else{
      setShowFullscreen(true)
    }
  }

  const handleDownload = () => downloadFileFromUrl(props.report.pdf.url, props.report.pdf.filename);

  return (
    <>
      <Wrapper>
        <TitleContentWrapper>
          <HoverFigure onClick={() => handleExternalLink(props?.report?.external_link)}>
            <img src={props?.report?.thumbnails?.medium || props?.report?.thumbnail} alt={props.report.post_title} />
            <figcaption>
              <FullScreenIcon />
            </figcaption>
          </HoverFigure>
          <div onClick={handleDownload}>
            <h2>{props.report.post_title}</h2>
            <DownloadIcon />
          </div>
        </TitleContentWrapper>
      </Wrapper>
      {showFullscreen && <FullscreenPDF pdf={props.file} title={props.report.post_title} onClose={() => setShowFullscreen(false)} />}
    </>
  );
};

export default AnualReportListItem;
