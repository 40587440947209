import { GetLocationResponse, getLocationsDetails } from '@api/locations';
import QueryKeys from '@lib/constants/QueryKeys';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

export default function useGetLocationsDetails(slug?: string) {
  return useQuery<GetLocationResponse, AxiosError>(QueryKeys.locationsDetails(slug), () => getLocationsDetails(slug), {
    enabled: Boolean(slug),
  });
}
