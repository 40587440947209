import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';

export interface RequestLocationsFields {
  locations: {
    id: number;
    title: string;
    image: string;
  }[];
  company_name: string;
  type_of_production: number;
  production_title: string;
  name: string;
  phone: string;
  email: string;
  email_confirmation: string;
  comment: string;
  data_protection: boolean;
  terms: boolean;
}

interface UseRequestLocationsFormOptions {
  initialValues?: RequestLocationsFields;
  onSubmit: (values: RequestLocationsFields, formikHelpers: FormikHelpers<RequestLocationsFields>) => Promise<any>;
}

export const useRequestLocationsFormik = (options: UseRequestLocationsFormOptions) => {
  const RequestLocationsSchema = Yup.object().shape({
    company_name: Yup.string().required('Produzent / Produktionsfirma is required'),
    type_of_production: Yup.number().required('Projektart is required'),
    production_title: Yup.string().required('Titel der Produktion is required'),
    name: Yup.string().required('Name is required'),
    phone: Yup.string().required('Telefon is required'),
    email: Yup.string().email('').required('Email-Adresse is required'),
    email_confirmation: Yup.string().email('').required('Email-Adresse wiedeholen is required'),
    data_protection: Yup.bool()
      .oneOf([true], 'Hiermit bestätige ich die Nutzungsbedingungen is required')
      .required('Hiermit bestätige ich die Nutzungsbedingungen is required'),
    terms: Yup.bool()
      .oneOf([true], 'Ich habe die Datenschutzbedingungen gelesen und akzeptiert is required')
      .required('Ich habe die Datenschutzbedingungen gelesen und akzeptiert is required'),
  });

  return useFormik({
    initialValues: {
      locations: [] as {
        id: number;
        title: string;
        image: string;
      }[],
      company_name: '',
      type_of_production: 0,
      production_title: '',
      name: '',
      phone: '',
      email: '',
      email_confirmation: '',
      comment: '',
      data_protection: false,
      terms: false,
      ...options.initialValues,
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: RequestLocationsSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type RequestLocationsFormik = ReturnType<typeof useRequestLocationsFormik>;
