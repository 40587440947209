import { News } from '@api/news';
import { ReactComponent as ArrowDown } from '@assets/icons/icons_arrow-down.svg';
import { ReactComponent as ArrowUp } from '@assets/icons/icons_arrow-up.svg';
import BranchesList from '@components/branches/BranchesList';
import LocationsList from '@components/locations/LocationsList';
import SearchResultsHeader from '@components/search/SearchResultsHeader';
import { Container } from '@components/shared/Container';
import LoadMoreButton from '@components/shared/LoadMoreButton';
import NewsListItem from '@components/shared/NewsListItem';
import NoResults from '@components/shared/NoResults';
import ProductionListItem from '@components/shared/ProductionListItem';
import { StyledShowAll } from '@components/user/Profile/MyLocations';
import breakpoints from '@lib/common/breakpoints';
import { useLocationBookmarksContext } from '@lib/context/LocationBookmarksContext/LocationBookmarksContext';
import useGetAllSearchResults from '@lib/hooks/queries/useGetAllSearchResults';
import React, { FC, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Col, Row } from 'react-styled-flexboxgrid';
import styled, { ThemeProvider } from 'styled-components';

const Wrapper = styled(Container)`
  padding-top: 120px;

  .search-row {
    //border: 1px solid red;
    //margin-bottom: 30px;
    margin-left: -27px;
    margin-right: -27px;

    @media only screen and ${breakpoints.maxWidth.md} {
      margin-left: -16px;
      margin-right: -16px;
    }

    @media only screen and ${breakpoints.maxWidth.xs} {
      margin-left: -5px;
      margin-right: -5px;
    }
  }

  .search-show-all-button {
    margin-bottom: 60px;
  }

  .line {
    margin-bottom: 60px;
    padding: 0 27px;

    @media only screen and ${breakpoints.maxWidth.md} {
      padding: 0 16px;
    }

    @media only screen and ${breakpoints.maxWidth.xs} {
      padding: 0 5px;
    }

    hr {
      border-width: 1px;
      border-color: #000000;
    }
  }
`;

const NewsStyledCol = styled(Col)`
  margin-bottom: 60px;
  padding: 0 27px;

  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 0 16px;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    padding: 0 5px;
    margin-bottom: 50px;
  }
`;

const NewsListWrapper = styled.div`
  position: relative;
`;

const SearchShowAllButton = styled(StyledShowAll)`
  margin: 0 0 80px 0;
`;

interface IAllResultsProps {
  className?: string;
  searchValue: string;
}

const RenderGuard = (props: any) => {
  if (!props.length || props.length <= 0) {
    return null;
  }
  return <>{props.children}</>;
};

const Line = () => {
  return (
    <div className="line">
      <hr />
    </div>
  );
};

const AllResults: FC<IAllResultsProps> = (props) => {
  const { formatMessage: t } = useIntl();
  const { bookmarks } = useLocationBookmarksContext();
  const { data, isFetching } = useGetAllSearchResults(props.searchValue);

  const [expanded, setExpanded] = useState({
    locations: false,
    news: false,
    branches: false,
    productions: false,
  });

  const theme = useMemo(() => {
    return {
      flexboxgrid: {
        gridSize: 12,
        gutterWidth: 0,
        outerMargin: 0,
        mediaQuery: 'only screen',
        breakpoints: {
          xs: 0,
          sm: 36,
          md: 48,
          lg: 61,
        },
      },
    };
  }, []);

  const toggleExpanded = (name: string) => {
    setExpanded({
      ...expanded,
      [name]: !(expanded as any)[name],
    });
  };

  const locations = data?.[0]?.locations?.data ?? [];
  const branches = data?.[1]?.branches?.data ?? [];
  const productions = data?.[2]?.productions?.data ?? [];
  const news = data?.[3]?.news?.data ?? [];

  const finalLocations = expanded.locations && locations.length > 3 ? locations : locations.slice(0, 3);
  const finalLocationsWithBookmarks = finalLocations?.map((item: any) => (bookmarks?.includes(item.slug) ? { ...item, bookmarked: true } : item));

  const finalBranches = expanded.branches && branches.length > 4 ? branches : branches.slice(0, 4);
  const finalNews = expanded.news && news.length > 3 ? news : news.slice(0, 3);
  const finalProductions = expanded.productions && productions.length > 4 ? productions : productions.slice(0, 4);

  const noResults = !finalLocationsWithBookmarks.length && !finalBranches.length && !finalNews.length && !finalProductions.length;

  return (
    <Wrapper className={props.className}>
      {isFetching ? (
        <LoadMoreButton isLoading />
      ) : (
        <>
          {noResults && <NoResults />}

          <RenderGuard length={finalLocationsWithBookmarks.length}>
            <div className="search-row">
              <SearchResultsHeader link="locations" title={`${t({ id: 'locations' })} (${locations.length})`} />
              <LocationsList locations={finalLocationsWithBookmarks} />
              {locations.length > 3 ? (
                <Container>
                  <SearchShowAllButton className="search-show-all-button" onClick={() => toggleExpanded('locations')}>
                    {expanded.locations ? <ArrowUp /> : <ArrowDown />}
                    <span>{expanded.locations ? t({ id: 'show_less' }) : t({ id: 'show_all' })}</span>
                  </SearchShowAllButton>
                </Container>
              ) : (
                <Line />
              )}
            </div>
          </RenderGuard>

          <RenderGuard length={finalBranches.length}>
            <div className="search-row">
              <SearchResultsHeader link="branches" title={`${t({ id: 'branches' })} (${branches.length})`} />
              <BranchesList branches={finalBranches} />
              {branches.length > 4 ? (
                <Container>
                  <SearchShowAllButton className="search-show-all-button" onClick={() => toggleExpanded('branches')}>
                    {expanded.branches ? <ArrowUp /> : <ArrowDown />}
                    <span>{expanded.branches ? t({ id: 'show_less' }) : t({ id: 'show_all' })}</span>
                  </SearchShowAllButton>
                </Container>
              ) : (
                <Line />
              )}
            </div>
          </RenderGuard>

          <RenderGuard length={finalNews.length}>
            <div className="search-row">
              <SearchResultsHeader link="news" title={`${t({ id: 'news' })} (${news.length})`} />
              {finalNews.length === 0 ? (
                <NoResults />
              ) : (
                <NewsListWrapper>
                  <ThemeProvider theme={theme}>
                    <Row>
                      {finalNews?.map((newsItem: News, index: number) => {
                        return (
                          <NewsStyledCol key={`m28-news-item-${index}`} xs={6} sm={6} md={6} lg={4}>
                            <NewsListItem
                              ID={index}
                              link={newsItem?.link}
                              post_author={''}
                              post_content={newsItem?.post_content || ''}
                              post_date={newsItem?.post_date || ''}
                              post_name={newsItem?.post_name || ''}
                              post_title={newsItem?.post_title || newsItem?.title || ''}
                              post_type={newsItem?.post_type || ''}
                              thumbnail={newsItem?.thumbnail || ''}
                            />
                          </NewsStyledCol>
                        );
                      })}
                    </Row>
                  </ThemeProvider>
                </NewsListWrapper>
              )}

              {news.length > 3 ? (
                <Container>
                  <SearchShowAllButton className="search-show-all-button" onClick={() => toggleExpanded('news')}>
                    {expanded.news ? <ArrowUp /> : <ArrowDown />}
                    <span>{expanded.news ? t({ id: 'show_less' }) : t({ id: 'show_all' })}</span>
                  </SearchShowAllButton>
                </Container>
              ) : (
                <Line />
              )}
            </div>
          </RenderGuard>

          <RenderGuard length={finalProductions.length}>
            <div className="search-row">
              <SearchResultsHeader link="productions" title={`${t({ id: 'productions' })} (${productions.length})`} />
              {finalProductions.length === 0 ? (
                <NoResults />
              ) : (
                <NewsListWrapper>
                  <ThemeProvider theme={theme}>
                    <Row>
                      {finalProductions?.map((production: any, index: number) => {
                        return (
                          <NewsStyledCol key={`${production.thumbnail}-${index}`} xs={6} sm={6} md={4} lg={3}>
                            <ProductionListItem productionItem={production as any} />
                          </NewsStyledCol>
                        );
                      })}
                    </Row>
                  </ThemeProvider>
                </NewsListWrapper>
              )}
              {productions.length > 4 && (
                <Container>
                  <SearchShowAllButton className="search-show-all-button" onClick={() => toggleExpanded('productions')}>
                    {expanded.productions ? <ArrowUp /> : <ArrowDown />}
                    <span>{expanded.productions ? t({ id: 'show_less' }) : t({ id: 'show_all' })}</span>
                  </SearchShowAllButton>
                </Container>
              )}
            </div>
          </RenderGuard>
        </>
      )}
    </Wrapper>
  );
};

export default AllResults;
