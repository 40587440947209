import { useRouter } from '@lib/hooks/useRouter';
import { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

const DefaultButton = styled.button`
  transition: all 0.2s;
  cursor: pointer;
  padding: 29px 80px;
  margin: 0;
  font-size: 49px;
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  outline: none;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.black};
  border: 1px solid ${(props) => props.theme.colors.black};
  background-color: ${(props) => props.theme.colors.white};

  &:hover {
    color: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.black};
  }

  svg {
    transition: all 0.2s;
  }
`;

const RedButton = styled(DefaultButton)`
  background-color: ${(props) => props.theme.colors.red};
  &:hover {
    color: ${(props) => props.theme.colors.red};
    background-color: ${(props) => props.theme.colors.black};
  }
`;

const YellowButton = styled(DefaultButton)`
  background-color: ${(props) => props.theme.colors.yellow};
  &:hover {
    color: ${(props) => props.theme.colors.yellow};
    background-color: ${(props) => props.theme.colors.black};
  }
`;

const VioletButton = styled(DefaultButton)`
  background-color: ${(props) => props.theme.colors.violet};
  &:hover {
    color: ${(props) => props.theme.colors.violet};
    border: 1px solid transparent;
    background-color: transparent;
  }
`;

const BlackButton = styled(DefaultButton)`
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.black};
  &:hover {
    color: ${(props) => props.theme.colors.black};
    border: 1px solid ${(props) => props.theme.colors.black};
    background-color: ${(props) => props.theme.colors.white};

    svg {
      transform: translateY(-5px);
    }
  }
`;

const Buttons: { [key: string]: any } = {
  red: RedButton,
  yellow: YellowButton,
  violet: VioletButton,
  black: BlackButton,
};

export type ButtonColor = 'red' | 'violet' | 'yellow' | 'black';

interface ButtonProps {
  to?: string;
  type?: string;
  color?: ButtonColor;
  icon?: ReactElement;
  disabled?: boolean;
  children: ReactNode;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export default function Button(props: ButtonProps) {
  const router = useRouter();
  const { children, to, ...rest } = props;

  const handleOnClick = () => {
    if (to) {
      router.history.push(to);
      return;
    }
    rest.onClick?.();
  };

  const ButtonElement = props.color ? Buttons[props.color] : DefaultButton;
  const Icon = props.icon || null;

  return (
    <ButtonElement {...rest} onClick={handleOnClick}>
      {Icon} {children}
    </ButtonElement>
  );
}
