import { resetPassword } from '@api/user';
import Button from '@components/shared/Button';
import breakpoints from '@lib/common/breakpoints';
import { useResetPasswordFormik } from '@lib/hooks/formik/useResetPasswordFormik';
import { useRouter } from '@lib/hooks/useRouter';
import React from 'react';
import { useIntl } from 'react-intl';
import { Form } from 'reactstrap';
import styled from 'styled-components';
import { Input } from '../Input';

const StyledForm = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  padding: 90px 120px;
  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 80px 24px 50px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 80px 24px 50px;
  }
`;

const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  @media only screen and ${breakpoints.maxWidth.md} {
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
  }
`;

const StyledButton = styled(Button)`
  font-size: 24px;
  padding: 24px 36px;
  margin-left: 16px;
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 20px;
    padding: 10px 18px;
    margin-left: 0;
    margin-top: 20px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 20px;
    padding: 10px 18px;
    margin-left: 0;
    margin-top: 20px;
  }
`;

const ResetPasswordForm = () => {
  const { formatMessage: t } = useIntl();
  const router = useRouter();

  const formik = useResetPasswordFormik({
    onSubmit: async (values, formikHelpers) => {
      try {
        await resetPassword({
          password: values.newPassword,
          password_confirmation: values.confirmPassword,
          token: router.location.search.replace('?token=', ''),
        });
        router.history.push('/login');
      } catch (e) {}
    },
  });

  return (
    <StyledForm className="ResetPasswordForm">
      <Form inline onSubmit={formik.handleSubmit}>
        <Input
          type="password"
          name="newPassword"
          id="newPassword"
          label={t({ id: 'new.password' })}
          labelInfo={t({ id: 'password.strength' })}
          error={formik.touched.newPassword && formik.errors.newPassword ? t({ id: formik.errors.newPassword?.toString() }) : undefined}
          value={formik.values.newPassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        <Input
          type="password"
          name="confirmPassword"
          id="confirmPassword"
          label={t({ id: 'confirm.password' })}
          labelInfo={t({ id: 'password.strength' })}
          error={formik.touched.confirmPassword && formik.errors.confirmPassword ? t({ id: formik.errors.confirmPassword?.toString() }) : undefined}
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <StyledButtonWrapper>
          <StyledButton type="submit" disabled={formik.isSubmitting} color="black">
            {formik.isSubmitting ? t({ id: 'loading' }) : t({ id: 'button_next_step' })}
          </StyledButton>
        </StyledButtonWrapper>
      </Form>
    </StyledForm>
  );
};

export default ResetPasswordForm;
