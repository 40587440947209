import * as API from '@api/locations';
import { Container } from '@components/shared/Container';
import { LocationForm, LOCATION_STEP } from '@components/user/Location/LocationForm';
import breakpoints from '@lib/common/breakpoints';
import { useLocationConfirmationFormik } from '@lib/hooks/formik/useLocationConfirmationFormik';
import { useLocationContactFormik } from '@lib/hooks/formik/useLocationContactFormik';
import { useLocationDataFormik } from '@lib/hooks/formik/useLocationDataFormik';
import { useLocationInfoFormik } from '@lib/hooks/formik/useLocationInfoFormik';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

const StyledCreateLocation = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.yellow};
  padding: 120px 0;
  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 100px 0;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 80px 0;
  }
`;

export const StyledPageHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 120px;
  @media only screen and ${breakpoints.maxWidth.md} {
    margin-bottom: 50px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    margin-bottom: 50px;
  }
  h1 {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 58px;
    line-height: 1.05em;
    color: ${(props) => props.theme.colors.black};
    margin-bottom: 27px;
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 28px;
      line-height: 1.18em;
      margin-bottom: 24px;
    }

    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 24px;
      line-height: 1.18em;
      margin-bottom: 24px;
    }
  }
  p {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 36px;
    letter-spacing: 0.4px;
    line-height: 1.18em;
    color: ${(props) => props.theme.colors.black};
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 20px;
      letter-spacing: 0.2px;
      line-height: 1.2em;
    }

    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 18px;
      letter-spacing: 0.2px;
      line-height: 1.2em;
    }
  }
`;

interface Props {}

export const CreateLocation = (props: RouteComponentProps<Props>) => {
  const [locationId, setLocationId] = useState<number | undefined>(undefined);
  const [step, setStep] = useState(LOCATION_STEP.LOCATION_INFO);

  const locationInfoFormik = useLocationInfoFormik({
    onSubmit: async (values, formikHelpers) => {
      try {
        const res = await API.locationsStep1({
          step: '1',
          location_name: values.location_name,
          location_id: locationId || 0,
          new_location_category: values.new_location_category,
          location_categories: values.location_categories,
          //@ts-ignore
          epochs: values.epochs ? [parseInt(values.epochs)] : 0,
          location_art: values.location_art,
          location_ausstattung: values.location_ausstattung,
          company_name: values.company_name,
          motive_description: values.motive_description,
          main_photo_id: values.main_photo_id?.id || 0,
          other_photos: values.other_photos || [],
          videos: values.videos || [],
          company_law_registration: values.company_law_registration,
        });
        setLocationId(res.data?.location_id);
        setStep(LOCATION_STEP.LOCATION_DATA);
      } catch (error) {
        //@ts-ignore
        formikHelpers.setErrors(error.response.data.data.errors);
      }
    },
  });

  const locationDataFormik = useLocationDataFormik({
    onSubmit: async (values, formikHelpers) => {
      try {
        await API.locationsStep2({
          step: '2',
          location_id: locationId || 0,
          company: values.company,
          street: values.street,
          postcode: values.postcode,
          ort: values.ort,
          location_contact_person: values.location_contact_person,
          email: values.email,
          phone: values.phone,
        });
        setStep(LOCATION_STEP.FURTHER_CONTACT);
      } catch (error) {
        //@ts-ignore
        formikHelpers.setErrors(error.response.data.data.errors);
      }
    },
  });
  const locationContactFormik = useLocationContactFormik({
    onSubmit: async (values, formikHelpers) => {
      try {
        await API.locationsStep3({
          step: '3',
          location_id: locationId || 0,
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          phone: values.phone,
        });
        setStep(LOCATION_STEP.CONFIRMATION);
      } catch (error) {
        //@ts-ignore
        formikHelpers.setErrors(error.response.data.data.errors);
      }
    },
  });

  const locationConfirmationFormik = useLocationConfirmationFormik({
    onSubmit: async (values, formikHelpers) => {
      try {
        await API.locationsStep4({
          step: '4',
          location_id: locationId || 0,
          accept_terms_conditions: values.accept_terms_conditions ? 1 : 0,
          i_wee: values.i_wee,
        });
        setLocationId(0);
        setStep(LOCATION_STEP.THANK_YOU);
      } catch (error) {
        //@ts-ignore
        formikHelpers.setErrors(error.response.data.data.errors);
      }
    },
  });

  return (
    <StyledCreateLocation className="CreateLocation">
      <Container>
        <LocationForm
          step={step}
          locationInfoFormik={locationInfoFormik}
          locationDataFormik={locationDataFormik}
          locationContactFormik={locationContactFormik}
          locationConfirmationFormik={locationConfirmationFormik}
          setStep={setStep}
        />
      </Container>
    </StyledCreateLocation>
  );
};
