import HyphenText from '@components/shared/HyphenText';
import Image from '@components/shared/Image';
import breakpoints from '@lib/common/breakpoints';
import prepareLink from '@lib/helpers/prepareLink';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled(Link)`
  cursor: pointer;

  &:hover {
    h3,
    img {
      opacity: 0.75;
    }
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 270px;
  margin-bottom: 20px;
  overflow: hidden;

  @media only screen and ${breakpoints.maxWidth.md} {
    height: 226px;
  }
  @media only screen and ${breakpoints.maxWidth.xs} {
    height: 116px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform ease-in-out 0.5s;
    transform-origin: center center;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: column;

  h6 {
    font-family: 'GTFlexa-Medium', sans-serif;
    font-size: 16px;
    margin-bottom: 10px;
    color: #000;
    flex: 1;
    opacity: 0.5;
  }

  h3 {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    text-transform: inherit;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #000;
    margin-right: 24px;
    /* word-break: break-all; */
    flex: 1;

    @media only screen and ${breakpoints.maxWidth.xs} {
      font-size: 16px;
      line-height: 1.15;
      width: 100%;
      //word-break: break-word;
    }
  }
`;

interface INewsListItemProps {
  ID: number | null;
  link?: string | null;
  post_author: string | null;
  post_content: string | null;
  post_date: string | null;
  post_name: string | null;
  post_title: string | null;
  post_type: string | null;
  thumbnail: string | null;
  hideDate?: boolean;
}

const NewsListItem: FC<INewsListItemProps> = (props) => {
  return (
    <Wrapper to={props.link ? prepareLink(props.link) : `/news/${props.post_name}`}>
      <ImageWrapper>{props.thumbnail && <Image src={props.thumbnail} />}</ImageWrapper>
      <TitleWrapper>
        {!props.hideDate && <h6>{props.post_date}</h6>}
        <HyphenText>
          <h3 dangerouslySetInnerHTML={{ __html: props.post_title ?? '' }} />
        </HyphenText>
      </TitleWrapper>
    </Wrapper>
  );
};

export default NewsListItem;
