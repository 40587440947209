import { LoginFields } from '@lib/hooks/formik/useLoginFormik';
import { RegisterFields } from '@lib/hooks/formik/useRegisterFormik';
import React, { useContext } from 'react';
import * as API from '../../../api';

export interface AuthContextType {
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  isLoading: boolean;
  user?: API.User;
  error?: string | { [key: string]: string };
  reloadAuthentication: () => void;
  login: (values: LoginFields) => Promise<void>;
  register: (values: RegisterFields) => Promise<any>;
  logout: () => void;
  setUser: (user: API.User) => void;
}

const voidFunction = () => {};
const voidPromise = (): Promise<void> =>
  new Promise(() => {
    return;
  });

const AuthContextValues: AuthContextType = {
  isAuthenticated: false,
  isAuthenticating: false,
  isLoading: false,
  user: undefined,
  error: undefined,
  reloadAuthentication: () => {},
  login: voidPromise,
  register: voidPromise,
  logout: voidFunction,
  setUser: voidFunction,
};

export const AuthContext = React.createContext<AuthContextType>(AuthContextValues);

export const useAuthContext = () => useContext(AuthContext);
