import LayoutWithHeaderFooter from '@components/layout/LayoutWithHeaderFooter';
import Button from '@components/shared/Button';
import breakpoints from '@lib/common/breakpoints';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

const Wrapper = styled.div`
  min-height: 100vh;
  background-color: ${(props) => props.theme.colors.yellow};
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  margin: 250px auto auto auto;

  h1 {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 116px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.52;
    letter-spacing: normal;
    color: #000;
    margin-bottom: 30px;

    @media only screen and ${breakpoints.maxWidth.xs} {
      font-size: 80px;
      line-height: 0.25;
    }
  }
  p {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000;

    @media only screen and ${breakpoints.maxWidth.xs} {
      font-size: 18px;
    }
  }

  button {
    margin-top: 80px;
    padding: 25px 36px;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #fff;

    @media only screen and ${breakpoints.maxWidth.xs} {
      padding: 15px 20px;
      font-size: 16px;
    }
  }
`;

export const Page404 = () => {
  const { formatMessage: t } = useIntl();
  return (
    <Wrapper>
      <LayoutWithHeaderFooter hideFooter>
        <Container>
          <h1>404</h1>

          <p>{t({ id: '404.info' })}</p>

          <Button color="black" to="/">
            {t({ id: 'zuruck.zur.startseite' })}
          </Button>
        </Container>
      </LayoutWithHeaderFooter>
    </Wrapper>
  );
};
