import { ReactComponent as ExternalArrow } from '@assets/icons/icons_arrow-external_small.svg';
import breakpoints from '@lib/common/breakpoints';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

const Module10Container = styled.div`
  display: flex;
  justify-content: center;
  background-color: black;
  padding: 18px 15px;
  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 12px 15px;
  }
`;

const Text = styled.p`
  color: #ffffff;
  font-size: 48px;
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  line-height: 1.25;
  font-weight: normal;
  text-align: center;
  margin-left: 15px;
  text-transform: uppercase;
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 25px;
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 22px;
  }
`;

const StyledArrow = styled(ExternalArrow)`
  width: 30px;
  height: 30px;
  path {
    stroke: white;
  }

  @media only screen and ${breakpoints.maxWidth.md} {
    width: 20px;
    height: 20px;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    display: none;
  }
`;

const Link = styled(RouterLink)`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
`;

interface Module10Props {
  data?: {
    button_text?: string;
  };
}

export default function Module10(props: Module10Props) {
  return (
    <Module10Container data-module="m10">
      <Link to="/">
        <StyledArrow />
        <Text>{props?.data?.button_text}</Text>
      </Link>
    </Module10Container>
  );
}
