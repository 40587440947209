import SearchResultsHeader from '@components/search/SearchResultsHeader';
import { Container } from '@components/shared/Container';
import Filters, { IFilters } from '@components/shared/Filters';
import LoadMoreButton from '@components/shared/LoadMoreButton';
import NoResults from '@components/shared/NoResults';
import ProductionListItem from '@components/shared/ProductionListItem';
import breakpoints from '@lib/common/breakpoints';
import objectToStringParams from '@lib/helpers/objectToStringParams';
import sortYears from '@lib/helpers/sortYears';
import useGetProductions from '@lib/hooks/queries/useGetProductions';
import useLocalStorageFilters from '@lib/hooks/useLocalStorageFilters';
import { ObjectAny } from '@lib/interfaces';
import ModuleType from '@lib/interfaces/Modules';
import React, { FC, useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useIntl } from 'react-intl';
import { Col, Row } from 'react-styled-flexboxgrid';
import styled, { ThemeProvider } from 'styled-components';
import { useDebounce } from 'use-debounce';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding-top: 110px;
  padding-bottom: 110px;
  width: 100%;

  @media only screen and ${breakpoints.maxWidth.sm} {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

const TextWrapper = styled.div`
  max-width: 1121px;
  width: 100%;
  margin-bottom: 80px;
  h3 {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 58px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.03;
    letter-spacing: normal;
    margin-bottom: 25px;

    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 32px;
      line-height: 1.17;
    }

    @media only screen and ${breakpoints.maxWidth.xs} {
      font-size: 20px;
      line-height: 1.17;
    }
  }
`;

const StyledRow = styled(Row)``;

const StyledCol = styled(Col)`
  margin-bottom: 60px;
  padding: 0 27px;

  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 0 16px;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    padding: 0 5px;
    margin-bottom: 50px;
  }
`;

const ListWrapper = styled.div`
  position: relative;
  margin-left: -27px;
  margin-right: -27px;

  @media only screen and ${breakpoints.maxWidth.md} {
    margin-left: -16px;
    margin-right: -16px;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    margin-left: -5px;
    margin-right: -5px;
  }
`;

const getInitialFilters = (key: string, filters?: ObjectAny): ObjectAny | undefined => {
  if (!filters || !filters[key] || typeof filters[key] !== 'object') {
    return undefined;
  }

  return Object.keys(filters[key]['values']).reduce((acc, next) => {
    return {
      ...acc,
      [next as any]: '',
    };
  }, {});
};

const prepareProductionCategories = (items?: ObjectAny) => {
  return {
    label: items?.label,
    values:
      items?.values?.reduce((acc: any, next: any) => {
        return {
          ...acc,
          [next['slug']]: next['name'],
        };
      }, {}) ?? {},
  };
};

interface IModule34Props {
  data?: {
    filters: IFilters;
    title: string;
  };
  searchProps?: {
    searchValue?: string;
    showResultsHeader?: boolean;
  };
}
const Module34: FC<IModule34Props> = (props) => {
  const { formatMessage: t } = useIntl();
  const { getLocalStorageFilterByName, setLocalStorageFilter } = useLocalStorageFilters(ModuleType.MODULE_34);

  const [searchValue, setSearchValue] = useState(props.searchProps?.searchValue ?? '');
  const [debouncedSearchValue] = useDebounce(searchValue, 500);

  const [selectedFilters, setSelectedFilters] = useState<ObjectAny>(
    getLocalStorageFilterByName('selectedFilters', {
      production_categories: {},
      veroffentlicht: {
        false: 'false',
      },
      years: getInitialFilters('years', props.data?.filters),
    }),
  );

  useEffect(() => {
    setLocalStorageFilter({
      selectedFilters,
    });
  }, [selectedFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSearchValue(props.searchProps?.searchValue ?? '');
  }, [props.searchProps?.searchValue]);

  const productionCategoriesFiltersParams = objectToStringParams(selectedFilters['production_categories'] || selectedFilters['categories']);
  const productionPhaseFiltersParams = objectToStringParams(selectedFilters.veroffentlicht);
  const yearFiltersParams = objectToStringParams(selectedFilters?.years);

  const {
    data: productionsData,
    isFetching,
    hasNextPage,
    fetchNextPage,
  } = useGetProductions(productionCategoriesFiltersParams, productionPhaseFiltersParams, yearFiltersParams, debouncedSearchValue);

  const handleFilterChange = (keyEl: string, el: string) => {
    if (keyEl === 'veroffentlicht') {
      const isSelected = !!selectedFilters?.[keyEl]?.[el];
      const newFilters = selectedFilters ? { [el]: isSelected ? undefined : el } : {};
      setSelectedFilters({
        ...selectedFilters,
        [keyEl]: { ...newFilters },
      });
      return;
    }

    const isSelected = !!selectedFilters?.[keyEl]?.[el];
    const newFilters = selectedFilters ? { ...selectedFilters[keyEl], [el]: isSelected ? undefined : el } : { [el]: isSelected ? undefined : el };
    setSelectedFilters({
      ...selectedFilters,
      [keyEl]: { ...newFilters },
    });
  };

  const theme = useMemo(() => {
    return {
      flexboxgrid: {
        gridSize: 12,
        gutterWidth: 0,
        outerMargin: 0,
        mediaQuery: 'only screen',
        breakpoints: {
          xs: 0,
          sm: 36,
          md: 48,
          lg: 61,
        },
      },
    };
  }, []);

  const prepareVeroffentlicht = (items?: ObjectAny) => {
    return {
      label: items?.label,
      values:
        items?.values?.reduce((acc: any, next: any) => {
          return {
            ...acc,
            [next['value']]: next['label'] === 'In Produktion' ? t({ id: 'in.production' }) : t({ id: 'released' }),
          };
        }, {}) ?? {},
    };
  };

  const allProductions = productionsData?.pages.map((item) => item.data).flat() ?? [];

  const isOnSearchPage = Boolean(props.searchProps);

  const sortedYears = props?.data?.filters?.years?.values ? sortYears(props.data.filters?.years?.values) : {};

  const allFilters = {
    veroffentlicht: prepareVeroffentlicht(props.data?.filters?.veroffentlicht),
    production_categories: prepareProductionCategories(props?.data?.filters?.production_categories ?? props?.data?.filters?.categories),
    years: {
      label: props.data?.filters?.years?.label ?? '',
      values: sortedYears,
    },
  };

  return (
    <>
      <Filters
        isOnSearch={isOnSearchPage}
        firstElementFull
        filters={allFilters as any}
        selectedFilters={selectedFilters}
        onFiltersChange={handleFilterChange}
      />
      <Wrapper data-module={ModuleType.MODULE_34}>
        <Container>
          {!isOnSearchPage && <TextWrapper>{props.data?.title && <h3>{props.data.title}</h3>}</TextWrapper>}
          <ListWrapper>
            <InfiniteScroll loader={null} scrollThreshold={1} dataLength={allProductions.length} hasMore={Boolean(hasNextPage)} next={fetchNextPage}>
              <ThemeProvider theme={theme}>
                {props.searchProps?.showResultsHeader && allProductions?.length ? (
                  <SearchResultsHeader link="productions" title={`${t({ id: 'productions' })} (${allProductions?.length}) `} />
                ) : null}

                <StyledRow>
                  {(!allProductions || allProductions.length === 0) && !isFetching ? (
                    <NoResults />
                  ) : (
                    allProductions?.map((production, index) => {
                      return (
                        <StyledCol key={`${production.thumbnail}-${index}`} xs={6} sm={6} md={4} lg={3}>
                          <ProductionListItem productionItem={production as any} />
                        </StyledCol>
                      );
                    })
                  )}
                </StyledRow>
              </ThemeProvider>
              {isFetching && <LoadMoreButton isLoading />}
            </InfiniteScroll>
          </ListWrapper>
        </Container>
      </Wrapper>
    </>
  );
};

export default Module34;
