import { ReactComponent as Facebook } from '@assets/icons/icons_facebook.svg';
import { ReactComponent as Instagram } from '@assets/icons/icons_instagram.svg';
import { ReactComponent as Youtube } from '@assets/icons/icons_youtube.svg';
import { ReactComponent as Vimeo } from '@assets/icons/icon_vimeo.svg';
import MobileFooter from '@components/footer/MobileFooter';
import { Container } from '@components/shared/Container';
import breakpoints from '@lib/common/breakpoints';
import useGetPagesGeneralInfo from '@lib/hooks/queries/useGetPagesGeneralInfo';
import { useWindowWidth } from '@lib/hooks/useWindowWidth';
import { useEffect } from 'react';
import { useIsFetching } from 'react-query';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const FooterWrapper = styled.footer`
  background-color: ${(props) => props.theme.colors.mediumRed};
  width: 100%;
  padding: 90px 0 80px 0;

  a:hover {
    color: #ffffff;
  }

  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 60px 0;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    padding: 40px 0;
  }
`;

const FooterContainer = styled.div`
  width: 100%;
  margin: 0;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  // @media only screen and ${breakpoints.maxWidth.sm} {
  //   justify-content: flex-start;
  // }

  @media only screen and ${breakpoints.maxWidth.xs} {
    flex-direction: column;
  }
`;

const Col = styled.div`
  padding: 0 15px;

  @media only screen and ${breakpoints.maxWidth.xs} {
    padding: 0;
  }
`;

const Col1 = styled(Col)`
  padding-left: 0;
  @media only screen and ${breakpoints.maxWidth.xs} {
    max-width: 100%;
    flex-direction: column-reverse;
    display: flex;
  }

  > div {
    margin-top: 32px;
    display: block;
  }
`;

const Col2 = styled(Col)`
  width: 100%;
  max-width: 318px;

  @media only screen and ${breakpoints.minWidth.sm} and ${breakpoints.maxWidth.md} {
    padding: 0 35px;
  }
`;

const Col2Links = styled(Col2)`
  @media only screen and ${breakpoints.maxWidth.xs} {
    flex-direction: column;
    width: 100%;
    margin-top: 50px;
    margin-left: 0;
  }
`;

export const Address = styled.p`
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  font-size: 16px;
  line-height: 1.22;
  font-weight: 300;

  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 14px;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    max-width: 100%;
  }
`;

export const FooterLinks = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;

  ul {
    padding: 0;
    margin: 0;
    li {
      margin-bottom: 14px;
      list-style: none;
    }
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    max-width: 215px;
  }
`;

export const SocialLinksWrapper = styled.div`
  width: 100%;
`;

export const SocialLink = styled.a`
  margin-right: 25px;

  &:hover {
    fill: ${(props) => props.theme.colors.white};
  }

  &:last-of-type {
    margin-right: 0;
  }

  svg {
    width: 35px;
    height: 35px;
  }

  @media only screen and ${breakpoints.maxWidth.md} {
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export const MenuLink = styled(Link)`
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  line-height: 1.22;
  color: #000000;
  letter-spacing: ${(props) => props.theme.helpers.pxToRem(0.5)};

  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 14px;
    line-height: 1.25;
  }
`;

const DesktopLogo = styled.div`
  height: 150px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media only screen and ${breakpoints.minWidth.sm} and ${breakpoints.maxWidth.md} {
    width: 200px;
    height: auto;
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    width: 148px;
    height: auto;
    margin-top: 0;
  }
`;

export const TextEmail = styled.p`
  color: #000000;
  font-size: 24px;
  line-height: 32px;
  font-family: GTFlexa-ExtendedMedium, sans-serif;
  text-transform: lowercase;

  &:hover span {
    color: #ffffff;
  }

  a,
  span {
    display: block;
    color: #000000;
    font-size: 24px;
    line-height: 32px;
    font-family: GTFlexa-ExtendedMedium, sans-serif;
    text-transform: lowercase;

    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 16px;
      line-height: 1.25;
    }
  }
`;

export default function Footer() {
  const isFetching = useIsFetching(['page']);
  const isFetchingPages = useIsFetching(['pages']);
  const isFetchingTranslations = useIsFetching(['translations']);
  const isFetchingFilmingForm = useIsFetching(['filming-form']);
  const isFetchingLocationDetails = useIsFetching(['location-details']);
  const isFetchingBranchDetails = useIsFetching(['branch-details']);
  const isFetchingNewsDetails = useIsFetching(['news-details']);
  const isFetchingProductionDetails = useIsFetching(['production-details']);

  const windowWidth = useWindowWidth();
  const { data } = useGetPagesGeneralInfo();

  useEffect(() => {
    document.addEventListener('scroll', function () {
      const body = document.querySelector('body');
      const logo = document.querySelector('.vfc-floating-logo');
      if (!body || !logo) return;
      if (window.scrollY >= body.scrollHeight - 1000) {
        logo?.classList.add('d-none');
      } else {
        logo?.classList.remove('d-none');
      }
    });
  }, []);
  const footer = data?.footer;

  const address = data?.footer?.contact?.address;
  const socialMedia = data?.footer?.social;
  const firstMenu = footer?.menu?.first_menu ?? [];
  const secondMenu = footer?.menu?.second_menu ?? [];

  const emailData = footer?.contact?.email?.split('@');
  const footerLogo = footer?.logo;

  const hideFooter =
    isFetching ||
    isFetchingPages ||
    isFetchingTranslations ||
    isFetchingFilmingForm ||
    isFetchingLocationDetails ||
    isFetchingBranchDetails ||
    isFetchingNewsDetails ||
    isFetchingProductionDetails;

  if (hideFooter) {
    return null;
  }

  return (
    <FooterWrapper>
      {windowWidth <= 768 ? (
        <MobileFooter />
      ) : (
        <Container>
          <FooterContainer>
            <Row>
              <Col1>
                <span>
                  <TextEmail>
                    <a href={`mailto:${footer?.contact?.email}`}>
                      <span>{emailData?.[0]}@</span>
                      <span>{emailData?.[1]}</span>
                    </a>
                  </TextEmail>
                  <TextEmail>
                    <a href={`tel:${footer?.contact?.phone}`}>{footer?.contact?.phone}</a>
                  </TextEmail>
                </span>
                <div>
                  <Address>{address?.line1}</Address>
                  <Address>{address?.line2}</Address>
                  <Address>{address?.line3}</Address>
                </div>
              </Col1>

              <Col2Links>
                <FooterLinks>
                  <ul>
                    {firstMenu.map((item: any, index: number) => {
                      return (
                        <li key={`${item.url}`}>
                          <MenuLink to={item.url} target={item?.target}>
                            {item.title}
                          </MenuLink>
                        </li>
                      );
                    })}
                  </ul>
                  <ul>
                    {secondMenu.map((item: any, index: number) => {
                      return (
                        <li key={`${item.url}`}>
                          <MenuLink to={item.url} target={item?.target}>
                            {item.title}
                          </MenuLink>
                        </li>
                      );
                    })}
                  </ul>
                </FooterLinks>

                <SocialLinksWrapper>
                  <SocialLink href={socialMedia?.vimeo}>
                    <Vimeo />
                  </SocialLink>
                  <SocialLink href={socialMedia?.instagram}>
                    <Instagram />
                  </SocialLink>
                  <SocialLink href={socialMedia?.facebook}>
                    <Facebook />
                  </SocialLink>
                  <SocialLink href={socialMedia?.youtube}>
                    <Youtube />
                  </SocialLink>
                </SocialLinksWrapper>
              </Col2Links>

              <Col>
                <Link to="/">
                  <DesktopLogo>
                    <img src={footerLogo} alt="VFC" />
                  </DesktopLogo>
                </Link>
              </Col>
            </Row>
          </FooterContainer>
        </Container>
      )}
    </FooterWrapper>
  );
}
