import { LocationItem } from '@api/locations';
import FloatingBookmarkItems from '@components/locations/FloatingBookmarkItems';
import LocationListItem from '@components/locations/LocationListItem';
import SearchResultsHeader from '@components/search/SearchResultsHeader';
import NoResults from '@components/shared/NoResults';
import breakpoints from '@lib/common/breakpoints';
import React, { FC, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Col, Row } from 'react-styled-flexboxgrid';
import styled, { ThemeProvider } from 'styled-components';

const StyledRow = styled(Row)``;

const StyledCol = styled(Col)`
  margin-bottom: 60px;
  padding: 0 27px;

  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 0 16px;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    padding: 0 5px;
    margin-bottom: 50px;
  }
`;

interface ILocationsListProps {
  loading?: boolean;
  showResultsHeader?: boolean;
  sticky?: boolean;
  hideBookmarkItems?: boolean;
  locations: Array<LocationItem>;
  bookmarksNumber?: number;
}

const LocationsList: FC<ILocationsListProps> = (props) => {
  const { formatMessage } = useIntl();
  const theme = useMemo(() => {
    return {
      flexboxgrid: {
        gridSize: 12,
        gutterWidth: 0,
        outerMargin: 0,
        mediaQuery: 'only screen',
        breakpoints: {
          xs: 0,
          sm: 36,
          md: 48,
          lg: 61,
        },
      },
    };
  }, []);

  useEffect(() => {
    const logo: any = document.querySelector('#vfc-floating-logo');

    if (!logo) {
      return;
    }

    if (props.sticky) {
      logo.classList.add('top-space');
    } else {
      logo.classList.remove('top-space');
    }

    return () => {
      logo.classList.remove('top-space');
    };
  }, [props.sticky]);

  return (
    <ThemeProvider theme={theme}>
      {props.showResultsHeader && props.locations?.length ? (
        <SearchResultsHeader link="locations" title={`${formatMessage({ id: 'locations' })} (${props.locations?.length}) `} />
      ) : null}

      {!props.hideBookmarkItems && props.bookmarksNumber && (
        <FloatingBookmarkItems text={`${formatMessage({ id: 'gespeichert.locations' })} (${props.bookmarksNumber})`} sticky={props.sticky} />
      )}
      <StyledRow>
        {!props.loading && (!props.locations || props.locations.length === 0) ? (
          <NoResults />
        ) : (
          props.locations?.map((location) => {
            return (
              <StyledCol key={location.id} xs={6} sm={6} md={6} lg={4}>
                <LocationListItem location={location} />
              </StyledCol>
            );
          })
        )}
      </StyledRow>
    </ThemeProvider>
  );
};

export default LocationsList;
