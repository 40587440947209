import { CloseButton } from '@components/shared/FullScreenModal';
import { Checkbox } from '@components/user/Checkbox';
import breakpoints from '@lib/common/breakpoints';
import { getCookie, setCookie } from '@lib/helpers/cookiesHelpers';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Modal from 'react-modal';
import styled from 'styled-components';
import Button from './Button';

interface Props {}

const StyledCookies = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  padding: 60px 80px;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1001;
  display: flex;
  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 35px 20px;
    flex-direction: column;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 35px 20px;
    flex-direction: column;
  }
`;

const StyledContent = styled.div`
  width: calc(100% - 330px);
  padding-right: 73px;
  @media only screen and ${breakpoints.maxWidth.md} {
    width: 100%;
    padding-right: 0;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    width: 100%;
    padding-right: 0;
  }
  h4 {
    font-family: 'GTFlexa-ExtendedMedium';
    font-size: 24px;
    line-height: 1.3em;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.back};
    margin-bottom: 20px;
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 16px;
    }
    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 16px;
    }
  }
  p {
    font-family: 'GTFlexa-ExtendedLight';
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 1.3em;
    color: ${(props) => props.theme.colors.back};
    @media only screen and ${breakpoints.maxWidth.md} {
      max-height: 300px;
      overflow-x: auto;
      position: relative;
      padding-bottom: 50px;
      &:before {
        content: '';
        position: fixed;
        width: 100%;
        height: 100px;
        bottom: 186px;
        left: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 1) 100%);
      }
    }
    @media only screen and ${breakpoints.maxWidth.sm} {
      max-height: 300px;
      overflow-x: auto;
      position: relative;
      padding-bottom: 50px;
      &:before {
        content: '';
        position: fixed;
        width: 100%;
        height: 100px;
        bottom: 186px;
        left: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 1) 100%);
      }
    }
  }
`;
const StyledButtons = styled.div`
  width: 330px;
  @media only screen and ${breakpoints.maxWidth.md} {
    width: 100%;
    margin-top: 30px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    width: 100%;
    margin-top: 30px;
  }
  button:last-child {
    margin-bottom: 0 !important;
  }
`;
const StyledButton = styled(Button)`
  width: 100%;
  font-size: 24px;
  padding: 25px 28px;
  margin-bottom: 24px;
  @media only screen and ${breakpoints.maxWidth.md} {
    width: 100%;
    font-size: 22px;
    padding: 10px 20px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    width: 100%;
    font-size: 22px;
    padding: 10px 20px;
  }
`;

const StyledModal = styled(Modal)`
  .CookiesModal__close {
    path {
      stroke: #000000;
    }
  }
`;

const ModalContent = styled.div`
  h1 {
    margin-bottom: 40px;
    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 20px;
    }
  }
  .ModalContent-checkbox {
    margin-bottom: 30px;
    > div {
      margin-bottom: 10px;
      label {
        opacity: 1;
        span {
          margin-top: 2px;
          color: #000000;
        }
      }
    }

    p {
      font-family: GTFlexa-ExtendedLight, sans-serif;
      font-size: 13px;
    }
  }
`;

const ActionButton = styled(Button)`
  font-size: 16px;
  padding: 15px 20px;
  margin: 15px 15px 15px 0;

  @media only screen and ${breakpoints.maxWidth.sm} {
    width: 100%;
    font-size: 14px;
  }
`;

export const Cookies = (props: Props) => {
  const { formatMessage } = useIntl();

  const [open, setOpen] = useState(false);
  const [cookiesOpen, setCookiesOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const hasCookies = getCookie('VFC');

  useEffect(() => {
    if (!hasCookies) {
      setCookiesOpen(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const acceptAllCookies = () => {
    acceptCookies();
    // set body script
  };

  const saveSelected = () => {
    if (!checked) {
      acceptCookies();
      return;
    }

    acceptAllCookies();
  };

  const acceptCookies = () => {
    setCookiesOpen(false);
    setOpen(false);
    setCookie('VFC', 'ACCEPT', 60);
  };

  return (
    <>
      {cookiesOpen && (
        <StyledCookies className="Cookies">
          <StyledContent>
            <h4> {formatMessage({ id: 'cookies.title' })}</h4>
            <p>{formatMessage({ id: 'cookies.content' })}</p>
          </StyledContent>
          <StyledButtons>
            <StyledButton color="black" onClick={acceptAllCookies}>
              {formatMessage({ id: 'akzeptieren' })}
            </StyledButton>
            <StyledButton onClick={() => setOpen(true)}>{formatMessage({ id: 'auswahl.treffen' })}</StyledButton>
          </StyledButtons>
        </StyledCookies>
      )}

      <StyledModal
        isOpen={open}
        onRequestClose={() => setOpen(false)}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        ariaHideApp={false}
        portalClassName="CookiesModal__overlay"
      >
        <CloseButton className="CookiesModal__close" onClick={() => setOpen(false)} />
        <ModalContent>
          <h1>{formatMessage({ id: 'privacy.policy' })}</h1>
          <div className="ModalContent-items">
            <div className="ModalContent-checkbox">
              <Checkbox checked disabled name="necessary" id="no_longer_use" label={formatMessage({ id: 'necessary.cookies' })} />
              <p>{formatMessage({ id: 'cookies.info1' })}</p>
            </div>

            <div className="ModalContent-checkbox">
              <Checkbox
                id="matomo"
                name="matomo"
                label={formatMessage({ id: 'cookies.matomo' })}
                checked={checked}
                onChange={(event) => {
                  setChecked(event.target.checked);
                }}
              />
              <p>{formatMessage({ id: 'cookies.matomo.info' })}</p>
            </div>
          </div>
          <div className="ModalContent-actions">
            <ActionButton onClick={saveSelected}>{formatMessage({ id: 'save.selected' })}</ActionButton>
            <ActionButton onClick={acceptAllCookies}>{formatMessage({ id: 'accept.all' })}</ActionButton>
          </div>
        </ModalContent>
      </StyledModal>
    </>
  );
};
