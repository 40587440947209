import { Container } from '@components/shared/Container';
import NewsListItem from '@components/shared/NewsListItem';
import breakpoints from '@lib/common/breakpoints';
import ModuleType from '@lib/interfaces/Modules';
import { FC, useMemo } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import styled, { css, ThemeProvider } from 'styled-components';

interface IWrapperProps {
  color?: string;
}

const Wrapper = styled.div<IWrapperProps>`
  background-color: ${(props: any) => props.data?.background_color};
  padding-top: 110px;
  padding-bottom: 110px;
  width: 100%;
  ${({ color }) =>
    color &&
    css`
      background-color: #${color};
    `}

  @media only screen and ${breakpoints.maxWidth.sm} {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  h3 {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.25em;
    text-transform: uppercase;
  }
`;

const ListWrapper = styled.div`
  position: relative;
  margin-left: -27px;
  margin-right: -27px;

  @media only screen and ${breakpoints.maxWidth.md} {
    margin-left: -16px;
    margin-right: -16px;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    margin-left: -5px;
    margin-right: -5px;
  }
`;

const StyledRow = styled(Row)``;

const StyledCol = styled(Col)`
  margin-bottom: 60px;
  padding: 0 27px;

  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 0 16px;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    padding: 0 5px;
    margin-bottom: 25px;
  }
`;

const Title = styled.h1`
  font-family: 'GTFlexa-ExtendedMedium', sans-serif;
  font-weight: 500;
  font-size: 58px;
  line-height: 1.03;
  margin-bottom: 44px;

  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 24px;
    margin-bottom: 24px;
    line-height: ${(props) => props.theme.helpers.pxToRem(28)};
  }
`;

const Paragraph = styled.p`
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  max-width: 823px;
  font-size: 36px;
  line-height: ${(props) => props.theme.helpers.pxToRem(42)};
  letter-spacing: 0.4px;
  margin-bottom: 100px;

  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 18px;
    line-height: ${(props) => props.theme.helpers.pxToRem(22)};
    letter-spacing: 0.2px;
    margin-bottom: 50px;
  }
`;

const TitleAbovePosts = styled(Title)`
  font-size: 48px;
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  font-weight: 300;
  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 24px;
    margin-bottom: 25px;
  }
`;

interface Post {
  ID: number;
  post_author: string;
  post_content: string;
  post_date: string;
  post_name: string;
  post_title: string;
  post_type: string;
  thumbnail: string;
}

interface IModule22Props {
  data?: {
    title: string;
    description?: string;
    background_color?: string;
    above_posts_title?: string;
    post_type?: string;
    news?: Array<Post>;
    presse?: Array<Post>;
  };
}

const Module22: FC<IModule22Props> = (props) => {
  const { data } = props;

  const theme = useMemo(() => {
    return {
      flexboxgrid: {
        gridSize: 12,
        gutterWidth: 0,
        outerMargin: 0,
        mediaQuery: 'only screen',
        breakpoints: {
          xs: 0,
          sm: 36,
          md: 48,
          lg: 61,
        },
      },
    };
  }, []);

  const posts = data?.post_type ? data?.[data.post_type as 'news' | 'presse'] : [];

  return (
    <Wrapper data-module={ModuleType.MODULE_22} color={props.data?.background_color}>
      <Container>
        <Title>{props.data?.title}</Title>
        <Paragraph>{props.data?.description}</Paragraph>
        <TitleAbovePosts>{props.data?.above_posts_title}</TitleAbovePosts>

        <ListWrapper>
          <ThemeProvider theme={theme}>
            <StyledRow>
              {posts &&
                Array.isArray(posts) &&
                posts.map((newsItem) => (
                  <StyledCol key={newsItem.ID} xs={6} sm={6} md={6} lg={4}>
                    <NewsListItem hideDate={data?.post_type === 'presse'} {...newsItem} />
                  </StyledCol>
                ))}
            </StyledRow>
          </ThemeProvider>
        </ListWrapper>
      </Container>
    </Wrapper>
  );
};

export default Module22;
