import { Input } from '@components/user/Input';
import breakpoints from '@lib/common/breakpoints';
import { LocationUploadVideo } from '@lib/hooks/formik/useLocationInfoFormik';
import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import * as Yup from 'yup';
import Button from './Button';

const StyledVideoUpload = styled.div`
  width: 100%;
  label {
    background-color: rgb(240 240 240) !important;
  }
  .silver-input {
    background-color: transparent;
  }
`;
const StyledSectionTitle = styled.h4`
  font-family: 'GTFlexa-StandardMedium', sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.back};
  margin-bottom: 24px;
`;
const StyledAddFile = styled.div`
  width: calc(100% + 300px);
  margin-top: 90px;
  margin-left: -150px;
  padding: 50px 150px;
  background-color: rgba(0, 0, 0, 0.06);
  @media only screen and ${breakpoints.maxWidth.md} {
    width: calc(100% + 48px);
    margin-top: 30px;
    margin-left: -24px;
    padding: 30px 20px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    width: calc(100% + 48px);
    margin-top: 30px;
    margin-left: -24px;
    padding: 30px 20px;
  }
  h3 {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 24px;
    line-height: 1.3em;
    color: ${(props) => props.theme.colors.black};
  }
  span {
    display: block;
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 1.2em;
    color: ${(props) => props.theme.colors.black};
    margin-bottom: 10px;
  }
`;
const StyledButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @media only screen and ${breakpoints.maxWidth.md} {
    flex-direction: column;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    flex-direction: column;
  }
`;
const StyledCancelButton = styled(Button)`
  font-size: 24px;
  padding: 25px 90px;
  margin-right: 30px;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.darkRed};
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 20px;
    padding: 10px 18px;
    margin-right: 0;
    margin-bottom: 10px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 20px;
    padding: 10px 18px;
    margin-right: 0;
    margin-bottom: 10px;
  }
`;
const StyledSubmitButton = styled(Button)`
  font-size: 24px;
  padding: 25px 90px;
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 20px;
    padding: 10px 18px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 20px;
    padding: 10px 18px;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      border-color: rgba(0, 0, 0, 0.3) !important;
      color: ${(props) => props.theme.colors.white}!important;
      background-color: rgba(0, 0, 0, 0.3) !important;
    `}
`;
const StyledButton = styled(Button)`
  width: 100%;
  font-size: 24px;
  padding: 24px 36px;
  margin-top: 50px;
  @media only screen and ${breakpoints.maxWidth.md} {
    width: 100%;
    font-size: 20px;
    padding: 10px 18px;
    margin-top: 20px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    width: 100%;
    font-size: 20px;
    padding: 10px 18px;
    margin-top: 20px;
  }
`;

interface Props {
  value: LocationUploadVideo | undefined;
  setValue: React.Dispatch<React.SetStateAction<LocationUploadVideo | undefined>>;
  onSubmit: (values: LocationUploadVideo) => void;
}

export const VideoUpload = (props: Props) => {
  const { formatMessage: t } = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const VideoUploadSchema = Yup.object().shape({
    url: Yup.string().required(t({ id: 'video_is_required' })),
    title: Yup.string().required(t({ id: 'caption_is_required' })),
    copyright: Yup.string().required(t({ id: 'image_copyright_is_required' })),
  });

  useEffect(() => {
    setIsOpen(!!props.value);
    !!props.value &&
      formik.setValues({
        url: props.value?.url || '',
        title: props.value?.title || '',
        copyright: props.value?.copyright || '',
      });
  }, [props.value]); // eslint-disable-line react-hooks/exhaustive-deps

  const formik = useFormik({
    initialValues: {
      url: '',
      title: '',
      copyright: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: VideoUploadSchema,
    onSubmit: async (values, helpers) => {
      try {
        const thumbnail = await getVideoThumbnail(values.url);
        if (!thumbnail) {
          helpers.setFieldError('url', t({ id: 'valid_youtube_vimeo_link' }));
          helpers.setFieldTouched('url');
          return;
        }
        props.onSubmit({
          url: values.url,
          thumbnail: thumbnail,
          title: values.title,
          copyright: values.copyright,
        });
        helpers.resetForm();
        setIsOpen(false);
        props.setValue(undefined);
      } catch (error) {
        console.log(error);
      }
    },
  });

  const getVideoThumbnail = async (video: string) => {
    try {
      const isYoutubeVideo = video.includes('youtube.com') || video.includes('youtu.be');
      if (isYoutubeVideo) {
        const res = await axios.get(`https://www.youtube.com/oembed?format=json&url=${video}`);
        //@ts-ignore
        return res.data.thumbnail_url;
      }
      const isVimeoVideo = video.includes('vimeo.com');
      if (isVimeoVideo) {
        const res = await axios.get(`https://vimeo.com/api/oembed.json?url=${video}`);
        //@ts-ignore
        return res.data.thumbnail_url;
      }
      return null;
    } catch (error) {}
  };

  const handleCancelUpload = () => {
    formik.resetForm();
    setIsOpen(false);
    props.setValue(undefined);
  };

  return (
    <StyledVideoUpload>
      {isOpen ? (
        <StyledAddFile>
          <StyledSectionTitle>{t({ id: 'add_more_videos_to_the_location' })}</StyledSectionTitle>
          <Input
            type="text"
            name="url"
            id="url"
            label={t({ id: 'viemeo_or_youtube_link' })}
            labelInfo={t({ id: 'viemeo_or_youtube_link' })}
            className="form-control-custom silver-input"
            value={formik.values.url}
            invalid={!!formik.errors.url && !!formik.touched.url}
            onChange={formik.handleChange}
            error={formik.errors.url}
          />
          <Input
            type="text"
            name="title"
            id="title"
            label={t({ id: 'video_title' })}
            labelInfo={t({ id: 'video_title' })}
            className="form-control-custom silver-input"
            value={formik.values.title}
            invalid={!!formik.errors.title && !!formik.touched.title}
            onChange={formik.handleChange}
            error={formik.errors.title}
          />
          <Input
            type="text"
            name="copyright"
            id="copyright"
            label={t({ id: 'video_copyright' })}
            className="form-control-custom silver-input"
            value={formik.values.copyright}
            invalid={!!formik.errors.copyright && !!formik.touched.copyright}
            onChange={formik.handleChange}
            error={formik.errors.copyright}
          />
          <StyledButtons>
            <StyledCancelButton color="red" onClick={handleCancelUpload}>
              {t({ id: 'private_request_button_back' })}
            </StyledCancelButton>
            <StyledSubmitButton color="black" onClick={(e)=>{
              e?.preventDefault();
              e?.stopPropagation();
              formik.handleSubmit();
            }}>
              {t({ id: 'send' })}
            </StyledSubmitButton>
          </StyledButtons>
        </StyledAddFile>
      ) : (
        <StyledButton
          color="black"
          onClick={() => {
            setIsOpen(true);
            props.setValue(undefined);
          }}
        >
          {t({ id: 'location_video_upload_button' })}
        </StyledButton>
      )}
    </StyledVideoUpload>
  );
};
