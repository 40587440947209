import { getRelatedBranches, GetRelatedBranchesInput } from '@api/branch';
import QueryKeys from '@lib/constants/QueryKeys';
import { ObjectAny } from '@lib/interfaces';
import { AxiosError } from 'axios';
import { useInfiniteQuery } from 'react-query';

export default function useGetRelatedBranchDetails(params: GetRelatedBranchesInput) {
  return useInfiniteQuery<ObjectAny, AxiosError>(QueryKeys.relatedBranches(params), () => getRelatedBranches(params), {
    enabled: Boolean(params?.slug),
  });
}
