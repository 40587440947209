import cs from 'classnames';
import { CSSProperties } from 'react';

interface ImageProps {
  placeholderSrc?: string;
  src?: string;
  alt?: string;
  style?: CSSProperties;
  className?: string;
}

export default function Image(props: ImageProps) {
  const src = props.src || props.placeholderSrc || '';
  return <img className={cs(props.className)} src={src} alt={props.alt} style={{ ...props.style }} />;
}
