import ModuleType from '@lib/interfaces/Modules';

export const vfcFiltersKey = 'vfcFilters';

export default function useLocalStorageFilters(type: ModuleType) {
  const setLocalStorageFilter = (value: any) => {
    const prevData = localStorage.getItem(vfcFiltersKey);
    const parsedPrevData = prevData ? JSON.parse(prevData) : {};

    const filters = {
      ...parsedPrevData,
      [type]: value,
    };
    localStorage.setItem(vfcFiltersKey, JSON.stringify(filters));
  };

  const getLocalStorageFilterByName = (keyName: string, initialValue?: any) => {
    const vfcFilters = localStorage.getItem(vfcFiltersKey);
    if (!vfcFilters) {
      return initialValue;
    }

    const parsed = JSON.parse(vfcFilters);

    return parsed?.[type]?.[keyName] ?? initialValue;
  };

  return { getLocalStorageFilterByName, setLocalStorageFilter };
}
