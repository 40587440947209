import { useEffect, useState } from 'react';

export default function useWindowScroll() {
  const [scrollX, setScrollX] = useState(0);
  const [scrollY, setScrollY] = useState(0);

  const handleOnScroll = (e: any) => {
    setScrollX(window.scrollX);
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleOnScroll);

    return () => {
      window.removeEventListener('scroll', handleOnScroll);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { scrollX, scrollY };
}
