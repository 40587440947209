export const downloadFileFromUrl = (url?: string, filename: string = 'no_name_file') => {
  if (!url) return;
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  link.download = filename;
  link.innerHTML = `Download`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
