import Button from '@components/shared/Button';
import breakpoints from '@lib/common/breakpoints';
import { useAuthContext } from '@lib/context/AuthContext/AuthContext';
import { useRouter } from '@lib/hooks/useRouter';
import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Form, FormFeedback } from 'reactstrap';
import styled from 'styled-components';
import { useLoginFormik } from '../../../lib/hooks/formik/useLoginFormik';
import { Input } from '../Input';

const StyledForm = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  padding: 150px 150px 120px;
  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 80px 24px 50px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 80px 24px 50px;
  }
`;
const StyledFormTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-bottom: 24px;
  @media only screen and ${breakpoints.maxWidth.md} {
    align-items: flex-start;
    flex-direction: column;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    align-items: flex-start;
    flex-direction: column;
  }
  h3 {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 36px;
    letter-spacing: 0.4px;
    line-height: 1.2em;
    color: ${(props) => props.theme.colors.black};
    margin-right: 20px;
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 24px;
      letter-spacing: 0.2px;
      margin-right: 0;
    }
    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 24px;
      letter-spacing: 0.2px;
      margin-right: 0;
    }
  }
  a {
    font-family: 'GTFlexa-StandardMedium';
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 1.24em;
    color: ${(props) => props.theme.colors.black};
    text-decoration: underline;
    margin-bottom: 5px;
    @media only screen and ${breakpoints.maxWidth.md} {
      margin-top: 5px;
    }
    @media only screen and ${breakpoints.maxWidth.sm} {
      margin-top: 5px;
    }
  }
`;
const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  @media only screen and ${breakpoints.maxWidth.md} {
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
  }
`;

const StyledButton = styled(Button)`
  font-size: 24px;
  padding: 24px 36px;
  margin-left: 16px;
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 20px;
    padding: 10px 18px;
    margin-left: 0;
    margin-top: 20px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 20px;
    padding: 10px 18px;
    margin-left: 0;
    margin-top: 20px;
  }
`;

const StyledForgot = styled.div`
  padding-left: 24px;
  margin-top: -20px;
  font-family: 'GTFlexa-StandardMedium', sans-serif;
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 1.2em;
  color: ${(props) => props.theme.colors.black};
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 14px;
    padding-left: 15px;
    margin-top: -10px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 14px;
    padding-left: 15px;
    margin-top: -10px;
  }
  a {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    text-decoration: underline;
  }
`;
const StyledFormFeedback = styled(FormFeedback)`
  font-family: 'GTFlexa-StandardMedium';
  font-size: 16px;
  letter-spacing: 0.2px;
  color: ${(props) => props.theme.colors.red};
  padding-top: 10px;
  text-align: right;
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 14px;
    padding-left: 15px;
    margin-top: -10px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 14px;
    padding-left: 15px;
    margin-top: -10px;
  }
`;

export const LoginForm = () => {
  const router = useRouter();
  const authCtx = useAuthContext();
  const { formatMessage: t } = useIntl();
  const formik = useLoginFormik({
    onSubmit: async (values, formikHelpers) => {
      if (authCtx.isLoading) return;
      try {
        await authCtx.login(values);

        router.history.push('/profile');
      } catch (e) {}
    },
  });

  return (
    <StyledForm className="LoginForm">
      <StyledFormTitle>
        <h3>{t({ id: 'login_data' })}</h3>
        <Link to="/register">{t({ id: 'you_dont_have_account' })}</Link>
      </StyledFormTitle>
      <Form inline onSubmit={formik.handleSubmit}>
        <Input
          className="LoginForm-input"
          type="email"
          name="username"
          id="username"
          label={t({ id: 'email' })}
          value={formik.values.username}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <Input
          type="password"
          name="password"
          id="password"
          label={t({ id: 'password' })}
          labelInfo="Password needs to be more than 8 characters"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <StyledForgot>
          {t({ id: 'forgot_password_click' })}
          <Link to="/forgot-password">{t({ id: 'here' })}</Link>.
        </StyledForgot>
        {/* @ts-ignore */}
        {authCtx.error && <StyledFormFeedback>{authCtx.error}</StyledFormFeedback>}

        <StyledButtonWrapper>
          <StyledButton type="button" onClick={() => router.history.push('/register')}>
            {t({ id: 'create_account' })}
          </StyledButton>
          <StyledButton type="submit" disabled={authCtx.isLoading} color="black">
            {authCtx.isLoading ? t({ id: 'loading' }) : t({ id: 'button_next_step' })}
          </StyledButton>
        </StyledButtonWrapper>
      </Form>
    </StyledForm>
  );
};
