import { Showreel } from '@api/showreels';
import { ReactComponent as ArrowLeft } from '@assets/icons/icons_arrow-left.svg';
import { ReactComponent as ArrowRight } from '@assets/icons/icons_arrow-right.svg';
import { ReactComponent as CloseIcon } from '@assets/icons/icons_close.svg';
import { ReactComponent as PauseButton } from '@assets/icons/icons_pause.svg';
import { ReactComponent as PlayButton } from '@assets/icons/icons_play.svg';
import { ReactComponent as SoundOff } from '@assets/icons/icons_sound-off.svg';
import { ReactComponent as SoundOn } from '@assets/icons/icons_sound-on.svg';
import { SimpleButton } from '@components/styled-components/StyledComponents';
import breakpoints from '@lib/common/breakpoints';
import Flickity from 'flickity';
import React, { FC, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  z-index: 1900;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
`;

const Content = styled.div`
  height: calc(100vh - 74px);
`;

const Slider = styled.div`
  height: 100%;
  .flickity-viewport {
    height: 100% !important;
  }
`;
const SliderItem = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const StyledReactPlayer = styled(ReactPlayer)`
  width: 100% !important;
  height: 100% !important;

  video {
    object-fit: cover;
  }
`;

const VideoContainer = styled.div<{ withHeader?: boolean }>`
  width: 100% !important;
  height: 706px;
  position: relative;

  @media only screen and ${breakpoints.maxWidth.sm} {
    height: 375px;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    height: 200px;
  }

  svg {
    cursor: pointer;
    width: 35px;
    height: 35px;
    margin-right: 10px;

    &:last-of-type {
      margin-right: 0;
    }

    path {
      stroke: #ffffff;
    }
  }
`;

const VideoControls = styled.div<{ withHeader?: boolean }>`
  z-index: 2001;
  position: absolute;
  bottom: ${(props) => (props.withHeader ? '90px' : '50px')};
  right: 30px;
  color: white;
  display: flex;
  align-items: center;
`;

const BottomBar = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 80px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${(props) => props.theme.colors.yellow};
  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 15px 40px;
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 15px 20px;
  }
`;

const BottomBarTitle = styled.h1`
  flex: 1;
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.11px;
  color: #000;
  padding-right: 15px;

  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 22px;
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 16px;
  }
`;

const BottomBarControls = styled.div`
  display: flex;
  align-items: center;

  button {
    width: 30px;
    margin-right: 36px;
    &:last-of-type {
      margin-right: 0;
    }

    @media only screen and ${breakpoints.maxWidth.md} {
      width: 20px;
      margin-right: 15px;
    }

    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 16px;
    }
  }
`;
const HiddenNavigation = styled.span`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 2000;

  > div {
    width: 50%;
  }
`;

const SoundOnIcon = styled(SoundOn)`
  g {
    stroke: #ffffff;
  }
`;

interface IFullscreenShowreelsProps {
  items?: Array<Showreel>;
  activeItem?: Showreel;
  className?: string;
  onClose?: () => void;
  onNext?: () => void;
  onPrev?: () => void;
  controls?: boolean;
}

const FullscreenShowreels: FC<IFullscreenShowreelsProps> = (props) => {
  const items = props.items ?? [];

  const flickityRef = useRef<any>(null);
  const bottomBarRef = useRef<any>(null);
  const playerRef = useRef<any>([]);

  const [playing, setPlaying] = useState<number | undefined>(undefined);
  const [muted, setMuted] = useState(false);

  useEffect(() => {
    const index = items.findIndex((item) => item.id === props.activeItem?.id);
    if (index !== -1) {
      playerRef?.current?.[index]?.seekTo(0);
    }

    setPlaying(props.activeItem?.id);
  }, [props.activeItem]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const elem = document.querySelector(`#showreel-fullscreen-slider`);
    const index = items.findIndex((item) => item.id === props.activeItem?.id);
    if (!elem) {
      return;
    }
    flickityRef.current = new Flickity(elem, {
      initialIndex: index ?? 0,
      groupCells: true,
      pageDots: false,
      prevNextButtons: false,
      draggable: false,
    });
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    document.body.classList.add('overflow-hidden');
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, []);

  const stopPlaying = () => {
    setPlaying(undefined);
  };

  const startPlaying = () => {
    setPlaying(props.activeItem?.id);
  };

  const unmute = () => {
    setMuted(false);
  };

  const mute = () => {
    setMuted(true);
  };

  const handlePrev = () => {
    stopPlaying();
    if (!flickityRef.current) {
      return;
    }
    (flickityRef.current as any).previous();
    props.onPrev?.();
  };

  const handleNext = () => {
    stopPlaying();
    if (!flickityRef.current) {
      return;
    }
    (flickityRef.current as any).next();
    props.onNext?.();
  };

  const bottomBarHeight = (bottomBarRef?.current as any)?.offsetHeight;
  const currentItem = items.find((item) => item.id === props.activeItem?.id);
  const currentItemIndex = items.findIndex((item) => item.id === currentItem?.id);

  return (
    <Wrapper className={props.className}>
      <Content style={{ height: `calc(100% - ${bottomBarHeight}px)` }}>
        <Slider id="showreel-fullscreen-slider">
          {items.map((item, index) => (
            <SliderItem className="fullscreen-slider-item" key={`showreel-slider-item-${item.id}`}>
              <VideoContainer>
                {!props.controls && (
                  <HiddenNavigation>
                    <div onClick={handlePrev} role="button" />
                    <div onClick={handleNext} role="button" />
                  </HiddenNavigation>
                )}

                <StyledReactPlayer
                  controls={props.controls}
                  stopOnUnmount
                  ref={(element: any) => (playerRef.current[index] = element)}
                  playing={playing === item.id}
                  url={item.video_id}
                  onPause={() => {}}
                  volume={muted ? 0 : 1}
                />

                <VideoControls>
                  {playing === item.id ? <PauseButton onClick={stopPlaying} /> : <PlayButton onClick={startPlaying} />}
                  {muted ? <SoundOff onClick={unmute} /> : <SoundOnIcon onClick={mute} />}
                </VideoControls>
              </VideoContainer>
            </SliderItem>
          ))}
        </Slider>
      </Content>

      <BottomBar ref={bottomBarRef}>
        <BottomBarTitle>{currentItem?.post_title}</BottomBarTitle>
        <BottomBarControls>
          <SimpleButton onClick={handlePrev} disabled={currentItemIndex === 0}>
            <ArrowLeft />
          </SimpleButton>
          <SimpleButton onClick={handleNext} disabled={currentItemIndex === items?.length - 1}>
            <ArrowRight />
          </SimpleButton>
          <SimpleButton onClick={props.onClose}>
            <CloseIcon />
          </SimpleButton>
        </BottomBarControls>
      </BottomBar>
    </Wrapper>
  );
};

export default FullscreenShowreels;
