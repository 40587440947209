import { getBranchDetails, GetBranchResponse } from '@api/branch';
import QueryKeys from '@lib/constants/QueryKeys';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

export default function useGetBranchDetails(slug?: string) {
  return useQuery<GetBranchResponse, AxiosError>(QueryKeys.branchDetails(slug), () => getBranchDetails(slug), {
    enabled: Boolean(slug),
  });
}
