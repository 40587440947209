import Button from '@components/shared/Button';
import { Container } from '@components/shared/Container';
import { ProfileEditForm } from '@components/user/Profile/ProfileEditForm';
import breakpoints from '@lib/common/breakpoints';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

const StyledProfileEdit = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.yellow};
  padding: 120px 0;
  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 80px 0;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 80px 0;
  }
`;

const StyledPageHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 120px;
  @media only screen and ${breakpoints.maxWidth.md} {
    margin-bottom: 30px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    margin-bottom: 30px;
  }
  h1 {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 58px;
    line-height: 1.05em;
    color: ${(props) => props.theme.colors.black};
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 28px;
      line-height: 1.18em;
      margin-bottom: 24px;
    }

    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 24px;
      line-height: 1.18em;
      margin-bottom: 24px;
    }
  }
`;
const StyledFormTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-bottom: 24px;
  h3 {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 36px;
    letter-spacing: 0.4px;
    line-height: 1.2em;
    color: ${(props) => props.theme.colors.black};
    margin-right: 20px;
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 24px;
      letter-spacing: 0.2px;
      margin-right: 0;
    }
    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 24px;
      letter-spacing: 0.2px;
      margin-right: 0;
    }
  }
`;
const StyledProfileDeleteSuccess = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  padding: 120px 150px;
  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 80px 20px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 80px 20px;
  }
`;
const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  @media only screen and ${breakpoints.maxWidth.md} {
    margin-top: 20px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    margin-top: 20px;
  }
`;

const StyledButton = styled(Button)`
  font-size: 24px;
  padding: 24px 36px;
  @media only screen and ${breakpoints.maxWidth.md} {
    width: 100%;
    font-size: 20px;
    padding: 10px 18px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    width: 100%;
    font-size: 20px;
    padding: 10px 18px;
  }
`;

interface Props {}

export const ProfileEdit = (props: RouteComponentProps<Props>) => {
  const { formatMessage: t } = useIntl();
  const [deletedSuccessfully, setDeletedSuccessfully] = useState(false);

  return (
    <StyledProfileEdit className="ProfileEdit">
      <Container>
        <StyledPageHeader>
          <h1>{t({ id: 'profile.edit' })}</h1>
        </StyledPageHeader>
        {deletedSuccessfully ? (
          <StyledProfileDeleteSuccess className="ProfileDeleteSuccess">
            <StyledFormTitle>
              <h3>{t({ id: 'profile.edit.success' })}</h3>
            </StyledFormTitle>
            <StyledButtonWrapper>
              <StyledButton color="black" onClick={() => props.history.push('/profile')}>
                {t({ id: 'back_to_profile' })}
              </StyledButton>
            </StyledButtonWrapper>
          </StyledProfileDeleteSuccess>
        ) : (
          <ProfileEditForm setDeletedSuccessfully={setDeletedSuccessfully} />
        )}
      </Container>
    </StyledProfileEdit>
  );
};
