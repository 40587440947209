import Modules from '@lib/constants/Modules';
import { useMemo } from 'react';

export default function useModules() {
  const modules = useMemo(() => Modules, []);

  const getModuleByName = (name?: string) => {
    if (!name) return null;
    return modules ? (modules as any)[name] : null;
  };

  return {
    modules,
    getModuleByName,
  };
}
