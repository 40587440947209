import { ReactComponent as Facebook } from '@assets/icons/icons_facebook.svg';
import { ReactComponent as Instagram } from '@assets/icons/icons_instagram.svg';
import { ReactComponent as Youtube } from '@assets/icons/icons_youtube.svg';
import { ReactComponent as Vimeo } from '@assets/icons/icon_vimeo.svg';
import Button from '@components/shared/Button';
import ContentSlider from '@components/shared/ContentSlider';
import breakpoints from '@lib/common/breakpoints';
import ModuleType from '@lib/interfaces/Modules';
import styled, { useTheme } from 'styled-components';

const Module05Container = styled.div`
  display: flex;

  @media only screen and ${breakpoints.maxWidth.xs} {
    flex-direction: column;
  }
`;

const GridItem = styled.div<{ fullWidth?: boolean }>`
  width: ${(props) => (props.fullWidth ? '100%' : '50%')};
  border-right: 2px solid #000000;
  &:last-of-type {
    border-right: none;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    width: 100%;
    border-right: none;
  }
`;

const Module05TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${(props) => props.theme.colors.yellow};
`;

const Module05TextWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;

  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 40px;
  }
`;

const Module05TextParagraph = styled.p`
  max-width: 458px;
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  font-size: 36px;
  font-weight: 300;
  line-height: 1.17;
  color: #000000 !important;
  letter-spacing: 0.4px;

  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 24px;
    line-height: 1.25;
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 18px;
    line-height: 1.22;
    letter-spacing: 0.2px;
  }
`;

const Module05TextButton = styled(Button)`
  width: 100%;
  border-width: 2px !important;
  border-right: none !important;
  border-left: none !important;
  margin: 0 !important;

  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 22px;
    line-height: 1.36;
    padding: 10px;
  }
`;

const Module05TextSocialLinks = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-bottom: 2px solid #000000;

  @media only screen and ${breakpoints.maxWidth.md} {
    height: 51px;
  }
`;

const SocialLink = styled.a`
  margin-right: 40px;
  display: flex;
  align-items: center;

  &:hover {
    fill: ${(props) => props.theme.colors.red};
  }

  &:last-of-type {
    margin-right: 0;
  }

  @media only screen and ${breakpoints.maxWidth.md} {
    margin-right: 26px;

    svg {
      height: 23px;
      width: 23px;
    }
  }
`;

const ButtonLinkWrapper = styled.a`
  width: auto;
  height: auto;
  display: flex;
`;

export interface Module05Button {
  target?: string;
  title?: string;
  url?: string;
}

export interface Module05Data {
  full_width?: boolean;
  show_white_background?: boolean;
  colors?: string;
  title?: string;
  pre_title?: string;
  description?: string;
  posts?: Array<object>;

  location_items?: Array<object>;
  branche_items?: Array<object>;
  news_items?: Array<object>;
  showreels_items?: Array<object>;

  post_type?: string;
  number_of_posts?: string;
  button?: Module05Button;
}

interface TextModule {
  button_1?: Module05Button;
  button_2?: Module05Button;
  social: { facebook?: string; instagram?: string; vimeo?: string; youtube?: string };
  text?: string;
}

interface Module05Props {
  autoPlay?: boolean;
  infinite?: boolean;
  hideAllButton?: boolean;
  data?: {
    left_side?: Module05Data;
    right_side?: Module05Data;
    show_text_module?: string;
    left_side_only?: boolean;
    text_module?: TextModule;
  };
}

const buttonColors: any = {
  white: undefined, // white is default Button color prop.
  '9D446E': 'violet',
  E3F1A2: 'yellow',
};

const MappedDataKeys = {
  branches: 'branche_items',
  locations: 'location_items',
  news: 'news_items',
  showreels: 'showreels_items',
};

const getPostsByPostType = (data?: Module05Data, type?: string): Array<object> => {
  if (!data || !type) {
    return [];
  }

  const key = (MappedDataKeys as any)[type];
  if (!key) {
    return [];
  }

  return (data?.[key as keyof Module05Data] ?? []) as Array<object>;
};

export default function Module05(props: Module05Props) {
  const { data } = props;
  const theme: any = useTheme();

  const leftData = data?.left_side;
  const buttonLeftColor = leftData?.colors ? buttonColors[leftData.colors] : buttonColors['white'];
  const leftBackgroundColor = leftData?.colors ? `#${leftData?.colors}` : theme.colors.yellow;
  const showLeftWhiteIcons = theme.colors?.violet?.toLowerCase()?.includes(leftData?.colors?.toLowerCase());
  const leftPosts = getPostsByPostType(leftData, leftData?.post_type) ?? [];

  const rightData = data?.right_side;
  const buttonRightColor = rightData?.colors ? buttonColors[rightData.colors] : buttonColors['white'];
  const rightBackgroundColor = rightData?.colors ? `#${rightData?.colors}` : theme.colors.yellow;
  const showRightWhiteIcons = theme.colors?.violet?.toLowerCase()?.includes(rightData?.colors?.toLowerCase());
  const rightPosts = getPostsByPostType(rightData, rightData?.post_type) ?? [];

  const isFullWidth = Boolean(data?.left_side_only);

  return (
    <Module05Container data-module={ModuleType.MODULE_05}>
      <GridItem fullWidth={isFullWidth}>
        <ContentSlider
          posts={leftPosts}
          infinite={props.infinite}
          autoPlay={props.autoPlay}
          pre_title={leftData?.pre_title}
          title={leftData?.title}
          description={leftData?.description}
          buttonColor={buttonLeftColor}
          button={leftData?.button}
          whiteIcons={showLeftWhiteIcons}
          hideAllButton={props.hideAllButton || isFullWidth}
          backgroundColor={leftBackgroundColor}
        />
      </GridItem>

      {!isFullWidth && (
        <GridItem>
          {!data?.show_text_module ? (
            <ContentSlider
              posts={rightPosts}
              infinite={props.infinite}
              autoPlay={false}
              pre_title={rightData?.pre_title}
              title={rightData?.title}
              description={rightData?.description}
              whiteIcons={showRightWhiteIcons}
              buttonColor={buttonRightColor}
              button={rightData?.button}
              hideAllButton={props.hideAllButton}
              backgroundColor={rightBackgroundColor}
            />
          ) : (
            <Module05Text data={data?.text_module} />
          )}
        </GridItem>
      )}
    </Module05Container>
  );
}

function Module05Text(props: { data?: TextModule }) {
  return (
    <Module05TextContainer>
      <Module05TextWrapper>
        <Module05TextParagraph>{props.data?.text}</Module05TextParagraph>
      </Module05TextWrapper>
      <div>
        {props.data?.button_1 && (
          <ButtonLinkWrapper href={props.data?.button_1?.url} target={props.data?.button_1?.target}>
            <Module05TextButton color="black">{props.data?.button_1?.title}</Module05TextButton>
          </ButtonLinkWrapper>
        )}
        {props.data?.button_2 && (
          <ButtonLinkWrapper href={props.data?.button_2?.url} target={props.data?.button_2?.target}>
            <Module05TextButton color="black">{props.data?.button_2?.title}</Module05TextButton>
          </ButtonLinkWrapper>
        )}
      </div>
      <Module05TextSocialLinks>
        {props.data?.social.vimeo && (
          <SocialLink href={props.data?.social.vimeo}>
            <Vimeo />
          </SocialLink>
        )}
        {props.data?.social.instagram && (
          <SocialLink href={props.data?.social.instagram}>
            <Instagram />
          </SocialLink>
        )}
        {props.data?.social.facebook && (
          <SocialLink href={props.data?.social.facebook}>
            <Facebook />
          </SocialLink>
        )}
        {props.data?.social.youtube && (
          <SocialLink href={props.data?.social.youtube}>
            <Youtube />
          </SocialLink>
        )}
      </Module05TextSocialLinks>
    </Module05TextContainer>
  );
}
