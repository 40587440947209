import { deleteRequest, get, ISelect, post } from '@api/axios';
import { File } from '@api/press';
import objectToQueryString from '@lib/helpers/objectToQueryString';
import { LocationDeleteFields } from '@lib/hooks/formik/useLocationDeleteFormik';
import { LocationUploadFile, LocationUploadVideo } from '@lib/hooks/formik/useLocationInfoFormik';
import { ObjectAny, PaginatedData, PaginationParams } from '@lib/interfaces';

export interface GetLocationsInput extends PaginationParams {}

export interface LocationItem extends ObjectAny {
  id: number;
  title: string;
  thumbnail: string;
  slug: string;
  date: string;
  description: string;
  bookmarked?: boolean;
  public_private?: string;
  post_status: string;
  thumbnails: {
    thumbnail: string;
    medium: string;
    medium_large: string;
    large: string;
  };
}

export interface AddOrRemoveLocationBookmarkInput {
  locationId?: number;
}

export interface GetLocationsResponse extends PaginatedData {
  locations: Array<LocationItem>;
}

export interface GetMyLocationsResponse extends PaginatedData {
  data: Array<LocationItem>;
}

export interface GetMyLocationsInput {
  showAll?: number;
}

export interface LocationsFormDataResponse {
  location_categories: ISelect[];
  epochs: ISelect[];
  art: ISelect[];
  ausstattung: ISelect[];
  step?: number;
  error?: string;
}

export interface LocationsStepResponse {
  message?: string;
  success?: boolean;
  error?: string;
  data?: {
    location_id: number;
  };
}

export interface LocationsStep1Input {
  step: '1';
  slug?: string;
  location_name: string;
  location_categories: number[];
  new_location_category: string; //new
  epochs: number[];
  location_art: number;
  company_name: string;
  location_ausstattung: number[];
  motive_description: string;
  main_photo_id: number;
  other_photos: LocationUploadFile[];
  videos: LocationUploadVideo[];
  company_law_registration: string;
}
export interface LocationsStep2Input {
  step: '2';
  slug?: string;
  location_id?: number;
  company: string;
  street: string;
  postcode: string;
  ort: string;
  location_contact_person: string;
  email: string;
  phone: string;
}
export interface LocationsStep3Input {
  step: '3';
  slug?: string;
  location_id?: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
}
export interface LocationsStep4Input {
  step: '4';
  slug?: string;
  location_id?: number;
  accept_terms_conditions: number;
  i_wee: string;
}

export interface GetLocationResponse {
  id: number;
  author_id: string;
  title: string;
  slug: string;
  thumbnail_caption?: string;
  thumbnail: string;
  main_photo_id_attachment_id: number;
  thumbnails: {
    thumbnail: string;
    medium: string;
    medium_large: string;
    large: string;
  };
  date: string;
  categories: {
    location_categories: ISelect[];
    epochs: ISelect[];
    location_ausstattung: ISelect[];
    location_art: ISelect[];
  };
  company_law_registration: string;
  motive_description: string;
  motif_description: string;
  other_photos: {
    image: File;
    image_title: string;
    image_copyright: string;
  }[];
  videos: {
    video_link: string;
    video_title: string;
    video_copyright: string;
    video_thumbnail: string;
  }[];
  photo_gallery: {
    image: File;
    image_title: string;
    image_copyright: string;
  }[];
  video_gallery: {
    video_link: string;
    video_title: string;
    video_copyright: string;
    video_thumbnail: string;
  }[];
  vfc_translate_text: boolean;
  company_name: string;
  public_private: 'private' | 'public' | null;
  short_description: string;
  long_description: string;
  description: string;
  i_wee: string;
  accept_terms_conditions: boolean;

  location_contact_person: string;
  company: string;
  street: string;
  postcode: string;
  ort: string;
  email: string;
  phone: string;
}
export interface GetLocationEditResponse {
  location: GetLocationResponse;
  art: ISelect[];
  ausstattung: ISelect[];
  epochs: ISelect[];
  location_categories: ISelect[];
}

export interface DeleteLocationsResponse {
  message?: string;
  error?: string;
}
export interface GetRelatedLocationsInput extends PaginationParams {
  slug?: string;
}

export const getLocations = (params: GetLocationsInput) => {
  return get<GetLocationsInput, GetLocationsResponse>({ url: `v1/locations/all?${objectToQueryString(params)}` });
};

export const getLocationBookmarks = () => {
  return get<undefined, any>({ url: 'v1/bookmarks/all' });
};

export const addLocationBookmark = async (data: AddOrRemoveLocationBookmarkInput) =>
  post<AddOrRemoveLocationBookmarkInput, Array<LocationItem>>({ url: 'v1/bookmarks/add', data });

export const removeLocationBookmark = async (data: AddOrRemoveLocationBookmarkInput) =>
  post<AddOrRemoveLocationBookmarkInput, Array<LocationItem>>({ url: 'v1/bookmarks/remove', data });

export const getLocationsFilters = async (letter?: string) =>
  get<undefined, ObjectAny>({
    url: 'v1/locations/filters',
    params: {
      letter,
    },
  });

export const getLocationsDetails = (slug?: string) => get<{ slug?: string }, GetLocationResponse>({ url: `v1/locations/${slug}/single` });

export const getLocationsEdit = (slug?: string) => get<{ slug?: string }, GetLocationEditResponse>({ url: `v1/locations/${slug}/edit-data` });

export const getMyLocations = () => {
  return get<undefined, GetMyLocationsResponse>({ url: `v1/locations/myLocations?${objectToQueryString({ showAll: -1 })}` });
};
export const getLocationsBySlug = (slugs: string[]) => {
  return get<string[], Array<LocationItem>>({
    url: `v1/locations/multiple_locations`,
    params: {
      slugs,
    },
  });
};

export const locationsStep1 = async (data: LocationsStep1Input) =>
  post<LocationsStep1Input, LocationsStepResponse>({ url: 'v1/locations/register', data });

export const locationsStep2 = async (data: LocationsStep2Input) =>
  post<LocationsStep2Input, LocationsStepResponse>({ url: 'v1/locations/register', data });

export const locationsStep3 = async (data: LocationsStep3Input) =>
  post<LocationsStep3Input, LocationsStepResponse>({ url: 'v1/locations/register', data });

export const locationsStep4 = async (data: LocationsStep4Input) =>
  post<LocationsStep4Input, LocationsStepResponse>({ url: 'v1/locations/register', data });

export const locationsStep1Edit = async (data: LocationsStep1Input) =>
  post<LocationsStep1Input, LocationsStepResponse>({ url: 'v1/locations/edit', data });

export const locationsStep2Edit = async (data: LocationsStep2Input) =>
  post<LocationsStep2Input, LocationsStepResponse>({ url: 'v1/locations/edit', data });

export const locationsStep3Edit = async (data: LocationsStep3Input) =>
  post<LocationsStep3Input, LocationsStepResponse>({ url: 'v1/locations/edit', data });

export const locationsStep4Edit = async (data: LocationsStep4Input) =>
  post<LocationsStep4Input, LocationsStepResponse>({ url: 'v1/locations/edit', data });

export const getLocationsFormData = async () => get<undefined, LocationsFormDataResponse>({ url: 'v1/locations/form_data' });

export const deleteLocations = async (data: LocationDeleteFields) =>
  deleteRequest<LocationDeleteFields, DeleteLocationsResponse>({ url: 'v1/locations/delete', data });

export const getRelatedLocations = ({ slug, ...rest }: GetRelatedLocationsInput) => {
  return get<GetRelatedLocationsInput, GetLocationsResponse>({ url: `v1/locations/${slug}/related?${objectToQueryString(rest)}` });
};
