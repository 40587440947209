import Button from '@components/shared/Button';
import { Container } from '@components/shared/Container';
import breakpoints from '@lib/common/breakpoints';
import ModuleType from '@lib/interfaces/Modules';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: #ffffff;
  padding: 80px 0;
  width: 100%;
  max-width: 1085px;

  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 40px 20px;
  }

  h3 {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 24px;
    line-height: 2em;

    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 18px;
      line-height: 1.36;
    }
  }

  p {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 36px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: 0.4px;
    margin-top: 20px;
    margin-bottom: 40px;

    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 18px;
      line-height: ${(props) => props.theme.helpers.pxToRem(22)};
      letter-spacing: 0.2px;
    }
  }

  button {
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    line-height: 1.36;
    padding: 0 74px;
    padding-top: 8px;
    padding-bottom: 8px;

    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 16px;
      padding: 0 20px;
    }
  }
`;

interface IModule19Props {
  data?: {
    title?: string;
    description?: string;
    button?: {
      title?: string;
      url?: string;
      target?: string;
    };
  };
}

const Module19: FC<IModule19Props> = (props) => {
  const { data } = props;
  return (
    <Container>
      <Wrapper data-module={ModuleType.MODULE_19}>
        <h3>{data?.title}</h3>
        <p>{data?.description}</p>
        <div>
          <Link to={data?.button?.url ?? '/'} target={data?.button?.target}>
            <Button>{data?.button?.title}</Button>
          </Link>
        </div>
      </Wrapper>
    </Container>
  );
};

export default Module19;
