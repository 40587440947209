import { ReactComponent as Facebook } from '@assets/icons/icons_facebook.svg';
import { ReactComponent as Instagram } from '@assets/icons/icons_instagram.svg';
import { ReactComponent as Youtube } from '@assets/icons/icons_youtube.svg';
import { ReactComponent as Vimeo } from '@assets/icons/icon_vimeo.svg';
import breakpoints from '@lib/common/breakpoints';
import useGetPagesGeneralInfo from '@lib/hooks/queries/useGetPagesGeneralInfo';
import ModuleType from '@lib/interfaces/Modules';
import styled from 'styled-components';

const Wrapper = styled.div<{ backgroundColor?: string }>`
  display: flex;
  background-color: ${(props) => props.backgroundColor || props.theme.colors.yellow};

  @media only screen and ${breakpoints.maxWidth.xs} {
    flex-direction: column;
  }
`;

const Item = styled.div`
  width: 50%;
  border-right: 2px solid #000000;
  &:last-of-type {
    border-right: none;
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    width: 100%;
    border-right: none;
  }
`;

const Left = styled.div`
  padding: 84px 10px;

  p {
    margin: 0 auto;
    max-width: 458px;
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 36px;
    font-weight: 300;
    line-height: 1.17;
    letter-spacing: 0.4px;
  }

  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 40px;
    p {
      max-width: 296px;
      font-size: 18px;
      line-height: 1.25;
    }
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 45px;
  }
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const RightText = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;

  a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 48px;
    line-height: 60px;
    background-color: #000000;
    color: ${(props) => props.theme.colors.white};
    border: 2px solid transparent;

    &:hover {
      background-color: #ffffff !important;
      color: #000000;
      border-color: #000000;
      border-left: transparent;
    }
  }

  @media only screen and ${breakpoints.maxWidth.md} {
    justify-content: center;
    align-items: flex-start;
    a {
      padding-top: 40px;
      padding-bottom: 35px;
      padding-left: 15px;
      padding-right: 15px;
      font-size: 22px;
      line-height: 1.25;
    }
  }
`;

const SocialMedia = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-bottom: 2px solid #000000;

  @media only screen and ${breakpoints.maxWidth.md} {
    height: 60px;
  }
`;

const SocialLink = styled.a`
  margin-right: 40px;
  display: flex;
  align-items: center;

  &:hover {
    fill: ${(props) => props.theme.colors.darkRed};
  }

  &:last-of-type {
    margin-right: 0;
  }

  @media only screen and ${breakpoints.maxWidth.md} {
    margin-right: 26px;

    svg {
      height: 23px;
      width: 23px;
    }
  }
`;

interface Module16Props {
  data?: {
    button?: {
      target?: string;
      title?: string;
      url?: string;
    };
    left_text?: string;
    backgroundColor?: string;
  };
}

export default function Module16(props: Module16Props) {
  const { data } = props;

  const generalInfo = useGetPagesGeneralInfo();

  const socialMedia = generalInfo?.data?.footer?.social;

  return (
    <Wrapper data-module={ModuleType.MODULE_16} backgroundColor={props.data?.backgroundColor}>
      <Item>
        <Left>
          <p>{data?.left_text}</p>
        </Left>
      </Item>
      <Item>
        <Right>
          <RightText>
            <a href={'mailto:office@viennafilmcommission.at'} target={data?.button?.target}>
              {data?.button?.title}
            </a>
          </RightText>

          <SocialMedia>
            <SocialLink href={socialMedia?.vimeo}>
              <Vimeo />
            </SocialLink>
            <SocialLink href={socialMedia?.instagram}>
              <Instagram />
            </SocialLink>
            <SocialLink href={socialMedia?.facebook}>
              <Facebook />
            </SocialLink>
            <SocialLink href={socialMedia?.youtube}>
              <Youtube />
            </SocialLink>
          </SocialMedia>
        </Right>
      </Item>
    </Wrapper>
  );
}
