import { Container } from '@components/shared/Container';
import RenderWordPressContent from '@components/shared/RenderWordPressContent';
import breakpoints from '@lib/common/breakpoints';
import ModuleType from '@lib/interfaces/Modules';
import React, { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding-top: 120px;
  padding-bottom: 120px;
  width: 100%;

  @media only screen and ${breakpoints.maxWidth.sm} {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

const StyledTitle = styled.h1`
  width: 100%;
  max-width: 900px;
  font-family: 'GTFlexa-ExtendedMedium', sans-serif;
  font-size: 58px;
  line-height: 1.2em;
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 24px;
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 38px;
  }
  @media only screen and ${breakpoints.maxWidth.xs} {
    font-size: 32px;
    line-height: 1.15;
  }
`;

interface IModule27Props {
  data?: {
    title: string;
    content: string;
  };
}

const Module27: FC<IModule27Props> = (props) => {
  return (
    <Container>
      <Wrapper data-module={ModuleType.MODULE_27}>
        <StyledTitle>{props.data?.title}</StyledTitle>
        <RenderWordPressContent content={props.data?.content} />
      </Wrapper>
    </Container>
  );
};

export default Module27;
