import { ISelect } from '@api/axios';
import breakpoints from '@lib/common/breakpoints';
import { useRouter } from '@lib/hooks/useRouter';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

const Wrapper = styled.a`
  cursor: pointer;

  &:hover {
    span,
    p,
    h6,
    h2 {
      color: #e20714;
    }

    img {
      transform: scale(1.1);
    }
  }
`;

const TitleContentWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: column;

  h6 {
    font-family: 'GTFlexa-Medium', sans-serif;
    font-size: 16px;
    margin-bottom: 10px;
    color: #000;
    flex: 1;
    opacity: 0.5;
    text-transform: uppercase;
  }

  h2 {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    text-transform: inherit;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #000;
    /* word-break: break-all; */
    flex: 1;
    margin-bottom: 16px;

    @media only screen and ${breakpoints.maxWidth.xs} {
      font-size: 16px;
      line-height: 1.15;
    }
  }

  p {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    text-transform: inherit;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.2px;
    color: #000;
    flex: 1;
    margin-bottom: 8px;

    @media only screen and ${breakpoints.maxWidth.xs} {
      font-size: 14px;
      line-height: 1.15;
    }
  }

  span {
    display: block;
    font-family: 'GTFlexa-StandardLight', sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    text-transform: inherit;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.2px;
    color: #000;
    flex: 1;

    @media only screen and ${breakpoints.maxWidth.xs} {
      font-size: 13px;
      line-height: 1.15;
    }
  }
`;

interface IProductionListItem {
  ID?: number | null;
  veroffentlicht?: string;
  post_author?: string | null;
  post_content: string | null;
  post_date?: string | null;
  post_name: string | null;
  post_title: string | null;
  post_type: string | null;
  thumbnail?: string | null;

  post_categories?: ISelect[];
}

interface IProductionListItemProps {
  productionItem: IProductionListItem;
}

const ProductionListItem: FC<IProductionListItemProps> = (props) => {
  const router = useRouter();
  const { formatMessage: t } = useIntl();

  const { productionItem } = props;

  return (
    <Wrapper onClick={() => router.history.push(`/production/${productionItem.post_name}`)}>
      <TitleContentWrapper>
        <h6>{productionItem.veroffentlicht === 'In Produktion' ? t({ id: 'in.production' }) : t({ id: 'released' })}</h6>
        <h2>{productionItem.post_title}</h2>
        <p>{productionItem.post_content}</p>
        <span>
          {productionItem?.post_categories &&
            Array.isArray(productionItem.post_categories) &&
            productionItem.post_categories.map((el) => el.name + ',')}
          {productionItem.post_date?.split('.')[2]}
        </span>
      </TitleContentWrapper>
    </Wrapper>
  );
};

export default ProductionListItem;
