import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';

export interface ForgotPasswordFields {
  username: string;
}

interface UseForgotPasswordOptions {
  initialValues?: ForgotPasswordFields;
  onSubmit: (values: ForgotPasswordFields, formikHelpers: FormikHelpers<ForgotPasswordFields>) => Promise<any>;
}

export const useForgotPasswordFormik = (options: UseForgotPasswordOptions) => {
  const LoginSchema = Yup.object().shape({
    username: Yup.string().email('Please enter a valid email!').required('Email is required'),
  });

  return useFormik({
    initialValues: {
      username: '',
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: LoginSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type ForgotPasswordik = ReturnType<typeof useForgotPasswordFormik>;
