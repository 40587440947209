import { Language, VFC_LANGUAGE_KEY } from '@lib/context/ReactIntlProvider/ReactIntlProvider';

export default function getLanguageFromLocalStorage() {
  const urlLanguage = window.location.pathname.slice(1, 3);
  if ((Language as any)[urlLanguage.toUpperCase() as string]) {
    localStorage.setItem(VFC_LANGUAGE_KEY, urlLanguage);
    return urlLanguage as Language;
  }

  const browserLanguage = window.navigator.language;
  const localStorageLang = localStorage.getItem(VFC_LANGUAGE_KEY);
  if (!localStorageLang || !(Language as any)[localStorageLang.toUpperCase() as string]) {
    const lng = browserLanguage?.includes('de') ? Language.DE : Language.EN;
    localStorage.setItem(VFC_LANGUAGE_KEY, lng);
    return lng;
  }

  return localStorageLang as Language;
}
