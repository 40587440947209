import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';

export interface ProfileChangePasswordFields {
  old_password: string;
  password: string;
  password_confirmation: string;
}

interface UseProfileChangePasswordFormOptions {
  initialValues?: ProfileChangePasswordFields;
  onSubmit: (values: ProfileChangePasswordFields, formikHelpers: FormikHelpers<ProfileChangePasswordFields>) => Promise<any>;
}

export const useProfileChangePasswordFormik = (options: UseProfileChangePasswordFormOptions) => {
  const BranchSchema = Yup.object().shape({
    old_password: Yup.string().required('Old password is required'),
    password: Yup.string().required('Password is required'),
    password_confirmation: Yup.string()
      .required('Password is required')
      .oneOf([Yup.ref('password'), null], 'Das Passwort stimmt nicht überein.'),
  });

  return useFormik<ProfileChangePasswordFields>({
    initialValues: {
      old_password: '',
      password: '',
      password_confirmation: '',
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: BranchSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type ProfileChangePasswordFormik = ReturnType<typeof useProfileChangePasswordFormik>;
