import { ReactNode } from 'react';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';

interface ThemeProviderProps {
  children: ReactNode;
}

const theme = {
  colors: {
    white: '#ffffff',
    black: '#000000',
    violet: '#9d446e',
    yellow: '#e3f1a2',
    red: '#ff3d3d',
    mediumRed: '#e20714',
    darkRed: '#c41522',
  },
  helpers: {
    pxToRem: (px: number) => {
      return (1 / 16) * px + 'rem';
    },
  },
};

export default function ThemeProvider(props: ThemeProviderProps) {
  return <StyledComponentsThemeProvider theme={theme}>{props.children}</StyledComponentsThemeProvider>;
}
