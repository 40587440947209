import { ObjectAny } from '@lib/interfaces';

const objectToStringParams = (filters?: ObjectAny) => {
  return filters && Object.keys(filters)?.length > 0
    ? Object.values(filters)
        ?.filter((x) => !!x)
        .join(',')
    : '';
};

export default objectToStringParams;
