import { Container } from '@components/shared/Container';
import Image from '@components/shared/Image';
import breakpoints from '@lib/common/breakpoints';
import ModuleType from '@lib/interfaces/Modules';
import styled from 'styled-components';

const Module14Wrapper = styled.div`
  padding: 74px 0;

  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 80px 0;
  }
`;

const Title = styled.h1`
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  font-size: 48px;
  font-weight: normal;
  line-height: 1.25;

  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 24px;
    line-height: 1.17;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 35px;
  margin-right: 35px;

  @media only screen and ${breakpoints.maxWidth.lg} {
    justify-content: flex-start;
  }

  @media only screen and ${breakpoints.maxWidth.md} {
    > div {
      margin-right: 0;
      flex: 1 0 50%;
    }
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 30px;
  }
`;

const Col = styled.div`
  margin-right: 40px;
  margin-bottom: 40px;
`;

const StyledImage = styled(Image)`
  max-height: 90px;
  max-width: 200px;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  object-fit: contain;

  &:hover {
    cursor: pointer;
    filter: none;
    -webkit-filter: grayscale(0);
  }
`;

const PartnersRow = styled.div`
  margin-bottom: 40px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

interface Partner {
  link?: {
    target?: string;
    title?: string;
    url?: string;
  };
  logo?: {
    ID?: number;
    url?: string;
  };
}

interface Props {
  data?: {
    title?: string;
    partners?: Partner[];
    title_2?: string;
    partners_2: Partner[];
  };
}

const getUrl = (url?: string) => {
  if (!url) {
    return {
      other: false,
      url: '',
    };
  }

  if (url.includes('http')) {
    return {
      other: true,
      url: url,
    };
  }

  return {
    other: false,
    url: url,
  };
};

export default function Module14(props: Props) {
  const { data } = props;

  const partners = data?.partners ?? [];
  const partners2 = data?.partners_2 ?? [];

  return (
    <Module14Wrapper data-module={ModuleType.MODULE_14}>
      <Container>
        <PartnersRow>
          <Title>{data?.title}</Title>
          <Row>
            {partners.map((partner, index: number) => {
              const urlData = getUrl(partner?.link?.url);
              return (
                <Col key={`${partner?.logo?.ID}-${index}`}>
                  {urlData.other ? (
                    <a href={urlData.url} target={partner?.link?.target}>
                      <StyledImage src={partner?.logo?.url} />
                    </a>
                  ) : (
                    <a href={`${partner.link?.url}`} target={partner.link?.target}>
                      <StyledImage src={partner?.logo?.url} />
                    </a>
                  )}
                </Col>
              );
            })}
          </Row>
        </PartnersRow>
        <PartnersRow>
          <Title>{data?.title_2}</Title>
          <Row>
            {partners2.map((partner, index: number) => {
              return (
                <Col key={`${partner?.logo?.ID}-${index}`}>
                  <a href={partner.link?.url ?? '/'} target={partner.link?.target}>
                    <StyledImage src={partner?.logo?.url} />
                  </a>
                </Col>
              );
            })}
          </Row>
        </PartnersRow>
      </Container>
    </Module14Wrapper>
  );
}
