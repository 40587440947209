import { ReactComponent as Close } from '@assets/icons/icons_close.svg';
import { ReactComponent as Facebook } from '@assets/icons/icons_facebook.svg';
import { ReactComponent as Instagram } from '@assets/icons/icons_instagram.svg';
import { ReactComponent as IconUser } from '@assets/icons/icons_profile.svg';
import { ReactComponent as Youtube } from '@assets/icons/icons_youtube.svg';
import { ReactComponent as Vimeo } from '@assets/icons/icon_vimeo.svg';
import { ReactComponent as Phone1 } from '@assets/icons/phone.svg';
import { ReactComponent as WhiteLogo } from '@assets/icons/vfc-logo-white.svg';
import { Address, SocialLink, SocialLinksWrapper, TextEmail } from '@components/footer/Footer';
import { Nav, SubNav } from '@components/header/Header';
import Button from '@components/shared/Button';
import { HEADER_HEIGHT } from '@lib/constants';
import { useAuthContext } from '@lib/context/AuthContext/AuthContext';
import { useUIContext } from '@lib/context/UIContext/UIContext';
import useGetPagesGeneralInfo from '@lib/hooks/queries/useGetPagesGeneralInfo';
import { PageType } from '@lib/interfaces/Pages';
import { useRef } from 'react';
import { useIntl } from 'react-intl';
import { NavLink as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

const HeaderActionsLayout = styled.div`
  display: flex;
  margin-left: auto;
`;

const StyledButton = styled(Button)<{ isActive?: boolean }>`
  padding: 10px 30px;
  font-size: 20px;
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  line-height: ${(props) => props.theme.helpers.pxToRem(30)};
  min-width: 78px;

  color: ${(props) => (props.isActive ? props.theme.colors.red : null)};

  &:hover {
    svg {
      g {
        stroke: #fff;
      }
    }
  }

  @media only screen and (max-width: 1405px) {
    font-size: 18px;
  } ;
`;

const StyledContactButton = styled(StyledButton)`
  //padding-left: 25px;
  //padding-right: 25px;
  width: 70px;
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.mediumRed};

  &:hover {
    background-color: ${(props) => props.theme.colors.red} !important;
  }

  svg {
    width: 58px;
  }
`;

const UserDropdown = styled.span<{ active?: boolean }>`
  width: auto;
  height: auto;
  button {
    height: 100%;
    border-color: transparent !important;
    //border-bottom: 1px solid #000000 !important;
    background-color: ${(props) => (props.active ? props.theme.colors.red : null)};
    color: ${(props) => (props.active ? '#000000' : null)};
    &:hover {
      background-color: ${(props) => props.theme.colors.red};
    }
  }
`;

const KontaktDropdownWrapper = styled.span<{ active?: boolean }>`
  width: auto;
  height: auto;
  button {
    height: 100%;
    border-color: transparent !important;
    //border-bottom: 1px solid #000000 !important;
    background-color: ${(props) => (props.active ? props.theme.colors.red : null)};
    color: ${(props) => (props.active ? '#000000 !important' : null)};
    &:hover {
      background-color: ${(props) => props.theme.colors.mediumRed};
    }
  }
`;

const KontaktDropdown = styled.div<{ showOnTop?: boolean; leftPadding?: number; open?: boolean }>`
  display: ${(props) => (props.open ? 'flex' : 'none')};
  align-items: center;
  background: ${(props) => props.theme.colors.red};
  width: 100%;
  left: 0;
  position: ${(props) => (props.showOnTop ? 'absolute' : 'absolute')};
  bottom: ${(props) => (props.showOnTop ? `${HEADER_HEIGHT + 2}px` : null)};
  padding: 40px 80px;
  a {
    font-size: 20px;
  }
`;

export const KontaktRow = styled.div`
  position: relative;
  width: 100%;
  display: flex;
`;
export const KontaktCol = styled.div`
  position: relative;
  margin-right: 80px;
`;

const CloseButton = styled(Close)`
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  width: 33px;
  height: 33px;
  z-index: 999;
  path {
    stroke: #000000;
  }
`;

export const Link = styled(RouterLink)<{ active?: boolean | string }>`
  text-transform: uppercase;
  cursor: pointer;
  display: inline-block;
  padding: 22px;
  font-size: 22px;
  font-weight: normal;
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  line-height: 1.25;
  color: ${(props) => (props.active ? props.theme.colors.white : props.theme.colors.black)};

  &:hover {
    color: #ffffff;
  }

  &.active {
    color: #ffffff;
  }

  @media only screen and (max-width: 1405px) {
    font-size: 18px;
    padding: 24px 20px;
  } ;
`;
 const DirectLink = styled.a`
  text-transform: uppercase;
  cursor: pointer;
  display: inline-block;
  padding: 22px;
  font-size: 22px;
  font-weight: normal;
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  line-height: 1.25;
  color: #000;
  &:hover {
    color: #ffffff;
  }
  @media only screen and (max-width: 1405px) {
    font-size: 18px;
    padding: 24px 20px;
  } ;
`;

const KontaktNewWrapper = styled.div`
  display: flex;
  height: 100%;

  a {
    padding: 0;
    height: 100%;
  }

  .logo-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    padding: 0 40px 0 20px;

    svg {
      width: 80px;
      height: 50px;
    }

    .film-com-mission path {
      fill: #000000;
    }
  }
`;

interface HeaderActionsProps {
  showDropdownOnTop?: boolean;
}

export default function HeaderActions(props: HeaderActionsProps) {
  const uiContext = useUIContext();
  const authCtx = useAuthContext();
  const { data } = useGetPagesGeneralInfo();
  const { formatMessage: t } = useIntl();

  const userDropdownRef = useRef<any>(null);

  const openUserDropdown = () => {
    uiContext.setState({ ...uiContext, kontaktDropdownOpened: false, userDropdownOpened: true });
  };

  const closeLanguage = () => {
    uiContext.setState({ ...uiContext, userDropdownOpened: false });
  };

  const openKontaktDropdown = () => {
    uiContext.setState({ ...uiContext, userDropdownOpened: false, kontaktDropdownOpened: true });
  };

  const closeKontaktDropdown = () => {
    uiContext.setState({ ...uiContext, kontaktDropdownOpened: false });
  };

  const leftPositionValue = userDropdownRef?.current?.getBoundingClientRect()?.left;

  const loginButton = data?.header?.right_side_buttons?.first_button;
  // const contactButton = data?.header?.right_side_buttons?.second_button;

  const footer = data?.footer;
  const address = data?.footer?.contact?.address;
  const socialMedia = data?.footer?.social;
  const emailData = footer?.contact?.email?.split('@');

  const isLoginActive = window.location.pathname.includes('login');
  const intl = useIntl();
  return (
    <HeaderActionsLayout>
      <UserDropdown ref={userDropdownRef} active={uiContext.state.userDropdownOpened} onMouseLeave={closeLanguage} onMouseEnter={openUserDropdown}>
        {authCtx.isAuthenticated ? (
          <StyledButton color="red" to={'/profile'}>
            <IconUser />
          </StyledButton>
        ) : (
          <StyledButton isActive={isLoginActive} color="black" to={loginButton?.url ?? '/login'}>
            {loginButton?.title ?? t({ id: 'login' })}
          </StyledButton>
        )}

        <SubNav leftPadding={leftPositionValue -300} showOnTop={props.showDropdownOnTop} open={uiContext.state.userDropdownOpened}>
          <Nav>
            {!authCtx.isAuthenticated ? (
              <>
                <DirectLink href="https://app.viennafilmcommission.at/">{intl.locale === 'de' ? 'DREHGENEHMIGUNGEN' : 'FILMING PERMITS'}</DirectLink>
                <Link to={`/${PageType.LOGIN}`} style={{ minWidth: 260 }}>
                {intl.locale === 'de' ? 'LOCATION | BRANCHE' : 'LOCATION | INDUSTRY'}
                </Link>
              </>
            ) : (
              <>
                <Link to={`/${PageType.PROFILE}`}>{t({ id: 'mein.profil' })}</Link>
                <Link
                  to={`/`}
                  onClick={(e) => {
                    e.preventDefault();
                    authCtx.logout();
                  }}
                >
                  {t({ id: 'ausloggen' })}
                </Link>
              </>
            )}
          </Nav>
        </SubNav>
      </UserDropdown>

      <KontaktDropdownWrapper active={uiContext.state.kontaktDropdownOpened}>
        {/*<StyledContactButton>{contactButton?.title ?? t({ id: 'kontakt' })}</StyledContactButton>*/}
        <KontaktNewWrapper>
          <StyledContactButton onClick={openKontaktDropdown}>
            <Phone1 />
          </StyledContactButton>
          <Link to="/">
            <span className="logo-wrapper">
              <WhiteLogo />
            </span>
          </Link>
        </KontaktNewWrapper>
        <KontaktDropdown open={uiContext.state.kontaktDropdownOpened} showOnTop={props.showDropdownOnTop}>
          <KontaktRow>
            <KontaktCol>
              <TextEmail>
                <a href={`mailto:${footer?.contact?.email}`}>
                  <span>{emailData?.[0]}@</span>
                  <span>{emailData?.[1]}</span>
                </a>
              </TextEmail>
              <TextEmail>
                <a href={`tel:${footer?.contact?.phone}`}>{footer?.contact?.phone}</a>
              </TextEmail>
            </KontaktCol>
            <KontaktCol>
              <Address>{address?.line1}</Address>
              <Address>{address?.line2}</Address>
              <Address>{address?.line3}</Address>
            </KontaktCol>
            <KontaktCol>
              <SocialLinksWrapper>
                <SocialLink href={socialMedia?.vimeo}>
                  <Vimeo />
                </SocialLink>
                <SocialLink href={socialMedia?.instagram}>
                  <Instagram />
                </SocialLink>
                <SocialLink href={socialMedia?.facebook}>
                  <Facebook />
                </SocialLink>
                <SocialLink href={socialMedia?.youtube}>
                  <Youtube />
                </SocialLink>
              </SocialLinksWrapper>
            </KontaktCol>
            <CloseButton onClick={closeKontaktDropdown} />
          </KontaktRow>
        </KontaktDropdown>
      </KontaktDropdownWrapper>
    </HeaderActionsLayout>
  );
}
