enum ModuleType {
  MODULE_00 = 'acf/m00-full-width-button',
  MODULE_01 = 'acf/m01-main-banner',
  MODULE_02 = 'acf/m02-full-video',
  MODULE_03 = 'acf/m03-full-width-text',
  MODULE_04 = 'acf/m04-dropdown-module',
  MODULE_05 = 'acf/m05-content-overview',
  MODULE_06 = 'acf/m06-inbetween-search-bar',
  MODULE_07 = 'acf/m07-full-width-image-slider',
  MODULE_08 = 'acf/m08-large-full-width-button', // TODO: deprecated
  MODULE_09 = 'acf/m09-production-overview',
  MODULE_10 = 'acf/m10-small-full-width-button', // TODO: deprecated
  MODULE_11 = 'acf/m11-quotes',
  MODULE_12 = 'acf/m12-showreel-overview',
  MODULE_13 = 'acf/m13-team-overview',
  MODULE_14 = 'acf/m14-partner-overview',
  MODULE_16 = 'acf/m16-contact-call-out',
  MODULE_17 = 'acf/m17-boxed-accordion',
  MODULE_18 = 'acf/m18-full-width-text',
  MODULE_19 = 'acf/m19-call-out-for-interaction',
  MODULE_20 = 'acf/m20-large-call-out-for-interaction',
  MODULE_21 = 'acf/m21-full-width-accordion',
  MODULE_22 = 'acf/m22-aktuele-news',
  MODULE_23 = 'acf/m23-production-listing',
  MODULE_24 = 'acf/m24-annual-reports',
  MODULE_25 = 'acf/m25-contact-person',
  MODULE_26 = 'acf/m26-download-area',
  MODULE_27 = 'acf/m27-content',
  MODULE_28 = 'acf/m28-news-archive',
  MODULE_29 = 'acf/m29-presse-archive',
  MODULE_31 = 'acf/m31-locations-archive',
  MODULE_32 = 'acf/m32-branches-archive',
  MODULE_34 = 'acf/m34-production-archive',
  MODULE_POSTS_LISTING = 'acf/posts-listing',
}

export default ModuleType;
