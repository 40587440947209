import { ReactComponent as IconLabel } from '@assets/icons/icon_info.svg';
import { Input as InputRS } from '@components/shared/Input/Input';
import breakpoints from '@lib/common/breakpoints';
import React from 'react';
import { FormFeedback, FormGroup, InputProps, Label } from 'reactstrap';
import styled, { css } from 'styled-components';

interface IStyledFormGroup {
  optional?: boolean;
  disabled?: boolean;
  error?: boolean;
  required?: boolean;
}
const StyledFormGroup = styled(FormGroup)<IStyledFormGroup>`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 18px;
  margin-bottom: 32px;
  @media only screen and ${breakpoints.maxWidth.md} {
    padding-top: 14px;
    margin-bottom: 20px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    padding-top: 14px;
    margin-bottom: 20px;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;
    `}
  label {
    position: absolute;
    top: 0px;
    left: 24px;
    padding: 8px 12px;
    background-color: ${(props) => props.theme.colors.white};
    font-family: 'GTFlexa-StandardMedium';
    font-size: 16px;
    letter-spacing: 0.2px;
    color: ${(props) => props.theme.colors.black};
    display: flex;
    align-items: center;
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 14px;
      left: 15px;
      padding: 4px 8px;
    }
    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 14px;
      left: 15px;
      padding: 4px 8px;
    }

    /* ${({ error }) =>
      error &&
      css`
        color: ${(props) => props.theme.colors.red};
      `} */
  }
  textarea,
  input {
    border: 1px solid #141414;
    padding: 28px 30px;
    font-family: 'GTFlexa-ExtendedMedium';
    font-size: 24px;
    color: ${(props) => props.theme.colors.black};
    outline: 0;
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 18px;
      padding: 18px 15px;
    }
    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 18px;
      padding: 18px 15px;
    }

    ${({ error }) =>
      error &&
      css`
        color: ${(props) => props.theme.colors.red};
      `}
    ${({ optional }) =>
      optional &&
      css`
        border-style: none;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='2' stroke-dasharray='8%2c10' stroke-dashoffset='7' stroke-linecap='square'/%3e%3c/svg%3e");
      `}
  }
  textarea {
    height: 206px;
    resize: none;
    font-size: 16px;
    line-height: 1.25em;
    font-family: 'GTFlexa-StandardLight';
    ${({ error }) =>
      error &&
      css`
        color: ${(props) => props.theme.colors.red};
      `}
    @media only screen and ${breakpoints.maxWidth.sm} {
      padding: 30px 15px;
    }
  }
  &.custom-input-radio {
    label {
      position: relative;
      cursor: pointer;
      transition: $transition1;
      padding: 0;
      left: 0;
      font-family: 'GTFlexa-ExtendedLight', sans-serif;
      font-size: 16px;
      letter-spacing: 0.2px;

      &:before {
        content: '';
        display: inline-block;
        width: 16px;
        height: 16px;
        border: 1px solid ${(props) => props.theme.colors.black};
        margin-right: 5px;
      }
      &:after {
        content: '';
        opacity: 0;
        width: 10px;
        height: 10px;
        background-color: ${(props) => props.theme.colors.black};
        position: absolute;
        left: 4px;
      }
    }
  }
  input[type='radio'] {
    display: none;
    &:disabled {
      ~ label {
        opacity: 0.3;
        cursor: not-allowed;
      }
    }
    &:checked {
      ~ label {
        &:after {
          opacity: 1;
        }
      }
    }
  }
  .input-image {
    font-size: 0;
    text-indent: -120px;
    min-height: 88px;
    cursor: pointer;
    ${({ required }) =>
      !required &&
      css`
        border-style: none;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='2' stroke-dasharray='8%2c10' stroke-dashoffset='7' stroke-linecap='square'/%3e%3c/svg%3e");
      `}
    @media only screen and ${breakpoints.maxWidth.md} {
      min-height: 60px;
    }
    @media only screen and ${breakpoints.maxWidth.sm} {
      min-height: 60px;
    }
  }
`;

const StyledFormFeedback = styled(FormFeedback)`
  font-family: 'GTFlexa-StandardMedium';
  font-size: 16px;
  letter-spacing: 0.2px;
  color: ${(props) => props.theme.colors.red};
  padding: 10px 24px 0 24px;

  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 14px;
    padding-left: 15px;
    margin-top: -10px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 14px;
    padding-left: 15px;
    margin-top: -10px;
  }
`;

const StyledInfoIcon = styled.div`
  width: 18px;
  height: 18px;
  margin-left: 10px;
  cursor: pointer;
  @media only screen and ${breakpoints.maxWidth.md} {
    width: 14px;
    height: 14px;
    svg {
      width: 14px;
      height: 14px;
    }
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    width: 14px;
    height: 14px;
    svg {
      width: 14px;
      height: 14px;
    }
  }
`;
const StyledFileText = styled.span`
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 1.2em;
  color: ${(props) => props.theme.colors.black};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  pointer-events: none;
  @media only screen and ${breakpoints.maxWidth.md} {
    white-space: nowrap;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    white-space: nowrap;
  }
`;

interface Props extends InputProps {
  label?: string;
  labelInfo?: string;
  optional?: boolean;
  fileText?: string;
}

export const Input = (props: Props) => {
  const { label, labelInfo, error, optional, fileText, ...rest } = props;

  return (
    <StyledFormGroup optional={optional} error={!!error} disabled={props.disabled} required={props.required} className={`custom-input-${props.type}`}>
      <InputRS {...rest} />
      {label && (
        <Label for={props.id} hidden>
          {label}
          {labelInfo && (
            <StyledInfoIcon>
              <IconLabel />
            </StyledInfoIcon>
          )}
        </Label>
      )}
      {fileText && <StyledFileText>{fileText}</StyledFileText>}
      {error && <StyledFormFeedback>{error}</StyledFormFeedback>}
    </StyledFormGroup>
  );
};
