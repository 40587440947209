import * as API from '@api/branch';
import Button from '@components/shared/Button';
import breakpoints from '@lib/common/breakpoints';
import { BranchCategoryFormik } from '@lib/hooks/formik/useBranchCategoryFormik';
import React from 'react';
import { useIntl } from 'react-intl';
//Components
import { Form } from 'reactstrap';
import styled, { css } from 'styled-components';
import { Input } from '../Input';
import { MultiSelect } from '../MultiSelect';
import { BRANCH_STEP } from './BranchForm';

const StyledForm = styled.div`
  width: 100%;
`;
const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  @media only screen and ${breakpoints.maxWidth.md} {
    margin-top: 20px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    margin-top: 20px;
  }
`;
const StyledRow = styled.div`
  margin-left: -16px;
  margin-right: -16px;
`;
const StyledCol = styled.div<IColType>`
  display: inline-block;
  width: 100%;
  padding: 0 16px;
  ${({ size }) =>
    size === 6 &&
    css`
      float: left;
      width: 50%;
    `}
`;

const StyledButton = styled(Button)`
  font-size: 24px;
  padding: 24px 36px;
  @media only screen and ${breakpoints.maxWidth.md} {
    width: 100%;
    font-size: 20px;
    padding: 10px 18px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    width: 100%;
    font-size: 20px;
    padding: 10px 18px;
  }
`;

interface IColType {
  size?: number;
}

interface Props {
  formik: BranchCategoryFormik;
  data?: API.BranchFormDataResponse;
  setStep: React.Dispatch<React.SetStateAction<BRANCH_STEP>>;
}

export const BranchCategory = ({ formik, data }: Props) => {
  const { formatMessage: t } = useIntl();

  const handleSelectChange = (key: string, value: number[]) => {
    formik.setFieldValue(key, value);
    formik.setFieldTouched(key);
  };

  return (
    <StyledForm className="BranchCategory">
      <Form onSubmit={formik.handleSubmit}>
        <StyledRow>
          <StyledCol>
            <MultiSelect
              id="production_types"
              name="production_types"
              label={t({ id: 'type_of_production' })}
              labelInfo={t({ id: 'type_of_production' })}
              className="form-control-custom"
              options={
                data?.production_types?.map((el) => {
                  return { label: el.name, value: el.term_id };
                }) || []
              }
              invalid={
                typeof formik.errors.production_types === 'string' ? !!formik.errors.production_types && !!formik.touched.production_types : undefined
              }
              error={typeof formik.errors.production_types === 'string' ? formik.errors.production_types : undefined}
              selectedOptions={formik.values.production_types || []}
              onChange={handleSelectChange}
            />
          </StyledCol>
          <StyledCol>
            <Input
              optional
              type="text"
              name="new_production_types"
              id="new_production_types"
              label={t({ id: 'new_location_category' })}
              className="form-control-custom"
              value={formik.values.new_production_types}
              invalid={!!formik.errors.new_production_types && formik.touched.new_production_types}
              onChange={formik.handleChange}
              error={formik.errors.new_production_types}
            />
          </StyledCol>
          <StyledCol>
            <MultiSelect
              optional
              id="eco_certifications"
              name="eco_certifications"
              label={t({ id: 'eco_certifications' })}
              labelInfo={t({ id: 'eco_certifications' })}
              className="form-control-custom"
              options={
                data?.eco_certifications?.map((el) => {
                  return { label: el.name, value: el.term_id };
                }) || []
              }
              invalid={
                typeof formik.errors.eco_certifications === 'string'
                  ? !!formik.errors.eco_certifications && !!formik.touched.eco_certifications
                  : undefined
              }
              error={typeof formik.errors.eco_certifications === 'string' ? formik.errors.eco_certifications : undefined}
              selectedOptions={formik.values.eco_certifications || []}
              onChange={handleSelectChange}
            />
          </StyledCol>
          <StyledCol>
            <StyledButtonWrapper>
              <StyledButton disabled={formik.isSubmitting} color="black" onClick={() => formik.submitForm()}>
                {formik.isSubmitting ? t({ id: 'loading' }) : t({ id: 'button_next_step' })}
              </StyledButton>
            </StyledButtonWrapper>
          </StyledCol>
        </StyledRow>
      </Form>
    </StyledForm>
  );
};
