import { getRelatedLocations, GetRelatedLocationsInput } from '@api/locations';
import QueryKeys from '@lib/constants/QueryKeys';
import { ObjectAny } from '@lib/interfaces';
import { AxiosError } from 'axios';
import { useInfiniteQuery } from 'react-query';

export default function useGetRelatedLocationsDetails(params: GetRelatedLocationsInput) {
  return useInfiniteQuery<ObjectAny, AxiosError>(QueryKeys.relatedLocations(params), () => getRelatedLocations(params), {
    enabled: Boolean(params?.slug),
  });
}
