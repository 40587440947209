export const size = {
  xs: '576px',
  sm: '768px',
  md: '992px',
  lg: '1200px',
};

const breakpoints = {
  maxWidth: {
    xs: `(max-width: ${size.xs})`,
    sm: `(max-width: ${size.sm})`,
    md: `(max-width: ${size.md})`,
    lg: `(max-width: ${size.lg})`,
  },

  minWidth: {
    xs: `(min-width: ${size.xs})`,
    sm: `(min-width: ${size.sm})`,
    md: `(min-width: ${size.md})`,
    lg: `(min-width: ${size.lg})`,
  },
};

export default breakpoints;
