import { Container } from '@components/shared/Container';
import { useReactIntlProvider } from '@lib/context/ReactIntlProvider/ReactIntlProvider';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled(Container)`
  margin-bottom: 30px;
`;

const Title = styled(Link)`
  font-family: GTFlexa-ExtendedMedium, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 0.5);
  text-transform: uppercase;

  &:hover {
    color: ${(props) => props.theme.colors.red};
  }
`;

const links = {
  locations: {
    en: 'locations-en',
    de: 'locations',
  },
  branches: {
    en: 'industry',
    de: 'branche',
  },
  news: {
    en: 'latest',
    de: 'news',
  },
  productions: {
    en: 'production-index',
    de: 'produktionsspiegel',
  },
};

type LinkType = 'locations' | 'branches' | 'news' | 'productions';

interface ISearchResultsItemProps {
  title: string;
  link?: LinkType;
  className?: string;
}

const SearchResultsHeader: FC<ISearchResultsItemProps> = (props) => {
  const { activeLanguage } = useReactIntlProvider();
  const getLink = () => {
    if (!props.link) {
      return;
    }

    return (links as any)?.[props.link]?.[activeLanguage] ?? '';
  };

  const link = getLink();

  return (
    <Wrapper className={props.className}>
      <Title
        to={link}
        onClick={(e) => {
          if (!link) {
            e.preventDefault();
          }
        }}
      >
        {props.title}
      </Title>
    </Wrapper>
  );
};

export default SearchResultsHeader;
