import { get } from '@api/axios';
import { ObjectAny } from '@lib/interfaces';

interface GetPageInput {
  slug?: string;
}

export const getPage = (slug?: string) => get<GetPageInput, ObjectAny>({ url: `v1/pages/${slug}` });
export const getPagesGeneralInfo = () => get<undefined, ObjectAny>({ url: 'v1/general/base' });
export const getPageMetaData = (slug?: string, activeLanguage?: string) =>
  get<any, any>({
    url: `https://backend.viennafilmcommission.at/wp-json/yoast/v1/get_head?url=https://backend.viennafilmcommission.at/${activeLanguage}${slug}${
      slug?.endsWith('/') ? '' : '/'
    }`,
  });
