import Button from '@components/shared/Button';
import { Container } from '@components/shared/Container';
import HandleFetchingState from '@components/shared/HandleFetchingState';
import { LocationDeleteForm } from '@components/user/Location/LocationDeleteForm';
import breakpoints from '@lib/common/breakpoints';
import { useAuthContext } from '@lib/context/AuthContext/AuthContext';
import useGetLocationsDetails from '@lib/hooks/queries/useGetLocationsDetails';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Redirect, RouteComponentProps } from 'react-router';
import styled from 'styled-components';

const StyledDeleteLocation = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.yellow};
  padding: 120px 0;
`;

export const StyledPageHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 120px;
  h1 {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 58px;
    line-height: 1.05em;
    color: ${(props) => props.theme.colors.black};
  }
`;
const StyledFormTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-bottom: 24px;
  h3 {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 36px;
    letter-spacing: 0.4px;
    line-height: 1.2em;
    color: ${(props) => props.theme.colors.black};
    margin-right: 20px;
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 24px;
      letter-spacing: 0.2px;
      margin-right: 0;
    }
    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 24px;
      letter-spacing: 0.2px;
      margin-right: 0;
    }
  }
`;
const StyledProfileDeleteSuccess = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  padding: 120px 150px;
  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 80px 20px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 80px 20px;
  }
`;
const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;

const StyledButton = styled(Button)`
  font-size: 24px;
  padding: 24px 36px;
`;

interface Props {
  slug: string;
}

export const DeleteLocation = (props: RouteComponentProps<Props>) => {
  const slug = props.match.params.slug;
  const { user } = useAuthContext();
  const { formatMessage: t } = useIntl();
  const [deletedSuccessfully, setDeletedSuccessfully] = useState(false);
  const { data, isLoading, error } = useGetLocationsDetails(slug);

  if (data?.author_id !== user?.ID) {
    return <Redirect to="/" />;
  }

  return (
    <HandleFetchingState loading={isLoading} error={error}>
      <StyledDeleteLocation className="DeleteLocation">
        <Container>
          <StyledPageHeader>
            <h1>{t({ id: 'delete_location' })}</h1>
          </StyledPageHeader>
          {deletedSuccessfully ? (
            <StyledProfileDeleteSuccess className="ProfileDeleteSuccess">
              <StyledFormTitle>
                <h3>{t({ id: 'location_delete_success' })}</h3>
              </StyledFormTitle>
              <StyledButtonWrapper>
                <StyledButton color="black" onClick={() => props.history.push('/profile')}>
                  {t({ id: 'back_to_profile' })}
                </StyledButton>
              </StyledButtonWrapper>
            </StyledProfileDeleteSuccess>
          ) : (
            <LocationDeleteForm setDeletedSuccessfully={setDeletedSuccessfully} location={data} />
          )}
        </Container>
      </StyledDeleteLocation>
    </HandleFetchingState>
  );
};
