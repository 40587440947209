import { Showreel } from '@api/showreels';
import { ReactComponent as FullScreenIcon } from '@assets/icons/icons_expand.svg';
import { SimpleButton } from '@components/styled-components/StyledComponents';
import breakpoints from '@lib/common/breakpoints';
import truncateString from '@lib/helpers/truncateString';
import React, { FC, useState } from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';

const Wrapper = styled.div``;

const VideoWrapper = styled.div`
  width: 100%;
  height: 270px;
  margin-bottom: 30px;
  position: relative;
  cursor: pointer;
  z-index: 2;

  @media only screen and ${breakpoints.maxWidth.md} {
    height: 226px;
  }
  @media only screen and ${breakpoints.maxWidth.xs} {
    height: 116px;
  }

  > div {
    width: 100% !important;
    height: 100% !important;
    html {
      height: 100%;
    }
    video {
      top: 0 !important;
      width: 100% !important;
      height: 100% !important;
      object-fit: cover !important;
    }
  }

  button {
    display: none;
  }

  &:hover {
    //> div:first-of-type {
    //opacity: 0.5;
    //}
    button {
      display: block;
    }
  }
`;

const FullScreenButton = styled(SimpleButton)`
  z-index: 1;
  width: 40px;
  height: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  svg path {
    stroke: #ffffff;
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    width: 30px;
    height: 30px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  h3 {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #000;
    margin-right: 24px;
    /* word-break: break-all; */
    flex: 1;

    @media only screen and ${breakpoints.maxWidth.xs} {
      font-size: 16px;
      line-height: 1.15;
    }
  }
  
  p {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.2px;
    color: #000;
  }
  }
`;

interface IShowreelListItemProps {
  item: Showreel;
  onFullscreenRequest: (item: Showreel) => void;
}

const ShowreelListItem: FC<IShowreelListItemProps> = (props) => {
  const { item } = props;

  const [playing, setPlaying] = useState(false);

  const startPlaying = () => {
    setPlaying(true);
  };

  const stopPlaying = () => {
    setPlaying(false);
  };

  const handleOnFullscreenClick = () => {
    props.onFullscreenRequest(item);
  };

  return (
    <>
      <Wrapper>
        <VideoWrapper onMouseEnter={startPlaying} onMouseLeave={stopPlaying}>
          <ReactPlayer muted playing={playing} url={item.video_id ?? ''} />
          <FullScreenButton onClick={handleOnFullscreenClick}>
            <FullScreenIcon />
          </FullScreenButton>
        </VideoWrapper>
        <TitleWrapper>
          <h3>{item.post_title}</h3>
          <p dangerouslySetInnerHTML={{ __html: truncateString(item.description, 95) ?? '' }} />
        </TitleWrapper>
      </Wrapper>
    </>
  );
};

export default ShowreelListItem;
