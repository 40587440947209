import { getPagesGeneralInfo } from '@api/page';
import QueryKeys from '@lib/constants/QueryKeys';
import { ObjectAny } from '@lib/interfaces';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

export default function useGetPagesGeneralInfo() {
  return useQuery<ObjectAny, AxiosError>(QueryKeys.pagesGeneralInfo(), () => getPagesGeneralInfo(), {
    cacheTime: 1800000, // 30 min
    staleTime: 1800000, // 30 min
  });
}
