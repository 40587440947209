import { validateFilmingStep4 } from '@api/filming';
import * as API from '@api/general';
import Button from '@components/shared/Button';
import breakpoints from '@lib/common/breakpoints';
import { FilmingFormik } from '@lib/hooks/formik/useFilmingFormik';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
//Components
import { Form } from 'reactstrap';
import styled, { css } from 'styled-components';
import { Checkbox } from '../Checkbox';
import { Input } from '../Input';
import { FILMING_STEP } from './FilmingForm';

const StyledForm = styled.div`
  width: 100%;
`;
const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  @media only screen and ${breakpoints.maxWidth.md} {
    flex-direction: column-reverse;
    margin-top: 20px;
    button:first-child {
      margin-bottom: 0 !important;
    }
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    flex-direction: column-reverse;
    margin-top: 20px;
    button:first-child {
      margin-bottom: 0 !important;
    }
  }
`;
const StyledRow = styled.div`
  margin-left: -15px;
  margin-right: -15px;
`;
const StyledCol = styled.div<IColType>`
  display: inline-block;
  width: 100%;
  padding: 0 15px;

  ${({ size }) =>
    size === 2 &&
    css`
      width: 16.6667%;
      @media only screen and ${breakpoints.maxWidth.md} {
        width: 50%;
      }
      @media only screen and ${breakpoints.maxWidth.sm} {
        width: 50%;
      }
    `}
  ${({ size }) =>
    size === 4 &&
    css`
      float: left;
      width: 38%;
      @media only screen and ${breakpoints.maxWidth.md} {
        width: 100%;
      }
      @media only screen and ${breakpoints.maxWidth.sm} {
        width: 100%;
      }
    `}
  ${({ size }) =>
    size === 8 &&
    css`
      float: left;
      width: 62%;
      @media only screen and ${breakpoints.maxWidth.md} {
        width: 100%;
      }
      @media only screen and ${breakpoints.maxWidth.sm} {
        width: 100%;
      }
    `}
`;

const StyledButton = styled(Button)`
  font-size: 24px;
  padding: 24px 36px;
  @media only screen and ${breakpoints.maxWidth.md} {
    width: 100%;
    font-size: 20px;
    padding: 10px 18px;
    margin-bottom: 20px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    width: 100%;
    font-size: 20px;
    padding: 10px 18px;
    margin-bottom: 20px;
  }
`;

const StyledFormSeperateTitle = styled.h4`
  font-family: 'GTFlexa-ExtendedMedium', sans-serif;
  font-size: 24px;
  line-height: 1.2em;
  color: ${(props) => props.theme.colors.black};
  margin-top: 30px;
  margin-bottom: 18px;
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 8px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 8px;
  }
`;

interface IColType {
  size?: number;
}

interface Props {
  formik: FilmingFormik;
  setStep: React.Dispatch<React.SetStateAction<FILMING_STEP>>;
  privacy_text?: string;
}

const step4Fields = [
  'location',
  'adaptation_of_location',
  'type_of_adaption',
  'owner_was_contacted',
  'location_contact_person_name',
  'location_contact_person_phone',
  'location_contact_person_email',
  'city_map_screenshot',
  'data_protection',
  'data',
  'name_signature',
];

export const FilmingLocation = ({ formik, setStep, privacy_text }: Props) => {
  const { formatMessage: t } = useIntl();
  const [loading, setLoading] = useState(false);
  const handleBack = () => setStep(FILMING_STEP.SHOOTING_TIME);

  const [file, setFile] = useState(t({ id: 'select_data' }));

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      setFile(e.target.files[0].name);
      const res = await API.uploadMedia(formData);
      formik.setFieldValue('city_map_screenshot', res.id);
      formik.setFieldTouched('city_map_screenshot');
      setLoading(false);
    } catch (error) {
      //@ts-ignore
      formik.setFieldError('city_map_screenshot', error.response.data.data.message);
      setLoading(false);
    }
  };

  const handleNext = async () => {
    try {
      setLoading(true);
      const errors = await formik.validateForm(formik.values);

      if (formik.errors.location) {
        return;
      }

      const step4Errors = Object.keys(errors).filter((err) => step4Fields.includes(err));

      const res = await validateFilmingStep4({
        step: '4',
        location: formik.values.location,
        adaptation_of_location: formik.values.adaptation_of_location,
        type_of_adaption: formik.values.type_of_adaption,
        owner_was_contacted: formik.values.owner_was_contacted,
        location_contact_person_name: formik.values.location_contact_person_name,
        location_contact_person_phone: formik.values.location_contact_person_phone,
        location_contact_person_email: formik.values.location_contact_person_email,
        city_map_screenshot: formik.values.city_map_screenshot || null,
        data_protection: formik.values.data_protection,
        date: formik.values.date,
        name_signature: formik.values.name_signature,
      });

      if (res.data?.valid && step4Errors?.length === 0) {
        await formik.submitForm();
      }
      setLoading(false);
      return;
    } catch (error) {
      //@ts-ignore
      formik.setErrors(error.response.data.data.errors);
      setLoading(false);
    }
  };

  return (
    <StyledForm className="FilmingLocation">
      <Form onSubmit={formik.handleSubmit}>
        <StyledRow>
          <StyledCol>
            <Input
              type="textarea"
              name="location"
              id="location"
              maxLength={1000}
              label={t({ id: 'location' })}
              labelInfo={t({ id: 'location' })}
              className="form-control-custom"
              value={formik.values.location}
              invalid={!!formik.errors.location && formik.touched.location}
              onChange={formik.handleChange}
              error={formik.errors.location}
            />
          </StyledCol>
          <StyledCol>
            <StyledFormSeperateTitle>{t({ id: 'adaptierung_des_drehorts' })}</StyledFormSeperateTitle>
          </StyledCol>
          <StyledCol size={2}>
            <Input
              type="radio"
              name="adaptation_of_location"
              id="adaptation_of_location-1"
              label={t({ id: 'ja' })}
              className="form-control-custom"
              value="JA"
              checked={formik.values.adaptation_of_location === 'JA'}
              invalid={!!formik.errors.adaptation_of_location && formik.touched.adaptation_of_location}
              onChange={formik.handleChange}
              error={formik.errors.adaptation_of_location}
            />
          </StyledCol>
          <StyledCol size={2}>
            <Input
              type="radio"
              name="adaptation_of_location"
              id="adaptation_of_location-2"
              label={t({ id: 'nein' })}
              className="form-control-custom"
              value="Nein"
              checked={formik.values.adaptation_of_location === 'Nein'}
              invalid={!!formik.errors.adaptation_of_location && formik.touched.adaptation_of_location}
              onChange={formik.handleChange}
              error={formik.errors.adaptation_of_location}
            />
          </StyledCol>
          <StyledCol>
            <Input
              type="text"
              name="type_of_adaption"
              id="type_of_adaption"
              label={t({ id: 'type_of_adaption' })}
              labelInfo={t({ id: 'type_of_adaption' })}
              className="form-control-custom"
              value={formik.values.type_of_adaption}
              invalid={!!formik.errors.type_of_adaption && formik.touched.type_of_adaption}
              onChange={formik.handleChange}
              error={formik.errors.type_of_adaption}
            />
          </StyledCol>
          <StyledCol>
            <StyledFormSeperateTitle>{t({ id: 'mit_dem_eigentumer' })}</StyledFormSeperateTitle>
          </StyledCol>
          <StyledCol size={2}>
            <Input
              type="radio"
              name="owner_was_contacted"
              id="owner_was_contacted-1"
              label={t({ id: 'ja' })}
              className="form-control-custom"
              value="JA"
              checked={formik.values.owner_was_contacted === 'JA'}
              invalid={!!formik.errors.owner_was_contacted && formik.touched.owner_was_contacted}
              onChange={formik.handleChange}
              error={formik.errors.owner_was_contacted}
            />
          </StyledCol>
          <StyledCol size={2}>
            <Input
              type="radio"
              name="owner_was_contacted"
              id="owner_was_contacted-2"
              label={t({ id: 'nein' })}
              className="form-control-custom"
              value="Nein"
              checked={formik.values.owner_was_contacted === 'Nein'}
              invalid={!!formik.errors.owner_was_contacted && formik.touched.owner_was_contacted}
              onChange={formik.handleChange}
              error={formik.errors.owner_was_contacted}
            />
          </StyledCol>
          <StyledCol>
            <Input
              type="text"
              name="location_contact_person_name"
              id="location_contact_person_name"
              label={t({ id: 'location_contact_person_name' })}
              className="form-control-custom"
              value={formik.values.location_contact_person_name}
              invalid={!!formik.errors.location_contact_person_name && formik.touched.location_contact_person_name}
              onChange={formik.handleChange}
              error={formik.errors.location_contact_person_name}
            />
          </StyledCol>
          <StyledCol>
            <Input
              type="text"
              name="location_contact_person_phone"
              id="location_contact_person_phone"
              label={t({ id: 'phone' })}
              className="form-control-custom"
              value={formik.values.location_contact_person_phone}
              invalid={!!formik.errors.location_contact_person_phone && formik.touched.location_contact_person_phone}
              onChange={formik.handleChange}
              error={formik.errors.location_contact_person_phone}
            />
          </StyledCol>
          <StyledCol>
            <Input
              type="email"
              name="location_contact_person_email"
              id="location_contact_person_email"
              label={t({ id: 'email' })}
              className="form-control-custom"
              value={formik.values.location_contact_person_email}
              invalid={!!formik.errors.location_contact_person_email && formik.touched.location_contact_person_email}
              onChange={formik.handleChange}
              error={formik.errors.location_contact_person_email}
            />
          </StyledCol>
          <StyledCol>
            <Input
              optional
              type="file"
              name="city_map_screenshot"
              id="city_map_screenshot"
              label={t({ id: 'city_map_screenshot' })}
              labelInfo={t({ id: 'city_map_screenshot' })}
              className="form-control-custom input-image"
              fileText={file}
              invalid={!!formik.errors.city_map_screenshot && formik.touched.city_map_screenshot}
              onChange={handleFileChange}
              error={formik.errors.city_map_screenshot}
            />
          </StyledCol>
          <StyledCol>
            <StyledFormSeperateTitle>{t({ id: 'datenschutz' })}</StyledFormSeperateTitle>
          </StyledCol>
          <StyledCol>
            <Checkbox
              name="data_protection"
              id="data_protection"
              label={t({ id: 'datenschutz_checkbox' }) || privacy_text || ''}
              className="form-control-custom"
              checked={formik.values.data_protection}
              invalid={!!formik.errors.data_protection && formik.touched.data_protection}
              onChange={formik.handleChange}
              error={formik.errors.data_protection}
            />
          </StyledCol>
          <StyledCol size={4}>
            <Input
              type="text"
              name="date"
              id="date"
              label={t({ id: 'date' })}
              placeholder="dd-mm-yyyy"
              className="form-control-custom"
              value={formik.values.date}
              invalid={!!formik.errors.date && formik.touched.date}
              onChange={formik.handleChange}
              error={formik.errors.date}
            />
          </StyledCol>
          <StyledCol size={8}>
            <Input
              type="text"
              name="name_signature"
              id="name_signature"
              label={t({ id: 'name_signature' })}
              className="form-control-custom"
              value={formik.values.name_signature}
              invalid={!!formik.errors.name_signature && formik.touched.name_signature}
              onChange={formik.handleChange}
              error={formik.errors.name_signature}
            />
          </StyledCol>
          <StyledCol>
            <StyledButtonWrapper>
              <StyledButton color="black" onClick={handleBack}>
                {t({ id: 'button_back' })}
              </StyledButton>
              <StyledButton disabled={formik.isSubmitting || loading} color="black" onClick={handleNext}>
                {formik.isSubmitting || loading ? t({ id: 'loading' }) : t({ id: 'button_next_step' })}
              </StyledButton>
            </StyledButtonWrapper>
          </StyledCol>
        </StyledRow>
      </Form>
    </StyledForm>
  );
};
