const regex = '^[0-9]+$';

export default function sortYears(obj: any) {
  var sortable = [];
  for (var key in obj) if (obj.hasOwnProperty(key)) sortable.push([key, obj[key]]);

  sortable.sort(function (a, b) {
    var x = a[1].toLowerCase(),
      y = b[1].toLowerCase();
    return x < y ? 1 : x > y ? -1 : 0;
  });

  const yearsArray = sortable.map((a: any) => a[0]).filter((item: any) => item.match(regex));
  return yearsArray.reduce((a: any, v: any) => ({ [v]: v, ...a }), {});
}
