import * as API from '@api/filming';
import Button from '@components/shared/Button';
import breakpoints from '@lib/common/breakpoints';
import { FilmingFormik } from '@lib/hooks/formik/useFilmingFormik';
import React from 'react';
import { useIntl } from 'react-intl';
//Components
import { Form } from 'reactstrap';
import styled, { css } from 'styled-components';
import { Input } from '../Input';
import { FILMING_STEP } from './FilmingForm';

const StyledForm = styled.div`
  width: 100%;
`;
const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  @media only screen and ${breakpoints.maxWidth.md} {
    margin-top: 20px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    margin-top: 20px;
  }
`;
const StyledRow = styled.div`
  margin-left: -16px;
  margin-right: -16px;
`;
const StyledCol = styled.div<IColType>`
  display: inline-block;
  width: 100%;
  padding: 0 16px;
  ${({ size }) =>
    size === 6 &&
    css`
      float: left;
      width: 50%;
      @media only screen and ${breakpoints.maxWidth.md} {
        width: 100%;
      }
      @media only screen and ${breakpoints.maxWidth.sm} {
        width: 100%;
      }
    `}
`;

const StyledButton = styled(Button)`
  font-size: 24px;
  padding: 24px 36px;
  @media only screen and ${breakpoints.maxWidth.md} {
    width: 100%;
    font-size: 20px;
    padding: 10px 18px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    width: 100%;
    font-size: 20px;
    padding: 10px 18px;
  }
`;

const StyledFormSeperateTitle = styled.h4`
  font-family: 'GTFlexa-ExtendedMedium', sans-serif;
  font-size: 24px;
  line-height: 1.2em;
  color: ${(props) => props.theme.colors.black};
  margin-top: 30px;
  margin-bottom: 18px;
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 8px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 8px;
  }
`;

interface IColType {
  size?: number;
}

interface Props {
  formik: FilmingFormik;
  setStep: React.Dispatch<React.SetStateAction<FILMING_STEP>>;
}

const step1Fields = [
  'first_name',
  'last_name',
  'company_name',
  'address',
  'phone',
  'email',
  'recording_manager_first_name',
  'recording_manager_last_name',
  'recording_manager_phone',
  'recording_manager_email',
];

export const FilmingApplicant = ({ formik, setStep }: Props) => {
  const { formatMessage: t } = useIntl();
  const handleNext = async () => {
    try {
      const errors = await formik.validateForm(formik.values);

      const step1Errors = Object.keys(errors).filter((err) => step1Fields.includes(err));

      const res = await API.validateFilmingStep1({
        step: '1',
        first_name: formik.values.first_name,
        last_name: formik.values.last_name,
        company_name: formik.values.company_name,
        address: formik.values.address,
        phone: formik.values.phone,
        email: formik.values.email,
        recording_manager_first_name: formik.values.recording_manager_first_name,
        recording_manager_last_name: formik.values.recording_manager_last_name,
        recording_manager_phone: formik.values.recording_manager_phone,
        recording_manager_email: formik.values.recording_manager_email,
      });

      if (res.data?.valid && step1Errors?.length === 0) {
        setStep(2);
        formik.setErrors({});
        return;
      }
      await formik.submitForm();
      return;
    } catch (error) {
      //@ts-ignore
      formik.setErrors(error.response.data.data.errors);
    }
  };

  return (
    <StyledForm className="FilmingApplicant">
      <Form onSubmit={formik.handleSubmit}>
        <StyledRow>
          <StyledCol size={6}>
            <Input
              type="text"
              name="first_name"
              id="first_name"
              label={t({ id: 'first_name' })}
              className="form-control-custom"
              value={formik.values.first_name}
              invalid={!!formik.errors.first_name && formik.touched.first_name}
              onChange={formik.handleChange}
              error={formik.errors.first_name}
            />
          </StyledCol>
          <StyledCol size={6}>
            <Input
              type="text"
              name="last_name"
              id="last_name"
              label={t({ id: 'last_name' })}
              className="form-control-custom"
              value={formik.values.last_name}
              invalid={!!formik.errors.last_name && formik.touched.last_name}
              onChange={formik.handleChange}
              error={formik.errors.last_name}
            />
          </StyledCol>
          <StyledCol>
            <Input
              type="text"
              optional
              name="company_name"
              id="company_name"
              label={t({ id: 'company_name' })}
              className="form-control-custom"
              value={formik.values.company_name}
              invalid={!!formik.errors.company_name && formik.touched.company_name}
              onChange={formik.handleChange}
              error={formik.errors.company_name}
            />
          </StyledCol>
          <StyledCol>
            <Input
              type="text"
              name="address"
              id="address"
              label={t({ id: 'address' })}
              className="form-control-custom"
              value={formik.values.address}
              invalid={!!formik.errors.address && formik.touched.address}
              onChange={formik.handleChange}
              error={formik.errors.address}
            />
          </StyledCol>
          <StyledCol>
            <Input
              type="text"
              name="phone"
              id="phone"
              label={t({ id: 'phone' })}
              className="form-control-custom"
              value={formik.values.phone}
              invalid={!!formik.errors.phone && formik.touched.phone}
              onChange={formik.handleChange}
              error={formik.errors.phone}
            />
          </StyledCol>
          <StyledCol>
            <Input
              type="email"
              name="email"
              id="email"
              label={t({ id: 'email' })}
              className="form-control-custom"
              value={formik.values.email}
              invalid={!!formik.errors.email && formik.touched.email}
              onChange={formik.handleChange}
              error={formik.errors.email}
            />
          </StyledCol>
          <StyledCol>
            <StyledFormSeperateTitle>{t({ id: 'aufnahmeleitung' })}</StyledFormSeperateTitle>
          </StyledCol>
          <StyledCol size={6}>
            <Input
              type="text"
              name="recording_manager_first_name"
              id="recording_manager_first_name"
              label={t({ id: 'first_name' })}
              className="form-control-custom"
              value={formik.values.recording_manager_first_name}
              invalid={!!formik.errors.recording_manager_first_name && formik.touched.recording_manager_first_name}
              onChange={formik.handleChange}
              error={formik.errors.recording_manager_first_name}
            />
          </StyledCol>
          <StyledCol size={6}>
            <Input
              type="text"
              name="recording_manager_last_name"
              id="recording_manager_last_name"
              label={t({ id: 'last_name' })}
              className="form-control-custom"
              value={formik.values.recording_manager_last_name}
              invalid={!!formik.errors.recording_manager_last_name && formik.touched.recording_manager_last_name}
              onChange={formik.handleChange}
              error={formik.errors.recording_manager_last_name}
            />
          </StyledCol>
          <StyledCol>
            <Input
              type="text"
              name="recording_manager_phone"
              id="recording_manager_phone"
              label={t({ id: 'recording_manager_phone' })}
              className="form-control-custom"
              value={formik.values.recording_manager_phone}
              invalid={!!formik.errors.recording_manager_phone && formik.touched.recording_manager_phone}
              onChange={formik.handleChange}
              error={formik.errors.recording_manager_phone}
            />
          </StyledCol>
          <StyledCol>
            <Input
              type="email"
              name="recording_manager_email"
              id="recording_manager_email"
              label={t({ id: 'recording_manager_email' })}
              className="form-control-custom"
              value={formik.values.recording_manager_email}
              invalid={!!formik.errors.recording_manager_email && formik.touched.recording_manager_email}
              onChange={formik.handleChange}
              error={formik.errors.recording_manager_email}
            />
          </StyledCol>
          <StyledCol>
            <StyledButtonWrapper>
              <StyledButton disabled={formik.isSubmitting} color="black" onClick={handleNext}>
                {formik.isSubmitting ? t({ id: 'loading' }) : t({ id: 'button_next_step' })}
              </StyledButton>
            </StyledButtonWrapper>
          </StyledCol>
        </StyledRow>
      </Form>
    </StyledForm>
  );
};
