import arrowLeft from '@assets/icons/icons_arrow-left.svg';
import arrowRight from '@assets/icons/icons_arrow-right.svg';
import breakpoints from '@lib/common/breakpoints';
import Flickity from 'flickity';
import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-top: 60px;
  position: relative;
  margin-bottom: -1px;
  padding: 0 80px;

  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 0 40px;
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 0 20px;
  }
`;

const CategorySlider = styled.div`
  position: relative;

  .flickity-slider.start {
    //left: 0px !important;
    @media only screen and ${breakpoints.maxWidth.md} {
      //left: 23px !important;
    }
  }

  .next {
    position: absolute;
    top: -30px;
    left: unset;
    right: 0;

    width: 18px;
    height: 18px;
    transition: 0.1s;

    background-image: ${`url(${arrowRight})`};
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent !important;
    transform: none;

    > svg {
      display: none;
    }

    &:hover {
      transform-origin: center;
      transform: scale(0.8);
    }
  }

  .previous {
    position: absolute;
    top: -30px;
    left: unset;
    right: 30px;

    width: 18px;
    height: 18px;
    transition: 0.1s;

    background-image: ${`url(${arrowLeft})`};
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent !important;
    transform: none;

    > svg {
      display: none;
    }

    &:hover {
      transform-origin: center;
      transform: scale(0.8);
    }
  }
`;

const CategorySliderItem = styled.div<{ [key: string]: any }>`
  display: block;
  padding-right: 20px;
  cursor: pointer;
  &:last-of-type {
    padding-right: 0;
  }

  > div {
    /* word-break: break-all; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 74px;
    border: 1px solid #000000;
    background-color: ${(props) => (props.active ? '#000000' : null)};
    &:hover {
      background-color: #000000;
      span {
        color: #ffffff;
      }
    }
  }

  span {
    font-family: GTFlexa-ExtendedLight, sans-serif;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    color: ${(props) => (props.active ? '#ffffff' : '#000000')};

    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 18px;
    }
  }
`;

interface ISearchCategoriesProps {
  className?: string;
  onSelect: (category: string) => void;
  active: string | null;
}

const categories = [
  {
    id: 'alle',
    label: 'Alle',
  },
  {
    id: 'locations',
    label: 'Locations',
  },
  {
    id: 'branches',
    label: 'Branchen',
  },
  {
    id: 'news',
    label: 'News',
  },
  {
    id: 'produktionsspiegel',
    label: 'Produktionsspiegel',
  },
];

const SearchCategories: FC<ISearchCategoriesProps> = (props) => {
  const ref = useRef<any>(null);
  const [countChange, setCountChange] = useState<any>();

  useEffect(() => {
    // ref?.current?.resize();
    const t = setTimeout(() => {
      ref?.current?.resize();
    }, 1000);
    return () => {
      clearTimeout(t);
    };
  }, [countChange]);

  useEffect(() => {
    const id = 'search-categories-slider';
    const elem = document.querySelector(`[data-sliderid=${id}]`);

    if (!elem) {
      return;
    }

    ref.current = new Flickity(elem, {
      cellAlign: 'center',
      contain: true,
      groupCells: true,
      pageDots: false,
      draggable: true,
      on: {
        ready: function (this: any) {
          setCountChange(countChange + 1);
          const target = document.querySelector(`[data-sliderid=${id}] .flickity-slider`);
          (target as any).classList.add('start');
        },
      },
    });

    ref?.current?.on('change', function (index: any) {
      setCountChange(countChange + 1);
      const target = document.querySelector(`[data-sliderid=${id}] .flickity-slider`);
      if (!target) return;
      if (index > 0) {
        (target as any).classList.remove('start');
      } else {
        (target as any).classList.add('start');
      }
    });
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = (id: string, index: number) => {
    // ref?.current?.select(index);
    props.onSelect(id);
  };

  return (
    <Wrapper className={props.className}>
      <CategorySlider data-sliderid="search-categories-slider">
        {categories.map((c, index) => {
          return (
            <CategorySliderItem key={c.id} active={c.id === props.active} onClick={() => handleClick(c.id, index)}>
              <div>
                <span>{c.label}</span>
              </div>
            </CategorySliderItem>
          );
        })}
      </CategorySlider>
    </Wrapper>
  );
};

export default SearchCategories;
