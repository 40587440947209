import Button from '@components/shared/Button';
import { useRouter } from '@lib/hooks/useRouter';
import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

const StyledForm = styled.div`
  width: 100%;
`;
const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;

const StyledButton = styled(Button)`
  font-size: 24px;
  padding: 24px 36px;
`;

export const LocationComplete = () => {
  const router = useRouter();
  const { formatMessage: t } = useIntl();

  return (
    <StyledForm className="LocationComplete">
      <StyledButtonWrapper>
        <StyledButton color="black" onClick={() => router.history.push('/')}>
          {t({ id: 'back_home' })}
        </StyledButton>
      </StyledButtonWrapper>
    </StyledForm>
  );
};
