import { getProductions, GetProductionsResponse } from '@api/productions';
import QueryKeys from '@lib/constants/QueryKeys';
import { AxiosError } from 'axios';
import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query';

export default function useGetProductions(
  production_categories: string = '',
  veroffentlicht: string = '',
  year: string = '',
  search: string | undefined = undefined,
  options?: UseInfiniteQueryOptions<GetProductionsResponse, AxiosError, any>,
) {
  return useInfiniteQuery<GetProductionsResponse, AxiosError>(
    [QueryKeys.productions(), production_categories, veroffentlicht, year, search],
    ({ pageParam = 1 }) => getProductions({ page: pageParam, production_categories: production_categories, veroffentlicht, year: year, search }),
    {
      getNextPageParam: ({ current_page, total_pages }) => {
        if (current_page === total_pages) {
          return;
        }
        return current_page + 1;
      },
      cacheTime: 0,
      ...options,
    },
  );
}
