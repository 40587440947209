import { Container } from '@components/shared/Container';
import ForgotPasswordForm from '@components/user/ForgotPassword/ForgotPasswordForm';
import breakpoints from '@lib/common/breakpoints';
import React from 'react';
import { useIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

const StyledForgotPassword = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.yellow};
  padding: 120px 0;
  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 100px 0;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 80px 0;
  }
`;

const StyledPageHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  @media only screen and ${breakpoints.maxWidth.md} {
    margin-bottom: 50px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    margin-bottom: 50px;
  }
  h1 {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 58px;
    line-height: 1.05em;
    color: ${(props) => props.theme.colors.black};
    margin-bottom: 27px;
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 28px;
      line-height: 1.18em;
      margin-bottom: 24px;
    }

    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 24px;
      line-height: 1.18em;
      margin-bottom: 24px;
    }
  }
  p {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 36px;
    letter-spacing: 0.4px;
    line-height: 1.18em;
    color: ${(props) => props.theme.colors.black};
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 20px;
      letter-spacing: 0.2px;
      line-height: 1.2em;
    }

    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 18px;
      letter-spacing: 0.2px;
      line-height: 1.2em;
    }
  }
`;

interface Props {}

const ForgotPassword = (props: RouteComponentProps<Props>) => {
  const { formatMessage: t } = useIntl();
  return (
    <StyledForgotPassword className="ForgotPassword">
      <Container>
        <StyledPageHeader>
          <h1>{t({ id: 'forgot.password' })}</h1>
        </StyledPageHeader>
        <ForgotPasswordForm />
      </Container>
    </StyledForgotPassword>
  );
};
export default ForgotPassword;
