import { ReactComponent as SearchIcon } from '@assets/icons/icons_search_small.svg';
import breakpoints from '@lib/common/breakpoints';
import { useUIContext } from '@lib/context/UIContext/UIContext';
import ModuleType from '@lib/interfaces/Modules';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const Module06Container = styled.div<{ withBorders?: boolean }>`
  width: 100%;
  padding: 12px 80px;
  display: flex;
  align-items: center;
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  font-size: 36px;
  font-weight: normal;
  line-height: 1.17;
  letter-spacing: 0.4px;
  background-color: #ffffff;
  border-block: ${(props) => (props.withBorders ? ' 2px solid #000000' : null)};

  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 6px 40px;
    font-size: 19px;
    line-height: 1.17;
    letter-spacing: 0.21px;
  }

  form {
    width: inherit;
    display: flex;
  }
`;

const Label = styled.label`
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  margin-right: 14px;
`;

const Input = styled.input`
  border: none;
  outline: none;
  width: 100%;
  color: #bebebe;
  font-size: 36px;
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  line-height: 1.17;
  letter-spacing: 0.4px;
  padding-right: 15px;
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 19px;
    line-height: 1.17;
    letter-spacing: 0.21px;
  }
`;

const StyledButton = styled.button`
  background: transparent;
  outline: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  &:focus {
    background: transparent;
  }
`;

const StyledSearchIcon = styled(SearchIcon)`
  width: 32px;
  height: 34px;
`;

interface Module06Props {
  showIcon?: boolean;
  className?: string;
  data?: {
    title?: string;
    with_borders?: boolean;
  };
}

export default function Module06(props: Module06Props) {
  const history = useHistory();
  const [value, setValue] = useState('');
  const uiContext = useUIContext();

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (value.length <= 3) {
      return;
    }

    uiContext.setState({ isSearchOpened: false });
    history.push(`/search?searchKeyword=${value}`);
  };

  return (
    <Module06Container data-module={ModuleType.MODULE_06} className={props.className} withBorders={props.data?.with_borders}>
      <Label htmlFor="search">{props.data?.title}:</Label>
      <form onSubmit={handleSubmit}>
        <Input value={value} onChange={(e: any) => setValue(e.target.value)} />
        {props.showIcon && (
          <StyledButton>
            <StyledSearchIcon />
          </StyledButton>
        )}
      </form>
    </Module06Container>
  );
}
