import * as API from '@api/user';
import Button from '@components/shared/Button';
import breakpoints from '@lib/common/breakpoints';
import { useProfileChangePasswordFormik } from '@lib/hooks/formik/useProfileChangePasswordFormik';
import { useRouter } from '@lib/hooks/useRouter';
import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Form } from 'reactstrap';
import styled, { css } from 'styled-components';
import { Input } from '../Input';

const StyledProfileChangePasswordForm = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  padding: 120px 150px;
  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 100px 20px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 80px 20px;
  }
`;
const StyledFormTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-bottom: 24px;
  @media only screen and ${breakpoints.maxWidth.md} {
    align-items: flex-start;
    flex-direction: column;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    align-items: flex-start;
    flex-direction: column;
  }
  h3 {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 36px;
    letter-spacing: 0.4px;
    line-height: 1.2em;
    color: ${(props) => props.theme.colors.black};
    margin-right: 20px;
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 24px;
      letter-spacing: 0.2px;
      margin-right: 0;
    }
    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 24px;
      letter-spacing: 0.2px;
      margin-right: 0;
    }
  }
  a {
    font-family: 'GTFlexa-StandardMedium';
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 1.24em;
    color: ${(props) => props.theme.colors.black};
    text-decoration: underline;
    margin-bottom: 5px;
    @media only screen and ${breakpoints.maxWidth.md} {
      margin-top: 5px;
    }
    @media only screen and ${breakpoints.maxWidth.sm} {
      margin-top: 5px;
    }
  }
`;
const StyledRow = styled.div`
  margin-left: -16px;
  margin-right: -16px;
`;

interface IColType {
  size?: number;
}
const StyledCol = styled.div<IColType>`
  display: inline-block;
  width: 100%;
  padding: 0 16px;
  ${({ size }) =>
    size === 6 &&
    css`
      float: left;
      width: 50%;
      @media only screen and ${breakpoints.maxWidth.md} {
        width: 100%;
      }
      @media only screen and ${breakpoints.maxWidth.sm} {
        width: 100%;
      }
    `}
`;
const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  @media only screen and ${breakpoints.maxWidth.md} {
    flex-direction: column-reverse;
    margin-top: 20px;
    button:first-child {
      margin-bottom: 0 !important;
    }
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    flex-direction: column-reverse;
    margin-top: 20px;
    button:first-child {
      margin-bottom: 0 !important;
    }
  }
`;
const StyledCancelButton = styled(Button)`
  min-width: 341px;
  font-size: 24px;
  padding: 24px 36px;
  margin-right: 30px;
  @media only screen and ${breakpoints.maxWidth.md} {
    min-width: 100%;
    width: 100%;
    font-size: 20px;
    padding: 10px 18px;
    margin-bottom: 20px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    width: 100%;
    font-size: 20px;
    padding: 10px 18px;
    margin-bottom: 20px;
  }
`;
const StyledButton = styled(Button)`
  font-size: 24px;
  padding: 24px 36px;
  @media only screen and ${breakpoints.maxWidth.md} {
    width: 100%;
    font-size: 20px;
    padding: 10px 18px;
    margin-bottom: 20px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    width: 100%;
    font-size: 20px;
    padding: 10px 18px;
    margin-bottom: 20px;
  }
`;

interface Props {
  setDeletedSuccessfully: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ProfileChangePasswordForm = (props: Props) => {
  const { formatMessage: t } = useIntl();
  const router = useRouter();

  const formik = useProfileChangePasswordFormik({
    onSubmit: async (values, formikHelpers) => {
      try {
        await API.changePassword(values);
        props.setDeletedSuccessfully(true);
        formikHelpers.resetForm();
      } catch (error) {
        //@ts-ignore
        formikHelpers.setErrors(error.response.data.data.errors);
      }
    },
  });

  return (
    <StyledProfileChangePasswordForm className="ProfileChangePasswordForm">
      <StyledFormTitle>
        <h3>{t({ id: 'login_data' })}</h3>
        <Link to="/profile">{t({ id: 'just_want_to_edit_your_profile' })}</Link>
      </StyledFormTitle>
      <Form onSubmit={formik.handleSubmit}>
        <StyledRow>
          <StyledCol>
            <Input
              type="password"
              name="old_password"
              id="old_password"
              label={t({ id: 'old_password' })}
              className="form-control-custom"
              value={formik.values.old_password}
              invalid={!!formik.errors?.old_password && formik.touched?.old_password}
              onChange={formik.handleChange}
              error={formik.errors?.old_password}
            />
          </StyledCol>
          <StyledCol>
            <Input
              type="password"
              name="password"
              id="password"
              label={t({ id: 'new_password' })}
              className="form-control-custom"
              value={formik.values.password}
              invalid={!!formik.errors?.password && formik.touched?.password}
              onChange={formik.handleChange}
              error={formik.errors?.password}
            />
          </StyledCol>
          <StyledCol>
            <Input
              type="password"
              name="password_confirmation"
              id="password_confirmation"
              label={t({ id: 'new_password_confirm' })}
              className="form-control-custom"
              value={formik.values.password_confirmation}
              invalid={!!formik.errors?.password_confirmation && formik.touched?.password_confirmation}
              onChange={formik.handleChange}
              error={formik.errors?.password_confirmation}
            />
          </StyledCol>

          <StyledCol>
            <StyledButtonWrapper>
              <StyledCancelButton onClick={() => router.history.push('/profile')}>{t({ id: 'private_request_button_back' })}</StyledCancelButton>
              <StyledButton disabled={formik.isSubmitting} color="black">
                {formik.isSubmitting ? t({ id: 'loading' }) : t({ id: 'change_password' })}
              </StyledButton>
            </StyledButtonWrapper>
          </StyledCol>
        </StyledRow>
      </Form>
    </StyledProfileChangePasswordForm>
  );
};
