import usePages from '@lib/hooks/usePages';
import React from 'react';
import { RouteComponentProps } from 'react-router';

interface Props {
  pageSlug?: string;
}

const WordpressPage: React.FC<RouteComponentProps<Props>> = (props) => {
  const { getPageBySlug } = usePages();

  const slug = props.match.params.pageSlug;

  const PageComponent = getPageBySlug(slug);

  return <PageComponent slug={slug} />;
};

export default WordpressPage;
