import { ReactNode } from 'react';

interface Props {
  isOpen: boolean;
  children: ReactNode;
  className?: string;
  toggle: () => void;
}

export const Modal = (props: Props) => {
  return (
    <></>
    // <Modal className={cs(props.className)} isOpen={props.isOpen} toggle={props.toggle}>
    //   {props.children}
    // </Modal>
  );
};
