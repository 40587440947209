import axios from '@api/axios';
import * as API from '@api/user';
import { LoginFields } from '@lib/hooks/formik/useLoginFormik';
import { RegisterFields } from '@lib/hooks/formik/useRegisterFormik';
import { useRouter } from '@lib/hooks/useRouter';
import { useLocalStorage } from '@rehooks/local-storage';
import React, { useEffect, useState } from 'react';
import { AuthContext, AuthContextType } from './AuthContext';

interface AuthContextProviderProps {
  children: React.ReactNode | null;
}

const LOCAL_STORAGE_KEY = 'VFC_TOKEN';

export default function AuthContextProvider(props: AuthContextProviderProps) {
  const [authToken] = useLocalStorage(LOCAL_STORAGE_KEY);
  const router = useRouter();

  const [user, setUser] = useState<API.User | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(false);
  const [error, setError] = useState<any>(undefined);

  useEffect(() => {
    (async () => {
      await reloadAuthentication();
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const reloadAuthentication = async () => {
    if (loading || !authToken) {
      return;
    }

    try {
      setIsAuthenticating(true);
      //@ts-ignore
      axios.defaults.headers.common.Authorization = `Bearer ${authToken}`;

      const res = await API.getUserDetails();

      setUser(res.me);
    } catch (err) {
      if ((err as any)?.response?.status === 403) {
        window.localStorage.removeItem(LOCAL_STORAGE_KEY);
        //@ts-ignore
        delete axios?.defaults?.headers?.common?.Authorization;
      }
    } finally {
      setIsAuthenticating(false);
    }
  };

  const login = async (values: LoginFields) => {
    try {
      setLoading(true);
      const res = await API.login(values);

      window.localStorage.setItem(LOCAL_STORAGE_KEY, res.token);

      //@ts-ignore
      axios.defaults.headers.common.Authorization = `Bearer ${res.token}`;
      setUser(res);
    } catch (error) {
      //@ts-ignore
      setError(error.response.data.code);
    } finally {
      setLoading(false);
    }
  };

  const register = async (values: RegisterFields) => {
    try {
      const res = await API.register(values);

      window.localStorage.setItem(LOCAL_STORAGE_KEY, res.token.token);

      //@ts-ignore
      axios.defaults.headers.common.Authorization = `Bearer ${res.token.token}`;
      setUser(res.token);
      router.history.push('/profile');
      return res;
    } catch (error) {
      //@ts-ignore
      setError(error.response.data.data.errors);
      return error;
    }
  };

  const logout = () => {
    setUser(undefined);
    window.localStorage.removeItem(LOCAL_STORAGE_KEY);
    //@ts-ignore
    delete axios?.defaults?.headers?.common?.Authorization;
  };

  const context: AuthContextType = {
    isAuthenticated: !!user,
    isAuthenticating,
    isLoading: loading,
    error: error,
    user: user,
    login: login,
    register: register,
    logout: logout,
    setUser: setUser,
    reloadAuthentication,
  };

  return <AuthContext.Provider value={context}>{props.children}</AuthContext.Provider>;
}
