import { validateFilmingStep3 } from '@api/filming';
import Button from '@components/shared/Button';
import breakpoints from '@lib/common/breakpoints';
import { FilmingFormik } from '@lib/hooks/formik/useFilmingFormik';
import React from 'react';
import { useIntl } from 'react-intl';
//Components
import { Form } from 'reactstrap';
import styled, { css } from 'styled-components';
import { Input } from '../Input';
import { FILMING_STEP } from './FilmingForm';

const StyledForm = styled.div`
  width: 100%;
`;
const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  @media only screen and ${breakpoints.maxWidth.md} {
    flex-direction: column-reverse;
    margin-top: 20px;
    button:first-child {
      margin-bottom: 0 !important;
    }
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    flex-direction: column-reverse;
    margin-top: 20px;
    button:first-child {
      margin-bottom: 0 !important;
    }
  }
`;
const StyledRow = styled.div`
  margin-left: -15px;
  margin-right: -15px;
`;
const StyledCol = styled.div<IColType>`
  display: inline-block;
  width: 100%;
  padding: 0 15px;

  ${({ size }) =>
    size === 6 &&
    css`
      float: left;
      width: 50%;
      @media only screen and ${breakpoints.maxWidth.md} {
        width: 100%;
      }
      @media only screen and ${breakpoints.maxWidth.sm} {
        width: 100%;
      }
    `}
`;

const StyledButton = styled(Button)`
  font-size: 24px;
  padding: 24px 36px;
  @media only screen and ${breakpoints.maxWidth.md} {
    width: 100%;
    font-size: 20px;
    padding: 10px 18px;
    margin-bottom: 20px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    width: 100%;
    font-size: 20px;
    padding: 10px 18px;
    margin-bottom: 20px;
  }
`;

interface IColType {
  size?: number;
}

interface Props {
  formik: FilmingFormik;
  setStep: React.Dispatch<React.SetStateAction<FILMING_STEP>>;
}

const step3Fields = [
  'shooting_date',
  'replacement_date',
  'time_of_construction',
  'time_of_dismantling',
  'start_of_shooting_time',
  'end_of_rotation_time',
];

export const FilmingShootingTime = ({ formik, setStep }: Props) => {
  const { formatMessage: t } = useIntl();
  const handleBack = () => setStep(FILMING_STEP.PRODUCTION);

  const handleNext = async () => {
    try {
      const errors = await formik.validateForm(formik.values);

      if (formik.errors.shooting_date || formik.errors.replacement_date) {
        return;
      }

      const step3Errors = Object.keys(errors).filter((err) => step3Fields.includes(err));

      const res = await validateFilmingStep3({
        step: '3',
        shooting_date: formik.values.shooting_date,
        replacement_date: formik.values.replacement_date,
        time_of_construction: formik.values.time_of_construction,
        time_of_dismantling: formik.values.time_of_dismantling,
        start_of_shooting_time: formik.values.start_of_shooting_time,
        end_of_rotation_time: formik.values.end_of_rotation_time,
      });

      if (res.data?.valid && step3Errors?.length === 0) {
        setStep(4);
        formik.setErrors({});
        return;
      }
      await formik.submitForm();
      return;
    } catch (error) {
      //@ts-ignore
      formik.setErrors(error.response.data.data.errors);
    }
  };

  return (
    <StyledForm className="FilmingShootingTime">
      <Form onSubmit={formik.handleSubmit}>
        <StyledRow>
          <StyledCol>
            <Input
              type="text"
              name="shooting_date"
              id="shooting_date"
              label={t({ id: 'shooting_date' })}
              className="form-control-custom"
              value={formik.values.shooting_date}
              invalid={!!formik.errors.shooting_date && formik.touched.shooting_date}
              onChange={formik.handleChange}
              error={formik.errors.shooting_date}
            />
          </StyledCol>
          <StyledCol>
            <Input
              type="text"
              name="replacement_date"
              id="replacement_date"
              label={t({ id: 'replacement_date' })}
              className="form-control-custom"
              value={formik.values.replacement_date}
              invalid={!!formik.errors.replacement_date && formik.touched.replacement_date}
              onChange={formik.handleChange}
              error={formik.errors.replacement_date}
            />
          </StyledCol>
          <StyledCol>
            <Input
              type="text"
              name="time_of_construction"
              id="time_of_construction"
              label={t({ id: 'time_of_construction' })}
              className="form-control-custom"
              value={formik.values.time_of_construction}
              invalid={!!formik.errors.time_of_construction && formik.touched.time_of_construction}
              onChange={formik.handleChange}
              error={formik.errors.time_of_construction}
            />
          </StyledCol>
          <StyledCol>
            <Input
              type="text"
              name="time_of_dismantling"
              id="time_of_dismantling"
              label={t({ id: 'time_of_dismantling' })}
              className="form-control-custom"
              value={formik.values.time_of_dismantling}
              invalid={!!formik.errors.time_of_dismantling && formik.touched.time_of_dismantling}
              onChange={formik.handleChange}
              error={formik.errors.time_of_dismantling}
            />
          </StyledCol>
          <StyledCol>
            <Input
              type="text"
              name="start_of_shooting_time"
              id="start_of_shooting_time"
              label={t({ id: 'start_of_shooting_time' })}
              className="form-control-custom"
              value={formik.values.start_of_shooting_time}
              invalid={!!formik.errors.start_of_shooting_time && formik.touched.start_of_shooting_time}
              onChange={formik.handleChange}
              error={formik.errors.start_of_shooting_time}
            />
          </StyledCol>
          <StyledCol>
            <Input
              type="text"
              name="end_of_rotation_time"
              id="end_of_rotation_time"
              label={t({ id: 'end_of_rotation_time' })}
              className="form-control-custom"
              value={formik.values.end_of_rotation_time}
              invalid={!!formik.errors.end_of_rotation_time && formik.touched.end_of_rotation_time}
              onChange={formik.handleChange}
              error={formik.errors.end_of_rotation_time}
            />
          </StyledCol>
          <StyledCol>
            <StyledButtonWrapper>
              <StyledButton color="black" onClick={handleBack}>
                {t({ id: 'button_back' })}
              </StyledButton>
              <StyledButton disabled={formik.isSubmitting} color="black" onClick={handleNext}>
                {formik.isSubmitting ? t({ id: 'loading' }) : t({ id: 'button_next_step' })}
              </StyledButton>
            </StyledButtonWrapper>
          </StyledCol>
        </StyledRow>
      </Form>
    </StyledForm>
  );
};
