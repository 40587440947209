import * as API from '@api/filming';
import * as APIL from '@api/locations';
import Button from '@components/shared/Button';
import { Container } from '@components/shared/Container';
import HandleFetchingState from '@components/shared/HandleFetchingState';
import { RequestLocationsForm } from '@components/user/Location/RequestLocationsForm';
import breakpoints from '@lib/common/breakpoints';
import { useLocationBookmarksContext } from '@lib/context/LocationBookmarksContext/LocationBookmarksContext';
import { useRequestLocationsFormik } from '@lib/hooks/formik/useRequestLocationsFormik';
import useGetMultiFilmingPermitForm from '@lib/hooks/queries/useGetMultiFilmingPermitForm';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

const StyledRequestPrivateLocations = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.yellow};
  padding: 120px 0;
  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 100px 0;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 80px 0;
  }
`;

const StyledPageHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  @media only screen and ${breakpoints.maxWidth.md} {
    margin-bottom: 50px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    margin-bottom: 50px;
  }
  h1 {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 58px;
    line-height: 1.05em;
    color: ${(props) => props.theme.colors.black};
    margin-bottom: 27px;
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 28px;
      line-height: 1.18em;
      margin-bottom: 24px;
    }

    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 24px;
      line-height: 1.18em;
      margin-bottom: 24px;
    }
  }
  p {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 36px;
    letter-spacing: 0.4px;
    line-height: 1.18em;
    color: ${(props) => props.theme.colors.black};
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 20px;
      letter-spacing: 0.2px;
      line-height: 1.2em;
    }

    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 18px;
      letter-spacing: 0.2px;
      line-height: 1.2em;
    }
  }
`;

const StyledSuccess = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  padding: 120px 150px 120px;
`;
const StyledSuccessTitle = styled.h3`
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  font-size: 36px;
  letter-spacing: 0.4px;
  line-height: 1.2em;
  color: ${(props) => props.theme.colors.black};
`;

const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 80px;
`;

const StyledButton = styled(Button)`
  font-size: 24px;
  padding: 24px 36px;
`;

interface Props {}

export const RequestPrivateLocations = (props: RouteComponentProps<Props>) => {
  const { formatMessage: t } = useIntl();
  const [success, setSuccess] = useState(false);
  const { bookmarks, removeBookmark } = useLocationBookmarksContext();
  const { data, isLoading, error } = useGetMultiFilmingPermitForm();

  const formik = useRequestLocationsFormik({
    initialValues: {
      locations: [],
      company_name: '',
      type_of_production: 0,
      production_title: '',
      name: '',
      phone: '',
      email: '',
      email_confirmation: '',
      comment: '',
      data_protection: false,
      terms: false,
    },
    onSubmit: async (values, formikHelpers) => {
      try {
        await API.multiPrivatFilmingPermit({
          location_ids: values.locations?.map((location) => location.id),
          company_name: values.company_name,
          type_of_production: data?.data?.type_of_production[values.type_of_production].type || '',
          production_title: values.production_title,
          name: values.name,
          phone: values.phone,
          email: values.email,
          email_confirmation: values.email_confirmation,
          comment: values.comment,
          terms: values.terms,
          data_protection: values.data_protection,
        });
        bookmarks.map((loc) => {
          return removeBookmark(loc);
        })
        setSuccess(true);
      } catch (e) {
        //@ts-ignore
        formikHelpers.setErrors(e.response.data.data.errors);
      }
    },
  });

  useEffect(() => {
    if (!bookmarks) {
      return;
    }
    (async () => {
      try {
        const locationsReq = bookmarks.map((id) => {
          return APIL.getLocationsDetails(`${id}`);
        });

        const res = await Promise.all(locationsReq);
        const locations = [] as { id: number; title: string; image: string }[];
        res.map((location) => {
          return locations.push({
            id: location.id,
            title: location.title,
            image: location.thumbnails.medium || location.thumbnail,
          });
        });
        formik.setFieldValue('locations', locations);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [bookmarks]); //eslint-disable-line react-hooks/exhaustive-deps

  const SuccessRequest = () => {
    return (
      <>
        <StyledPageHeader>
          <h1>{t({ id: 'private_location_title1' })}</h1>
        </StyledPageHeader>
        <StyledSuccess className="RegisterForm">
          <StyledSuccessTitle>{t({ id: 'private_location_success' })}</StyledSuccessTitle>
          <StyledButtonWrapper>
            <StyledButton color="black" to="/">
              {t({ id: 'back_home' })}
            </StyledButton>
          </StyledButtonWrapper>
        </StyledSuccess>
      </>
    );
  };

  return (
    <HandleFetchingState loading={isLoading} error={error}>
      <StyledRequestPrivateLocations className="RequestPrivateLocations">
        <Container>
          {success ? (
            <SuccessRequest />
          ) : (
            <>
              <StyledPageHeader>
                <h1>{t({ id: 'private_location_title1' })}</h1>
                <p>{t({ id: 'private_location_title2' })}</p>
              </StyledPageHeader>
              <RequestLocationsForm formik={formik} data={data} />
            </>
          )}
        </Container>
      </StyledRequestPrivateLocations>
    </HandleFetchingState>
  );
};
