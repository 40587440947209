import breakpoints from '@lib/common/breakpoints';
import { LocationConfirmationFormik } from '@lib/hooks/formik/useLocationConfirmationFormik';
import { LocationContactFormik } from '@lib/hooks/formik/useLocationContactFormik';
import { LocationDataFormik } from '@lib/hooks/formik/useLocationDataFormik';
import { LocationInfoFormik } from '@lib/hooks/formik/useLocationInfoFormik';
import useGetLocationsForm from '@lib/hooks/queries/useGetLocationsForm';
import { StyledPageHeader } from '@pages/manage-location/CreateLocation';
import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { LocationComplete } from './LocationComplete';
import { LocationConfirmation } from './LocationConfirmation';
import { LocationContact } from './LocationContact';
import { LocationData } from './LocationData';
import { LocationInfo } from './LocationInfo';

const StyledForm = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  padding: 80px 150px 120px;
  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 80px 24px 50px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 80px 24px 50px;
  }
`;
const StyledFormTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-bottom: 24px;
  @media only screen and ${breakpoints.maxWidth.md} {
    align-items: flex-start;
    flex-direction: column;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    align-items: flex-start;
    flex-direction: column;
  }
  h3 {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 36px;
    letter-spacing: 0.4px;
    line-height: 1.2em;
    color: ${(props) => props.theme.colors.black};
    margin-right: 20px;
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 24px;
      letter-spacing: 0.2px;
      margin-right: 0;
    }
    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 24px;
      letter-spacing: 0.2px;
      margin-right: 0;
    }
  }
  a {
    font-family: 'GTFlexa-StandardMedium';
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 1.24em;
    color: ${(props) => props.theme.colors.black};
    text-decoration: underline;
    margin-bottom: 5px;
    @media only screen and ${breakpoints.maxWidth.md} {
      margin-top: 5px;
    }
    @media only screen and ${breakpoints.maxWidth.sm} {
      margin-top: 5px;
    }
  }
`;

const StyledStepDots = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
  @media only screen and ${breakpoints.maxWidth.md} {
    margin-bottom: 30px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    margin-bottom: 30px;
  }
`;

interface IStyledStepDot {
  filled?: boolean;
}
const StyledStepDot = styled.div<IStyledStepDot>`
  width: 13px;
  height: 13px;
  border: 2px solid ${(props) => props.theme.colors.black};
  margin-right: 30px;
  @media only screen and ${breakpoints.maxWidth.md} {
    width: 8px;
    height: 8px;
    margin-right: 20px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    width: 8px;
    height: 8px;
    margin-right: 20px;
  }
  &:last-child {
    margin-right: 0;
  }
  ${({ filled }) =>
    filled &&
    css`
      background-color: ${(props) => props.theme.colors.black};
    `}
`;

export enum LOCATION_STEP {
  LOCATION_INFO = 1,
  LOCATION_DATA = 2,
  FURTHER_CONTACT = 3,
  CONFIRMATION = 4,
  THANK_YOU = 5,
}

interface Props {
  step: LOCATION_STEP;
  locationInfoFormik: LocationInfoFormik;
  locationDataFormik: LocationDataFormik;
  locationContactFormik: LocationContactFormik;
  locationConfirmationFormik: LocationConfirmationFormik;
  setStep: React.Dispatch<React.SetStateAction<LOCATION_STEP>>;
}

export const LocationForm = ({ step, locationInfoFormik, locationDataFormik, locationContactFormik, locationConfirmationFormik, setStep }: Props) => {
  const { formatMessage: t } = useIntl();
  const { isLoading, data } = useGetLocationsForm();
  const steps = [
    {
      name: `1. ${t({ id: 'location_registrations_step1_title' })}`,
      description: t({ id: 'location_registrations_step1_description' }),
    },
    {
      name: `2. ${t({ id: 'location_registrations_step2_title' })}`,
    },
    {
      name: `3. ${t({ id: 'location_registrations_step3_title' })}`,
    },
    {
      name: `4. ${t({ id: 'location_registrations_step4_title' })}`,
    },
    {
      name: t({ id: 'location_registrations_success_title' }),
      description: t({ id: 'location_registrations_success_description' }),
    },
  ];

  const renderActiveStep = () => {
    switch (step) {
      case LOCATION_STEP.LOCATION_INFO:
        return <LocationInfo setStep={setStep} formik={locationInfoFormik} data={data} />;
      case LOCATION_STEP.LOCATION_DATA:
        return <LocationData setStep={setStep} formik={locationDataFormik} />;
      case LOCATION_STEP.FURTHER_CONTACT:
        return <LocationContact setStep={setStep} formik={locationContactFormik} />;
      case LOCATION_STEP.CONFIRMATION:
        return <LocationConfirmation setStep={setStep} formik={locationConfirmationFormik} />;
      case LOCATION_STEP.THANK_YOU:
        return <LocationComplete />;
      default:
        return null;
    }
  };

  return (
    <>
      <StyledPageHeader>
        <h1>{t({ id: 'location_registration_title1' })}</h1>
        {steps[step - 1]?.description && <p>{steps[step - 1]?.description}</p>}
      </StyledPageHeader>
      <StyledForm className="LocationForm">
        {step !== LOCATION_STEP.THANK_YOU && (
          <StyledStepDots>
            {steps
              .filter((s, i) => i !== steps.length - 1)
              .map((el, index) => {
                return <StyledStepDot key={`location-form-dot-${index}`} filled={index + 1 <= step} />;
              })}
          </StyledStepDots>
        )}
        <StyledFormTitle>
          <h3>{steps[step - 1].name}</h3>
        </StyledFormTitle>
        {isLoading ? <p>Loading...</p> : renderActiveStep()}
      </StyledForm>
    </>
  );
};
