import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
  font-size: 24px;
  font-family: GTFlexa-ExtendedLight, sans-serif;
  color: #585858;
`;

interface INoResultsProps {
  className?: string;
}

const NoResults: FC<INoResultsProps> = (props) => {
  const { formatMessage } = useIntl();

  return <Wrapper className={props.className}>{formatMessage({ id: 'no_results' })}</Wrapper>;
};

export default NoResults;
