import { ReactComponent as FullScreenButton } from '@assets/icons/icons_expand.svg';
import { ReactComponent as PauseButton } from '@assets/icons/icons_pause.svg';
import { ReactComponent as PlayButton } from '@assets/icons/icons_play.svg';
import { ReactComponent as SoundOff } from '@assets/icons/icons_sound-off.svg';
import { ReactComponent as SoundOn } from '@assets/icons/icons_sound-on.svg';
import { ReactComponent as Logo } from '@assets/icons/vfc-logo.svg';
import { Container } from '@components/shared/Container';
import FullScreenModal from '@components/shared/FullScreenModal';
import breakpoints from '@lib/common/breakpoints';
import ModuleType from '@lib/interfaces/Modules';
import cs from 'classnames';
import { useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div<{ showGrayBackground?: boolean }>`
  width: 100%;
  display: flex;
  background-color: ${(props) => (props.showGrayBackground ? '#1f1f1f' : null)};
  padding: 90px 0;
  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 90px 0 40px;
  }
`;

const Module02Wrapper = styled.div<{ withHeader?: boolean }>`
  position: relative;
  width: 100%;
  height: ${(props) => (props.withHeader ? '100vh' : 'auto')};

  &.Module02--fullscreen {
    @media only screen and ${breakpoints.maxWidth.md} {
      height: auto !important;

      > div:first-of-type {
        height: 400px !important;
      }

      [data-element='m02-video-details'] {
        padding-right: 0;
        bottom: 10px;
      }

      [data-element='m02-video-controls'] {
        display: none !important;
      }

      header[data-position='fixed'] {
        position: fixed;
      }

      header[data-position='absolute'] {
        position: relative;
      }
    }

    @media only screen and ${breakpoints.maxWidth.xs} {
      height: auto !important;

      > div:first-of-type {
        height: 183px !important;
      }
    }
  }
`;

const VideoContainer = styled.div<{ withHeader?: boolean }>`
  width: 100%;
  height: ${(props) => (props.withHeader ? '100% !important' : '706px')};
  position: relative;

  @media only screen and ${breakpoints.maxWidth.md} {
    height: 450px;
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    height: 375px;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    height: 200px;
  }
`;

const FullScreenVideoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Video = styled(ReactPlayer)`
  video {
    object-fit: cover;
  }
`;

const VideoDetails = styled.div<{ withHeader?: boolean }>`
  position: absolute;
  bottom: ${(props) => (props.withHeader ? '90px' : '50px')};
  right: 30px;
  color: white;
  display: flex;
  align-items: center;

  @media only screen and ${breakpoints.maxWidth.md} {
    bottom: ${(props) => (props.withHeader ? '120px' : '50px')};
    padding-left: 15px;
    padding-right: 15px;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    right: 0;
    bottom: ${(props) => (props.withHeader ? '120px' : '20px')};
  }
`;

const VideoControls = styled.div`
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and ${breakpoints.maxWidth.md} {
    width: 70px;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    width: 50px;
  }
`;

const FullscreenVideoControls = styled(VideoControls)`
  width: unset;
`;

const VideoTitle = styled.span`
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  font-size: 18px;
  line-height: ${(props) => props.theme.helpers.pxToRem(22)};
  margin-right: 27px;

  @media only screen and ${breakpoints.maxWidth.xs} {
    font-size: 16px;
    line-height: 1.22;
  }
`;

const VideoControlButton = styled.span`
  cursor: pointer;
  path {
    stroke: #ffffff;
  }
  &:hover {
    opacity: 0.7;
  }
`;

const VideoLogo = styled(Logo)`
  position: absolute;
  top: 34px;
  right: 36px;
  cursor: pointer;
  z-index: 1;
  @media only screen and ${breakpoints.maxWidth.sm} {
    width: 156px;
  }
  @media only screen and ${breakpoints.maxWidth.xs} {
    width: 78px;
    height: 50px;
    top: 20px;
    right: 20px;
  }
`;

const StyledPauseButton = styled(PauseButton)`
  height: 35px;

  @media only screen and ${breakpoints.maxWidth.md} {
    height: 25px;
  }
`;

const StyledPlayButton = styled(PlayButton)`
  height: 35px;

  @media only screen and ${breakpoints.maxWidth.md} {
    height: 25px;
  }
`;

const StyledSoundOffButton = styled(SoundOff)`
  height: 35px;

  @media only screen and ${breakpoints.maxWidth.md} {
    height: 25px;
  }
`;

const StyledSoundOnButton = styled(SoundOn)`
  g {
    stroke: #ffffff;
  }

  height: 35px;
  @media only screen and ${breakpoints.maxWidth.md} {
    height: 25px;
  }
`;

const StyledFullScreenButton = styled(FullScreenButton)`
  width: 38px;
  height: 38px;
  @media only screen and ${breakpoints.maxWidth.md} {
    height: 25px;
  }
`;

// const FullScreenPlayPauseButton = styled(VideoControlButton)`
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translateX(-50%) translateY(-50%);
//   z-index: 9999;
// `;

// const StyledVideoProgress = styled.div<{ played?: number }>`
//   width: 100%;
//   height: 5px;
//   background-color: #000000;
//   &:before {
//     content: '';
//     width: ${(props) => `${props.played}%` ?? 0};
//     height: 5px;
//     background-color: ${(props) => props.theme.colors.red};
//     position: absolute;
//     display: inline-block;
//   }
// `;
// function VideoProgress(props: any) {
//   return <StyledVideoProgress played={props.played} />;
// }

interface Module02Props {
  withHeader?: boolean;
  className?: string;
  data?: {
    gray_backgrodun?: boolean;
    full_screen?: boolean;
    bottom_right_text?: string;
    top_right_logo?: boolean;
    video_id?: string;
    video_cover?: string;
  };
}

const Module02 = (props: Module02Props) => {
  const { data } = props;

  const history = useHistory();

  const ref = useRef<any>(null);
  // const position = useHeaderPosition(ref);

  const [playing, setPlaying] = useState(true);
  const [muted, setMuted] = useState(true);
  const [fullScreen, setFullScreen] = useState(false);

  // const [duration, setDuration] = useState<any>(null);
  // const [played, setPlayed] = useState(0);

  const toggleFullScreen = () => {
    setFullScreen(!fullScreen);
  };

  const togglePlaying = () => {
    setPlaying(!playing);
  };

  const toggleMuted = () => {
    setMuted(!muted);
  };

  // const withHeader = data?.full_screen;

  // const playedValue = duration ? (played / duration) * 100 : 0;

  return (
    <>
      <Wrapper showGrayBackground={data?.gray_backgrodun}>
        <Container>
          <Module02Wrapper
            // withHeader={withHeader}
            data-module={ModuleType.MODULE_02}
            ref={ref}
            // className={cs(props.className, {
            //   'Module02--fullscreen': withHeader,
            // })}
          >
            <VideoContainer>
              {!data?.top_right_logo ? null : <VideoLogo onClick={() => history.push('/')} />}
              <Video loop muted playing={playing && !fullScreen} url={data?.video_id} width="100%" height="100%" />
              <VideoDetails data-element="m02-video-details">
                <VideoTitle data-element="m02-video-title">{data?.bottom_right_text}</VideoTitle>
                <VideoControls data-element="m02-video-controls">
                  <VideoControlButton onClick={togglePlaying}> {playing ? <StyledPauseButton /> : <StyledPlayButton />} </VideoControlButton>
                  <VideoControlButton
                    onClick={() => {
                      setPlaying(true);
                      setMuted(false);
                      toggleFullScreen();
                    }}
                  >
                    <StyledFullScreenButton />
                  </VideoControlButton>
                </VideoControls>
              </VideoDetails>
            </VideoContainer>

            {/*{withHeader && <Header position={position} />}*/}
          </Module02Wrapper>
        </Container>
      </Wrapper>

      <FullScreenModal open={fullScreen} onClose={toggleFullScreen}>
        <FullScreenVideoWrapper>
          <VideoContainer className={cs(props.className)}>
            {/*<FullScreenPlayPauseButton onClick={togglePlaying}>{playing ? <StyledPauseButton /> : <StyledPlayButton />}</FullScreenPlayPauseButton>*/}
            <Video
              controls={true}
              // onProgress={(state: any) => setPlayed(state.playedSeconds)}
              // onDuration={(data: any) => setDuration(data)}
              volume={muted ? 0 : 1}
              playing={playing}
              url={data?.video_id}
              width="100%"
              height="100%"
            />
            {/*<VideoProgress played={playedValue} />*/}
            <VideoDetails>
              <VideoTitle>{data?.bottom_right_text}</VideoTitle>
              <FullscreenVideoControls>
                <VideoControlButton onClick={toggleMuted}>{muted ? <StyledSoundOffButton /> : <StyledSoundOnButton />}</VideoControlButton>
              </FullscreenVideoControls>
            </VideoDetails>
          </VideoContainer>
        </FullScreenVideoWrapper>
      </FullScreenModal>
    </>
  );
};

export default Module02;
