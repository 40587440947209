import { useRouter } from '@lib/hooks/useRouter';
import React, { useEffect, useState } from 'react';
import { UIContext, UIContextType } from './UIContext';

interface UIContextProviderProps {
  children: React.ReactNode | null;
}

interface UIContextProviderState extends UIContextType {}

export const UIContextProvider = (props: UIContextProviderProps) => {
  const router = useRouter();
  const [state, setState] = useState<UIContextProviderState>({
    isMobileMenuOpened: false,
    isSearchOpened: false,
    removeNavigation: false,
    languageOpened: false,
    userDropdownOpened: false,
    kontaktDropdownOpened: false,
  });

  useEffect(() => {
    handleSetState({ isMobileMenuOpened: false, removeNavigation: false });
  }, [router.location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSetState = (newState: UIContextProviderState) => {
    setState({
      ...state,
      ...newState,
    });
  };

  const context: UIContextProviderState = {
    state,
    setState: handleSetState,
  };

  return <UIContext.Provider value={context}>{props.children}</UIContext.Provider>;
};
