import { File } from '@api/press';
import ContentSlider from '@components/shared/ContentSlider';
import breakpoints from '@lib/common/breakpoints';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.yellow};
`;

const Photos = styled.div``;

const Videos = styled.div`
  padding-bottom: 100px;

  @media only screen and ${breakpoints.maxWidth.sm} {
    padding-bottom: 50px;
  }
`;

interface ILocationsGalleryProps {
  images?: {
    image: File;
    image_title: string;
    image_copyright: string;
  }[];
  videos?: {
    video_link: string;
    video_title: string;
    video_copyright: string;
    video_thumbnail: string;
  }[];
}

const LocationsGallery: FC<ILocationsGalleryProps> = (props) => {
  const { formatMessage: t } = useIntl();
  const images = props.images?.map((image, index) => {
    return {
      ID: index,
      post_title: image.image_title,
      post_type: 'locations',
      post_content: image.image_title,
      description: image.image_copyright,
      thumbnail: image.image.url,
    };
  });
  const videos = props.videos?.map((video, index) => {
    return {
      ID: index,
      post_title: video.video_title,
      post_type: 'locations',
      post_content: video.video_title,
      description: video.video_copyright,
      thumbnail: video.video_thumbnail,
      link: video.video_link,
    };
  });
  return (
    <Wrapper>
      {images && images.length > 0 && (
        <Photos>
          <ContentSlider title={t({ id: 'photo_gallery' })} hideAllButton fullscreen posts={images} />
        </Photos>
      )}
      {videos && videos.length > 0 && (
        <Videos>
          <ContentSlider title={t({ id: 'videos' })} hideAllButton posts={videos} />
        </Videos>
      )}
    </Wrapper>
  );
};

export default LocationsGallery;
