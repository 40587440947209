import { ReactComponent as SmallArrow } from '@assets/icons/icons_arrow-down_small.svg';
import { ReactComponent as ExternalArrow } from '@assets/icons/icons_arrow-external_small.svg';
import { Container } from '@components/shared/Container';
import breakpoints from '@lib/common/breakpoints';
import ModuleType from '@lib/interfaces/Modules';
import { useState } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  background-color: black;
  padding: 18px 15px;
  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 14px 15px 8px 15px;
  }
`;

const Text = styled.p`
  color: #ffffff;
  font-size: 48px;
  font-weight: normal;
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  line-height: 1.25;
  text-align: center;
  margin-left: 8px;
  text-transform: uppercase;

  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 25px;
    line-height: 1.25;
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 22px;
    line-height: 1.36;
  }
`;

const StyledArrow = styled(SmallArrow)`
  transition: 0.2s;
  width: 28px;
  height: 28px;
  margin-top: 1px;
  path {
    stroke: white;
  }

  @media only screen and ${breakpoints.maxWidth.md} {
    width: 19px;
    height: 19px;
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    display: none;
  }
`;

const Toggle = styled.span`
  display: inline-flex;
  align-items: center;
  justify-items: center;
  cursor: pointer;
`;

const Content = styled.div`
  background-color: ${(props) => props.theme.colors.violet};
  padding-top: 80px;
  @media only screen and ${breakpoints.maxWidth.md} {
    padding-top: 40px;
  }
`;

const ContentText = styled.p`
  /* max-width: 1050px; */
  margin: 0;
  font-size: 58px;
  font-weight: normal;
  font-family: 'GTFlexa-ExtendedMedium', sans-serif;
  line-height: 1.03;
  color: #ffffff;

  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 25px;
    line-height: 1.25;
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 22px;
    line-height: 1.36;
  }
`;

const Links = styled.ul`
  padding: 0;
  margin: 80px 0 0 0;
  list-style: none;
`;

const LinkItem = styled.li`
  padding: 32px 0;
  border-bottom: 2px solid white;

  &:last-of-type {
    border-bottom: none;
  }

  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 10px 20px;
  }
`;

const linkStyles = css`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  svg {
    margin-right: 35px;
  }

  span {
    font-size: 36px;
    font-weight: 300;
    letter-spacing: 0.4px;
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    line-height: 1.22;
    color: #ffffff;

    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 25px;
      line-height: 1.25;
    }

    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 22px;
      line-height: 1.36;
      width: calc(100% - 63px);
    }
  }

  &:hover {
    span {
      color: #ff3d3d;
    }
    svg path {
      stroke: #ff3d3d;
    }
  }
`;

const Link = styled(ReactLink)`
  ${linkStyles}
`;

const AnchorLink = styled.a`
  ${linkStyles}
`;

const StyledExternalArrow = styled(ExternalArrow)`
  width: 35px;
  height: 35px;
  path {
    stroke: #ffffff;
  }

  @media only screen and ${breakpoints.maxWidth.md} {
    width: 28px;
    height: 28px;
  }
`;

interface Module04Props {
  data?: {
    always_open?: boolean;
    title?: string;
    description?: string;
    links?: Array<{
      link?: {
        title: string;
        url: string;
        target: string;
      };
    }>;
  };
}

const getUrl = (url?: string) => {
  if (!url) {
    return {
      other: false,
      url: '',
    };
  }

  if (url.includes('http')) {
    return {
      other: true,
      url: url,
    };
  }

  return {
    other: false,
    url: url,
  };
};

export default function Module04(props: Module04Props) {
  const { data } = props;
  const [opened, setOpened] = useState(data?.always_open ?? false);

  const handleClick = () => {
    if (data?.always_open) {
      return;
    }

    setOpened(!opened);
  };

  const links = data?.links ?? [];
  const isAlwaysOpen = data?.always_open;

  return (
    <div data-module={ModuleType.MODULE_04}>
      {!isAlwaysOpen && (
        <Wrapper role="button" onClick={handleClick}>
          <Toggle>
            {!isAlwaysOpen && <StyledArrow style={{ transform: opened ? 'rotate(180deg)' : 'inherit' }} />} <Text>{data?.title}</Text>
          </Toggle>
        </Wrapper>
      )}

      {opened && (
        <Content>
          <Container>
            <ContentText>{data?.description}</ContentText>

            <Links>
              {links.map(({ link }, index) => {
                const urlData = getUrl(link?.url);
                return urlData.other ? (
                  <LinkItem key={`${link?.url}-${index}`}>
                    <AnchorLink href={urlData.url} target={link?.target}>
                      <StyledExternalArrow /> <span>{link?.title}</span>
                    </AnchorLink>
                  </LinkItem>
                ) : (
                  <LinkItem key={`${link?.url}-${index}`}>
                    <Link to={`${link?.url}`} target={link?.target}>
                      <StyledExternalArrow /> <span>{link?.title}</span>
                    </Link>
                  </LinkItem>
                );
              })}
            </Links>
          </Container>
        </Content>
      )}
    </div>
  );
}
