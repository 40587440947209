import Module05, { Module05Data } from '@components/modules/Module05';
import ModuleType from '@lib/interfaces/Modules';
import styled from 'styled-components';
const Module12Wrapper = styled.div``;

interface Props {
  autoPlay?: boolean;
  infinite?: boolean;
  hideAllButton?: boolean;
  data?: Module05Data;
}

export default function Module12(props: Props) {
  const { data } = props;

  return (
    <Module12Wrapper data-module={ModuleType.MODULE_12}>
      <Module05
        infinite={false}
        autoPlay={false}
        hideAllButton={props.hideAllButton}
        data={{
          left_side: {
            ...data,
            full_width: true,
          },
        }}
      />
    </Module12Wrapper>
  );
}
