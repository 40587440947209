import { ReactComponent as IconClose } from '@assets/icons/icons_close_small.svg';
import { ReactComponent as IconEdit } from '@assets/icons/icons_edit.svg';
import { VideoUpload } from '@components/shared/VideoUpload';
import breakpoints from '@lib/common/breakpoints';
import { LocationInfoFormik, LocationUploadVideo as ILocationUploadVideo } from '@lib/hooks/formik/useLocationInfoFormik';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { FormFeedback } from 'reactstrap';
import styled, { css } from 'styled-components';

const StyledLocationUploadVideo = styled.div`
  width: 100%;
  margin-top: 50px;
  @media only screen and ${breakpoints.maxWidth.md} {
    margin-top: 20px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    margin-top: 20px;
  }
`;
interface IStyledTitle {
  noSpace?: boolean;
}
const StyledTitle = styled.h5<IStyledTitle>`
  font-family: 'GTFlexa-ExtendedMedium', sans-serif;
  font-size: 24px;
  line-height: 1.3em;
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 40px;
  ${({ noSpace }) =>
    noSpace &&
    css`
      margin-bottom: 0;
    `}
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 20px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 20px;
  }
`;

const StyledFileItem = styled.div`
  width: 100%;
  padding: 20px 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #979797;
`;
const StyledFileItemThumb = styled.div`
  width: 104px;
  height: 72px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const StyledFileItemActions = styled.div`
  width: 104px;
  height: 72px;
  display: flex;
  align-items: center;
  svg {
    width: 32px;
    height: 32px;
    margin-left: 40px;
    cursor: pointer;
    &:hover {
      g,
      path {
        stroke: ${(props) => props.theme.colors.red};
      }
    }
    &:first-child {
      margin-left: 0;
    }
  }
`;
const StyledFileItemContent = styled.div`
  width: calc(100% - 208px);
  padding-left: 40px;
  h3 {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 24px;
    line-height: 1.3em;
    color: ${(props) => props.theme.colors.black};
  }
  span {
    display: block;
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 1.2em;
    color: ${(props) => props.theme.colors.black};
    margin-bottom: 10px;
  }
`;

const StyledFormFeedback = styled(FormFeedback)`
  font-family: 'GTFlexa-StandardMedium';
  font-size: 16px;
  letter-spacing: 0.2px;
  color: ${(props) => props.theme.colors.red};
  padding: 10px 24px 0 0;

  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 14px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 14px;
  }
`;

interface Props {
  formik: LocationInfoFormik;
}

export const LocationUploadVideo = ({ formik }: Props) => {
  const { formatMessage: t } = useIntl();
  const [editableVideo, setEditableVideo] = useState<ILocationUploadVideo | undefined>(undefined);

  const handleSubmitFile = (value: ILocationUploadVideo) => {
    const videos = formik.values.videos && formik.values.videos.length > 0 ? [...formik.values?.videos, value] : [value];
    formik.setFieldValue('videos', videos);
    formik.setFieldTouched('videos');
    setEditableVideo(undefined);
  };

  const handleVideoEdit = (index: number) => {
    const selectedVideo = formik.values.videos && formik.values.videos[index];
    setEditableVideo(selectedVideo);
  };

  const handleVideoRemove = (index: number) => {
    const videos = formik.values.videos?.filter((el, i) => i !== index);
    formik.setFieldValue('videos', videos);
    formik.setFieldTouched('videos');
  };
  return (
    <StyledLocationUploadVideo className="LocationUploadVideo">
      <StyledTitle noSpace={!formik.values.videos || formik.values.videos?.length === 0}>{t({ id: 'location_video_upload_text' })}</StyledTitle>
      {/* List of videos up to 20 */}
      {formik.values.videos && (
        <>
          {formik.values.videos.map((video, index) => {
            return (
              <StyledFileItem key={`uploaded-video-${index}`}>
                <StyledFileItemThumb>
                  <img src={video.thumbnail} alt={video.title} />
                </StyledFileItemThumb>
                <StyledFileItemContent>
                  <span>{video.copyright}</span>
                  <h3>{video.title}</h3>
                </StyledFileItemContent>
                <StyledFileItemActions>
                  <IconEdit onClick={() => handleVideoEdit(index)} />
                  <IconClose onClick={() => handleVideoRemove(index)} />
                </StyledFileItemActions>
              </StyledFileItem>
            );
          })}
        </>
      )}

      <VideoUpload onSubmit={handleSubmitFile} value={editableVideo} setValue={setEditableVideo} />
      {formik.errors.videos && <StyledFormFeedback>{formik.errors.videos}</StyledFormFeedback>}
    </StyledLocationUploadVideo>
  );
};
