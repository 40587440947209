import { ReactComponent as Close } from '@assets/icons/icons_close.svg';
import { ReactNode } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const StyledModal = styled(Modal)``;

export const CloseButton = styled(Close)`
  cursor: pointer;
  position: absolute;
  top: 40px;
  right: 40px;
  width: 33px;
  height: 33px;
  z-index: 999;
  path {
    stroke: #ffffff;
  }
`;

interface FullScreenModalProps {
  open?: boolean;
  children: ReactNode;
  onClose?: () => void;
}

export default function FullScreenModal(props: FullScreenModalProps) {
  const { children, open, onClose } = props;

  if (!open) {
    return null;
  }

  return (
    <StyledModal
      isOpen={open}
      onRequestClose={onClose}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      ariaHideApp={false}
      bodyOpenClassName="overflow-hidden"
      overlayClassName="FullScreenModal__overlay"
    >
      <CloseButton onClick={onClose} />
      <ContentWrapper>{children}</ContentWrapper>
    </StyledModal>
  );
}
