import { getBranches, GetBranchesInput, GetBranchesResponse } from '@api/branch';
import QueryKeys from '@lib/constants/QueryKeys';
import { AxiosError } from 'axios';
import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query';

export default function useGetBranches(params: GetBranchesInput, options?: UseInfiniteQueryOptions<GetBranchesResponse, AxiosError, any>) {
  return useInfiniteQuery<GetBranchesResponse, AxiosError>(
    [QueryKeys.branches(JSON.stringify(params))],
    ({ pageParam = 1 }) => getBranches({ ...params, page: pageParam }),
    {
      getNextPageParam: ({ current_page, total_pages }) => {
        if (total_pages === 0) {
          return;
        }

        if (current_page === total_pages) {
          return;
        }

        return current_page + 1;
      },
      cacheTime: 0,
      ...options,
    },
  );
}
