import { VFC_LOCATION_BOOKMARKS_KEY } from '@lib/constants/LocalStorageKeys';
import { createContext, ReactNode, useContext, useState } from 'react';

interface LocationBookmarksType {
  bookmarks: string[];
  addBookmark: (slug: string) => void;
  removeBookmark: (slug: string) => void;
}

export const LocationBookmarksContext = createContext<LocationBookmarksType>({
  bookmarks: [],
  addBookmark: (slug: string) => {},
  removeBookmark: (slug: string) => {},
});

interface Props {
  children: ReactNode;
}

export const LocationBookmarksContextProvider = (props: Props) => {
  const [bookmarks, setBookmarks] = useState<string[]>(() => {
    const storedBookmarks = localStorage.getItem(VFC_LOCATION_BOOKMARKS_KEY);
    return storedBookmarks ? JSON.parse(storedBookmarks) : [];
  });

  const addBookmark = (slug: string) => {
    const newData = [...bookmarks, slug];
    setBookmarks(newData);
    localStorage.setItem(VFC_LOCATION_BOOKMARKS_KEY, JSON.stringify(newData));
  };

  const removeBookmark = (slug: string) => {
    const newData = bookmarks.filter((x) => !x.includes(slug));
    setBookmarks(newData);
    localStorage.setItem(VFC_LOCATION_BOOKMARKS_KEY, JSON.stringify(newData));
  };

  return <LocationBookmarksContext.Provider value={{ bookmarks, addBookmark, removeBookmark }}>{props.children}</LocationBookmarksContext.Provider>;
};

export const useLocationBookmarksContext = () => useContext(LocationBookmarksContext);
