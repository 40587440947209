import { LocationsFormDataResponse } from '@api/locations';
import Button from '@components/shared/Button';
import breakpoints from '@lib/common/breakpoints';
import { LocationInfoFormik } from '@lib/hooks/formik/useLocationInfoFormik';
import React from 'react';
import { useIntl } from 'react-intl';
//Components
import { Form } from 'reactstrap';
import styled, { css } from 'styled-components';
import { Input } from '../Input';
import { MultiSelect } from '../MultiSelect';
import { Select } from '../Select';
import { LOCATION_STEP } from './LocationForm';
import { LocationUploadFiles } from './LocationUploadFiles';
import { LocationUploadVideo } from './LocationUploadVideo';

const StyledForm = styled.div`
  width: 100%;
`;
const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  @media only screen and ${breakpoints.maxWidth.md} {
    margin-top: 20px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    margin-top: 20px;
  }
`;
const StyledRow = styled.div`
  margin-left: -16px;
  margin-right: -16px;
`;
const StyledCol = styled.div<IColType>`
  display: inline-block;
  width: 100%;
  padding: 0 16px;
  ${({ size }) =>
    size === 2 &&
    css`
      width: 16.6667%;
      @media only screen and ${breakpoints.maxWidth.md} {
        width: 33.333%;
      }
      @media only screen and ${breakpoints.maxWidth.sm} {
        width: 33.333%;
      }
    `}
  ${({ size }) =>
    size === 6 &&
    css`
      float: left;
      width: 50%;
    `}
`;

const StyledButton = styled(Button)`
  font-size: 24px;
  padding: 24px 36px;
  @media only screen and ${breakpoints.maxWidth.md} {
    width: 100%;
    font-size: 20px;
    padding: 10px 18px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    width: 100%;
    font-size: 20px;
    padding: 10px 18px;
  }
`;

const StyledFormInputTitle = styled.h4`
  font-family: 'GTFlexa-ExtendedMedium', sans-serif;
  font-size: 24px;
  line-height: 1.2em;
  color: ${(props) => props.theme.colors.black};
  margin-top: 80px;
  margin-bottom: 10px;
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 16px;
    margin-top: 50px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 16px;
    margin-top: 50px;
  }
`;
const StyledFormInputDescription = styled.h4`
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  font-size: 18px;
  letter-spacing: 0.2px;
  line-height: 1.15em;
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 2px;
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 14px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 14px;
  }
`;

interface IColType {
  size?: number;
}

interface Props {
  formik: LocationInfoFormik;
  setStep: React.Dispatch<React.SetStateAction<LOCATION_STEP>>;
  data?: LocationsFormDataResponse;
}

export const LocationInfo = ({ formik, data }: Props) => {
  const { formatMessage: t } = useIntl();
  const handleSelectChange = (key: string, value: number[]) => {
    formik.setFieldValue(key, value);
    formik.setFieldTouched(key);
  };

  return (
    <StyledForm className="LocationInfo">
      <Form onSubmit={(e)=>{e.preventDefault()}}>
        <StyledRow>
          <StyledCol>
            <Input
              type="text"
              name="location_name"
              id="location_name"
              label={t({ id: 'location_name' })}
              className="form-control-custom"
              value={formik.values.location_name}
              invalid={!!formik.errors.location_name && formik.touched.location_name}
              onChange={formik.handleChange}
              error={formik.errors.location_name}
            />
          </StyledCol>
          <StyledCol>
            <MultiSelect
              id="location_categories"
              name="location_categories"
              label={t({ id: 'location_categories' })}
              className="form-control-custom"
              options={
                data?.location_categories?.map((el) => {
                  return { label: el.name, value: el.term_id };
                }) || []
              }
              selectedOptions={formik.values.location_categories}
              onChange={handleSelectChange}
              invalid={
                typeof formik.errors.location_categories === 'string'
                  ? !!formik.errors.location_categories && !!formik.touched.location_categories
                  : undefined
              }
              error={typeof formik.errors.location_categories === 'string' ? formik.errors.location_categories : undefined}
            />
          </StyledCol>
          <StyledCol>
            <Input
              optional
              type="text"
              name="new_location_category"
              id="new_location_category"
              label={t({ id: 'new_location_category' })}
              className="form-control-custom"
              value={formik.values.new_location_category}
              invalid={!!formik.errors.new_location_category && formik.touched.new_location_category}
              onChange={formik.handleChange}
              error={formik.errors.new_location_category}
            />
          </StyledCol>
          <StyledCol>
            <Select
              id="epochs"
              name="epochs"
              label={t({ id: 'epochs' })}
              className="form-control-custom"
              options={
                data?.epochs?.map((el) => {
                  return { label: el.name, value: el.term_id };
                }) || []
              }
              selectedOption={formik.values.epochs}
              onChange={formik.handleChange}
              error={typeof formik.errors.epochs === 'string' ? formik.errors.epochs : undefined}
            />
          </StyledCol>
          <StyledCol>
            <Select
              id="location_art"
              name="location_art"
              label={t({ id: 'location_art' })}
              labelInfo={t({ id: 'location_art' })}
              className="form-control-custom"
              options={
                data?.art?.map((el) => {
                  return { label: el.name, value: el.term_id };
                }) || []
              }
              selectedOption={formik.values.location_art}
              onChange={formik.handleChange}
              error={typeof formik.errors.location_art === 'string' ? formik.errors.location_art : undefined}
            />
          </StyledCol>
          <StyledCol>
            <MultiSelect
              optional
              id="location_ausstattung"
              name="location_ausstattung"
              label={t({ id: 'location_ausstattung' })}
              labelInfo={t({ id: 'location_ausstattung' })}
              className="form-control-custom"
              options={
                data?.ausstattung?.map((el) => {
                  return { label: el.name, value: el.term_id };
                }) || []
              }
              selectedOptions={formik.values.location_ausstattung}
              onChange={handleSelectChange}
              invalid={
                typeof formik.errors.location_ausstattung === 'string'
                  ? !!formik.errors.location_ausstattung && !!formik.touched.location_ausstattung
                  : undefined
              }
              error={typeof formik.errors.location_ausstattung === 'string' ? formik.errors.location_ausstattung : undefined}
            />
          </StyledCol>
          <StyledCol>
            <Input
              type="textarea"
              name="motive_description"
              id="motive_description"
              label={t({ id: 'motive_description' })}
              labelInfo={t({ id: 'motive_description' })}
              placeholder="Hier kann eine Beschreibung von video schreiben werden."
              className="form-control-custom"
              value={formik.values.motive_description}
              invalid={!!formik.errors.motive_description && formik.touched.motive_description}
              onChange={formik.handleChange}
              error={formik.errors.motive_description}
            />
          </StyledCol>
          <StyledCol>
            <LocationUploadFiles formik={formik} />
          </StyledCol>
          <StyledCol>
            <LocationUploadVideo formik={formik} />
          </StyledCol>
          <StyledCol>
            <StyledFormInputTitle>{t({ id: 'company_law_registration_title' })}</StyledFormInputTitle>
            <StyledFormInputDescription>{t({ id: 'company_law_registration_text' })}</StyledFormInputDescription>
          </StyledCol>
          <StyledCol size={2}>
            <Input
              type="radio"
              name="company_law_registration"
              id="company_law_registration-1"
              label={t({ id: 'ja' })}
              className="form-control-custom"
              value="JA"
              checked={formik.values.company_law_registration === 'JA'}
              invalid={!!formik.errors.company_law_registration && formik.touched.company_law_registration}
              onChange={formik.handleChange}
              error={formik.errors.company_law_registration}
            />
          </StyledCol>
          <StyledCol size={2}>
            <Input
              type="radio"
              name="company_law_registration"
              id="company_law_registration-2"
              label={t({ id: 'nein' })}
              className="form-control-custom"
              value="Nein"
              checked={formik.values.company_law_registration === 'Nein'}
              invalid={!!formik.errors.company_law_registration && formik.touched.company_law_registration}
              onChange={formik.handleChange}
              error={formik.errors.company_law_registration}
            />
          </StyledCol>
          <StyledCol>
            <Input
              type="textarea"
              name="company_name"
              id="company_name"
              label={t({ id: 'location_company_name' })}
              labelInfo={t({ id: 'location_company_name' })}
              placeholder={t({ id: 'location_company_name_description' })}
              className="form-control-custom"
              value={formik.values.company_name}
              invalid={!!formik.errors.company_name && formik.touched.company_name}
              onChange={formik.handleChange}
              error={formik.errors.company_name}
            />
          </StyledCol>
          <StyledCol>
            <StyledButtonWrapper>
              <StyledButton color="black" onClick={(e)=>{
                e?.preventDefault();
                formik.handleSubmit();
              }}>{t({ id: 'button_next_step' })}</StyledButton>
            </StyledButtonWrapper>
          </StyledCol>
        </StyledRow>
      </Form>
    </StyledForm>
  );
};
