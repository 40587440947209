import Module00 from '@components/modules/Module00';
import Module01 from '@components/modules/Module01';
import Module02 from '@components/modules/Module02';
import Module03 from '@components/modules/Module03';
import Module04 from '@components/modules/Module04';
import Module05 from '@components/modules/Module05';
import Module06 from '@components/modules/Module06';
import Module07 from '@components/modules/Module07';
import Module08 from '@components/modules/Module08';
import Module09 from '@components/modules/Module09';
import Module10 from '@components/modules/Module10';
import Module11 from '@components/modules/Module11';
import Module12 from '@components/modules/Module12';
import Module13 from '@components/modules/Module13';
import Module14 from '@components/modules/Module14';
import Module16 from '@components/modules/Module16';
import Module17 from '@components/modules/Module17';
import Module18 from '@components/modules/Module18';
import Module19 from '@components/modules/Module19';
import Module20 from '@components/modules/Module20';
import Module21 from '@components/modules/Module21';
import Module22 from '@components/modules/Module22';
import Module23 from '@components/modules/Module23';
import Module24 from '@components/modules/Module24';
import Module25 from '@components/modules/Module25';
import Module26 from '@components/modules/Module26';
import Module27 from '@components/modules/Module27';
import Module28 from '@components/modules/Module28';
import Module29 from '@components/modules/Module29';
import Module31 from '@components/modules/Module31';
import Module32 from '@components/modules/Module32';
import Module34 from '@components/modules/Module34';
import ModuleType from '@lib/interfaces/Modules';

const Modules = {
  [ModuleType.MODULE_00]: Module00,
  [ModuleType.MODULE_01]: Module01,
  [ModuleType.MODULE_02]: Module02,
  [ModuleType.MODULE_03]: Module03,
  [ModuleType.MODULE_04]: Module04,
  [ModuleType.MODULE_05]: Module05,
  [ModuleType.MODULE_06]: Module06,
  [ModuleType.MODULE_07]: Module07,
  [ModuleType.MODULE_08]: Module08,
  [ModuleType.MODULE_09]: Module09,
  [ModuleType.MODULE_10]: Module10,
  [ModuleType.MODULE_11]: Module11,
  [ModuleType.MODULE_12]: Module12,
  [ModuleType.MODULE_13]: Module13,
  [ModuleType.MODULE_14]: Module14,
  [ModuleType.MODULE_16]: Module16,
  [ModuleType.MODULE_17]: Module17,
  [ModuleType.MODULE_18]: Module18,
  [ModuleType.MODULE_19]: Module19,
  [ModuleType.MODULE_20]: Module20,
  [ModuleType.MODULE_21]: Module21,
  [ModuleType.MODULE_22]: Module22,
  [ModuleType.MODULE_23]: Module23,
  [ModuleType.MODULE_24]: Module24,
  [ModuleType.MODULE_25]: Module25,
  [ModuleType.MODULE_26]: Module26,
  [ModuleType.MODULE_27]: Module27,
  [ModuleType.MODULE_28]: Module28,
  [ModuleType.MODULE_29]: Module29,
  [ModuleType.MODULE_31]: Module31,
  [ModuleType.MODULE_32]: Module32,
  [ModuleType.MODULE_34]: Module34,
};

export default Modules;
