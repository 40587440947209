import Button from '@components/shared/Button';
import { LocationConfirmationFormik } from '@lib/hooks/formik/useLocationConfirmationFormik';
import React from 'react';
import { useIntl } from 'react-intl';
//Components
import { Form } from 'reactstrap';
import styled, { css } from 'styled-components';
import { Checkbox } from '../Checkbox';
import { Input } from '../Input';
import { LOCATION_STEP } from './LocationForm';

const StyledForm = styled.div`
  width: 100%;
`;
const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
`;
const StyledRow = styled.div`
  margin-left: -15px;
  margin-right: -15px;
`;
const StyledCol = styled.div<IColType>`
  display: inline-block;
  width: 100%;
  padding: 0 15px;

  ${({ size }) =>
    size === 6 &&
    css`
      float: left;
      width: 50%;
    `}
`;

const StyledButton = styled(Button)`
  font-size: 24px;
  padding: 24px 36px;
`;
const StyledTermsAndConditions = styled.div`
  width: 100%;
  p {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 1.25em;
    color: ${(props) => props.theme.colors.black};
    margin-bottom: 24px;
    a {
      font-family: inherit;
      font-size: inherit;
      color: inherit;
      text-decoration: underline;
    }
  }
`;

interface IColType {
  size?: number;
}

interface Props {
  formik: LocationConfirmationFormik;
  setStep: React.Dispatch<React.SetStateAction<LOCATION_STEP>>;
}

export const LocationConfirmation = ({ formik, setStep }: Props) => {
  const { formatMessage: t } = useIntl();
  const handleBack = () => setStep(LOCATION_STEP.FURTHER_CONTACT);

  return (
    <StyledForm className="LocationConfirmation">
      <Form onSubmit={formik.handleSubmit}>
        <StyledRow>
          <StyledCol>
            <Checkbox
              name="accept_terms_conditions"
              id="accept_terms_conditions"
              label={t({ id: 'accept_terms_conditions' })}
              checked={formik.values.accept_terms_conditions}
              invalid={!!formik.errors.accept_terms_conditions && formik.touched.accept_terms_conditions}
              onChange={formik.handleChange}
              error={formik.errors.accept_terms_conditions}
            />
          </StyledCol>
          <StyledCol>
            <Input
              type="text"
              name="i_wee"
              id="i_wee"
              label={t({ id: 'i_we' })}
              className="form-control-custom"
              value={formik.values.i_wee}
              invalid={!!formik.errors.i_wee && formik.touched.i_wee}
              onChange={formik.handleChange}
              error={formik.errors.i_wee}
            />
          </StyledCol>
          <StyledCol>
            <StyledTermsAndConditions>
              <p>
                habe/n der Vienna Film Commission GmbH ("VFC") Fotos zur Verwendung in der Motivdatenbank der VFC zur Verfügung gestellt. Es wird
                ausdrücklich gegenüber der VFC erklärt, dass die Zustimmung des Fotografen/der Fotografin und/oder des/der Verwertungsberechtigten
                sowie etwaiger abgebildeter Personen zu dieser beabsichtigten Fotoverwendung, insbesondere auch zum Bereithalten der Fotos auf der
                Website der VFC zum Abruf, eingeholt wurde und diese Zustimmung vorliegt.
              </p>
              <p>
                Ich/Wir werde/n die VFC daher von sämtlichen Ansprüchen Dritter wegen der Verwendung dieser Fotos auf der Homepage der VFC freihalten
                und schad- und klaglos halten, wobei dies sowohl die außergerichtliche als auch die gerichtliche Geltendmachung etwaiger Ansprüche auf
                Unterlassung und/oder Schadenersatz betrifft.
              </p>
              <p>
                Hinweis: Die VFC verarbeitet personenbezogene Daten gemäß den Bestimmungen des Datenschutzgesetzes. Weitere Informationen zur
                Verarbeitung der personenbezogenen Daten sind unter <a href="https://google.com">Datenschutz</a> zu finden.{' '}
              </p>
            </StyledTermsAndConditions>
          </StyledCol>
          <StyledCol>
            <Checkbox
              name="data_protection"
              id="data_protection"
              label={t({ id: 'data_protection' })}
              checked={formik.values.data_protection}
              invalid={!!formik.errors.data_protection && formik.touched.data_protection}
              onChange={formik.handleChange}
              error={formik.errors.data_protection}
            />
          </StyledCol>
          <StyledCol>
            <StyledButtonWrapper>
              <StyledButton color="black" onClick={handleBack}>
                {t({ id: 'button_back' })}
              </StyledButton>
              <StyledButton color="black">{t({ id: 'send' })}</StyledButton>
            </StyledButtonWrapper>
          </StyledCol>
        </StyledRow>
      </Form>
    </StyledForm>
  );
};
