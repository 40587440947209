import { BranchItem } from '@api/branch';
import { ReactComponent as BranchePlaceholder } from '@assets/icons/branche_placeholder.svg';
import Image from '@components/shared/Image';
import breakpoints from '@lib/common/breakpoints';
import { PageType } from '@lib/interfaces/Pages';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);

  width: 196px;

  @media only screen and ${breakpoints.maxWidth.md} {
    width: 30%;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    width: 48%;
  }

  &:hover {
    cursor: pointer;
    filter: none;
    -webkit-filter: grayscale(0);
    svg {
      opacity: 1;
    }
  }

  h4 {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: ${(props) => props.theme.colors.mediumRed};
    margin: 24px 0 0 0;

    @media only screen and ${breakpoints.maxWidth.xs} {
      font-size: 16px;
      line-height: 1.25;

      margin: 18px 0 0 0;
    }
  }
`;

const Thumb = styled.div`
  width: 100%;
  height: 165px;

  border: 1px solid rgba(0, 0, 0, 0.25);

  @media only screen and ${breakpoints.maxWidth.sm} {
    height: 174px;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    height: 136px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const Placeholder = styled(Thumb)`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 84px;
    height: 84px;
    opacity: 0.5;
  }
`;

interface BranchesItemProps {
  branch: BranchItem;
}

const BranchesItem: FC<BranchesItemProps> = (props) => {
  return (
    <Wrapper>
      <Link to={`/${PageType.BRANCHES}/${props.branch.slug}`}>
        {props.branch.thumbnail ? (
          <Thumb>
            <Image src={props.branch.thumbnail} alt={props.branch.title} />
          </Thumb>
        ) : (
          <Placeholder>
            <BranchePlaceholder />
          </Placeholder>
        )}
        <h4 dangerouslySetInnerHTML={{ __html: props.branch.title }} />
      </Link>
    </Wrapper>
  );
};

export default BranchesItem;
