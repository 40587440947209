import { get, ISelect } from '@api/axios';
import objectToQueryString from '@lib/helpers/objectToQueryString';
import { PaginatedData, PaginationParams } from '@lib/interfaces';
import { File } from './press';
export interface News {
  description: string | null;
  post_content: string | null;
  post_date: string | null;
  post_name: string | null;
  post_title: string | null;
  title: string | null;
  post_type: string | null;
  thumbnail: string | null;
  video_id: string | null;
  link?: string | null;
}

export interface GetNewsDetailResponse {
  showreels: Array<News>;
}
interface GetPageInput {
  slug?: string;
}
export interface GetNewsResponse extends PaginatedData {
  news: Array<News>;
}

export interface GetNewsDetailsResponse {
  post_title: string | null;
  post_name: string | null;
  post_type: string | null;
  post_date: string | null;
  year: string | null;
  news_categories: ISelect[];
  thumbnail: string | null;
  images:
    | {
        image: string;
        text: string;
      }[]
    | null;
  post_content: string | null;
  page: {
    target: string;
    title: string;
    url: string;
  } | null;
  download_files: {
    file: File;
    file_description: string;
  }[];
  photo_gallery_title: string | null;
  photo_gallery: {
    image: File;
    image_title: string | null;
    image_copyright: string | null;
  }[];
}

export interface GetNewsInput extends PaginationParams {
  year: string;
  search?: string;
}

export const getNews = (params: GetNewsInput) => {
  return get<GetNewsInput, GetNewsResponse>({ url: `v1/news/archive?${objectToQueryString(params)}` });
};

export const getNewsDetails = (slug?: string) => get<GetPageInput, GetNewsDetailsResponse>({ url: `v1/news/${slug}` });
