import breakpoints from '@lib/common/breakpoints';
import { ObjectAny } from '@lib/interfaces';
import React, { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  @media only screen and ${breakpoints.maxWidth.md} {
    gap: 0;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    gap: 10px;
  }

  div {
    &:last-of-type {
      margin-right: 0;
    }
  }
`;

const TagWrapper = styled.div`
  background-color: #000000;
  padding: 6px 10px 4px 10px;
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.22;
  letter-spacing: 0.5px;
  color: #ffffff;
  text-transform: uppercase;

  @media only screen and ${breakpoints.maxWidth.md} {
    margin-right: 20px;
    margin-bottom: 20px;
  }
`;

interface ITagsProps {
  tags?: ObjectAny[];
}

const Tags: FC<ITagsProps> = (props) => {
  const tags = props.tags ?? [];

  if (tags.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      {tags?.map((tag, index) => (
        <Tag tag={tag} key={`${tag.name}-${index}`} />
      ))}
    </Wrapper>
  );
};

export const Tag = (props: ObjectAny) => {
  const { tag } = props;
  return <TagWrapper>{tag.name}</TagWrapper>;
};

export default Tags;
