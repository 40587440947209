import { getSearchFilters } from '@api/search';
import Module28 from '@components/modules/Module28';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

interface Props {
  searchValue?: string;
}

const NewsSearch = (props: Props) => {
  const { formatMessage } = useIntl();

  const [filters, setFilters] = useState<any>({});

  useEffect(() => {
    (async () => {
      try {
        const res = await getSearchFilters('news');
        setFilters(res);
      } catch (e) {}
    })();
  }, []);

  return (
    <Module28 data={{ title: formatMessage({ id: 'news' }), filters }} searchProps={{ searchValue: props.searchValue, showResultsHeader: true }} />
  );
};

export default NewsSearch;
