import { ReactComponent as BookmarkIcon } from '@assets/icons/icons_bookmark.svg';
import Tooltip from '@components/shared/Tooltip';
import React, { FC } from 'react';
import styled from 'styled-components';

const Button = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;

  &:hover {
    svg path {
      stroke: ${(props) => props.theme.colors.mediumRed};
    }
  }
`;

const StyledBookmarkIcon = styled(BookmarkIcon)<{ isfilled?: boolean }>`
  width: 31px;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  path {
    fill: ${(props) => (props.isfilled ? '#000000' : null)};
  }
`;

interface IBookmarkButtonProps {
  isLoading?: boolean;
  isfilled?: boolean;
  onClick: () => void;
}

const BookmarkButton: FC<IBookmarkButtonProps> = (props) => {
  return (
    <Tooltip text="Als Favorit speichern">
      <Button onClick={() => props.onClick()}>
        <StyledBookmarkIcon isfilled={props?.isfilled} />
      </Button>
    </Tooltip>
  );
};

export default BookmarkButton;
