import { ReactComponent as BookmarkIcon } from '@assets/icons/icons_bookmark_small.svg';
import breakpoints from '@lib/common/breakpoints';
import { HEADER_HEIGHT } from '@lib/constants';
import React, { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<{ sticky?: boolean }>`
  cursor: pointer;
  position: ${(props) => (props.sticky ? 'fixed' : 'absolute')};
  right: ${(props) => (props.sticky ? 0 : '26px')};
  top: ${HEADER_HEIGHT}px;
  display: inline-flex;
  align-items: center;
  padding: 17px 27px 17px 40px;
  background-color: ${(props) => props.theme.colors.red};
  z-index: 1;

  @media only screen and ${breakpoints.maxWidth.md} {
    top: ${(props) => (props.sticky ? `${HEADER_HEIGHT - 20}px` : '10px')};
    padding: 10px 15px 10px 15px;
  }

  p {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.2px;
    color: #000;
    margin-right: 20px;
  }

  svg path {
    fill: #000000;
  }
`;

interface IFloatingBookmarkItemsProps {
  text: string;
  sticky?: boolean;
}

const FloatingBookmarkItems: FC<IFloatingBookmarkItemsProps> = (props) => {
  const handleClick = () => {
    const myBookmarks = document.querySelector('#my-bookmarks');
    if (!myBookmarks) {
      return;
    }
    myBookmarks.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <Wrapper sticky={props.sticky} onClick={handleClick}>
      <p>{props.text}</p>
      <BookmarkIcon />
    </Wrapper>
  );
};

export default FloatingBookmarkItems;
