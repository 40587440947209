import { PageType } from '@lib/interfaces/Pages';
import Default from '@pages/wordpress-pages/Default';
import Showreels from '@pages/wordpress-pages/Showreels';

const Pages = {
  // [PageType.ABOUT_US]: About, // Uber ubs
  [PageType.SHOWREELS]: Showreels,
  // [PageType.FILMING_PERMITS]: FilmingPermits, // drehgenehmigungen
  // [PageType.SERVICES]: Services,
  // [PageType.LOCATIONS]: Locations,
  // [PageType.BRANCHES]: Branches,

  // Default page for dynamic WordPress pages.
  [PageType.DEFAULT]: Default,
};
export default Pages;
