import { BranchItem } from '@api/branch';
import BranchesItem from '@components/branches/BranchesItem';
import SearchResultsHeader from '@components/search/SearchResultsHeader';
import { Container } from '@components/shared/Container';
import NoResults from '@components/shared/NoResults';
import breakpoints from '@lib/common/breakpoints';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

const StyledContainer = styled(Container)<{ showResultsHeader?: boolean }>`
  padding-top: ${(props) => (props.showResultsHeader ? '120px' : null)};
`;

const StyledSearchResultsHeader = styled(SearchResultsHeader)`
  margin-left: -27px;
  margin-right: -27px;
`;

const Wrapper = styled.div``;

const ListWrapper = styled.div<{ showResultsHeader?: boolean }>`
  width: 100%;
  padding: 80px 0 60px 0;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 133px;

  padding-top: ${(props) => (props.showResultsHeader ? 0 : null)};

  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 80px 0;
    gap: 0;
    > div {
      width: 33.33333%;
      padding: 0 15px;
      margin-bottom: 30px;
    }
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    padding: 60px 0;
    gap: 60px 13px;
  }

  @media only screen and (max-width: 375px) {
    gap: 60px 10px;
  }
`;

interface BranchesListProps {
  className?: string;
  loading?: boolean;
  branches?: Array<BranchItem>;
  showResultsHeader?: boolean;
}

const BranchesList: FC<BranchesListProps> = (props) => {
  const { formatMessage } = useIntl();
  return (
    <StyledContainer showResultsHeader={props.showResultsHeader}>
      <Wrapper className={props.className}>
        {props.showResultsHeader && props.branches?.length ? (
          <StyledSearchResultsHeader link="branches" title={`${formatMessage({ id: 'branches' })} (${props.branches?.length}) `} />
        ) : null}

        {(!props.branches || props.branches?.length === 0) && !props.loading ? (
          <NoResults />
        ) : (
          <ListWrapper showResultsHeader={props.showResultsHeader}>
            {props?.branches?.map((branch) => {
              return <BranchesItem key={branch.id} branch={branch} />;
            })}
          </ListWrapper>
        )}
      </Wrapper>
    </StyledContainer>
  );
};

export default BranchesList;
