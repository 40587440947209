import { Press } from '@api/press';
import { ReactComponent as DownloadIcon } from '@assets/icons/icons_download.svg';
import Image from '@components/shared/Image';
import breakpoints from '@lib/common/breakpoints';
import { downloadFileFromUrl } from '@lib/helpers/downloadFileFromUrl';
import { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.a`
  cursor: pointer;

  > span {
    width: 100%;
    height: 100%;
    display: inline-block;
  }

  &:hover > span {
    opacity: 0.75;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 270px;
  margin-bottom: 20px;
  overflow: hidden;

  @media only screen and ${breakpoints.maxWidth.md} {
    height: 226px;
  }
  @media only screen and ${breakpoints.maxWidth.xs} {
    height: 116px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform ease-in-out 0.5s;
    transform-origin: center center;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;

  h3 {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    text-transform: inherit;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #000;
    margin-right: 24px;
    /* word-break: break-all; */
    flex: 1;

    @media only screen and ${breakpoints.maxWidth.xs} {
      font-size: 16px;
      line-height: 1.15;
    }
  }
`;

const DescriptionWrapper = styled.div`
  p {
    font-family: 'GTFlexa-StandardLight', sans-serif;
    font-size: 15px;
    line-height: 1.25em;
    letter-spacing: 0.2px;
    color: #000;
    margin-top: 10px;
  }
`;

interface IPressListItemProps {
  press: Press;
}

const PressListItem: FC<IPressListItemProps> = (props) => {
  const handleClick = () => downloadFileFromUrl(props.press.file.url, props.press.file.filename);

  return (
    <Wrapper onClick={handleClick}>
      <span>
        <ImageWrapper>{props.press.thumbnail && <Image src={props.press.thumbnail} />}</ImageWrapper>
        <TitleWrapper>
          <h3>{props.press.post_title}</h3>
          <DownloadIcon />
        </TitleWrapper>
        <DescriptionWrapper>
          <p>{props.press.description}</p>
        </DescriptionWrapper>
      </span>
    </Wrapper>
  );
};

export default PressListItem;
