import Button from '@components/shared/Button';
import { LocationDataFormik } from '@lib/hooks/formik/useLocationDataFormik';
import React from 'react';
import { useIntl } from 'react-intl';
//Components
import { Form } from 'reactstrap';
import styled, { css } from 'styled-components';
import { Input } from '../Input';
import { LOCATION_STEP } from './LocationForm';

const StyledForm = styled.div`
  width: 100%;
`;
const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
`;
const StyledRow = styled.div`
  margin-left: -10px;
  margin-right: -10px;
`;
const StyledCol = styled.div<IColType>`
  display: inline-block;
  width: 100%;
  padding: 0 10px;

  ${({ size }) =>
    size === 2 &&
    css`
      width: 20%;
      float: left;
    `}
  ${({ size }) =>
    size === 3 &&
    css`
      width: 20.5%;
      float: left;
    `}
  ${({ size }) =>
    size === 7 &&
    css`
      width: 59.5%;
      float: left;
    `}
`;

const StyledButton = styled(Button)`
  font-size: 24px;
  padding: 24px 36px;
`;

interface IColType {
  size?: number;
}

interface Props {
  formik: LocationDataFormik;
  setStep: React.Dispatch<React.SetStateAction<LOCATION_STEP>>;
}

export const LocationData = ({ formik, setStep }: Props) => {
  const { formatMessage: t } = useIntl();
  const handleBack = () => setStep(LOCATION_STEP.LOCATION_INFO);

  return (
    <StyledForm className="LocationData">
      <Form onSubmit={formik.handleSubmit}>
        <StyledRow>
          <StyledCol>
            <Input
              type="text"
              name="company"
              id="company"
              optional
              label={t({ id: 'company_name' })}
              className="form-control-custom"
              value={formik.values.company}
              invalid={!!formik.errors.company && formik.touched.company}
              onChange={formik.handleChange}
              error={formik.errors.company}
            />
          </StyledCol>
          <StyledCol size={7}>
            <Input
              type="text"
              name="street"
              id="street"
              label={t({ id: 'street' })}
              className="form-control-custom"
              value={formik.values.street}
              invalid={!!formik.errors.street && formik.touched.street}
              onChange={formik.handleChange}
              error={formik.errors.street}
            />
          </StyledCol>
          <StyledCol size={3}>
            <Input
              type="text"
              name="postcode"
              id="postcode"
              label={t({ id: 'postcode' })}
              className="form-control-custom"
              value={formik.values.postcode}
              invalid={!!formik.errors.postcode && formik.touched.postcode}
              onChange={formik.handleChange}
              error={formik.errors.postcode}
            />
          </StyledCol>
          <StyledCol size={2}>
            <Input
              type="text"
              name="ort"
              id="ort"
              disabled
              label={t({ id: 'ort' })}
              labelInfo={t({ id: 'ort' })}
              className="form-control-custom"
              value={formik.values.ort}
              invalid={!!formik.errors.ort && formik.touched.ort}
              onChange={formik.handleChange}
              error={formik.errors.ort}
            />
          </StyledCol>
          <StyledCol>
            <Input
              type="text"
              name="location_contact_person"
              id="location_contact_person"
              label={t({ id: 'location_contact_person' })}
              className="form-control-custom"
              value={formik.values.location_contact_person}
              invalid={!!formik.errors.location_contact_person && formik.touched.location_contact_person}
              onChange={formik.handleChange}
              error={formik.errors.location_contact_person}
            />
          </StyledCol>
          <StyledCol>
            <Input
              type="email"
              name="email"
              id="email"
              label={t({ id: 'email' })}
              className="form-control-custom"
              value={formik.values.email}
              invalid={!!formik.errors.email && formik.touched.email}
              onChange={formik.handleChange}
              error={formik.errors.email}
            />
          </StyledCol>
          <StyledCol>
            <Input
              type="email"
              name="email_confirm"
              id="email_confirm"
              label={t({ id: 'email_adresse_wiedeholen' })}
              className="form-control-custom"
              value={formik.values.email_confirm}
              invalid={!!formik.errors.email_confirm && formik.touched.email_confirm}
              onChange={formik.handleChange}
              error={formik.errors.email_confirm}
            />
          </StyledCol>
          <StyledCol>
            <Input
              type="text"
              name="phone"
              id="phone"
              label={t({ id: 'phone' })}
              className="form-control-custom"
              value={formik.values.phone}
              invalid={!!formik.errors.phone && formik.touched.phone}
              onChange={formik.handleChange}
              error={formik.errors.phone}
            />
          </StyledCol>
          <StyledCol>
            <StyledButtonWrapper>
              <StyledButton color="black" onClick={handleBack}>
                {t({ id: 'button_back' })}
              </StyledButton>
              <StyledButton color="black">{t({ id: 'button_next_step' })}</StyledButton>
            </StyledButtonWrapper>
          </StyledCol>
        </StyledRow>
      </Form>
    </StyledForm>
  );
};
