import { PrivateFilmingFormDataResponse } from '@api/filming';
import { ReactComponent as IconClose } from '@assets/icons/icons_close_small.svg';
import Button from '@components/shared/Button';
import breakpoints from '@lib/common/breakpoints';
import { useAuthContext } from '@lib/context/AuthContext/AuthContext';
import { useRouter } from '@lib/hooks/useRouter';
import React from 'react';
import { useIntl } from 'react-intl';
import { Form, FormFeedback } from 'reactstrap';
import styled from 'styled-components';
import { RequestLocationsFormik } from '../../../lib/hooks/formik/useRequestLocationsFormik';
import { Checkbox } from '../Checkbox';
import { Input } from '../Input';
import { Select } from '../Select';

const StyledForm = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  padding: 150px 150px 120px;
  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 80px 24px 50px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 80px 24px 50px;
  }
`;
const StyledFormTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-bottom: 24px;
  @media only screen and ${breakpoints.maxWidth.md} {
    align-items: flex-start;
    flex-direction: column;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    align-items: flex-start;
    flex-direction: column;
  }
  h3 {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 36px;
    letter-spacing: 0.4px;
    line-height: 1.2em;
    color: ${(props) => props.theme.colors.black};
    margin-right: 20px;
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 24px;
      letter-spacing: 0.2px;
      margin-right: 0;
    }
    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 24px;
      letter-spacing: 0.2px;
      margin-right: 0;
    }
  }
  a {
    font-family: 'GTFlexa-StandardMedium';
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 1.24em;
    color: ${(props) => props.theme.colors.black};
    text-decoration: underline;
    margin-bottom: 5px;
    @media only screen and ${breakpoints.maxWidth.md} {
      margin-top: 5px;
    }
    @media only screen and ${breakpoints.maxWidth.sm} {
      margin-top: 5px;
    }
  }
`;
const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 80px;
  @media only screen and ${breakpoints.maxWidth.md} {
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
  }
`;

const StyledButton = styled(Button)`
  font-size: 24px;
  padding: 24px 36px;
  margin-left: 16px;
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 20px;
    padding: 10px 18px;
    margin-left: 0;
    margin-top: 20px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 20px;
    padding: 10px 18px;
    margin-left: 0;
    margin-top: 20px;
  }
`;

const StyledFormFeedback = styled(FormFeedback)`
  font-family: 'GTFlexa-StandardMedium';
  font-size: 16px;
  letter-spacing: 0.2px;
  color: ${(props) => props.theme.colors.red};
  padding-top: 10px;
  text-align: right;
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 14px;
    padding-left: 15px;
    margin-top: -10px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 14px;
    padding-left: 15px;
    margin-top: -10px;
  }
`;
const StyledSelectedLocations = styled.div`
  width: 100%;
  margin-bottom: 80px;
`;
const StyledSelectedLocation = styled.div`
  width: 100%;
  padding: 20px 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #979797;
  &:first-child:last-child {
    border-bottom: 0;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    flex-wrap: wrap;
    position: relative;
  }
`;
const StyledSelectedLocationThumb = styled.div`
  width: 104px;
  height: 72px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const StyledSelectedLocationActions = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  @media only screen and ${breakpoints.maxWidth.sm} {
    width: 22px;
    height: 22px;
    position: absolute;
    top: 20px;
    right: 10px;
  }
  svg {
    width: 32px;
    height: 32px;
    margin-left: 40px;
    cursor: pointer;
    &:hover {
      g,
      path {
        stroke: ${(props) => props.theme.colors.red};
      }
    }
    &:first-child {
      margin-left: 0;
    }
  }
`;
const StyledSelectedLocationContent = styled.div`
  width: calc(100% - 136px);
  padding-left: 40px;
  @media only screen and ${breakpoints.maxWidth.sm} {
    padding-left: 0;
    width: 100%;
    padding-top: 10px;
  }
  h3 {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 24px;
    line-height: 1.3em;
    color: ${(props) => props.theme.colors.black};
    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 20px;
    }
  }
  span {
    display: block;
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 1.2em;
    color: ${(props) => props.theme.colors.black};
    margin-bottom: 5px;
    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 14px;
    }
  }
`;

interface Props {
  formik: RequestLocationsFormik;
  data?: PrivateFilmingFormDataResponse;
}

export const RequestLocationsForm = ({ formik, data }: Props) => {
  const router = useRouter();
  const authCtx = useAuthContext();
  const { formatMessage: t } = useIntl();

  const handleLocationRemove = (id: number) => {
    const locations = formik.values.locations.filter((location) => location.id !== id);
    formik.setFieldValue('locations', locations);
  };

  return (
    <StyledForm className="RequestLocationsForm">
      <Form inline onSubmit={formik.handleSubmit}>
        <StyledFormTitle>
          <h3>{t({ id: 'all_selected_locations' })}</h3>
        </StyledFormTitle>
        <StyledSelectedLocations>
          {formik.values.locations.map((location, index) => {
            return (
              <StyledSelectedLocation key={`selected-location-${index}`}>
                <StyledSelectedLocationThumb>
                  <img src={location.image} alt={location.title} />
                </StyledSelectedLocationThumb>
                <StyledSelectedLocationContent>
                  <span>#{location.id}</span>
                  <h3>{location.title}</h3>
                </StyledSelectedLocationContent>
                {formik.values.locations.length > 1 && (
                  <StyledSelectedLocationActions>
                    <IconClose onClick={() => handleLocationRemove(location.id)} />
                  </StyledSelectedLocationActions>
                )}
              </StyledSelectedLocation>
            );
          })}
        </StyledSelectedLocations>
        <StyledFormTitle>
          <h3>{t({ id: 'private_request_title' })}</h3>
        </StyledFormTitle>
        <Input
          className="RequestLocationsForm-input"
          type="text"
          name="company_name"
          id="company_name"
          label={t({ id: 'produzent_produktionsfirma' })}
          value={formik.values.company_name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          invalid={!!formik.errors.company_name && formik.touched.company_name}
          error={formik.errors.company_name}
        />
        <Select
          id="type_of_production"
          name="type_of_production"
          label={t({ id: 'projektart' })}
          className="form-control-custom"
          options={
            data?.data?.type_of_production?.map((el, index) => {
              return { label: el.type, value: index };
            }) || []
          }
          selectedOption={formik.values.type_of_production}
          onChange={formik.handleChange}
          error={typeof formik.errors.type_of_production === 'string' ? formik.errors.type_of_production : undefined}
        />
        <Input
          type="text"
          name="production_title"
          id="production_title"
          label={t({ id: 'titel_der_produktion' })}
          value={formik.values.production_title}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          invalid={!!formik.errors.production_title && formik.touched.production_title}
          error={formik.errors.production_title}
        />
        <Input
          type="text"
          name="name"
          id="name"
          label={t({ id: 'name' })}
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          invalid={!!formik.errors.name && formik.touched.name}
          error={formik.errors.name}
        />
        <Input
          type="text"
          name="phone"
          id="phone"
          label={t({ id: 'phone' })}
          value={formik.values.phone}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          invalid={!!formik.errors.phone && formik.touched.phone}
          error={formik.errors.phone}
        />
        <Input
          type="email"
          name="email"
          id="email"
          label="Email-Adresse"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          invalid={!!formik.errors.email && formik.touched.email}
          error={formik.errors.email}
        />
        <Input
          type="email"
          name="email_confirmation"
          id="email_confirmation"
          label={t({ id: 'email_adresse_wiedeholen' })}
          value={formik.values.email_confirmation}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          invalid={!!formik.errors.email_confirmation && formik.touched.email_confirmation}
          error={formik.errors.email_confirmation}
        />
        <Input
          type="textarea"
          optional
          name="comment"
          id="comment"
          label={t({ id: 'kommentare' })}
          value={formik.values.comment}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          invalid={!!formik.errors.comment && formik.touched.comment}
          error={formik.errors.comment}
        />
        <Checkbox
          name="terms"
          id="terms"
          label={t({ id: 'private_request_checkbox1' })}
          checked={formik.values.terms}
          onChange={formik.handleChange}
          invalid={!!formik.errors.terms && formik.touched.terms}
          error={formik.errors.terms}
        />
        <Checkbox
          name="data_protection"
          id="data_protection"
          label={t({ id: 'private_request_checkbox2' })}
          checked={formik.values.data_protection}
          onChange={formik.handleChange}
          invalid={!!formik.errors.data_protection && formik.touched.data_protection}
          error={formik.errors.data_protection}
        />
        {/* @ts-ignore */}
        {authCtx.error && <StyledFormFeedback>{authCtx.error}</StyledFormFeedback>}

        <StyledButtonWrapper>
          <StyledButton type="button" onClick={() => router.history.goBack()}>
            {t({ id: 'private_request_button_back' })}
          </StyledButton>
          <StyledButton type="submit" disabled={authCtx.isLoading} color="black">
            {authCtx.isLoading ? t({ id: 'loading' }) : t({ id: 'private_request_button_send' })}
          </StyledButton>
        </StyledButtonWrapper>
      </Form>
    </StyledForm>
  );
};
