import { ReactComponent as BookmarkIcon } from '@assets/icons/icons_bookmark.svg';
import breakpoints from '@lib/common/breakpoints';
import React, { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.violet};
  padding: 80px 145px;
  margin-bottom: 20px;
  display: flex;

  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 40px 0;
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 40px 20px;
    flex-direction: column;
  }
`;

const Icon = styled.div`
  svg path {
    stroke: #ffffff;
    stroke-width: 4px;
  }
`;

const Content = styled.div`
  margin-left: 40px;

  @media only screen and ${breakpoints.maxWidth.sm} {
    margin-left: 8px;
    margin-top: 20px;
  }

  h3 {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 1px;
    color: #fff;
    margin-bottom: 10px;
  }

  p {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 36px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: 0.4px;
    color: #fff;

    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 16px;
    }
  }
`;

interface ILocationRequestInfoProps {
  title?: string;
  description?: string;
}

const LocationRequestInfo: FC<ILocationRequestInfoProps> = (props) => {
  return (
    <Wrapper>
      <Icon>
        <BookmarkIcon />
      </Icon>
      <Content>
        <h3>{props.title}</h3>
        <p>{props.description}</p>
      </Content>
    </Wrapper>
  );
};

export default LocationRequestInfo;
