import { getPageMetaData } from '@api/page';
import { Cookies } from '@components/shared/Cookies';
import FullScreenLoader from '@components/shared/FullScreenLoader';
// import VfcLogo from '@components/shared/VfcLogo';
import { useAuthContext } from '@lib/context/AuthContext/AuthContext';
import { useReactIntlProvider } from '@lib/context/ReactIntlProvider/ReactIntlProvider';
import useGetPagesGeneralInfo from '@lib/hooks/queries/useGetPagesGeneralInfo';
import { useScrollToTop } from '@lib/hooks/useScrollToTop';
import { Routes } from '@routes/Routes';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const AppLayout = styled.div`
  width: 100%;
  height: auto;
`;

function App() {
  useScrollToTop();
  const authCtx = useAuthContext();
  const location = useLocation();
  const history = useHistory();
  const { isLoading } = useGetPagesGeneralInfo();
  const { activeLanguage } = useReactIntlProvider();

  const [metaData, setMetaData] = useState<any>(null);

  useEffect(() => {
    switch (location.pathname) {
      case '/Kontakt':
      case '/Contact': {
        history.push('/');
        break;
      }
      case '/Foerderung': {
        history.push('/foerderstellen');
        break;
      }

      case '/Vienna-Film-Commission/Team': {
        history.push('/about');
        break;
      }
      default:
        return;
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setMetaData(null);
    const isHome = location.pathname === '/';

    const pathName = isHome ? '/home/' : location.pathname;

    getPageMetaData(pathName, activeLanguage)
      .then((response) => {
        setMetaData(response.json);
      })
      .catch((e) => {
        console.log('e', e);
      });
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  if (authCtx.isAuthenticating || isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <>
      {metaData && (
        <>
          {Object.keys(metaData)?.length && (
            <Helmet title={metaData?.title ?? ''} defaultTitle="Vienna Film Commission">
              {Object.keys(metaData)?.map((key) => {
                if (key === 'description' || key === 'title') {
                  return <meta name={key} content={metaData[key]} />;
                }

                if (key.startsWith('og')) {
                  const content = metaData[key].includes('backend.') ? metaData[key].replace('backend.', 'www.') : metaData[key];
                  return <meta key={key} property={key} content={content} />;
                } else {
                  return null;
                }
              })}
            </Helmet>
          )}
        </>
      )}

      <AppLayout>
        {/*<VfcLogo />*/}
        <Routes />
        <Cookies />
      </AppLayout>
    </>
  );
}

export default App;
