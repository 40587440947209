import { ReactComponent as EditIcon } from '@assets/icons/icons_edit.svg';
import { ReactComponent as Facebook } from '@assets/icons/icons_facebook.svg';
import { ReactComponent as Instagram } from '@assets/icons/icons_instagram.svg';
import { ReactComponent as Vimeo } from '@assets/icons/icon_vimeo.svg';
import BranchesList from '@components/branches/BranchesList';
import Module00Button from '@components/modules/Module00';
import Module17 from '@components/modules/Module17';
import Button from '@components/shared/Button';
import { Container } from '@components/shared/Container';
import HandleFetchingState from '@components/shared/HandleFetchingState';
import Image from '@components/shared/Image';
import LoadMoreButton from '@components/shared/LoadMoreButton';
import ShareButton from '@components/shared/ShareButton';
import Tags from '@components/shared/Tags';
import breakpoints from '@lib/common/breakpoints';
import { useAuthContext } from '@lib/context/AuthContext/AuthContext';
import useGetBranchDetails from '@lib/hooks/queries/useGetBranchDetails';
import useGetRelatedBranchDetails from '@lib/hooks/queries/useGetRelatedBranches';
import { useRouter } from '@lib/hooks/useRouter';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding-top: 180px;
  display: flex;
  flex-direction: column;

  @media only screen and ${breakpoints.maxWidth.md} {
    padding-top: 80px;
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    padding-top: 50px;
  }
`;

const InnerContainer = styled.div`
  width: 100%;

  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 50px 0;
  }
`;

const Logo = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 42px;
  img {
    max-width: 200px;
    max-height: 200px;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const H1 = styled.h1<{ color?: string }>`
  font-family: 'GTFlexa-ExtendedMedium', sans-serif;
  font-size: 58px;
  font-weight: normal;
  line-height: 1.03;
  color: ${(props) => props.color};
  margin: 0;
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 36px;
    line-height: 0.88;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 24px;
    line-height: 1.17;
  }
`;

const TitleWrapper = styled.div`
  margin-bottom: 30px;
  max-width: 890px;

  @media only screen and ${breakpoints.maxWidth.md} {
    margin-bottom: 21px;
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    flex-direction: column;
  }

  > div:last-of-type {
    flex: 1;
    display: flex;
    justify-content: flex-end;

    @media only screen and ${breakpoints.maxWidth.md} {
      margin-top: 20px;
    }
  }

  h1 {
    margin-bottom: 17px;
  }

  p {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 36px;
    font-weight: normal;
    line-height: 1.17;
    letter-spacing: 0.4px;
    color: #000000;

    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 18px;
      line-height: 1.22;
    }
  }
`;

const ShareButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 30px;
`;

const TagsWrapper = styled.div`
  margin-bottom: 37px;
`;

const Content = styled.div`
  max-width: 725px;
  width: 100%;
  margin-bottom: 39px;
  p {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: 0.5px;
    color: #000;
    margin-bottom: 20px;
  }
`;

const Contact = styled.div`
  background-color: ${(props) => props.theme.colors.violet};
  display: flex;
  flex-direction: column;
  padding-top: 80px;

  p {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 24px;
    font-weight: normal;
    line-height: 1.33;
    color: #fff;

    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 18px;
      line-height: 1.25;
    }
  }

  > div:last-of-type {
    button {
      border-width: 2px;
      border-color: #ffffff;
      background-color: transparent !important;
      color: #ffffff;
      width: 100%;

      &:hover {
        //background-color: #ffffff !important;
        // color: ${(props) => props.theme.colors.violet};
      }

      @media only screen and ${breakpoints.maxWidth.md} {
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 22px;
        line-height: 1.36;
      }
    }
  }
`;

const ContactRow = styled.div`
  margin-top: 40px;
  display: flex;

  padding: 40px 0 60px 0;

  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 40px 0;
  }

  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 40px 0;
    flex-direction: column;
    > div {
      margin-bottom: 40px;
    }
  }
`;

const ContactCol = styled.div`
  width: 33.33%;

  p {
    max-width: 292px;
  }

  @media only screen and ${breakpoints.maxWidth.md} {
    width: 100%;
    p {
      max-width: 100%;
    }
  }

  a {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 24px;
    font-weight: normal;
    line-height: 1.33;
    color: #fff;

    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 18px;
      line-height: 1.25;
    }

    &:hover {
      color: ${(props) => props.theme.colors.red};
    }
  }
`;

const SocialMedia = styled.div`
  margin-top: 42px;
`;

const SocialLink = styled.a`
  margin-right: 40px;
  fill: #ffffff;

  &:hover {
    fill: ${(props) => props.theme.colors.mediumRed};
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

const StyledModule17 = styled(Module17)`
  max-width: 100%;
`;

const StyledModuleLinkButton = styled(Module00Button)`
  svg {
    transform: rotate(-90deg);
  }
`;

const RelatedIndustries = styled.div`
  padding: 80px;

  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 40px;
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 40px 20px;
  }

  h1 {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 36px;
    font-weight: normal;
    line-height: 1.67;
    letter-spacing: -0.15px;
    color: #000;
    margin-bottom: 53px;

    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 36px;
      line-height: 0.88;
    }
    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 24px;
      line-height: 1.17;
    }
  }
  p {
    display: block;
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.25;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 32px;
  }
`;

const StyledBranchesList = styled(BranchesList)`
  > div:first-of-type {
    padding: 0 0 80px 0 !important;
  }
`;
const FiltersToggleButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  cursor: pointer;
  padding: 20px 15px;
  color: #ffffff;
  svg {
    transition: 0.2s;
    width: 28px;
    height: 28px;
    path {
      stroke: white;
    }
  }
  p {
    font-size: 48px;
    font-weight: normal;
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    line-height: 1.25;
    text-align: center;
    margin-left: 8px;
    text-transform: uppercase;
  }
  @media only screen and ${breakpoints.maxWidth.md} {
    p {
      font-size: 25px;
      line-height: 1.25;
    }

    svg {
      width: 19px;
      height: 19px;
    }
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 14px 15px 8px 15px;

    p {
      font-size: 22px;
      line-height: 1.36;
    }
  }
`;
const FiltersWrapper = styled.div`
  background-color: #000000;
  border-top: 1px solid #707070;

  @media only screen and ${breakpoints.maxWidth.md} {
    padding-top: 20px;
  }
`;

const StyledAccordionMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 33px 0 23px;
  transition: all 0.3s ease-in-out;
  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 13px 0 3px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 13px 0 3px;
  }
`;
const StyledAction = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  color: ${(props) => props.theme.colors.white};
  margin-bottom: 45px;
  @media only screen and ${breakpoints.maxWidth.md} {
    margin-bottom: 25px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    margin-bottom: 25px;
  }
  &:hover {
    color: ${(props) => props.theme.colors.red};
  }
`;
const StyledActionTitle = styled.h4`
  width: 100%;
  display: flex;
  font-size: 24px;
  font-family: 'GTFlexa-ExtendedMedium', sans-serif;
  line-height: 1.3em;
  margin-bottom: 8px;
  transition: all 0.3s ease-in-out;
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 20px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 20px;
  }
`;
const StyledActionContent = styled.p`
  width: 100%;
  font-family: 'GTFlexa-StandardLight', sans-serif;
  font-size: 18px;
  line-height: 1.2em;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.colors.white};
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 16px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 16px;
  }
`;
const StyledEditIcon = styled(EditIcon)`
  g {
    stroke: #fff;
  }
`;

interface Props {
  branchenSlug?: string;
}

const BranchesDetails = (props: RouteComponentProps<Props>) => {
  const slug = props.match.params.branchenSlug;
  const { user } = useAuthContext();
  const router = useRouter();
  const [open, setOpen] = useState(false);

  const { formatMessage: t } = useIntl();
  const { data, isLoading, error } = useGetBranchDetails(slug);
  const related = useGetRelatedBranchDetails({ slug });

  const categoryItems = data?.categories
    ? Object.values(data.categories)
        .map((value) => {
          return value;
        })
        .filter((item: any) => item)
        .flat()
    : [];

  const relatedBranches = related?.data?.pages?.map((item) => item.related).flat() ?? [];

  return (
    <HandleFetchingState loading={isLoading} error={error}>
      <Wrapper>
        <Container>
          <InnerContainer>
            {data?.thumbnail && (
              <Logo>
                <Image src={data?.thumbnail} />
              </Logo>
            )}

            <TitleWrapper>
              <H1>{data?.title}</H1>
              <p dangerouslySetInnerHTML={{ __html: data?.description ?? '' }} />
            </TitleWrapper>

            <ShareButtonWrapper>
              <ShareButton title={data?.title} />
            </ShareButtonWrapper>

            {categoryItems.length > 0 && (
              <TagsWrapper>
                <Tags tags={categoryItems.map((item: any) => ({ name: item?.name }))} />
              </TagsWrapper>
            )}

            <Content>
              <p dangerouslySetInnerHTML={{ __html: data?.description_text ?? '' }} />
            </Content>
            <StyledModule17 />
          </InnerContainer>
        </Container>

        {user?.ID === data?.author_id && (
          <>
            <FiltersToggleButton role="button" onClick={() => setOpen(!open)}>
              <StyledEditIcon /> <p>{t({ id: 'branch.bearbeiten' })}</p>
            </FiltersToggleButton>
            {open && (
              <FiltersWrapper>
                <Container>
                  <InnerContainer>
                    <StyledAccordionMain>
                      <StyledAction onClick={() => router.history.push(`/manage-branch/${data?.slug}/edit`)}>
                        <StyledActionTitle>→ {t({ id: 'branch.edit' })}</StyledActionTitle>
                        <StyledActionContent>{t({ id: 'branch.edit.description' })} </StyledActionContent>
                      </StyledAction>
                      <StyledAction onClick={() => router.history.push(`/manage-branch/${data?.slug}/delete`)}>
                        <StyledActionTitle>→ {t({ id: 'branch.delete' })}</StyledActionTitle>
                        <StyledActionContent>{t({ id: 'branch.delete.description' })}</StyledActionContent>
                      </StyledAction>
                    </StyledAccordionMain>
                  </InnerContainer>
                </Container>
              </FiltersWrapper>
            )}
          </>
        )}

        <Contact>
          <Container>
            <H1 color="#ffffff">{t({ id: 'kontaktdetails' })}</H1>
            <ContactRow>
              <ContactCol>
                <p>
                  {data?.company_details?.company_profile} {data?.company_details?.company_profile && ', '} {data?.street} {data?.postcode}{' '}
                  {data?.location}
                </p>
                <p style={{ marginTop: 30, maxWidth: '100%' }}>
                  <a href={`${data?.website}`}>{data?.website}</a>
                </p>
                <p>
                  <a href={`mailto:${data?.email}`}>{data?.email}</a>
                </p>
                <SocialMedia>
                  {data?.company_details?.vimeo_url && (
                    <SocialLink href={data?.company_details?.vimeo_url}>
                      <Vimeo />
                    </SocialLink>
                  )}
                  {data?.company_details?.instagram_url && (
                    <SocialLink href={data?.company_details?.instagram_url}>
                      <Instagram />
                    </SocialLink>
                  )}
                  {data?.company_details?.facebook_url && (
                    <SocialLink href={data?.company_details?.facebook_url}>
                      <Facebook />
                    </SocialLink>
                  )}
                </SocialMedia>
              </ContactCol>
              <ContactCol>
                <p>
                  {data?.contact_person?.first_name} {data?.contact_person?.last_name}
                </p>
                <p> {data?.contact_person?.mobile}</p>
                <a href={`tel:${data?.contact_person?.phone}`}>
                  <p>{data?.contact_person?.phone}</p>
                </a>
              </ContactCol>
              <ContactCol>
                <p>
                  {data?.further_person?.first_name} {data?.further_person?.last_name}
                </p>
                <p> {data?.further_person?.mobile}</p>
                <p> {data?.further_person?.phone}</p>
              </ContactCol>
            </ContactRow>
          </Container>
          <div>
            <a href={`mailto:${data?.email}`}>
              <Button>{t({ id: 'anschreiben' })}</Button>
            </a>
          </div>
        </Contact>

        <InfiniteScroll
          hasMore={Boolean(related.hasNextPage)}
          dataLength={relatedBranches?.length}
          next={related.fetchNextPage}
          loader={null}
          scrollThreshold={1}
        >
          <RelatedIndustries>
            <Container>
              <h1>{t({ id: 'firma.company' })}</h1>
            </Container>
            {/*<p>Ausstattung, Personalmanagment</p>*/}
            <StyledBranchesList branches={relatedBranches} />
            {related.isFetching && <LoadMoreButton isLoading />}
          </RelatedIndustries>
        </InfiniteScroll>

        {user?.ID === data?.author_id && (
          <StyledModuleLinkButton
            data={{
              link: {
                title: t({ id: 'zuruck.zu.meinem.profil' }),
                url: '/profile',
              },
            }}
          />
        )}
      </Wrapper>
    </HandleFetchingState>
  );
};

export default BranchesDetails;
