import HandleFetchingState from '@components/shared/HandleFetchingState';
import useGetPage from '@lib/hooks/queries/useGetPage';
import useModules from '@lib/hooks/useModules';
import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div``;

interface FilmingPermitsDetailsProps extends RouteComponentProps<{ pageSlug?: string }> {}

const FilmingPermitsDetails: FC<FilmingPermitsDetailsProps> = (props) => {
  const pageSlug = props?.match?.params?.pageSlug;
  const { isLoading, data, error } = useGetPage(pageSlug);

  console.log(data);

  const { getModuleByName } = useModules();

  const renderModules = () => {
    const blocks = data?.blocks ?? [];

    return blocks
      ?.map((block: any, index: number) => {
        const moduleProps = block?.attrs?.data;
        const Module = getModuleByName(block?.blockName);

        if (!Module) {
          return null;
        }

        return <Module key={`module-${block?.blockName}-${index}`} data={moduleProps} />;
      })
      .filter((m: any) => Boolean(m));
  };

  return (
    <HandleFetchingState loading={isLoading} error={error}>
      <Wrapper>{renderModules()}</Wrapper>
    </HandleFetchingState>
  );
};

export default FilmingPermitsDetails;
