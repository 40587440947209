export default function prepareLink(link?: string | null) {
  if (!link) {
    return '';
  }

  if (link.startsWith('/de/') || link.startsWith('/en/')) {
    return link.replace(/.{3}/, '');
  }

  return link;
}
