import Module31 from '@components/modules/Module31';
import Module32 from '@components/modules/Module32';
import AllResults from '@components/search/AllResults';
import NewsSearch from '@components/search/NewsSearch';
import ProductionIndexSearch from '@components/search/ProductionIndexSearch';
import SearchCategories from '@components/search/SearchCategories';
import SearchKeyword from '@components/search/SearchKeyword';
import { vfcFiltersKey } from '@lib/hooks/useLocalStorageFilters';
import queryString from 'query-string';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce/esm';

const Wrapper = styled.div`
  padding-top: 120px;
  padding-bottom: 120px;
`;

const SearchResults = styled.div``;

interface ISearchProps {
  className?: string;
}

const Search: FC<ISearchProps> = (props) => {
  const location = useLocation();
  const params = queryString.parse(location.search);

  const [searchCategory, setSearchCategory] = useState<string | null>('alle');
  const [searchKeyword, setSearchKeyword] = useState((params.searchKeyword as string) || '');
  const [debouncedSearchValue] = useDebounce(searchKeyword, 200);

  const handleOnSelect = (category: string) => {
    setSearchCategory(category);
  };

  useEffect(() => {
    return () => {
      localStorage.removeItem(vfcFiltersKey);
    };
  }, []);

  const getSearchResults = () => {
    switch (searchCategory) {
      case 'alle':
        return <AllResults searchValue={debouncedSearchValue} />;

      case 'locations': {
        return <Module31 searchProps={{ searchValue: debouncedSearchValue, showResultsHeader: true }} />;
      }

      case 'branches': {
        return <Module32 searchProps={{ searchValue: debouncedSearchValue, showResultsHeader: true }} />;
      }

      case 'news': {
        return <NewsSearch searchValue={debouncedSearchValue} />;
      }

      case 'produktionsspiegel': {
        return <ProductionIndexSearch searchValue={debouncedSearchValue} />;
      }
    }
  };

  return (
    <Wrapper className={props.className}>
      <SearchKeyword value={searchKeyword} onChange={(e: string) => setSearchKeyword(e)} />

      <div>
        <SearchCategories onSelect={handleOnSelect} active={searchCategory} />
      </div>

      <SearchResults>{getSearchResults()}</SearchResults>
    </Wrapper>
  );
};

export default Search;
