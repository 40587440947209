import { ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 100000,
      staleTime: 100000,
      refetchOnWindowFocus: false,
      retryDelay: 60000,
      retry(failureCount, error: any) {
        if (error?.response?.status === 404) return true;
        return failureCount < 3;
      },
    },
  },
});

interface ReactQueryProviderProps {
  children: ReactNode;
}

export default function ReactQueryProvider(props: ReactQueryProviderProps) {
  return (
    <QueryClientProvider client={queryClient}>
      {props.children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
