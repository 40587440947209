import Footer from '@components/footer/Footer';
import Header from '@components/header/Header';
import breakpoints from '@lib/common/breakpoints';
import { HEADER_HEIGHT } from '@lib/constants';
import { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

interface LayoutWithHeaderProps {
  hideFooter?: boolean;
  hideHeader?: boolean;
  children: ReactNode;
}

const Layout = styled.div<{ noPaddingTop?: boolean; customPadding?: number | null }>`
  // set dynamic paddingTop
  padding-top: ${(props) => (!props.customPadding ? null : `${props.customPadding}px`)}}
  padding-top: ${(props) => (props.noPaddingTop ? null : `${HEADER_HEIGHT}px`)}}

  @media only screen and ${breakpoints.maxWidth.md} {
    padding-top: ${(props) => (props.noPaddingTop ? null : `52px`)} !important;
  }
`;

export default function LayoutWithHeaderFooter(props: LayoutWithHeaderProps) {
  const [headerHeight, setHeaderHeight] = useState(HEADER_HEIGHT);

  useEffect(() => {
    const headerElement = document.querySelector('header');

    const handleResize = () => {
      setHeaderHeight(headerElement?.clientHeight ?? HEADER_HEIGHT);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <Layout customPadding={!props.hideHeader ? headerHeight : null} noPaddingTop={props.hideHeader}>
      {!props.hideHeader && <Header position="fixed" forceBottom />}
      {props.children}
      {!props.hideFooter && <Footer />}
    </Layout>
  );
}
