import { ReactComponent as External } from '@assets/icons/icons_arrow-external.svg';
import breakpoints from '@lib/common/breakpoints';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

const Module08Container = styled.div`
  width: 100%;
  padding: 80px 20px 80px 20px;
  background-color: ${(props) => props.theme.colors.violet};
  display: flex;
  justify-content: center;

  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 48px 20px;
  }

  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 30px 20px;
  }
`;

const StyledExternal = styled(External)`
  width: 30px;
  height: 30px;
  margin-right: 17px;

  path {
    stroke: #ffffff;
  }

  @media only screen and ${breakpoints.maxWidth.md} {
    width: 20px;
    height: 20px;
    margin-right: 9px;
  }
  @media only screen and ${breakpoints.maxWidth.md} {
    width: 15px;
    height: 15px;
  }
`;

const Text = styled.p`
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  font-size: 48px;
  font-weight: 300;
  line-height: 1.25;
  color: #ffffff;
  text-transform: uppercase;
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 26px;
    line-height: 1.23;
  }

  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 22px;
    line-height: 1.18;
  }
`;

const Link = styled(RouterLink)`
  display: flex;
  align-items: center;
  color: #ffffff;
`;

interface Module08Props {
  data?: {
    button_text?: string;
  };
}

export default function Module08(props: Module08Props) {
  return (
    <Module08Container data-module="m08">
      <Link to="/">
        <StyledExternal />
        <Text>{props?.data?.button_text}</Text>
      </Link>
    </Module08Container>
  );
}
