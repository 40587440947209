import { Container } from '@components/shared/Container';
import HandleFetchingState from '@components/shared/HandleFetchingState';
import { FilmingForm } from '@components/user/Filming/FilmingForm';
import breakpoints from '@lib/common/breakpoints';
import useGetFilmingForm from '@lib/hooks/queries/useGetFilmingForm';
import React from 'react';
import styled from 'styled-components';

const StyledFilming = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.yellow};
  padding: 120px 0;
  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 100px 0;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 80px 0;
  }
`;

export const StyledPageHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 120px;
  @media only screen and ${breakpoints.maxWidth.md} {
    margin-bottom: 80px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    margin-bottom: 50px;
  }
  h1 {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 58px;
    line-height: 1.05em;
    color: ${(props) => props.theme.colors.black};
    margin-bottom: 27px;
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 28px;
      line-height: 1.18em;
      margin-bottom: 24px;
    }

    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 24px;
      line-height: 1.18em;
      margin-bottom: 24px;
    }
  }
  p {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 36px;
    letter-spacing: 0.4px;
    line-height: 1.18em;
    color: ${(props) => props.theme.colors.black};
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 20px;
      letter-spacing: 0.2px;
      line-height: 1.2em;
    }

    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 18px;
      letter-spacing: 0.2px;
      line-height: 1.2em;
    }
  }
`;

interface Props {}

export const Filming = (props: Props) => {
  const { isLoading, data } = useGetFilmingForm();
  return (
    <StyledFilming className="Filming">
      <Container>
        <HandleFetchingState loading={isLoading}>
          <FilmingForm data={data} />
        </HandleFetchingState>
      </Container>
    </StyledFilming>
  );
};
