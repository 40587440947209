import { Container } from '@components/shared/Container';
import ProductionListItem from '@components/shared/ProductionListItem';
import breakpoints from '@lib/common/breakpoints';
import ModuleType from '@lib/interfaces/Modules';
import React, { FC, useMemo } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import styled, { ThemeProvider } from 'styled-components';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding-top: 100px;
  padding-bottom: 40px;
  width: 100%;

  @media only screen and ${breakpoints.maxWidth.sm} {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  h3 {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.25em;
    text-transform: uppercase;
  }
`;

const Title = styled.h1`
  font-family: 'GTFlexa-ExtendedMedium', sans-serif;
  font-weight: 500;
  font-size: 58px;
  line-height: 1.03;
  margin-bottom: 60px;
  padding-right: 140px;

  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 24px;
    margin-bottom: 24px;
    line-height: ${(props) => props.theme.helpers.pxToRem(28)};
    padding-right: 0;
  }
`;

const StyledRow = styled(Row)`
  margin-left: -27px;
  margin-right: -27px;

  @media only screen and ${breakpoints.maxWidth.md} {
    margin-left: -16px;
    margin-right: -16px;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    margin-left: -5px;
    margin-right: -5px;
  }
`;

const StyledCol = styled(Col)`
  margin-bottom: 60px;
  padding: 0 27px;

  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 0 16px;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    padding: 0 5px;
    margin-bottom: 25px;
  }
`;

interface IModule23Props {
  data?: {
    title: string;
    production?: {
      ID: number;
      post_author: string;
      post_content: string;
      post_date: string;
      post_name: string;
      post_title: string;
      post_type: string;
      thumbnail: string;
    }[];
  };
}

const Module23: FC<IModule23Props> = (props) => {
  const theme = useMemo(() => {
    return {
      flexboxgrid: {
        gridSize: 12,
        gutterWidth: 0,
        outerMargin: 0,
        mediaQuery: 'only screen',
        breakpoints: {
          xs: 0,
          sm: 36,
          md: 48,
          lg: 61,
        },
      },
    };
  }, []);
  return (
    <Wrapper data-module={ModuleType.MODULE_23}>
      <Container>
        <Title>{props.data?.title}</Title>
        <ThemeProvider theme={theme}>
          <StyledRow>
            {props.data?.production?.map((productionItem, index) => (
              <StyledCol key={`${productionItem.ID}-${index}`} xs={6} sm={6} md={6} lg={3}>
                <ProductionListItem productionItem={productionItem as any} />
              </StyledCol>
            ))}
          </StyledRow>
        </ThemeProvider>
      </Container>
    </Wrapper>
  );
};

export default Module23;
