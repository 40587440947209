import { get } from '@api/axios';
import objectToQueryString from '@lib/helpers/objectToQueryString';
import { PaginatedData, PaginationParams } from '@lib/interfaces';

export interface Showreel {
  id?: number;
  ID?: number;
  description: string | null;
  post_content: string | null;
  post_date: string | null;
  post_name: string | null;
  post_title: string | null;
  thumbnail: string | null;
  video_id: string | null;
}

export interface GetShowreelsResponse extends PaginatedData {
  showreels: Array<Showreel>;
}

export const getShowreels = (params: PaginationParams) => {
  return get<PaginationParams, GetShowreelsResponse>({ url: `v1/showreels/all?${objectToQueryString(params)}` });
};
