import { forgotPassword } from '@api/user';
import Button from '@components/shared/Button';
import breakpoints from '@lib/common/breakpoints';
import { useForgotPasswordFormik } from '@lib/hooks/formik/useForgotPasswordFormik';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Form } from 'reactstrap';
import styled from 'styled-components';
import { Input } from '../Input';

const StyledForm = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  padding: 90px 120px;
  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 80px 24px 50px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 80px 24px 50px;
  }
`;

const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  @media only screen and ${breakpoints.maxWidth.md} {
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
  }
`;

const StyledButton = styled(Button)`
  font-size: 24px;
  padding: 24px 36px;
  margin-left: 16px;
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 20px;
    padding: 10px 18px;
    margin-left: 0;
    margin-top: 20px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 20px;
    padding: 10px 18px;
    margin-left: 0;
    margin-top: 20px;
  }
`;
const StyledSuccess = styled.p`
  font-size: 20px;
  text-align: right;
`;

const ForgotPasswordForm = () => {
  const { formatMessage: t } = useIntl();
  const [success, setSuccess] = useState(undefined);
  const [error, setError] = useState(undefined);
  const formik = useForgotPasswordFormik({
    onSubmit: async (values, formikHelpers) => {
      try {
        setSuccess(undefined);
        setError(undefined);
        const res = await forgotPassword({
          email: values.username,
        });
        if (res.data.success) {
          setSuccess(res.data.success);
        }
        formikHelpers.resetForm();
      } catch (e) {
        //@ts-ignore
        setError(e.response.data.data.message);
      }
    },
  });

  return (
    <StyledForm className="ForgotPasswordForm">
      <Form inline onSubmit={formik.handleSubmit}>
        <Input
          className="ForgotPasswordForm-input"
          type="email"
          name="username"
          id="username"
          label={t({ id: 'email' })}
          value={formik.values.username}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={error}
        />
        {success && <StyledSuccess>{success}</StyledSuccess>}
        <StyledButtonWrapper>
          <StyledButton type="submit" disabled={formik.isSubmitting} color="black">
            {formik.isSubmitting ? t({ id: 'loading' }) : t({ id: 'button_next_step' })}
          </StyledButton>
        </StyledButtonWrapper>
      </Form>
    </StyledForm>
  );
};

export default ForgotPasswordForm;
