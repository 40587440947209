import { Container } from '@components/shared/Container';
import DownloadListItem from '@components/shared/DownloadListItem';
import breakpoints from '@lib/common/breakpoints';
import ModuleType from '@lib/interfaces/Modules';
import React, { FC, useMemo } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import styled, { ThemeProvider } from 'styled-components';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.yellow};
  padding-top: 120px;
  padding-bottom: 100px;
  width: 100%;

  @media only screen and ${breakpoints.maxWidth.md} {
    padding-top: 80px;
    padding-bottom: 20px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    padding-top: 40px;
    padding-bottom: 20px;
  }
`;

const Title = styled.h3`
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  font-size: 48px;
  letter-spacing: -0.2px;
  line-height: 1.25em;
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 40px;
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 32px;
    margin-bottom: 30px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 20px;
    margin-bottom: 25px;
  }
`;

const StyledRow = styled(Row)`
  margin-left: -27px;
  margin-right: -27px;

  @media only screen and ${breakpoints.maxWidth.md} {
    margin-left: -16px;
    margin-right: -16px;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    margin-left: -5px;
    margin-right: -5px;
  }
`;

const StyledCol = styled(Col)`
  margin-bottom: 60px;
  padding: 0 27px;

  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 0 16px;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    padding: 0 5px;
    margin-bottom: 30px;
  }
`;

interface IModule26Props {
  data?: {
    title: string;
    downloads: {
      title: string;
      image: {
        ID: number;
        title: string;
        filename: string;
        filesize: number;
        url: string;
        caption: string;
        description: string;
        name: string;
      };
      file: {
        ID: number;
        title: string;
        filename: string;
        url: string;
        description: string;
        filesize: number;
        caption: string;
        name: string;
        mime_type: string;
      };
    }[];
  };
}

const Module26: FC<IModule26Props> = (props) => {
  const theme = useMemo(() => {
    return {
      flexboxgrid: {
        gridSize: 12,
        gutterWidth: 0,
        outerMargin: 0,
        mediaQuery: 'only screen',
        breakpoints: {
          xs: 0,
          sm: 36,
          md: 48,
          lg: 61,
        },
      },
    };
  }, []);
  return (
    <Wrapper data-module={ModuleType.MODULE_26}>
      <Container>
        <Title>{props.data?.title}</Title>
        <ThemeProvider theme={theme}>
          <StyledRow>
            {props.data?.downloads?.map((downloadItem, index) => (
              <StyledCol key={`download-item-${index}`} xs={6} sm={6} md={6} lg={4}>
                <DownloadListItem {...downloadItem} />
              </StyledCol>
            ))}
          </StyledRow>
        </ThemeProvider>
      </Container>
    </Wrapper>
  );
};

export default Module26;
