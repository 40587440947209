import React, { FC } from 'react';
import styled from 'styled-components';

const TooltipContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  .tooltip-box {
    display: none;
    position: absolute;
    color: ${(props) => props.theme.colors.mediumRed};
    padding: 5px;
    border-radius: 5px;
    top: 100%;
    min-width: 250px;
    text-align: center;
  }

  .tooltip-box.visible {
    display: block;
  }
`;

interface ITooltipProps {
  text: string;
  children: React.ReactNode;
}

const Tooltip: FC<ITooltipProps> = (props) => {
  const [show, setShow] = React.useState(false);

  const { children, text, ...rest } = props;
  return (
    <TooltipContainer>
      <div className={show ? 'tooltip-box visible' : 'tooltip-box'}>{text}</div>

      <div onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)} {...rest}>
        {children}
      </div>
    </TooltipContainer>
  );
};

export default Tooltip;
