import { ReactComponent as ArrowLeft } from '@assets/icons/icons_arrow-left.svg';
import { ReactComponent as ArrowRight } from '@assets/icons/icons_arrow-right.svg';
import { ReactComponent as CloseIcon } from '@assets/icons/icons_close.svg';
import Image from '@components/shared/Image';
import { SimpleButton } from '@components/styled-components/StyledComponents';
import breakpoints from '@lib/common/breakpoints';
import { ObjectAny } from '@lib/interfaces';
import Flickity from 'flickity';
import React, { FC, useEffect, useRef } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  z-index: 1900;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
`;

const Content = styled.div`
  height: calc(100vh - 74px);
`;

const Slider = styled.div`
  height: 100%;
  .flickity-viewport {
    height: 100% !important;
  }
`;
const SliderItem = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  figure {
    height: 100%;
    max-height: 700px;
    position: relative;
    margin: 0;
  }

  figcaption {
    text-align: right;
    font-family: GTFlexa-ExtendedLight, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: 0.5px;
    color: #fff;
    margin-top: 10px;

    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 16px;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const BottomBar = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 80px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${(props) => props.theme.colors.yellow};
  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 15px 40px;
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 15px 20px;
  }
`;

const BottomBarTitle = styled.h1`
  flex: 1;
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.11px;
  color: #000;
  padding-right: 15px;

  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 22px;
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 16px;
  }
`;

const BottomBarControls = styled.div`
  display: flex;
  align-items: center;

  button {
    width: 30px;
    margin-right: 36px;
    &:last-of-type {
      margin-right: 0;
    }

    @media only screen and ${breakpoints.maxWidth.md} {
      width: 20px;
      margin-right: 15px;
    }

    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 16px;
    }
  }
`;
const HiddenNavigation = styled.span`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 2000;

  > div {
    width: 50%;
  }
`;

interface IFullscreenSliderProps {
  className?: string;

  items?: Array<ObjectAny>;
  activeItem?: ObjectAny;
  onClose?: () => void;
  onNext?: () => void;
  onPrev?: () => void;
}

const FullscreenSlider: FC<IFullscreenSliderProps> = (props) => {
  const ref = useRef<any>(null);
  const bottomBarRef = useRef<null>(null);

  const items = props.items ?? [];
  const bottomBarHeight = (bottomBarRef?.current as any)?.offsetHeight;
  const currentItem = items.find((item) => item.ID === props.activeItem?.ID);
  const currentItemIndex = items.findIndex((item) => item.ID === currentItem?.ID);

  useEffect(() => {
    const elem = document.querySelector(`.fullscreen-slider`);
    const initialIndex = items.findIndex((item) => item.ID === currentItem?.ID);

    if (!elem) {
      return;
    }

    ref.current = new Flickity(elem, {
      initialIndex: initialIndex ?? 0,
      groupCells: true,
      pageDots: false,
      prevNextButtons: false,
      draggable: false,
    });
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    document.body.classList.add('overflow-hidden');
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, []);

  const handlePrev = () => {
    if (!ref.current) {
      return;
    }
    (ref.current as any).previous();
    props.onPrev?.();
  };

  const handleNext = () => {
    if (!ref.current) {
      return;
    }
    (ref.current as any).next();
    props.onNext?.();
  };

  return (
    <Wrapper className={props.className}>
      <Content style={{ height: `calc(100% - ${bottomBarHeight}px)` }}>
        <Slider className="fullscreen-slider">
          {items?.map((item: any, index) => {
            return (
              <SliderItem key={`${item.ID}-${index}`} className="fullscreen-slider-item">
                <figure>
                  <HiddenNavigation>
                    <div onClick={handlePrev} role="button" />
                    <div onClick={handleNext} role="button" />
                  </HiddenNavigation>
                  <Image src={item?.thumbnail} alt={item?.post_title} />
                  <figcaption>{item?.post_title}</figcaption>
                </figure>
              </SliderItem>
            );
          })}
        </Slider>
      </Content>

      <BottomBar ref={bottomBarRef}>
        <BottomBarTitle>{currentItem?.post_title || currentItem?.title}</BottomBarTitle>
        <BottomBarControls>
          <SimpleButton onClick={handlePrev} disabled={currentItemIndex === 0}>
            <ArrowLeft />
          </SimpleButton>
          <SimpleButton onClick={handleNext} disabled={currentItemIndex === items?.length - 1}>
            <ArrowRight />
          </SimpleButton>
          <SimpleButton onClick={props.onClose}>
            <CloseIcon />
          </SimpleButton>
        </BottomBarControls>
      </BottomBar>
    </Wrapper>
  );
};

export default FullscreenSlider;
