import { getBranchFilters } from '@api/branch';
import QueryKeys from '@lib/constants/QueryKeys';
import { ObjectAny } from '@lib/interfaces';
import { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';

export default function useGetBranchFilters(letter?: string, options?: UseQueryOptions<ObjectAny, AxiosError, any>) {
  const queryClient = useQueryClient();
  return useQuery<ObjectAny, AxiosError>(QueryKeys.branchFilters(letter), () => getBranchFilters(letter), {
    staleTime: 5,
    initialData: () => {
      return queryClient.getQueryData(QueryKeys.branchFilters());
    },
    ...options,
  });
}
