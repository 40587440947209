import { LoginFields } from '@lib/hooks/formik/useLoginFormik';
import { ProfileChangePasswordFields } from '@lib/hooks/formik/useProfileChangePasswordFormik';
import { ProfileDeleteFields } from '@lib/hooks/formik/useProfileDeleteFormik';
import { ProfileEditFields } from '@lib/hooks/formik/useProfileEditFormik';
import { deleteRequest, get, post, put } from './axios';

export interface LoginResponse extends User {
  error?: string;
}

export interface RegisterResponse {
  token: User;
  error?: string;
}

export interface UserDetailsResponse {
  me: User;
}

export interface RegisterInput {
  first_name: string;
  last_name: string;
  company?: string;
  email: string;
  email_confirmation: string;
  password: string;
  password_confirmation: string;
}

export interface User {
  ID: string;
  user_login: string;
  user_nicename: string;
  user_email: string;
  user_url: string;
  user_registred: string;
  user_activation_key: string;
  user_status: string;
  display_name: string;
  token: string;

  company: string;
  first_name: string;
  last_name: string;
  user_registered: string;
}

export interface DeleteProfileResponse {
  message?: string;
  error?: string;
}

export interface ChangePasswordResponse {
  message?: string;
  error?: string;
}

export interface UpdateProfileResponse {
  user: User;
  error?: string;
}

export const register = async (data: RegisterInput) => post<RegisterInput, RegisterResponse>({ url: 'v1/auth/register', data });

export const login = async (data: LoginFields) => post<LoginFields, LoginResponse>({ url: 'jwt-auth/v1/token', data });

export const getUserDetails = async () => get<undefined, UserDetailsResponse>({ url: 'v1/auth/me' });

export const deleteProfile = async (data: ProfileDeleteFields) =>
  deleteRequest<ProfileDeleteFields, DeleteProfileResponse>({ url: 'v1/users/delete_profile', data });

export const changePassword = async (data: ProfileChangePasswordFields) =>
  put<ProfileChangePasswordFields, ChangePasswordResponse>({ url: 'v1/users/change_password', data });

export const updateProfile = async (data: ProfileEditFields) =>
  put<ProfileEditFields, UpdateProfileResponse>({ url: 'v1/users/update_profile', data });

export const forgotPassword = async (data: any) => post<any, any>({ url: 'v1/users/forgot_password', data });

export const resetPassword = async (data: any) => post<any, any>({ url: 'v1/users/reset_password', data });
