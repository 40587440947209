import { GetRelatedBranchesInput } from '@api/branch';
import { GetRelatedLocationsInput } from '@api/locations';

const QueryKeys = {
  page: (slug?: string) => ['page', `page-${slug}`],
  pagesGeneralInfo: () => ['pages', 'general-info'],
  branchForm: () => [`branch-form`],
  branches: (params: string) => ['branches', params],
  branchDetails: (slug?: string) => ['branch-details', slug],
  branchEdit: (slug?: string) => [`branch-edit-${slug}`],
  relatedBranches: (params?: GetRelatedBranchesInput) => ['related-branches', params],
  branchFilters: (letter?: string) => ['branch-filters', letter],
  filmingForm: () => [`filming-form`],
  filmingPermitForm: () => [`filming-permit-form`],
  multiFilmingPermitForm: () => [`multi-filming-permit-form`],
  showreels: () => ['showreels'],
  press: () => ['press'],
  productions: () => ['productions'],
  news: () => ['news'],
  newsDetails: (slug?: string) => ['news-details', `news-details-${slug}`],
  productionDetails: (slug?: string) => ['production-details', `productions-details-${slug}`],
  locations: (params: string) => ['locations', params],
  locationsDetails: (slug?: string) => ['location-details', `locations-details-${slug}`],
  locationsEdit: (slug?: string) => [`locations-edit-${slug}`],
  myLocations: () => ['my-locations'],
  relatedLocations: (params?: GetRelatedLocationsInput) => ['related-locations', params],
  locationsBySlug: (slugs: string[]) => ['my-locations', slugs],
  myBranches: () => ['my-branches'],
  locationsFilters: (letter?: string) => ['locations-filters', letter],
  locationsForm: () => [`locations-form`],
  locationBookmarks: () => ['location-bookmarks'],
  translations: () => ['translations'],
  search: (keyword: string) => ['search', keyword],
};

export default QueryKeys;
