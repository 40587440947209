import { BranchItem } from '@api/branch';
import { ReactComponent as BranchePlaceholder } from '@assets/icons/branche_placeholder.svg';
import { ReactComponent as ArrowDown } from '@assets/icons/icons_arrow-down.svg';
import { ReactComponent as ArrowUp } from '@assets/icons/icons_arrow-up.svg';
import { ReactComponent as PlusIcon } from '@assets/icons/icons_plus.svg';
import Image from '@components/shared/Image';
import breakpoints from '@lib/common/breakpoints';
import strLimit from '@lib/helpers/strLimit';
import { useRouter } from '@lib/hooks/useRouter';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const StyledMyBranches = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.violet};
  padding: 120px 140px 120px 80px;
  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 60px 20px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 60px 20px;
  }
`;
const StyledMyBranchesTitle = styled.div`
  width: 100%;
  span {
    display: block;
    font-family: 'GTFlexa-ExtendedLight';
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    line-height: 1.17em;
    color: ${(props) => props.theme.colors.white};
    margin-bottom: 10px;
  }
  h3 {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 58px;
    line-height: 1.05em;
    color: ${(props) => props.theme.colors.white};
    margin-bottom: 80px;
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 24px;
      line-height: 1.3em;
      margin-bottom: 25px;
    }
    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 24px;
      line-height: 1.3em;
      margin-bottom: 25px;
    }
  }
`;
const StyledNoBranch = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-column-start: 2;
  grid-column-end: 4;
  padding-left: 30px;
  @media only screen and ${breakpoints.maxWidth.md} {
    grid-column-start: unset;
    grid-column-end: unset;
    padding-left: 0;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    grid-column-start: unset;
    grid-column-end: unset;
    padding-left: 0;
  }
  h3 {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-weight: 300;
    font-size: 36px;
    letter-spacing: 0.4px;
    line-height: 1.4em;
    color: ${(props) => props.theme.colors.white};
    margin-bottom: 40px;
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 18px;
    }
    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 18px;
    }
  }
  a {
    display: block;
    max-width: 467px;
    width: 100%;
    border: 1px solid ${(props) => props.theme.colors.white};
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-weight: 300;
    font-size: 22px;
    color: ${(props) => props.theme.colors.white};
    text-transform: uppercase;
    text-align: center;
    padding: 10px;
    transition: all 0.3s ease-in-out;
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 20px;
    }
    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 20px;
    }
    &:hover {
      background-color: ${(props) => props.theme.colors.white};
      color: ${(props) => props.theme.colors.black};
    }
  }
`;
const StyledNewBranch = styled.div`
  width: 100%;
  height: 270px;
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media only screen and ${breakpoints.maxWidth.md} {
    margin: 0 15px;
    width: calc(33.333% - 30px);
    height: 153px;
    max-width: 100%;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    max-width: 222px;
    height: 153px;
  }
  span {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 24px;
    line-height: 1.4em;
    text-align: center;
    color: ${(props) => props.theme.colors.black};
    display: flex;
    margin-top: 19px;
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 16px;
      line-height: 1.3em;
      margin-top: 10px;
    }
    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 16px;
      line-height: 1.3em;
      margin-top: 10px;
    }
  }
`;
const StyledPlusIcon = styled(PlusIcon)`
  width: 32px;
  height: 32px;
  path {
    stroke: ${(props) => props.theme.colors.black}!important;
  }
`;

const StyledList = styled.div`
  display: grid;
  grid-gap: 32px;
  grid-template-columns: repeat(3, 1fr);
  @media only screen and ${breakpoints.maxWidth.md} {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 0 !important;
    margin-left: -15px;
    margin-right: -15px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    grid-template-columns: unset;
    grid-gap: 30px;
  }
`;

const StyledBranch = styled(Link)<{ isDraft?: boolean }>`
  width: 100%;
  @media only screen and ${breakpoints.maxWidth.md} {
    width: 33.333%;
    padding: 0 15px;
    margin-bottom: 30px;
  }
  ${({ isDraft }) =>
    isDraft &&
    css`
      cursor: auto;
      opacity: 0.5;
    `}
  &:hover {
    img {
      transform: scale(1.05);
    }
  }
`;
const StyledBranchThumb = styled.div`
  width: 100%;
  height: 270px;
  margin-bottom: 24px;
  overflow: hidden;
  @media only screen and ${breakpoints.maxWidth.md} {
    height: 230px;
    margin-bottom: 15px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    height: 230px;
    margin-bottom: 15px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.3s ease-in-out;
  }
`;
const StyledBranchContent = styled.div`
  width: 100%;
  color: ${(props) => props.theme.colors.white};
  h4 {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 24px;
    line-height: 1.2em;
    margin-bottom: 8px;
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 18px;
    }
    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 18px;
    }
  }
  span {
    display: block;
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 1.3em;
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 16px;
    }
    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 14px;
    }
  }
`;
const StyledShowAll = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 0 21px;
  border-top: 2px solid ${(props) => props.theme.colors.white};
  border-bottom: 2px solid ${(props) => props.theme.colors.white};
  margin-top: 80px;
  cursor: pointer;
  @media only screen and ${breakpoints.maxWidth.md} {
    margin-top: 40px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    margin-top: 40px;
  }
  span {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 22px;
    line-height: 1.2em;
    color: ${(props) => props.theme.colors.white};
    text-transform: uppercase;
    margin-left: 10px;
  }
  svg {
    width: 18px;
    height: 18px;
    path {
      stroke: ${(props) => props.theme.colors.white}!important;
    }
  }
`;

const Placeholder = styled(StyledBranchThumb)`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 84px;
    height: 84px;
    opacity: 0.5;
  }
`;

interface Props {
  branches?: BranchItem[];
}

export const MyBranches = (props: Props) => {
  const { formatMessage: t } = useIntl();
  const router = useRouter();
  const [showMore, setShowMore] = useState(false);

  const temp = props?.branches ?? [];
  const data = showMore && temp.length > 2 ? temp : temp.slice(0, 2);
  return (
    <StyledMyBranches className="MyBranches">
      <StyledMyBranchesTitle>
        <span>{t({ id: 'branches' })}</span>
        <h3>{t({ id: 'my_branches' })}</h3>
      </StyledMyBranchesTitle>
      <StyledList>
        <StyledNewBranch onClick={() => router.history.push('/manage-branch/create')}>
          <StyledPlusIcon />
          <span>{t({ id: 'create_new_branch' })}</span>
        </StyledNewBranch>
        {(!data || data.length === 0) && (
          <StyledNoBranch>
            <h3>{t({ id: 'create_new_branch_title' })}</h3>
            <Link to="/manage-branch/create">{t({ id: 'branch_register' })}</Link>
          </StyledNoBranch>
        )}
        {data.map((el) => {
          const isDraft = el.post_status === 'draft';
          return (
            <StyledBranch
              key={`my-branch-${el.id}`}
              to={`/branches/${el.slug}`}
              isDraft={isDraft}
              onClick={(e) => {
                isDraft && e.preventDefault();
              }}
            >
              {el.thumbnails.medium || el.thumbnail ? (
                <StyledBranchThumb>
                  <Image src={el.thumbnails.medium || el.thumbnail} alt={el.title} />
                </StyledBranchThumb>
              ) : (
                <Placeholder>
                  <BranchePlaceholder />
                </Placeholder>
              )}
              <StyledBranchContent>
                <h4>{el.title}</h4>
                {el.description && <span>{strLimit(el.description, 130)}</span>}
              </StyledBranchContent>
            </StyledBranch>
          );
        })}
      </StyledList>
      {temp && temp.length > 2 && (
        <StyledShowAll onClick={() => setShowMore(!showMore)}>
          {showMore ? <ArrowUp /> : <ArrowDown />}
          <span>{showMore ? t({ id: 'show_less' }) : t({ id: 'show_all' })}</span>
        </StyledShowAll>
      )}
    </StyledMyBranches>
  );
};
