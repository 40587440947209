import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';

export interface FilmingFields {
  //step1
  first_name: string;
  last_name: string;
  company_name: string;
  address: string;
  phone: string;
  email: string;
  recording_manager_first_name: string;
  recording_manager_last_name: string;
  recording_manager_phone: string;
  recording_manager_email: string;

  //step2
  production_title: string;
  type_of_production: string[] | any;
  scene_description: string;
  synopsis: number; //image
  technical_effort: string;
  number_of_people_on_crew: string;
  number_of_people_cast: string;

  //step3
  shooting_date: string;
  replacement_date: string;
  time_of_construction: string;
  time_of_dismantling: string;
  start_of_shooting_time: string;
  end_of_rotation_time: string;

  //step4
  location: string;
  adaptation_of_location: string;
  type_of_adaption: string;
  owner_was_contacted: string;
  location_contact_person_name: string;
  location_contact_person_phone: string;
  location_contact_person_email: string;
  city_map_screenshot: number; //image
  data_protection: boolean;
  date: string;
  name_signature: string;
}

interface UseBranchFormOptions {
  initialValues?: FilmingFields;
  onSubmit: (values: FilmingFields, formikHelpers: FormikHelpers<FilmingFields>) => Promise<any>;
}

export const useFilmingFormik = (options: UseBranchFormOptions) => {
  const BranchSchema = Yup.object().shape({
    //step1
    first_name: Yup.string().required('First name is required'),
    last_name: Yup.string().required('Last name is required'),
    // company_name: Yup.string().required('Company is required'),
    address: Yup.string().required('Address is required'),
    phone: Yup.string().required('Phone is required'),
    email: Yup.string().required('email is required'),
    recording_manager_first_name: Yup.string().required('Manager first name is required'),
    recording_manager_last_name: Yup.string().required('Manager last name is required'),
    recording_manager_phone: Yup.string().required('Manager phone is required'),
    recording_manager_email: Yup.string().required('Manager email is required'),

    //step2
    production_title: Yup.string().required('Production title is required'),
    type_of_production: Yup.array().min(1, 'Art der Produktion is required').required('Art der Produktion is required'),
    scene_description: Yup.string().max(1000, 'Scene description has a limit of max of 1000 characters!').required('Scene description is required'),
    synopsis: Yup.number().min(0, 'Synopsis is required').required('Synopsis is required'),
    technical_effort: Yup.string().max(1000, 'Technical effort has a limit of max of 1000 characters!').required('Technical effort is required'),
    number_of_people_on_crew: Yup.string().required('Number of team is required'),
    number_of_people_cast: Yup.string().required('Number of cast is required'),

    //step3
    shooting_date: Yup.string().required('Shooting date is required'),
    replacement_date: Yup.string().required('Replacement date is required'),
    time_of_construction: Yup.string().required('Time of construction is required'),
    time_of_dismantling: Yup.string().required('Time of dismantling is required'),
    start_of_shooting_time: Yup.string().required('Start of shooting time is required'),
    end_of_rotation_time: Yup.string().required('End of shooting time is required'),

    //step4
    location: Yup.string().max(1000, 'Location has a limit of max of 1000 characters!').required('Location is required'),
    adaptation_of_location: Yup.string().required('Adaptation of the location is required'),
    type_of_adaption: Yup.string().when('adaptation_of_location', {
      is: (val: string) => val === 'JA',
      then: Yup.string().required('Type of adaptation is required'),
    }),
    owner_was_contacted: Yup.string().required('Has the owner been contacted is required'),
    location_contact_person_name: Yup.string().when('owner_was_contacted', {
      is: (val: string) => val === 'JA',
      then: Yup.string().required('Location contact person is required'),
    }),
    location_contact_person_phone: Yup.string().when('owner_was_contacted', {
      is: (val: string) => val === 'JA',
      then: Yup.string().required('Location contact phone is required'),
    }),
    location_contact_person_email: Yup.string().when('owner_was_contacted', {
      is: (val: string) => val === 'JA',
      then: Yup.string().required('Location contact email is required'),
    }),
    // city_map_screenshot: Yup.number().required('City map screenshot is required'), //image
    data_protection: Yup.bool().oneOf([true]).required('Data protection is required'),
    date: Yup.string().required('Date is required'),
    name_signature: Yup.string().required('Name Signature is required'),
  });

  return useFormik({
    initialValues: {
      //step1
      first_name: '',
      last_name: '',
      company_name: '',
      address: '',
      phone: '',
      email: '',
      recording_manager_first_name: '',
      recording_manager_last_name: '',
      recording_manager_phone: '',
      recording_manager_email: '',

      //step2
      production_title: '',
      type_of_production: [],
      scene_description: '',
      synopsis: 0, //image
      technical_effort: '',
      number_of_people_on_crew: '',
      number_of_people_cast: '',

      //step3
      shooting_date: '',
      replacement_date: '',
      time_of_construction: '',
      time_of_dismantling: '',
      start_of_shooting_time: '',
      end_of_rotation_time: '',

      //step4
      location: '',
      adaptation_of_location: '',
      type_of_adaption: '',
      owner_was_contacted: '',
      location_contact_person_name: '',
      location_contact_person_phone: '',
      location_contact_person_email: '',
      city_map_screenshot: 0, //image
      data_protection: false,
      date: '',
      name_signature: '',
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: BranchSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type FilmingFormik = ReturnType<typeof useFilmingFormik>;
