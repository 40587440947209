import breakpoints from '@lib/common/breakpoints';
import { BranchCategoryFormik } from '@lib/hooks/formik/useBranchCategoryFormik';
import { BranchContactFormik } from '@lib/hooks/formik/useBranchContactFormik';
import { BranchContactSecondaryFormik } from '@lib/hooks/formik/useBranchContactSecondaryFormik';
import { BranchDataFormik } from '@lib/hooks/formik/useBranchDataFormik';
import useGetBranchForm from '@lib/hooks/queries/useGetBranchForm';
import { StyledPageHeader } from '@pages/manage-branch/CreateBranch';
import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { BranchCategory } from './BranchCategory';
import { BranchComplete } from './BranchComplete';
import { BranchContact } from './BranchContact';
import { BranchContactSecondary } from './BranchContactSecondary';
import { BranchData } from './BranchData';

const StyledForm = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  padding: 80px 150px 120px;
  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 80px 24px 50px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 80px 24px 50px;
  }
`;
const StyledFormTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-bottom: 24px;
  @media only screen and ${breakpoints.maxWidth.md} {
    align-items: flex-start;
    flex-direction: column;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    align-items: flex-start;
    flex-direction: column;
  }
  h3 {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 36px;
    letter-spacing: 0.4px;
    line-height: 1.2em;
    color: ${(props) => props.theme.colors.black};
    margin-right: 20px;
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 24px;
      letter-spacing: 0.2px;
      margin-right: 0;
    }
    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 24px;
      letter-spacing: 0.2px;
      margin-right: 0;
    }
  }
  a {
    font-family: 'GTFlexa-StandardMedium';
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 1.24em;
    color: ${(props) => props.theme.colors.black};
    text-decoration: underline;
    margin-bottom: 5px;
    @media only screen and ${breakpoints.maxWidth.md} {
      margin-top: 5px;
    }
    @media only screen and ${breakpoints.maxWidth.sm} {
      margin-top: 5px;
    }
  }
`;

const StyledStepDots = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
  @media only screen and ${breakpoints.maxWidth.md} {
    margin-bottom: 30px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    margin-bottom: 30px;
  }
`;

interface IStyledStepDot {
  filled?: boolean;
}
const StyledStepDot = styled.div<IStyledStepDot>`
  width: 13px;
  height: 13px;
  border: 2px solid ${(props) => props.theme.colors.black};
  margin-right: 30px;
  @media only screen and ${breakpoints.maxWidth.md} {
    width: 8px;
    height: 8px;
    margin-right: 20px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    width: 8px;
    height: 8px;
    margin-right: 20px;
  }
  &:last-child {
    margin-right: 0;
  }
  ${({ filled }) =>
    filled &&
    css`
      background-color: ${(props) => props.theme.colors.black};
    `}
`;

export enum BRANCH_STEP {
  BRANCH_CATEGORY = 1,
  BRANCH_DATA = 2,
  CONTACT_PERSON = 3,
  CONTACT_PERSON_SECONDARY = 4,
  THANK_YOU = 5,
}

interface Props {
  step: BRANCH_STEP;
  branchCategoryFormik: BranchCategoryFormik;
  branchDataFormik: BranchDataFormik;
  branchContactFormik: BranchContactFormik;
  branchContactSecondaryFormik: BranchContactSecondaryFormik;
  setStep: React.Dispatch<React.SetStateAction<BRANCH_STEP>>;
}

export const BranchForm = ({ step, branchCategoryFormik, branchDataFormik, branchContactFormik, branchContactSecondaryFormik, setStep }: Props) => {
  const { isLoading, data } = useGetBranchForm();
  const { formatMessage: t } = useIntl();

  const steps = [
    {
      name: `1. ${t({ id: 'branch_registrations_step1_title' })}`,
      description: t({ id: 'branch_registrations_step1_description' }),
    },
    { name: `2. ${t({ id: 'branch_registrations_step2_title' })}` },
    { name: `3. ${t({ id: 'branch_registrations_step3_title' })}` },
    { name: `4. ${t({ id: 'branch_registrations_step4_title' })}` },
    { name: t({ id: 'branch_registrations_success_title' }), description: t({ id: 'branch_registrations_success_description' }) },
  ];

  const renderActiveStep = () => {
    switch (step) {
      case BRANCH_STEP.BRANCH_CATEGORY:
        return <BranchCategory setStep={setStep} formik={branchCategoryFormik} data={data} />;
      case BRANCH_STEP.BRANCH_DATA:
        return <BranchData setStep={setStep} formik={branchDataFormik} />;
      case BRANCH_STEP.CONTACT_PERSON:
        return <BranchContact setStep={setStep} formik={branchContactFormik} />;
      case BRANCH_STEP.CONTACT_PERSON_SECONDARY:
        return <BranchContactSecondary setStep={setStep} formik={branchContactSecondaryFormik} />;
      case BRANCH_STEP.THANK_YOU:
        return <BranchComplete />;
      default:
        return null;
    }
  };

  return (
    <>
      <StyledPageHeader>
        <h1>{t({ id: 'branch_registration_title1' })}</h1>
        {steps[step - 1]?.description && <p>{steps[step - 1]?.description}</p>}
      </StyledPageHeader>
      <StyledForm className="BranchForm">
        {step !== BRANCH_STEP.THANK_YOU && (
          <StyledStepDots>
            {steps
              .filter((s, i) => i !== steps.length - 1)
              .map((el, index) => {
                return <StyledStepDot key={`branch-dot-${index}`} filled={index + 1 <= step} />;
              })}
          </StyledStepDots>
        )}
        <StyledFormTitle>
          <h3>{steps[step - 1]?.name}</h3>
        </StyledFormTitle>

        {isLoading ? <p>Loading...</p> : renderActiveStep()}
      </StyledForm>
    </>
  );
};
