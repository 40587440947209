import { Container } from '@components/shared/Container';
import Image from '@components/shared/Image';
import breakpoints from '@lib/common/breakpoints';
import styled from 'styled-components';

const Module13Wrapper = styled.div`
  padding-top: 107px;
  padding-bottom: 50px;

  @media only screen and ${breakpoints.maxWidth.lg} {
    padding-top: 50px;
  }

  background-color: ${(props) => props.theme.colors.violet};
`;

const Module13Container = styled.div`
  margin: 0;
  padding-left: 0;
  padding-right: 0;
`;

const Title = styled.h1`
  max-width: 700px;
  font-family: 'GTFlexa-ExtendedMedium', sans-serif;
  font-weight: normal;
  font-size: 58px;
  line-height: 1.03;
  color: #ffffff;

  @media only screen and ${breakpoints.maxWidth.lg} {
    font-size: 24px;
    line-height: 1.17;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    font-size: 20px;
  }
`;

const TeamCol = styled.div<{ isEven?: boolean }>`
  width: 50%;
  padding: 55px 60px 53px 0;
  border-bottom: 2px solid #ffffff;

  &:nth-last-child(-n + ${(props) => (props.isEven ? 2 : 1)}) {
    border-bottom: none;
  }

  &:before {
    content: '';
    width: 100%;
    height: 2px;
    background-color: #ffffff;
    margin-bottom: -2px;
    left: 100%;
    bottom: 0;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #ffffff;
    margin-bottom: -2px;
    right: 100%;
    bottom: 0;
  }

  @media only screen and ${breakpoints.maxWidth.lg} {
    width: 100%;
    border: none;
    margin-bottom: 30px;
    padding: 0;
  }
`;

const TeamMembers = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 70px;
  @media only screen and ${breakpoints.maxWidth.lg} {
    margin-top: 26px;
  }
`;

const TeamMember = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
`;

const TeamMemberImage = styled.div`
  min-width: 290px;
  width: 290px;
  height: 217px;
  margin-right: 36px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    min-width: 105px;
    width: 105px;
    height: 80px;
    margin-right: 12px;
  }
`;

const TeamMemberInfo = styled.div`
  width: 100%;
  max-width: 403px;

  @media only screen and ${breakpoints.maxWidth.md} {
    max-width: 100%;
  }
`;

const TeamMemberInfoText = styled.p`
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.22;
  letter-spacing: 0.5px;
  color: #ffffff;
  word-break: break-word;

  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.25;
    letter-spacing: 0;
  }
`;

const TeamMemberName = styled(TeamMemberInfoText)`
  font-family: 'GTFlexa-ExtendedMedium', sans-serif;
  margin-bottom: 25px;
  font-size: 24px;

  @media only screen and ${breakpoints.maxWidth.lg} {
    font-size: 16px;
    margin-bottom: 15px;
  }
`;

const TeamMemberPosition = styled(TeamMemberInfoText)`
  margin-bottom: 25px;

  @media only screen and ${breakpoints.maxWidth.lg} {
    margin-bottom: 15px;
  }
`;

interface Props {
  data?: {
    posts?: Array<object>;
    title?: string;
  };
}

export default function Module13(props: Props) {
  const { data } = props;

  const teamData = data?.posts ?? [];

  const isEven = teamData?.length % 2 === 0;

  return (
    <Module13Wrapper data-module="m13">
      <Container>
        <Module13Container>
          <Title>{data?.title}</Title>
        </Module13Container>

        <TeamMembers>
          {teamData.map((member: any) => {
            const emailData = member?.email?.split('@');
            return (
              <TeamCol key={member.ID} isEven={isEven}>
                <TeamMember>
                  <TeamMemberImage>
                    <Image src={member?.thumbnail} />
                  </TeamMemberImage>
                  <TeamMemberInfo>
                    <TeamMemberName>{member?.post_title}</TeamMemberName>

                    <TeamMemberPosition>{member?.job_position}</TeamMemberPosition>

                    <TeamMemberInfoText>{member?.phone}</TeamMemberInfoText>
                    {member.email && (
                      <a href={`mailto:${member.email}`}>
                        <TeamMemberInfoText>{emailData?.[0]}@</TeamMemberInfoText>
                        <TeamMemberInfoText>{emailData[1]}</TeamMemberInfoText>
                      </a>
                    )}
                  </TeamMemberInfo>
                </TeamMember>
              </TeamCol>
            );
          })}
        </TeamMembers>
      </Container>
    </Module13Wrapper>
  );
}
