import { useEffect, useState } from 'react';

export default function useElementPosition(ref?: any): any {
  const [position, setPosition] = useState({});

  const handleScroll = () => {
    if (!ref?.current) {
      return;
    }

    const elementPosition = ref.current.getBoundingClientRect();
    setPosition(elementPosition);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return position;
}
