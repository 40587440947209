import { ReactComponent as ArrowLeft } from '@assets/icons/icons_arrow-left.svg';
import { ReactComponent as ArrowRight } from '@assets/icons/icons_arrow-right.svg';
import Image from '@components/shared/Image';
import breakpoints from '@lib/common/breakpoints';
import { useRef } from 'react';
import { default as ReactSlick } from 'react-slick';
import styled from 'styled-components';

interface ElementProps {
  withHeader?: boolean;
}
const Wrapper = styled.div<ElementProps>`
  width: 100%;
  height: ${(props) => (props.withHeader ? '100vh' : 'auto')};
  position: relative;
`;

const Module07Container = styled.div<ElementProps>`
  overflow: hidden;
  height: ${(props) => (props.withHeader ? '100%' : 'auto')};

  .slick-slider {
    height: ${(props) => (props.withHeader ? '100%' : null)};
  }
`;

const Slider = styled(ReactSlick)<ElementProps>`
  .slick-list {
    margin-right: -4px;
    margin-bottom: -4px;
  }

  .slick-list,
  .slick-track,
  .slick-item,
  .slick-slide > div {
    height: ${(props) => (props.withHeader ? '100%' : null)};
  }
`;

const SliderItem = styled.div<ElementProps>`
  position: relative;
  width: 100%;
  height: ${(props) => (props.withHeader ? '100%' : '649px')};
  margin: 0;
  padding: 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media only screen and ${breakpoints.maxWidth.md} {
    height: ${(props) => (props.withHeader ? '100%' : '346px')};
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    height: ${(props) => (props.withHeader ? '100%' : '222px')};
  }
`;

const Title = styled.p<{ fullScreen?: boolean }>`
  position: absolute;
  right: 30px;
  bottom: ${(props) => (props.fullScreen ? '30px' : '30px')};
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 1.22;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.colors.white};

  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 16px;
    line-height: 1.13;
    right: 20px;
    bottom: 11px;
  }

  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 12px;
    line-height: 1.83;
    right: 20px;
    bottom: 14px;
  }
`;

const SliderArrowLeft = styled(ArrowLeft)`
  z-index: 1;
  width: 34px;
  height: 36px;
  top: 45%;
  left: 116px;
  transition: 0.1s;
  transform: none;

  path {
    stroke: #ffffff;
  }

  &:hover {
    transform-origin: center;
    transform: scale(0.8);
  }

  @media only screen and ${breakpoints.maxWidth.md} {
    left: 40px !important;
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    left: 20px !important;
  }
`;
const SliderArrowRight = styled(ArrowRight)`
  z-index: 1;
  width: 34px;
  height: 36px;
  right: 116px;
  top: 45%;
  transition: 0.1s;
  transform: none;

  path {
    stroke: #ffffff;
  }

  &:hover {
    transform-origin: center;
    transform: scale(0.8);
  }

  @media only screen and ${breakpoints.maxWidth.md} {
    right: 40px !important;
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    right: 20px !important;
  }
`;

interface Images {
  image?: string;
  text?: string;
}

interface Module07Props {
  withHeader?: boolean;
  forceBottom?: boolean;
  data?: {
    full_screen?: boolean;
    images?: Images[];
  };
}

export default function Module07(props: Module07Props) {
  const { data /*forceBottom*/ } = props;

  const ref = useRef<any>(null);
  // const position = useHeaderPosition(ref);

  const settings = {
    center: true,
    fade: false,
    dots: false,
    infinite: true,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  const sliderImages = data?.images ?? [];
  // const headerPosition = position === 'absolute' ? 'relative' : position;
  const withHeader = props.withHeader || data?.full_screen;

  return (
    // <Wrapper data-module="m07" withHeader={withHeader}>
    <Wrapper data-module="m07">
      <Module07Container ref={ref}>
        <Slider {...settings}>
          {sliderImages?.map((img) => (
            <SliderItem key={img.image}>
              <Image src={img.image} />
              <Title fullScreen={withHeader}>{img.text}</Title>
            </SliderItem>
          ))}
        </Slider>
      </Module07Container>
      {/*{withHeader && <Header forceBottom={forceBottom} position={position} />}*/}
    </Wrapper>
  );
}

function PrevArrow(props: any) {
  const { className, onClick } = props;
  return <SliderArrowLeft className={className} onClick={onClick} />;
}

function NextArrow(props: any) {
  const { className, onClick } = props;
  return <SliderArrowRight className={className} onClick={onClick} />;
}
