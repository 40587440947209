import quoteIcon from '@assets/icons/icons_quotes.svg';
import { Container } from '@components/shared/Container';
import breakpoints from '@lib/common/breakpoints';
import { default as ReactSlick } from 'react-slick';
import styled from 'styled-components';

const Module11Wrapper = styled.div`
  margin: 0 auto;
  padding: 120px 0;
  overflow: hidden;
  max-width: 1029px;

  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 80px 20px;
  }
`;

const Slider = styled(ReactSlick)`
  .slick-dots {
    margin-top: 30px;
    li {
      margin: 0 19px 0 0;
      padding: 0;
      button {
        width: 7px;
        height: 7px;
        padding: 0;

        &:before {
          opacity: 1;
          content: '';
          width: 7px;
          height: 7px;
          border: 2px solid #000000;
        }
      }

      &.slick-active {
        button:before {
          background: #000000;
        }
      }
    }
  }
`;

const SliderItem = styled.div`
  position: relative;
  width: 100%;
  margin: 0 0 30px 0;
  padding: 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Text = styled.p`
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  font-size: 36px;
  font-weight: normal;
  line-height: 1.17;
  letter-spacing: 0.4px;
  &:before {
    display: inline-block;
    content: '';
    width: 40px;
    height: 40px;
    margin-right: 14px;
    background-image: url(${quoteIcon});
    background-repeat: no-repeat;
  }
  &:after {
    position: relative;
    display: inline-block;
    content: '';
    width: 40px;
    height: 40px;
    top: 22px;
    margin-left: 10px;
    transform: rotate(180deg);
    background-image: url(${quoteIcon});
    background-repeat: no-repeat;
  }

  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 18px;
    line-height: 1.22;
    letter-spacing: 0.2px;
    color: #000;

    &:after {
      display: block;
      margin-top: 20px;
      top: 0;
      margin-left: 0;
    }

    &:before {
      display: block;
      margin-bottom: 20px;
    }
  }
`;

const Author = styled.p`
  display: block;
  margin-top: 45px;
  font-family: 'GTFlexa-ExtendedMedium', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 1px;
  text-align: center;
  color: #000;
  text-transform: uppercase;
`;

interface Props {
  data?: {
    quotes?: Array<{ quote?: string; author?: string }>;
  };
}

export default function Module11(props: Props) {
  const settings = {
    center: true,
    fade: false,
    dots: true,
    infinite: true,
    arrows: true,
    autoplay: false,
    autoplaySpeed: 4000,
    adaptiveHeight: true,
  };

  const quotes = props.data?.quotes ?? [];

  return (
    <Container>
      <Module11Wrapper data-module="m11">
        <Slider {...settings}>
          {quotes?.map(({ quote, author }, index) => {
            return (
              <SliderItem key={`quote-${index}`}>
                <Text>{quote}</Text>
                <Author>{author}</Author>
              </SliderItem>
            );
          })}
        </Slider>
      </Module11Wrapper>
    </Container>
  );
}
