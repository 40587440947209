import React, { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  max-width: 900px;
  color: ${(props) => props.theme.colors.black};
  font,
  a,
  span {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: 0.2px;
  }
  a {
    color: black;
    &:hover {
      color: ${(props) => props.theme.colors.red};
    }
  }
  p {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: inherit;
    line-height: 1.25;
  }
  li {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: inherit;
    margin-bottom: 5px;
    line-height: 1.25;
  }
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    line-height: 1.3em;
    color: inherit;
    margin-bottom: 15px;
  }
  h2 {
    font-size: 38px;
  }
  h3 {
    font-size: 32px;
  }
  h4 {
    font-size: 24px;
  }
  h5 {
    font-size: 20px;
  }
  h6 {
    font-size: 16px;
  }
`;

interface IRenderWordPressContentProps {
  className?: string;
  content?: string;
}

const RenderWordPressContent: FC<IRenderWordPressContentProps> = (props) => {
  return <Wrapper className={props.className} dangerouslySetInnerHTML={{ __html: props?.content || '' }} />;
};

export default RenderWordPressContent;
