import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';

export interface LocationDataFields {
  company: string;
  street: string;
  postcode: string;
  ort: string;
  location_contact_person: string;
  email: string;
  email_confirm: string;
  phone: string;
}

interface UseLocationFormOptions {
  initialValues?: LocationDataFields;
  onSubmit: (values: LocationDataFields, formikHelpers: FormikHelpers<LocationDataFields>) => Promise<any>;
}

export const useLocationDataFormik = (options: UseLocationFormOptions) => {
  const LocationSchema = Yup.object().shape({
    street: Yup.string().required('Address is required'),
    postcode: Yup.string().required('PLZ is required'),
    ort: Yup.string().required('ORT is required'),
    location_contact_person: Yup.string().required('Contact person is required'),
    email: Yup.string().email().required('Email is required'),
    email_confirm: Yup.string().email().required('Email confirm is required'),
    phone: Yup.string().required('Phone is required'),
  });

  return useFormik({
    initialValues: {
      company: '',
      street: '',
      postcode: '',
      ort: 'Wien',
      location_contact_person: '',
      email: '',
      email_confirm: '',
      phone: '',
      ...options.initialValues,
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: LocationSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type LocationDataFormik = ReturnType<typeof useLocationDataFormik>;
