import { Container } from '@components/shared/Container';
import HandleFetchingState from '@components/shared/HandleFetchingState';
import LoadMoreButton from '@components/shared/LoadMoreButton';
import ShowreelList from '@components/showreels/ShowreelList';
import breakpoints from '@lib/common/breakpoints';
import useGetPage from '@lib/hooks/queries/useGetPage';
import useGetShowreels from '@lib/hooks/queries/useGetShowreels';
import useModules from '@lib/hooks/useModules';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';

const ShowreelsLayout = styled.div`
  // div[data-module='acf/m03-full-width-text'] {
  //   margin-top: 94px;
  //   @media only screen and ${breakpoints.maxWidth.md} {
  //     margin-top: 62px;
  //   }
  //   p {
  //     max-width: 1115px;
  //   }
  // }
`;

const ShowreelsListWrapper = styled.div`
  position: relative;
  padding-top: 120px;
  padding-bottom: 120px;
  margin-left: -27px;
  margin-right: -27px;

  @media only screen and ${breakpoints.maxWidth.md} {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    padding: 60px 15px;
  }
`;

interface ShowreelsProps {
  slug?: string;
}

export default function Showreels(props: ShowreelsProps) {
  const { getModuleByName } = useModules();
  const { isLoading, data } = useGetPage(props.slug);

  const { data: showreelsData, isFetching, hasNextPage, fetchNextPage } = useGetShowreels();

  const renderModules = () => {
    const blocks = data?.blocks ?? [];
    return blocks
      ?.map((block: any, index: number) => {
        const moduleProps = block?.attrs?.data;
        const Module = getModuleByName(block?.blockName);

        if (!Module) {
          return null;
        }
        return <Module key={`module-${block?.blockName}-${index}`} data={moduleProps} />;
      })
      .filter((m: any) => Boolean(m));
  };

  const allShowreels = showreelsData?.pages.map((item) => item.showreels).flat() ?? [];

  return (
    <HandleFetchingState loading={isLoading}>
      <ShowreelsLayout>
        {renderModules()}
        <InfiniteScroll hasMore={Boolean(hasNextPage)} dataLength={allShowreels.length} next={fetchNextPage} loader={null} scrollThreshold={1}>
          <Container>
            <ShowreelsListWrapper>
              <ShowreelList items={allShowreels} />
              {isFetching && <LoadMoreButton isLoading />}
            </ShowreelsListWrapper>
          </Container>
        </InfiniteScroll>
      </ShowreelsLayout>
    </HandleFetchingState>
  );
}
