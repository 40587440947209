import { ReactComponent as SmallArrow } from '@assets/icons/icons_arrow-down_small.svg';
import { ReactComponent as CloseIcon } from '@assets/icons/icons_close_small.svg';
import { Container } from '@components/shared/Container';
import { SimpleButton } from '@components/styled-components/StyledComponents';
import breakpoints from '@lib/common/breakpoints';
import generateAlphabet from '@lib/helpers/generateAlphabet';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

const Wrapper = styled.div``;

// toggle button
const FiltersToggleButton = styled.div<{ isOnSearch?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  cursor: pointer;
  padding: 20px 15px;
  color: #ffffff;
  svg {
    transition: 0.2s;
    width: ${(props) => (props.isOnSearch ? '18px' : '28px')};
    height: ${(props) => (props.isOnSearch ? '18px' : '28px')};

    path {
      stroke: white;
    }
  }
  p {
    font-size: ${(props) => (props.isOnSearch ? '24px' : '48px')};
    font-weight: normal;
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    line-height: 1.25;
    text-align: center;
    margin-left: 8px;
    text-transform: uppercase;
  }

  @media only screen and ${breakpoints.maxWidth.md} {
    p {
      font-size: 25px;
      line-height: 1.25;
    }

    svg {
      width: 19px;
      height: 19px;
    }
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 14px 15px 8px 15px;

    p {
      font-size: 22px;
      line-height: 1.36;
    }
  }
`;
const FiltersWrapper = styled.div`
  padding-top: 65px;
  padding-bottom: 73px;
  background-color: #000000;
  border-top: 1px solid #707070;

  @media only screen and ${breakpoints.maxWidth.md} {
    padding-top: 20px;
  }
`;

// alphabet
const AlphabetWrapper = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;

  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 0;
    overflow-x: auto;
  }
`;
const AlphabetItem = styled.li<{ disabled?: boolean; active?: boolean }>`
  display: flex;
  align-items: flex-end;
  height: 60px;
  cursor: pointer;
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  font-weight: normal;
  line-height: 1.25;
  letter-spacing: 22.9px;
  color: #fff;
  flex: 1;
  transition: 0.2s;

  opacity: ${(props) => (props.disabled ? '0.3' : null)};
  font-size: ${(props) => (props.active ? '48px' : '16px')};

  &:hover {
    font-size: ${(props) => (props.disabled ? null : '48px')};
  }
`;

// filters
const FilterItemsWrapper = styled.div`
  margin-top: 18px;
  margin-bottom: 36px;

  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 0;
  }
`;
const FilterItemsRow = styled.div<{ noData?: boolean }>`
  display: flex;
  padding-top: 40px;
  padding-bottom: 40px;
  color: #ffffff;
  border-top: 2px solid #ffffff;
  border-bottom: ${(props) => (props.noData ? null : '2px solid #ffffff')};

  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 24px 40px;
    flex-direction: column;
  }
  @media only screen and ${breakpoints.maxWidth.xs} {
    padding: 20px 20px;
  }
`;
const FilterItemsCol = styled.div`
  width: 50%;
  padding-right: 108px;

  @media only screen and ${breakpoints.maxWidth.md} {
    padding-right: 0;
    width: 100%;
  }
`;
const FilterItemsList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const FilterItemsListItem = styled.li<{ isSelected?: boolean }>`
  cursor: pointer;
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
  align-items: end;
  span {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 36px;
    line-height: 1.27;
    letter-spacing: 0.4px;
    color: ${(props) => (props.isSelected ? props.theme.colors.red : '#ffffff')};
  }
  span:first-of-type {
    margin-right: 10px;
    flex: 1;
  }

  @media only screen and ${breakpoints.maxWidth.lg} {
    span {
      font-size: 25px;
      letter-spacing: 0.3px;
    }
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    span {
      font-size: 30px;
      letter-spacing: 0.3px;
    }
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    span {
      font-size: 16px;
      line-height: 1.5;
    }
  }
`;

// tags
const TagsWrapper = styled(Container)``;
const TagsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 50px 0;
  @media only screen and ${breakpoints.maxWidth.md} {
    gap: 0;
    flex-direction: column;
  }
`;
const TagsCol = styled.div<{ fullWidth: boolean }>`
  width: 50%;
  padding-right: 40px;
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      &:first-of-type {
        width: 100%;
      }
    `}
  @media only screen and ${breakpoints.maxWidth.md} {
    width: 100%;
    padding-right: 0;
  }
`;
const TagsTitle = styled.h6`
  font-family: 'GTFlexa-ExtendedMedium', sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.25;
  letter-spacing: 1px;
  color: #fff;
  text-transform: uppercase;
`;
const TagsList = styled.ul`
  margin: 26px 0 0 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  @media only screen and ${breakpoints.maxWidth.md} {
    gap: 0;
    margin-bottom: 25px;
    margin-right: -20px;
  }
`;
const TagsListItem = styled.li<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid #ffffff;
  background-color: ${(props) => (props.selected ? '#ffffff' : null)};
  @media only screen and ${breakpoints.maxWidth.md} {
    margin-right: 20px;
    margin-bottom: 20px;
  }

  span {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 18px;
    font-weight: normal;
    line-height: 1.22;
    letter-spacing: 0.5px;
    color: ${(props) => (props.selected ? '#000000' : '#ffffff')};

    @media only screen and ${breakpoints.maxWidth.xs} {
      font-size: 16px;
    }
  }

  button {
    margin-left: 14px;
    display: ${(props) => (props.selected ? 'flex' : 'none')};
    svg {
      width: 14px;
      height: 14px;
    }
  }
`;

export interface IFilters {
  [key: string]: {
    label: string;
    values: {
      [key: string]: string;
    };
  };
}

export interface ISelectedFilters {
  [key: string]: {
    [key: string]: string | undefined;
  };
}

interface FilterCategories {
  label: string;
  values: Array<{
    label: string;
    value: string;
    count?: string;
  }>;
}

interface IFiltersProps {
  isOnSearch?: boolean;
  firstElementFull?: boolean;
  className?: string;
  selectedLetter?: string;
  letters?: string[];
  categories?: FilterCategories;
  selectedCategory?: string;
  filters?: IFilters;
  selectedFilters?: ISelectedFilters;
  onFiltersChange?: (keyEl: string, el: string) => void;
  onSelectLetterChange?: (letter?: string) => void;
  onCategoriesChange?: (category: string) => void;
}

const getCategories = (categories: FilterCategories['values'], selectedLetter?: string) => {
  const filteredCategories = categories?.filter((category: any) => category?.value?.toLowerCase()?.startsWith(selectedLetter?.toLowerCase()));
  const half = Math.ceil(filteredCategories.length / 2);
  return {
    firstCategories: filteredCategories.slice(0, half),
    secondCategories: filteredCategories.slice(half, filteredCategories.length),
  };
};

const filtersKey = 'vfcIsFiltersToggled';

const Filters: FC<IFiltersProps> = (props) => {
  const { selectedLetter } = props;
  const [opened, setOpened] = useState(() => {
    const value = localStorage.getItem(filtersKey);
    return value === 'true';
  });

  const { formatMessage: t } = useIntl();

  useEffect(() => {
    localStorage.setItem(filtersKey, String(opened));
  }, [opened]);

  const toggleOpened = () => setOpened(!opened);

  const alphabetItems = useMemo(() => generateAlphabet(), []);
  const filters = !!props.filters && props.filters;
  const categories = getCategories(props.categories?.values ?? [], selectedLetter);

  return (
    <Wrapper className={props.className}>
      <FiltersToggleButton role="button" onClick={toggleOpened} isOnSearch={props.isOnSearch}>
        <SmallArrow style={{ transform: opened ? 'rotate(180deg)' : 'inherit' }} />
        <p>{opened ? t({ id: 'filter.schliessen' }) : t({ id: 'filter.zeigen' })}</p>
      </FiltersToggleButton>

      {opened && (
        <FiltersWrapper>
          {!props.letters ||
            (props.letters.length === 0 ? null : (
              <Container>
                <AlphabetWrapper>
                  {alphabetItems.map((item) => {
                    const active = item?.toLowerCase() === selectedLetter?.toLowerCase();
                    const disabled = !props.letters?.includes(item);
                    return (
                      <AlphabetItem
                        disabled={disabled}
                        active={active}
                        key={item}
                        onClick={() => {
                          if (disabled) {
                            return;
                          }
                          props.onSelectLetterChange?.(item);
                        }}
                      >
                        {item}
                      </AlphabetItem>
                    );
                  })}
                </AlphabetWrapper>

                <FilterItemsWrapper>
                  <>
                    <FilterItemsRow noData={!categories?.firstCategories?.length}>
                      <FilterItemsCol>
                        <FilterItemsList>
                          {categories?.firstCategories?.map((category) => {
                            const isSelected = props.selectedCategory === category?.value;
                            return (
                              <FilterItemsListItem
                                key={category.value}
                                isSelected={Boolean(isSelected)}
                                onClick={() => props.onCategoriesChange?.(category.value)}
                              >
                                <span dangerouslySetInnerHTML={{ __html: category.label ?? '' }} />
                                <span>{category?.count}</span>
                              </FilterItemsListItem>
                            );
                          })}
                        </FilterItemsList>
                      </FilterItemsCol>
                      <FilterItemsCol>
                        <FilterItemsList>
                          {categories?.secondCategories?.map((category) => {
                            const isSelected = props.selectedCategory === category?.value;
                            return (
                              <FilterItemsListItem
                                key={category.value}
                                isSelected={isSelected}
                                onClick={() => props.onCategoriesChange?.(category.value)}
                              >
                                <span dangerouslySetInnerHTML={{ __html: category.label ?? '' }} />
                                <span>{category?.count}</span>
                              </FilterItemsListItem>
                            );
                          })}
                        </FilterItemsList>
                      </FilterItemsCol>
                    </FilterItemsRow>
                  </>
                </FilterItemsWrapper>
              </Container>
            ))}

          <TagsWrapper>
            <TagsRow>
              {filters &&
                Object.keys(filters).map((keyEl, index) => {
                  const filterName = filters?.[keyEl]?.label;
                  const tags = filters?.[keyEl]?.values;

                  return (
                    <TagsCol fullWidth={!!props.firstElementFull && index === 0} key={`filter-item-${filterName}-${index}`}>
                      <TagsTitle>{t({ id: `${filterName?.toLocaleLowerCase()}` })}</TagsTitle>
                      <TagsList>
                        {tags &&
                          Object.keys(tags)
                            .sort(function (a, b) {
                              if (keyEl !== 'years') {
                                return 0;
                              }
                              const aVal = parseInt(a);
                              const bVal = parseInt(b);
                              return aVal < bVal ? 1 : aVal > bVal ? -1 : 0;
                            })
                            .map((el) => {
                              const isSelected = props.selectedFilters && props.selectedFilters?.[keyEl]?.[el] === el;
                              return (
                                <TagsListItem
                                  key={`filter-el-${filterName}-${el}`}
                                  selected={isSelected}
                                  onClick={() => props.onFiltersChange?.(keyEl, el)}
                                >
                                  <span>{tags[el]} </span>
                                  {isSelected && (
                                    <SimpleButton onClick={() => props.onFiltersChange?.(keyEl, el)}>
                                      <CloseIcon />
                                    </SimpleButton>
                                  )}
                                </TagsListItem>
                              );
                            })}
                      </TagsList>
                    </TagsCol>
                  );
                })}
            </TagsRow>
          </TagsWrapper>
        </FiltersWrapper>
      )}
    </Wrapper>
  );
};

export default Filters;
