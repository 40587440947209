import { HEADER_HEIGHT } from '@lib/constants';
import { useEffect, useState } from 'react';

export function useHeaderPosition(ref?: any) {
  const [position, setPosition] = useState('absolute');

  const handleScroll = () => {
    if (ref?.current?.clientHeight - HEADER_HEIGHT <= window.scrollY) {
      setPosition('fixed');
      return;
    }
    setPosition('absolute');
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return position;
}
