import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';

export interface ProfileEditFields {
  first_name: string;
  last_name: string;
  company: string;
}

interface UseProfileEditFormOptions {
  initialValues?: ProfileEditFields;
  onSubmit: (values: ProfileEditFields, formikHelpers: FormikHelpers<ProfileEditFields>) => Promise<any>;
}

export const useProfileEditFormik = (options: UseProfileEditFormOptions) => {
  const BranchSchema = Yup.object().shape({
    first_name: Yup.string().required('First name is required'),
    last_name: Yup.string().required('Last name is required'),
  });

  return useFormik<ProfileEditFields>({
    initialValues: {
      first_name: '',
      last_name: '',
      company: '',
      ...options.initialValues,
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: BranchSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type ProfileEditFormik = ReturnType<typeof useProfileEditFormik>;
