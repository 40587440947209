import { getShowreels, GetShowreelsResponse } from '@api/showreels';
import QueryKeys from '@lib/constants/QueryKeys';
import { AxiosError } from 'axios';
import { useInfiniteQuery } from 'react-query';

export default function useGetShowreels() {
  return useInfiniteQuery<GetShowreelsResponse, AxiosError>(QueryKeys.showreels(), ({ pageParam = 1 }) => getShowreels({ page: pageParam }), {
    getNextPageParam: ({ current_page, total_pages }) => {
      if (current_page === total_pages) {
        return;
      }
      return current_page + 1;
    },
  });
}
