import { getPage } from '@api/page';
import QueryKeys from '@lib/constants/QueryKeys';
import { ObjectAny } from '@lib/interfaces';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

export default function useGetPage(slug?: string) {
  return useQuery<ObjectAny, AxiosError>(QueryKeys.page(slug), () => getPage(slug), {
    enabled: Boolean(slug),
  });
}
