import HandleFetchingState from '@components/shared/HandleFetchingState';
import useGetPage from '@lib/hooks/queries/useGetPage';
import useModules from '@lib/hooks/useModules';
import ModuleType from '@lib/interfaces/Modules';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const HomeLayout = styled.div`
  width: 100%;
  height: 100%;
`;

const Module02Wrapper = styled.div`
  display: block;
  position: relative;
`;
//
// const LogoWrapper = styled.span<{ fixed?: boolean }>`
//   cursor: pointer;
//   position: ${(props) => (props.fixed ? 'fixed' : 'absolute')};
//   right: 25px;
//   top: ${(props) => (props.fixed ? '98px' : '25px')};
//   display: none;
//   z-index: 2;
//
//   @media only screen and ${breakpoints.maxWidth.md} {
//     display: block;
//     top: 30px;
//     right: 20px;
//
//     &.fixed {
//       top: 60px;
//     }
//     &.absolute {
//       top: 15px;
//     }
//
//     svg {
//       width: 90px;
//     }
//   }
// `;

export default function Home() {
  const { getModuleByName } = useModules();
  // const [position, setPosition] = useState<string | undefined>(undefined);

  const ref = useRef<any>(null);

  const { isLoading, data } = useGetPage('home');

  const setHeaderPosition = () => {
    const header = document.querySelector('header');

    if (!header) {
      return;
    }

    // setPosition(header?.dataset?.position);
  };

  useEffect(() => {
    const handleOnScroll = (e: any) => setHeaderPosition();

    window.addEventListener('scroll', handleOnScroll);

    return () => {
      window.removeEventListener('scroll', handleOnScroll);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleScrollDown = () => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const renderModules = () => {
    const blocks = data?.blocks ?? [];

    return blocks
      ?.map((block: any, index: number) => {
        const moduleProps = block?.attrs?.data;
        const Module = getModuleByName(block?.blockName);

        if (!Module) {
          return null;
        }

        if (block?.blockName === ModuleType.MODULE_01) {
          return <Module key={`module-${block?.blockName}-${index}`} data={moduleProps} onScrollDown={handleScrollDown} />;
        }

        if (block?.blockName === ModuleType.MODULE_02 && index === 1) {
          return (
            <Module02Wrapper ref={ref} key={`module-${block?.blockName}-${index}`}>
              <Module data={moduleProps} />
              {/*<LogoWrapper fixed={position === 'fixed'} className={`vfc-floating-logo ${position}`}>*/}
              {/*  <VfcLogo />*/}
              {/*</LogoWrapper>*/}
            </Module02Wrapper>
          );
        }

        return <Module key={`module-${block?.blockName}-${index}`} data={moduleProps} />;
      })
      .filter((m: any) => Boolean(m));
  };

  return (
    <HandleFetchingState loading={isLoading}>
      <HomeLayout>{renderModules()}</HomeLayout>
    </HandleFetchingState>
  );
}
