import { ReactComponent as MinusIcon } from '@assets/icons/icons_minus.svg';
import { ReactComponent as PlusIcon } from '@assets/icons/icons_plus.svg';
import { Container } from '@components/shared/Container';
import breakpoints from '@lib/common/breakpoints';
import { useAuthContext } from '@lib/context/AuthContext/AuthContext';
import { useRouter } from '@lib/hooks/useRouter';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

const StyledIntro = styled.div`
  width: 100%;
  padding: 120px 0;
  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 60px 0;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 60px 0;
  }
`;
const StyledIntroTitle = styled.div`
  width: 100%;
  h3 {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 58px;
    line-height: 1.05em;
    color: ${(props) => props.theme.colors.black};
    margin-bottom: 24px;
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 24px;
      margin-bottom: 15px;
    }
    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 24px;
      margin-bottom: 15px;
    }
  }
  p {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 36px;
    letter-spacing: 0.4px;
    line-height: 1.17em;
    color: ${(props) => props.theme.colors.black};
    margin-bottom: 40px;
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 18px;
    }
    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 18px;
    }
  }
`;
const StyledAccordion = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${(props) => props.theme.colors.black};
  border-bottom: 1px solid ${(props) => props.theme.colors.black};
`;
const StyledAccordionHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 29px 0 25px;
  cursor: pointer;
  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 19px 0 15px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 19px 0 15px;
  }
  span {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 26px;
    letter-spacing: 0.29px;
    line-height: 1.2em;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.black};
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 18px;
    }
    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 18px;
    }
  }
`;
const StyledPlusIcon = styled(PlusIcon)`
  width: 32px;
  height: 32px;
  path {
    stroke: ${(props) => props.theme.colors.black}!important;
  }
  @media only screen and ${breakpoints.maxWidth.md} {
    width: 22px;
    height: 22px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    width: 22px;
    height: 22px;
  }
`;
const StyledMinusIcon = styled(MinusIcon)`
  width: 32px;
  height: 32px;
  path {
    stroke: ${(props) => props.theme.colors.black}!important;
  }
  @media only screen and ${breakpoints.maxWidth.md} {
    width: 22px;
    height: 22px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    width: 22px;
    height: 22px;
  }
`;
const StyledAccordionMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 33px 0 23px;
  transition: all 0.3s ease-in-out;
  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 13px 0 3px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 13px 0 3px;
  }
`;
const StyledAction = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 45px;
  @media only screen and ${breakpoints.maxWidth.md} {
    margin-bottom: 25px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    margin-bottom: 25px;
  }
  &:hover {
    color: ${(props) => props.theme.colors.red};
  }
`;
const StyledActionTitle = styled.h4`
  width: 100%;
  display: flex;
  font-size: 24px;
  font-family: 'GTFlexa-ExtendedMedium', sans-serif;
  line-height: 1.3em;
  margin-bottom: 8px;
  transition: all 0.3s ease-in-out;
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 20px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 20px;
  }
`;
const StyledActionContent = styled.p`
  width: 100%;
  font-family: 'GTFlexa-StandardLight';
  font-size: 18px;
  line-height: 1.2em;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.colors.black};
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 16px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 16px;
  }
`;

export const ProfileIntro = () => {
  const [isOpen, setIsOpen] = useState(false);
  const authCtx = useAuthContext();
  const router = useRouter();
  const { formatMessage: t } = useIntl();

  return (
    <StyledIntro className="ProfileIntro">
      <Container>
        <StyledIntroTitle>
          <h3>
            {t({ id: 'hello' })} {authCtx.user?.first_name} {authCtx.user?.last_name}
          </h3>
          <p>{t({ id: 'welcome_message' })}</p>
        </StyledIntroTitle>
        <StyledAccordion>
          <StyledAccordionHeader onClick={() => setIsOpen(!isOpen)}>
            <span>{t({ id: 'edit_my_profile' })}</span>
            {isOpen ? <StyledMinusIcon /> : <StyledPlusIcon />}
          </StyledAccordionHeader>
          {isOpen && (
            <StyledAccordionMain>
              <StyledAction onClick={() => router.history.push('/profile/edit')}>
                <StyledActionTitle>→ {t({ id: 'profile.edit' })}</StyledActionTitle>
                <StyledActionContent>{t({ id: 'profile.edit.description' })}</StyledActionContent>
              </StyledAction>
              <StyledAction onClick={() => router.history.push('/change-password')}>
                <StyledActionTitle>→ {t({ id: 'change_password' })}</StyledActionTitle>
                <StyledActionContent>{t({ id: 'change_password_description' })}</StyledActionContent>
              </StyledAction>
              <StyledAction onClick={() => router.history.push('/profile/delete')}>
                <StyledActionTitle>→ {t({ id: 'account_delete' })}</StyledActionTitle>
                <StyledActionContent>{t({ id: 'account_delete_description' })}</StyledActionContent>
              </StyledAction>
            </StyledAccordionMain>
          )}
        </StyledAccordion>
      </Container>
    </StyledIntro>
  );
};
