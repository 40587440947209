import FullScreenLoader from '@components/shared/FullScreenLoader';
import QueryKeys from '@lib/constants/QueryKeys';
import getLanguageFromLocalStorage from '@lib/helpers/getLanguageFromLocalStorage';
import useGetTranslations from '@lib/hooks/queries/useGetTranslations';
import { vfcFiltersKey } from '@lib/hooks/useLocalStorageFilters';
import { createContext, ReactNode, useContext, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';

interface ReactIntlProviderProps {
  children: ReactNode;
}

export enum Language {
  EN = 'en',
  DE = 'de',
}

interface IReactIntlProvider {
  activeLanguage: Language;
  setActiveLanguage: (lang: Language) => void;
}

const ReactIntlProviderContext = createContext<IReactIntlProvider>({
  activeLanguage: Language.DE,
  setActiveLanguage: (lang) => {},
});

export const VFC_LANGUAGE_KEY = 'VFC_LANGUAGE_KEY';

export default function ReactIntlProvider(props: ReactIntlProviderProps) {
  const location = useLocation();
  const queryClient = useQueryClient();
  const { data: translations, isFetching, error } = useGetTranslations();

  const [activeLanguage, setActiveLanguage] = useState<Language>(getLanguageFromLocalStorage);

  const updateUrlAndReload = (lang: Language) => {
    const pathname = location.pathname.replace('/', '');
    const queryData: any = queryClient.getQueryData(QueryKeys.page(pathname));
    const nextUrl = queryData?.page_in_other_lang?.post_name;
    if (!nextUrl) {
      window.location.href = `/${lang}/${pathname}`;
      return;
    }
    window.location.href = `/${lang}/${nextUrl}`;
  };

  const handleSetActiveLanguage = (lang: Language) => {
    localStorage.removeItem(vfcFiltersKey);
    localStorage.setItem(VFC_LANGUAGE_KEY, lang);
    setActiveLanguage(lang);
    updateUrlAndReload(lang);
  };

  if (isFetching && !error) {
    return <FullScreenLoader />;
  }

  const messages = (translations as any)[activeLanguage] ?? {};

  return (
    <ReactIntlProviderContext.Provider value={{ activeLanguage, setActiveLanguage: handleSetActiveLanguage }}>
      <IntlProvider locale={activeLanguage} messages={messages}>
        {props.children}
      </IntlProvider>
    </ReactIntlProviderContext.Provider>
  );
}

export const useReactIntlProvider = () => useContext(ReactIntlProviderContext);
