import { getLocations, GetLocationsInput, GetLocationsResponse } from '@api/locations';
import QueryKeys from '@lib/constants/QueryKeys';
import { AxiosError } from 'axios';
import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query';

export default function useGetLocations(params: GetLocationsInput, options?: UseInfiniteQueryOptions<GetLocationsResponse, AxiosError, any>) {
  return useInfiniteQuery<GetLocationsResponse, AxiosError>(
    [QueryKeys.locations(JSON.stringify(params))],
    ({ pageParam = 1 }) => getLocations({ ...params, page: pageParam }),
    {
      getNextPageParam: ({ current_page, total_pages }) => {
        if (total_pages === 0) {
          return;
        }

        if (current_page === total_pages) {
          return;
        }

        return current_page + 1;
      },
      cacheTime: 0,
      ...options,
    },
  );
}
