// import { keyframes } from 'styled-components';

// const pulse = keyframes`
//   0% {
//     transform: scale(0.95);
//   }
//   70% {
//     transform: scale(1);
//   }
//   100% {
//     transform: scale(0.95);
//   }
// `;
//
// const FullScreenLoaderWrapper = styled.div`
//   background-color: #ffffff;
//   width: 100%;
//   height: 100vh;
//   position: fixed;
//   top: 0;
//   left: 0;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   transition: 1s;
//   z-index: 110;
// `;

// const LogoWithAnimation = styled(Logo)`
//   animation-name: ${pulse};
//   animation-duration: 1s;
//   animation-iteration-count: infinite;
// `;

export default function FullScreenLoader() {
  return null;
  // <FullScreenLoaderWrapper>
  //   <LogoWithAnimation />
  // </FullScreenLoaderWrapper>
}
