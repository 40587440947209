import Module00Button from '@components/modules/Module00';
import Module02 from '@components/modules/Module02';
import { Container } from '@components/shared/Container';
import HandleFetchingState from '@components/shared/HandleFetchingState';
import breakpoints from '@lib/common/breakpoints';
import useGetProductionDetails from '@lib/hooks/queries/useGetProductionDetails';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
`;

const InnerContainer = styled.div`
  padding-top: 120px;
  padding-bottom: 50px;
  width: 100%;
  margin: 0 auto;

  @media only screen and ${breakpoints.maxWidth.lg} {
    padding-top: 50px;
    padding-bottom: 30px;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    padding: 50px 0;
  }
`;

const H1 = styled.h1<{ color?: string }>`
  font-family: 'GTFlexa-ExtendedMedium', sans-serif;
  font-size: 58px;
  font-weight: normal;
  line-height: 1.03;
  color: ${(props) => props.color};
  margin: 0;

  @media only screen and ${breakpoints.maxWidth.lg} {
    font-size: 32px;
    line-height: 0.88;
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 24px;
    line-height: 1.17;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;

  @media only screen and ${breakpoints.maxWidth.md} {
    margin-bottom: 20px;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    flex-direction: column;
  }
`;

const ContentWrapper = styled.div`
  max-width: 900px;
`;

const TextContent = styled.div`
  p {
    font-family: 'GTFlexa-StandardLight', sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: 0.5px;
    color: #000;
    margin-bottom: 50px;
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 16px;
    }
    @media only screen and ${breakpoints.maxWidth.xs} {
      font-size: 16px;
    }
  }
`;

const StyledModuleLinkButton = styled(Module00Button)`
  svg {
    transform: rotate(-90deg);
  }
`;

const ListWrapper = styled.div`
  width: 100%;
  border-top: 1px solid ${(props) => props.theme.colors.black};
`;

const ListItem = styled.div`
  width: 100%;
  padding: 20px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.black};
  h4 {
    width: 50%;
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 24px;
    line-height: 1.3em;
    color: ${(props) => props.theme.colors.black};
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 16px;
    }
    @media only screen and ${breakpoints.maxWidth.xs} {
      font-size: 16px;
    }
  }
  p {
    width: 50%;
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 1.3em;
    color: ${(props) => props.theme.colors.black};
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 14px;
      letter-spacing: 0.15px;
    }
    @media only screen and ${breakpoints.maxWidth.xs} {
      font-size: 14px;
      letter-spacing: 0.15px;
    }
    a {
      font-family: inherit;
      font-size: inherit;
      letter-spacing: inherit;
      line-height: inherit;
      text-decoration: none;
      color: inherit;
      &:hover {
        color: ${(props) => props.theme.colors.red};
      }
    }
  }
`;

const VideoTitle = styled.h3`
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  font-size: 48px;
  line-height: 1.3em;
  letter-spacing: -0.3px;
  color: ${(props) => props.theme.colors.black};
  margin-top: 100px;
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 32px;
    margin-top: 80px;
  }
  @media only screen and ${breakpoints.maxWidth.xs} {
    font-size: 28px;
    margin-top: 50px;
  }
`;

interface IProductionDetailsProps {
  productionSlug: string;
}

const ProductionDetails: FC<RouteComponentProps<IProductionDetailsProps>> = (props) => {
  const { formatMessage: t } = useIntl();
  const { data, isLoading } = useGetProductionDetails(props.match.params.productionSlug);

  const fields = data?.fields ?? [];

  return (
    <HandleFetchingState loading={isLoading}>
      <Wrapper>
        <Container>
          <InnerContainer>
            <ContentWrapper>
              <TitleWrapper>
                <H1 dangerouslySetInnerHTML={{ __html: data?.post_title ?? '' }} />
              </TitleWrapper>
              <TextContent>
                <p>{data?.description}</p>
              </TextContent>
            </ContentWrapper>
            <ListWrapper>
              {fields.map((field: any, index: number) => {
                const isLink = field.value.indexOf('http') !== -1;
                return (
                  <ListItem key={`field-${index}`}>
                    <h4>{field.title}</h4>
                    <p>{isLink ? <a href={field.value}>{field.value}</a> : <>{field.value}</>}</p>
                  </ListItem>
                );
              })}
            </ListWrapper>
            <VideoTitle>{data?.video_title}</VideoTitle>
          </InnerContainer>

          {data?.video_id && (
            <Module02
              data={{
                bottom_right_text: data?.bottom_right_text || undefined,
                video_id: data?.video_id || undefined,
              }}
            />
          )}
        </Container>
        <StyledModuleLinkButton
          data={{
            link: {
              title: t({ id: 'back_to_all_productions' }),
              url: '/produktionsspiegel',
            },
          }}
        />
      </Wrapper>
    </HandleFetchingState>
  );
};

export default ProductionDetails;
