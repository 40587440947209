import { get } from '@api/axios';

export const getSearchFilters = (postType: string) => {
  return get<any, any>({ url: `v1/${postType}/filters` });
};

export const search = (keyword: string) => {
  return get<any, any>({
    url: `v1/search`,
    params: {
      search: keyword,
    },
  });
};
