import Pages from '@lib/constants/Pages';
import { useMemo } from 'react';

export default function usePages() {
  const pages = useMemo(() => Pages, []);

  const getPageBySlug = (slug?: string) => {
    if (!slug) return null;
    return pages ? ((pages as any)[slug] ? (pages as any)[slug] : pages['default']) : null;
  };

  return {
    pages,
    getPageBySlug,
  };
}
