import { ReactComponent as WhiteLogo } from '@assets/icons/vfc-logo-white.svg';
import HeaderActions from '@components/header/HeaderActions';
import HeaderMobile, { scrollToFooter } from '@components/header/HeaderMobile';
import SearchAndLanguage from '@components/header/SearchAndLanguage';
import Module06 from '@components/modules/Module06';
import Button from '@components/shared/Button';
import FullScreenModal from '@components/shared/FullScreenModal';
import breakpoints from '@lib/common/breakpoints';
import { HEADER_HEIGHT } from '@lib/constants';
import { useUIContext } from '@lib/context/UIContext/UIContext';
import useGetPage from '@lib/hooks/queries/useGetPage';
import useGetPagesGeneralInfo from '@lib/hooks/queries/useGetPagesGeneralInfo';
import useWindowScroll from '@lib/hooks/useWindowScroll';
import { useWindowWidth } from '@lib/hooks/useWindowWidth';
import { ObjectAny } from '@lib/interfaces';
import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link as RouterLink, NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

const HeaderLayout = styled.header<HeaderProps>`
  position: ${(props) => props.position ?? 'relative'};
  top: ${(props) => (props.position === 'fixed' ? 0 : 'unset')};
  bottom: ${(props) => (props.position === 'fixed' ? 'unset' : 0)};
  left: 0;
  width: 100%;
  background: black;
  z-index: 10;

  @media only screen and ${breakpoints.maxWidth.md} {
    top: ${(props) => (props.position === 'fixed' ? '-53px' : 'unset')};
  }
`;

const HeaderItems = styled.div`
  display: flex;
`;

export const Nav = styled.nav<{ isMobile?: boolean }>`
  display: flex;
  ${({ isMobile }) =>
    isMobile &&
    css`
      justify-content: center;
      width: 100%;
    `}

  >span:first-of-type a {
    padding-left: 40px;
  }
`;

export const Link = styled(RouterLink)<{ active?: boolean | string }>`
  text-transform: uppercase;
  cursor: pointer;
  display: inline-block;
  padding: 22px;
  font-size: 22px;
  font-weight: normal;
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  line-height: 1.25;
  color: ${(props) => (props.active ? props.theme.colors.white : props.theme.colors.black)};

  &:hover {
    color: #ffffff;
  }

  &.active {
    color: #ffffff;
  }

  @media only screen and (max-width: 1405px) {
    font-size: 18px;
    padding: 24px 20px;
  } ;
`;

export const DirectLink = styled.a`
text-transform: uppercase;
    cursor: pointer;
    display: inline-block;
    padding: 22px;
    font-size: 22px;
    font-weight: normal;
    font-family: 'GTFlexa-ExtendedLight',sans-serif;
    line-height: 1.25;
    color: #000000;
    &:hover {
        color: #ffffff;
    }
    &.active {
        color: #ffffff;
    }


    `;

export const NavItem = styled(NavLink)<{ isActive?: boolean }>`
  text-transform: uppercase;
  cursor: pointer;
  display: inline-block;
  padding: 22px;
  font-size: 20px;
  font-weight: normal;
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  line-height: 1.25;
  background-color: ${(props) => (props.isActive ? props.theme.colors.red : 'inherit')};
  color: ${(props) => (props.isActive ? props.theme.colors.black : props.theme.colors.white)};

  &:hover,
  &.active {
    background-color: ${(props) => props.theme.colors.red};
    color: ${(props) => props.theme.colors.black};
  }

  &.current-page {
    background-color: #000000;
    color: ${(props) => props.theme.colors.red};
  }

  @media only screen and (max-width: 1405px) {
    font-size: 18px;
    padding: 24px 20px;
    display: flex;
    align-items: center;
  }

  @media only screen and ${breakpoints.maxWidth.lg} {
    font-size: 18px;
    padding: 24px 10px;
  } ;
`;

export const SubNav = styled.div<{ showOnTop?: boolean; leftPadding?: number; open?: boolean; isMobile?: boolean }>`
  display: ${(props) => (props.open ? 'flex' : 'none')};
  align-items: center;
  background: ${(props) => props.theme.colors.red};
  width: 100%;
  height: 123px;
  left: 0;
  position: ${(props) => (props.showOnTop ? 'absolute' : 'absolute')};
  bottom: ${(props) => (props.showOnTop ? `${HEADER_HEIGHT}px` : `-${HEADER_HEIGHT + 54}px`)};
  padding-left: ${(props) => `${props.leftPadding}px` ?? null};
  a {
    font-size: 20px;
  }
  ${({ isMobile }) =>
    isMobile &&
    css`
      height: 60px;
      bottom: -62px;
    `}
`;

const MobileMenuButtons = styled.div`
  display: flex;
  flex-direction: column;
  //border-top: 2px solid #ffffff;
  width: 100%;
  bottom: 0;

  button {
    font-size: 22px;
    line-height: 1.36;
    padding: 10px 10px 10px 60px;
    text-align: left;

    @media only screen and ${breakpoints.maxWidth.md} {
      padding: 10px;
      text-align: center;
    }

    @media only screen and ${breakpoints.maxWidth.xs} {
      text-align: center;
    }

    &:last-of-type {
      &:hover {
        color: #000000;
        background-color: #ffffff !important;
      }
    }
  }
`;

const InnerMenuButtons = styled.div`
  background-color: #ffffff;
  display: flex;

  > div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #000000;

    &:last-of-type {
      justify-content: flex-end;
      border-left: none;
    }
  }

  button {
    border: none;
    width: 100%;
    &:hover {
      border: none;
    }
  }

  a {
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .logo-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    padding: 0 7px;
    margin-right: 5px;

    svg {
      width: 65px;
      height: 40px;
    }

    .film-com-mission path {
      fill: #000000;
    }
  }
`;

interface HeaderProps {
  position?: string;
  forceBottom?: boolean;
}

export default function Header(props: HeaderProps) {
  const windowWidth = useWindowWidth();
  const { scrollY } = useWindowScroll();
  const uiContext = useUIContext();
  const { formatMessage: t } = useIntl();
  const ref = useRef<HTMLDivElement>(null);
  const menuRefs = useRef<any>([]);
  const [active, setActive] = useState<number | undefined>(undefined);
  const [leftPosition, setLeftPosition] = useState<number | undefined>(undefined);

  const { data } = useGetPagesGeneralInfo();
  const { data: homeData } = useGetPage('home');

  const navItems = data?.header?.menu ?? [];

  const setActiveItem = (id?: number) => {
    if (!id) {
      setActive(undefined);
      return;
    }

    setActive(id);
  };

  const blockM06Data = homeData?.blocks?.find((b: any) => b?.blockName?.includes('m06'))?.attrs?.data;

  const checkIfChildPageIsActive = (item: any) => {
    const windowPathname = window.location.pathname;
    const tempPath = windowPathname?.slice(1, windowPathname.length);
    const pathName = tempPath.slice(3, tempPath.length);

    let navItem: any = null;
    navItems.forEach((item: any) => {
      if (!item.children || !item.children.length) {
        return;
      }
      item.children.forEach((childItem: any) => {
        if (childItem.slug === pathName) {
          navItem = item;
        }
      });
    });

    return Boolean(navItem?.ID === item?.ID);
  };

  const checkIfSubItemIsActive = (item: any) => {
    const windowPathname = window.location.pathname;
    const tempPath = windowPathname?.slice(1, windowPathname.length);
    const pathName = tempPath?.slice(3, tempPath.length);
    let navItem: any = null;

    navItems.forEach((item: any) => {
      if (!item.children || !item.children.length) {
        return;
      }

      item.children.forEach((childItem: any) => {
        if (childItem.slug === pathName) {
          navItem = childItem;
        }
      });
    });

    return navItem;
  };

  const showDropdownOnTop = !props.forceBottom && scrollY <= HEADER_HEIGHT;

  return (
    <>
      <HeaderLayout ref={ref} position={props.position} data-position={props.position}>
        {windowWidth >= 992 ? (
          <>
            <HeaderItems>
              <Nav>
                {navItems.map((item: ObjectAny, index: number) => {
                  const subItems = item?.children ?? [];
                  


                  const isActive = active === item.ID;
                  const isChildActive = checkIfChildPageIsActive(item);

                  return (
                    <span
                      key={item.ID}
                      ref={(element) => (menuRefs.current[index] = element)}
                      onMouseLeave={() => {
                        setActiveItem(undefined);
                        setLeftPosition(undefined);
                      }}
                    >
                      <NavItem
                        activeClassName="current-page"
                        className={isActive ? 'active' : isChildActive ? 'current-page' : ''}
                        to={`/${item.slug}`}
                        onClick={(e: any) => {
                          windowWidth <= 992 && e.preventDefault();
                          return;
                        }}
                        onMouseEnter={(e) => {
                          if (item.href) {
                            return;
                          }
                          const element = menuRefs?.current[index];
                          const leftPositionValue = element?.getBoundingClientRect()?.left;
                          const newValue = index === 2 ? leftPositionValue - 30 : leftPositionValue / 2;
                          setLeftPosition(newValue);
                          setActiveItem(item.ID);
                        }}
                      >
                        {item.title}
                      </NavItem>

                      <SubNav open={isActive} showOnTop={showDropdownOnTop} leftPadding={isActive ? leftPosition : 0}>
                        {subItems.map((item2: ObjectAny) => {
                          const activeSubItem = checkIfSubItemIsActive(item2);
                          const isActive = activeSubItem?.ID === item2.ID;
                          if(!item2.url.includes('backend.viennafilmcommission.at'))
                          {
                            return (
                              <DirectLink  key={`sub-item-${item2.ID}`} href={`${item2.url}`}>
                                {item2.title}
                              </DirectLink>
                            );
                          }
                          return (
                            <Link className={isActive ? 'active' : ''} key={`sub-item-${item2.ID}`} to={`/${item2.slug}`}>
                              {item2.title}
                            </Link>
                          );
                        })}
                      </SubNav>
                    </span>
                  );
                })}
              </Nav>
              <SearchAndLanguage showDropdownOnTop={showDropdownOnTop} />
              <HeaderActions showDropdownOnTop={showDropdownOnTop} />
            </HeaderItems>
          </>
        ) : (
          <MobileMenuButtons>
            <Button
              color="black"
              onClick={() => {
                uiContext.setState({ ...uiContext, isMobileMenuOpened: false });
                scrollToFooter();
              }}
            >
              {t({ id: 'kontakt' })}
            </Button>
            <InnerMenuButtons>
              <div>
                <Button onClick={() => uiContext.setState({ isMobileMenuOpened: !uiContext.state.isMobileMenuOpened })}>
                  {uiContext.isMobileMenuOpened ? t({ id: 'menu.schließen' }) : t({ id: 'menu' })}
                </Button>
              </div>

              <div>
                <Link to="/">
                  <span className="logo-wrapper">
                    <WhiteLogo />
                  </span>
                </Link>
              </div>
            </InnerMenuButtons>
          </MobileMenuButtons>
        )}
      </HeaderLayout>

      {windowWidth <= 991 && uiContext.state.isMobileMenuOpened && <HeaderMobile showDropdownOnTop={showDropdownOnTop} />}

      <FullScreenModal open={uiContext.state.isSearchOpened} onClose={() => uiContext.setState({ isSearchOpened: false })}>
        <Module06 data={blockM06Data} showIcon />
      </FullScreenModal>
    </>
  );
}
