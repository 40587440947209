import breakpoints from '@lib/common/breakpoints';
import React, { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  padding: 12px 80px;
  display: flex;
  align-items: center;
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  font-size: 36px;
  font-weight: normal;
  line-height: 1.17;
  letter-spacing: 0.4px;
  background-color: #ffffff;
  border-bottom: 2px solid #000000;

  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 6px 40px;
    font-size: 19px;
    line-height: 1.17;
    letter-spacing: 0.21px;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    padding: 6px 24px;
  }
`;

const Label = styled.label`
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  margin-right: 14px;

  @media only screen and ${breakpoints.maxWidth.xs} {
    font-size: 16px;
    margin-right: 5px;
  }
`;

const Input = styled.input`
  flex-grow: 1;
  border: none;
  outline: none;
  color: #bebebe;
  font-size: 36px;
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  line-height: 1.17;
  letter-spacing: 0.4px;
  padding-right: 15px;
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 19px;
    line-height: 1.17;
    letter-spacing: 0.21px;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    width: 100%;
  }
`;

interface IFullWidthSearchProps {
  title: string;
  className?: string;
  name?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const FullWidthSearch: FC<IFullWidthSearchProps> = (props) => {
  return (
    <Wrapper className={props.className}>
      <Label htmlFor={props.name}>{props.title}:</Label>
      <Input name={props.name} value={props.value ?? ''} onChange={props.onChange} />
    </Wrapper>
  );
};

export default FullWidthSearch;
