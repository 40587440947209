import getLanguageFromLocalStorage from '@lib/helpers/getLanguageFromLocalStorage';
import { AxiosRequestConfig, AxiosResponse, default as defaultAxios } from 'axios';

const axios = defaultAxios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

// set language for each request
axios.interceptors.request.use(
  function (config) {
    const language = getLanguageFromLocalStorage();
    return { ...config, baseURL: config.baseURL?.replace('{language}', language) };
  },
  function (error) {
    return Promise.reject(error);
  },
);

async function apiRequest<Input, Response>(options: AxiosRequestConfig<Input>) {
  const res = await axios.request<Input, AxiosResponse<Response>>(options);
  return res.data;
}

async function get<Input, Response>(options: AxiosRequestConfig<Input>) {
  return await apiRequest<Input, Response>({ method: 'get', ...options });
}

async function post<Input, Response>(options: AxiosRequestConfig<Input>) {
  return await apiRequest<Input, Response>({ method: 'post', ...options });
}

async function put<Input, Response>(options: AxiosRequestConfig<Input>) {
  return await apiRequest<Input, Response>({ method: 'put', ...options });
}

async function deleteRequest<Input, Response>(options: AxiosRequestConfig<Input>) {
  return await apiRequest<Input, Response>({ method: 'delete', ...options });
}

export { get, post, put, deleteRequest, apiRequest };
export interface ISelect {
  term_id: number;
  name: string;
  slug: string;
  term_group: number;
  term_taxonomy_id: number;
  taxonomy: string;
  description: string;
  parent: number;
  count: number;
  filter: string;
}

export default axios;
