import { getProductionsDetails, GetProductionsDetailsResponse } from '@api/productions';
import QueryKeys from '@lib/constants/QueryKeys';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

export default function useGetProductionDetails(slug?: string) {
  return useQuery<GetProductionsDetailsResponse, AxiosError>(QueryKeys.productionDetails(slug), () => getProductionsDetails(slug), {
    enabled: Boolean(slug),
  });
}
