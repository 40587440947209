import { ReactComponent as IconArrowDown } from '@assets/icons/icons_arrow-down.svg';
import { ReactComponent as IconClose } from '@assets/icons/icons_close_small.svg';
import { ReactComponent as IconLabel } from '@assets/icons/icon_info.svg';
import breakpoints from '@lib/common/breakpoints';
import React from 'react';
import { FormFeedback, FormGroup, Label } from 'reactstrap';
import styled, { css } from 'styled-components';
/*eslint-disable eqeqeq*/

interface IStyledFormGroup {
  optional?: boolean;
  error?: boolean;
}

const StyledFormGroup = styled(FormGroup)<IStyledFormGroup>`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 18px;
  margin-bottom: 32px;
  @media only screen and ${breakpoints.maxWidth.md} {
    padding-top: 14px;
    margin-bottom: 20px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    padding-top: 14px;
    margin-bottom: 20px;
  }

  label {
    position: absolute;
    top: 0px;
    left: 24px;
    padding: 8px 12px;
    background-color: ${(props) => props.theme.colors.white};
    font-family: 'GTFlexa-StandardMedium';
    font-size: 16px;
    letter-spacing: 0.2px;
    color: ${(props) => props.theme.colors.black};
    display: flex;
    align-items: center;
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 14px;
      left: 15px;
      padding: 4px 8px;
    }
    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 14px;
      left: 15px;
      padding: 4px 8px;
    }
    /* ${({ error }) =>
      error &&
      css`
        color: ${(props) => props.theme.colors.red};
      `} */
  }
  select {
    appearance: none;
    border: 1px solid #141414;
    padding: 28px 30px;
    font-family: 'GTFlexa-ExtendedMedium';
    font-size: 24px;
    color: ${(props) => props.theme.colors.black};
    outline: 0;
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 18px;
      padding: 18px 15px;
    }
    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 18px;
      padding: 18px 15px;
    }
    ${({ optional }) =>
      optional &&
      css`
        border-style: none;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='2' stroke-dasharray='8%2c10' stroke-dashoffset='7' stroke-linecap='square'/%3e%3c/svg%3e");
      `}
  }
`;

const StyledInfoIcon = styled.div`
  width: 18px;
  height: 18px;
  margin-left: 10px;
  cursor: pointer;
`;

const StyledSelectedOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledSelectedOption = styled.div`
  background-color: ${(props) => props.theme.colors.black};
  padding: 6px 10px;
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-top: 20px;
  @media only screen and ${breakpoints.maxWidth.md} {
    margin-right: 10px;
    margin-top: 10px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    margin-right: 10px;
    margin-top: 10px;
  }
  span {
    font-size: 18px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.white};
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 14px;
    }
    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 14px;
    }
  }
`;

const StyledClose = styled(IconClose)`
  width: 13px;
  height: 13px;
  cursor: pointer;
  margin-left: 13px;
  path {
    stroke: #fff !important;
  }
`;
const StyledDownArrow = styled(IconArrowDown)`
  width: 32px;
  height: 32px;
  cursor: pointer;
  position: absolute;
  top: 45px;
  right: 30px;
  pointer-events: none;
  @media only screen and ${breakpoints.maxWidth.md} {
    width: 20px;
    height: 20px;
    top: 34px;
    right: 18px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    width: 20px;
    height: 20px;
    top: 34px;
    right: 18px;
  }
`;
const StyledFormFeedback = styled(FormFeedback)`
  font-family: 'GTFlexa-StandardMedium';
  font-size: 16px;
  letter-spacing: 0.2px;
  color: ${(props) => props.theme.colors.red};
  padding: 10px 24px 0 24px;
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 14px;
    padding-left: 15px;
    margin-top: -10px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 14px;
    padding-left: 15px;
    margin-top: -10px;
  }
`;

interface Option {
  value: number;
  label: string;
}

interface Props {
  id: string;
  className: string;
  name: string;
  error?: string | string[];
  invalid?: boolean | never[];
  label?: string;
  labelInfo?: string;
  optional?: boolean;
  options: Option[];
  selectedOptions: number[];
  onChange: (key: string, value: number[]) => void;
}

export const MultiSelect = (props: Props) => {
  const { name, label, labelInfo, error, optional, id, options, className, onChange, selectedOptions } = props;

  const handleRemove = (option: Option) => {
    onChange(
      name,
      selectedOptions?.filter((opt) => opt !== option.value),
    );
  };

  const handleChange = (e: React.ChangeEvent<any>) => {
    const option = options.find((option) => option.value === parseInt(e.target.value));
    option && onChange(name, [...selectedOptions, option.value]);
  };

  const exists = (option: Option) => !!selectedOptions?.find((opt) => opt === option.value);

  return (
    <StyledFormGroup optional={optional} error={!!props.error} className={className}>
      {label && (
        <Label for={id} hidden>
          {label}
          {labelInfo && (
            <StyledInfoIcon>
              <IconLabel />
            </StyledInfoIcon>
          )}
        </Label>
      )}
      <select id={id} name={name} onChange={handleChange}>
        <option value="0" selected></option>
        {options.map((option) => {
          return (
            <option key={`${name}-${option.value}-${option.label}`} value={option.value} disabled={exists(option)}>
              {option.label}
            </option>
          );
        })}
      </select>
      <StyledDownArrow />
      {selectedOptions && selectedOptions.length > 0 && (
        <StyledSelectedOptions>
          {options
            .filter((opt) => selectedOptions?.find((o) => opt.value === o))
            .map((option) => {
              return (
                <StyledSelectedOption key={`${name}-selected-${option.value}-${option.label}`}>
                  <span>{option.label}</span>
                  <StyledClose onClick={() => handleRemove(option)} />
                </StyledSelectedOption>
              );
            })}
        </StyledSelectedOptions>
      )}

      {error && <StyledFormFeedback>{error}</StyledFormFeedback>}
    </StyledFormGroup>
  );
};
