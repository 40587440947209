import Image from '@components/shared/Image';
import breakpoints from '@lib/common/breakpoints';
import ModuleType from '@lib/interfaces/Modules';
import React, { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.violet};
  padding: 80px 140px;
  width: 100%;

  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 40px 20px;
  }

  h3 {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 48px;
    letter-spacing: -0.2px;
    line-height: 1.25em;
    color: ${({ theme }) => theme.colors.white};
    margin-bottom: 40px;
    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 24px;
      margin-bottom: 25px;
    }
  }
`;

const PersonWrapper = styled.div`
  display: flex;
  @media only screen and ${breakpoints.maxWidth.sm} {
    flex-direction: column;
  }
`;
const PersonThumb = styled.div`
  width: 290px;
  height: 220px;
  @media only screen and ${breakpoints.maxWidth.sm} {
    width: 165px;
    height: 120px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const PersonDetails = styled.div`
  width: 100%;
  max-width: 33.333%;
  margin-left: 40px;
  color: ${({ theme }) => theme.colors.white};
  @media only screen and ${breakpoints.maxWidth.sm} {
    max-width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }

  p.text {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1.3em;
    margin-bottom: 5px;
    margin-top: 0;
    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 16px;
    }
  }

  p {
    font-family: 'GTFlexa-StandardLight', sans-serif;
    font-size: 18px;
    letter-spacing: 0.5px;
    line-height: 1.3em;
    margin-top: 30px;
    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 16px;
      letter-spacing: 0.3px;
    }
  }
`;

interface IModule25Props {
  data: {
    title: string;
    person: {
      ID: number;
      email?: string;
      job_position?: string;
      name?: string;
      phone?: string;
      post_name?: string;
      thumbnail?: string;
    };
    address?: string;
  };
}

const Module25: FC<IModule25Props> = (props) => {
  return (
    <Wrapper data-module={ModuleType.MODULE_25}>
      {props.data?.title && <h3>{props.data?.title}</h3>}
      <PersonWrapper>
        <PersonThumb>{props.data?.person?.thumbnail && <Image src={props.data?.person?.thumbnail} />}</PersonThumb>
        <PersonDetails>
          {props.data.person?.name && <p className="text">{props?.data?.person?.name}</p>}
          {props.data.person?.job_position && <p className="text">{props?.data?.person?.job_position}</p>}
        </PersonDetails>
        <PersonDetails>
          {props.data.person?.phone && <p className="text">{props?.data?.person?.phone}</p>}
          {props.data.person?.email && <p className="text">{props?.data?.person?.email}</p>}
          <p dangerouslySetInnerHTML={{ __html: props.data?.address || '' }}></p>
        </PersonDetails>
      </PersonWrapper>
    </Wrapper>
  );
};

export default Module25;
