import { Showreel } from '@api/showreels';
import FullscreenShowreels from '@components/showreels/FullscreenShowreels';
import ShowreelListItem from '@components/showreels/ShowreelListItem';
import breakpoints from '@lib/common/breakpoints';
import React, { FC, useMemo, useState } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import styled, { ThemeProvider } from 'styled-components';

const StyledRow = styled(Row)``;

const StyledCol = styled(Col)`
  margin-bottom: 60px;
  padding: 0 27px;

  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 0 16px;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    padding: 0 5px;
    margin-bottom: 50px;
  }
`;

interface IShowreelListProps {
  sticky?: boolean;
  items?: Array<Showreel>;
}

const ShowreelList: FC<IShowreelListProps> = (props) => {
  const { items } = props;
  const showreelItems = items?.map((item, index) => ({ ...item, id: index })) ?? [];

  const [fullScreen, setFullscreen] = useState(false);
  const [activeItem, setActiveItem] = useState<Showreel | undefined>(undefined);

  const theme = useMemo(() => {
    return {
      flexboxgrid: {
        gridSize: 12,
        gutterWidth: 0,
        outerMargin: 0,
        mediaQuery: 'only screen',
        breakpoints: {
          xs: 0,
          sm: 36,
          md: 48,
          lg: 61,
        },
      },
    };
  }, []);

  const openFullscreenModal = () => {
    setFullscreen(true);
  };

  const closeFullscreenModal = () => {
    setFullscreen(false);
    setActiveItem(undefined);
  };

  const handleOnFullscreenRequest = (item: Showreel) => {
    setActiveItem(item);
    openFullscreenModal();
  };

  const handleOnNext = () => {
    const currentIndex = showreelItems?.findIndex((x) => x.id === activeItem?.id);

    if (currentIndex === -1) {
      return;
    }

    const nextItem = showreelItems.find((x, index) => index === currentIndex + 1);

    if (!nextItem) {
      setActiveItem(undefined);
      closeFullscreenModal();
      return;
    }

    setActiveItem(nextItem);
  };

  const handleOnPrev = () => {
    const currentIndex = showreelItems?.findIndex((x) => x.id === activeItem?.id);

    if (currentIndex === -1) {
      return;
    }

    const nextItem = showreelItems.find((x, index) => index === currentIndex - 1);

    if (!nextItem) {
      setActiveItem(undefined);
      closeFullscreenModal();
      return;
    }

    setActiveItem(nextItem);
  };

  return (
    <ThemeProvider theme={theme}>
      <StyledRow>
        {showreelItems?.map((showreel, index) => {
          return (
            <StyledCol key={`${showreel.thumbnail}-${index}`} xs={6} sm={6} md={6} lg={4}>
              <ShowreelListItem item={showreel} onFullscreenRequest={handleOnFullscreenRequest} />
            </StyledCol>
          );
        })}
      </StyledRow>

      {fullScreen && (
        <FullscreenShowreels
          controls={true}
          items={showreelItems}
          activeItem={activeItem}
          onClose={closeFullscreenModal}
          onNext={handleOnNext}
          onPrev={handleOnPrev}
        />
      )}
    </ThemeProvider>
  );
};

export default ShowreelList;
