export enum PageType {
  PAGE_NOT_FOUND = 'page-not-found',
  HOME = '/',
  LOGIN = 'login',
  REGISTER = 'register',
  FORGOT_PASSWORD = 'forgot-password',
  RESET_PASSWORD = 'reset_password',
  PROFILE = 'profile',
  PROFILE_EDIT = 'profile/edit',
  PROFILE_DELETE = 'profile/delete',
  CHANGE_PASSWORD = 'change-password',
  MANAGE_LOCATION_CREATE = 'manage-location/create',
  MANAGE_LOCATION_EDIT = 'manage-location/:slug/edit',
  MANAGE_LOCATION_DELETE = 'manage-location/:slug/delete',
  MANAGE_BRANCH_CREATE = 'manage-branch/create',
  MANAGE_BRANCH_EDIT = 'manage-branch/:slug/edit',
  MANAGE_BRANCH_DELETE = 'manage-branch/:slug/delete',
  FILMING = 'formular',
  REQUEST_PRIVATE_LOCATIONS = 'request-private-locations',
  REQUEST_PRIVATE_LOCATION = 'request-private-location/:slug',
  SEARCH = 'search',
  ABOUT_US = 'uber-uns',
  SHOWREELS = 'showreels',
  FILMING_PERMITS = 'drehgenehmigungen',
  FILMING_PERMITS_DETAILS = 'drehgenehmigungen/:pageSlug',
  FILMING_PERMITS_DETAILS_EN = 'permits/:pageSlug',
  SERVICES = 'services',
  BRANCHES = 'branches',
  BRANCHES_DETAILS = 'branches/:branchenSlug',
  LOCATIONS = 'locations',
  LOCATIONS_DETAILS = 'locations/:locationSlug',
  NEWS_DETAILS = 'news/:newsSlug',
  PRODUCTION_DETAILS = 'production/:productionSlug',
  DEFAULT = 'default',
  WORDPRESS_PAGE = ':pageSlug',
  POST_DELETE_CONFIRMATION = 'delete',
}
