import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';

export interface BranchContactFields {
  first_name: string;
  last_name: string;
  contact_person_email: string;
  contact_person_email_confirmation: string;
  contact_person_phone: string;
  contact_person_mobile: string;
}

interface UseBranchFormOptions {
  initialValues?: BranchContactFields;
  onSubmit: (values: BranchContactFields, formikHelpers: FormikHelpers<BranchContactFields>) => Promise<any>;
}

export const useBranchContactFormik = (options: UseBranchFormOptions) => {
  const BranchSchema = Yup.object().shape({
    first_name: Yup.string().required('First name is required.'),
    last_name: Yup.string().required('Last name is required.'),
    contact_person_email: Yup.string().email('Email should be valid!').required('Email is required.'),
    contact_person_email_confirmation: Yup.string()
      .email('Email should be valid!')
      .oneOf([Yup.ref('contact_person_email'), null], 'Email confirm must match'),
    contact_person_phone: Yup.string().required('Phone is required.'),
    contact_person_mobile: Yup.string().required('Mobile is required.'),
  });

  return useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      contact_person_email: '',
      contact_person_email_confirmation: '',
      contact_person_phone: '',
      contact_person_mobile: '',
      ...options.initialValues,
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: BranchSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type BranchContactFormik = ReturnType<typeof useBranchContactFormik>;
