import { Container } from '@components/shared/Container';
import LoadMoreButton from '@components/shared/LoadMoreButton';
import PressListItem from '@components/shared/PressListItem';
import breakpoints from '@lib/common/breakpoints';
import useGetPress from '@lib/hooks/queries/useGetPress';
import ModuleType from '@lib/interfaces/Modules';
import React, { FC, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Col, Row } from 'react-styled-flexboxgrid';
import styled, { ThemeProvider } from 'styled-components';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding-top: 110px;
  padding-bottom: 110px;
  width: 100%;

  @media only screen and ${breakpoints.maxWidth.sm} {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

const TextWrapper = styled.div`
  max-width: 1121px;
  width: 100%;
  margin-bottom: 80px;
  h3 {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 58px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.03;
    letter-spacing: normal;
    margin-bottom: 25px;

    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 28px;
      line-height: 1.17;
    }

    @media only screen and ${breakpoints.maxWidth.xs} {
      font-size: 20px;
      line-height: 1.17;
    }
  }
  p {
    font-family: 'GTFlexa-StandardLight', sans-serif;
    font-size: 18px;
    letter-spacing: 0.5px;
    line-height: 1.3em;
    color: ${(props) => props.theme.colors.black};

    @media only screen and ${breakpoints.maxWidth.xs} {
      font-size: 16px;
    }
  }
`;

const StyledRow = styled(Row)``;

const StyledCol = styled(Col)`
  margin-bottom: 60px;
  padding: 0 27px;

  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 0 16px;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    padding: 0 5px;
    margin-bottom: 50px;
  }
`;

const ListWrapper = styled.div`
  position: relative;

  margin-left: -27px;
  margin-right: -27px;

  @media only screen and ${breakpoints.maxWidth.md} {
    margin-left: -16px;
    margin-right: -16px;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    margin-left: -5px;
    margin-right: -5px;
  }
`;

interface IModule29Props {
  data?: {
    title: string;
    description: string;
  };
}

const Module29: FC<IModule29Props> = (props) => {
  const { data: pressData, isFetching, hasNextPage, fetchNextPage } = useGetPress();
  const theme = useMemo(() => {
    return {
      flexboxgrid: {
        gridSize: 12,
        gutterWidth: 0,
        outerMargin: 0,
        mediaQuery: 'only screen',
        breakpoints: {
          xs: 0,
          sm: 36,
          md: 48,
          lg: 61,
        },
      },
    };
  }, []);

  const allPress = pressData?.pages.map((item) => item.data).flat() ?? [];
  return (
    <Wrapper data-module={ModuleType.MODULE_29}>
      <Container>
        <TextWrapper>
          {props.data?.title && <h3>{props.data.title}</h3>}
          <p>{props.data?.description}</p>
        </TextWrapper>
        <ListWrapper>
          <InfiniteScroll hasMore={Boolean(hasNextPage)} dataLength={allPress.length} next={fetchNextPage} loader={null} scrollThreshold={1}>
            <ThemeProvider theme={theme}>
              <StyledRow>
                {allPress?.map((pressItem, index) => {
                  return (
                    <StyledCol key={`${pressItem.thumbnail}-${index}`} xs={6} sm={6} md={6} lg={4}>
                      <PressListItem press={pressItem} />
                    </StyledCol>
                  );
                })}
              </StyledRow>
            </ThemeProvider>
            {isFetching && <LoadMoreButton isLoading />}
          </InfiniteScroll>
        </ListWrapper>
      </Container>
    </Wrapper>
  );
};

export default Module29;
