import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<{ type?: AlertType }>`
  font-family: GTFlexa-ExtendedLight, sans-serif;
  font-size: 16px;
  color: #ffffff;
  padding: 10px;
  margin: 5px 0;
  background-color: ${(props) => (props.type ? props.theme.colors.red : props.theme.colors.yellow)};
`;

type AlertType = 'error' | 'success';

interface IAlertProps {
  className?: string;
  type?: AlertType;
  children?: ReactNode;
}

const Alert: FC<IAlertProps> = (props) => {
  return (
    <Wrapper className={props.className} type={props.type}>
      {props.children}
    </Wrapper>
  );
};

export default Alert;
