import { get } from '@api/axios';
import objectToQueryString from '@lib/helpers/objectToQueryString';
import { PaginatedData, PaginationParams } from '@lib/interfaces';
export interface File {
  ID: number;
  title: string;
  filename: string;
  filesize: number;
  url: string;
  link: string;
  alt: string;
  author: string;
  description: string;
  caption: string;
  name: string;
  status: string;
  uploaded_to: number;
  date: string;
  modified: string;
  menu_order: number;
  mime_type: string;
  type: string;
  subtype: string;
  icon: string;
  width: number;
  height: number;
  sizes: {
    thumbnail: string;
    'thumbnail-width': number;
    'thumbnail-height': number;
    medium: string;
    'medium-width': number;
    'medium-height': number;
    medium_large: string;
    'medium_large-width': number;
    'medium_large-height': number;
    large: string;
    'large-width': number;
    'large-height': number;
    '1536x1536': string;
    '1536x1536-width': number;
    '1536x1536-height': number;
    '2048x2048': string;
    '2048x2048-width': number;
    '2048x2048-height': number;
  };
}
export interface Press {
  description: string | null;
  post_content: string | null;
  post_date: string | null;
  post_name: string | null;
  post_title: string | null;
  post_type: string | null;
  thumbnail: string | null;
  video_id: string | null;
  year: string | null;
  file: File;
}

export interface GetPressResponse extends PaginatedData {
  data: Array<Press>;
}
export interface GetPressDetailResponse {
  press: Array<Press>;
}

export const getPress = (params: PaginationParams) => {
  return get<PaginationParams, GetPressResponse>({ url: `v1/presse/archive?${objectToQueryString(params)}` });
};

export const getPressBySlug = (slug?: string) => get<undefined, GetPressDetailResponse>({ url: `v1/presse/all`, params: { slug } });
