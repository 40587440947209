import Button from '@components/shared/Button';
import { LocationContactFormik } from '@lib/hooks/formik/useLocationContactFormik';
import React from 'react';
import { useIntl } from 'react-intl';
//Components
import { Form } from 'reactstrap';
import styled, { css } from 'styled-components';
import { Input } from '../Input';
import { LOCATION_STEP } from './LocationForm';

const StyledForm = styled.div`
  width: 100%;
`;
const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
`;
const StyledRow = styled.div`
  margin-left: -15px;
  margin-right: -15px;
`;
const StyledCol = styled.div<IColType>`
  display: inline-block;
  width: 100%;
  padding: 0 15px;

  ${({ size }) =>
    size === 6 &&
    css`
      float: left;
      width: 50%;
    `}
`;

const StyledButton = styled(Button)`
  font-size: 24px;
  padding: 24px 36px;
`;

interface IColType {
  size?: number;
}

interface Props {
  formik: LocationContactFormik;
  setStep: React.Dispatch<React.SetStateAction<LOCATION_STEP>>;
}

export const LocationContact = ({ formik, setStep }: Props) => {
  const { formatMessage: t } = useIntl();
  const handleBack = () => setStep(LOCATION_STEP.LOCATION_DATA);

  return (
    <StyledForm className="LocationContact">
      <Form onSubmit={formik.handleSubmit}>
        <StyledRow>
          <StyledCol size={6}>
            <Input
              optional
              type="text"
              name="first_name"
              id="first_name"
              label={t({ id: 'first_name' })}
              className="form-control-custom"
              value={formik.values.first_name}
              invalid={!!formik.errors.first_name && formik.touched.first_name}
              onChange={formik.handleChange}
              error={formik.errors.first_name}
            />
          </StyledCol>
          <StyledCol size={6}>
            <Input
              optional
              type="text"
              name="last_name"
              id="last_name"
              label={t({ id: 'last_name' })}
              className="form-control-custom"
              value={formik.values.last_name}
              invalid={!!formik.errors.last_name && formik.touched.last_name}
              onChange={formik.handleChange}
              error={formik.errors.last_name}
            />
          </StyledCol>
          <StyledCol>
            <Input
              optional
              type="email"
              name="email"
              id="email"
              label={t({ id: 'email' })}
              className="form-control-custom"
              value={formik.values.email}
              invalid={!!formik.errors.email && formik.touched.email}
              onChange={formik.handleChange}
              error={formik.errors.email}
            />
          </StyledCol>
          <StyledCol>
            <Input
              optional
              type="email"
              name="email_confirm"
              id="email_confirm"
              label={t({ id: 'email_adresse_wiedeholen' })}
              className="form-control-custom"
              value={formik.values.email_confirm}
              invalid={!!formik.errors.email_confirm && formik.touched.email_confirm}
              onChange={formik.handleChange}
              error={formik.errors.email_confirm}
            />
          </StyledCol>
          <StyledCol>
            <Input
              optional
              type="text"
              name="phone"
              id="phone"
              label={t({ id: 'phone' })}
              className="form-control-custom"
              value={formik.values.phone}
              invalid={!!formik.errors.phone && formik.touched.phone}
              onChange={formik.handleChange}
              error={formik.errors.phone}
            />
          </StyledCol>
          <StyledCol>
            <StyledButtonWrapper>
              <StyledButton color="black" onClick={handleBack}>
                {t({ id: 'button_back' })}
              </StyledButton>
              <StyledButton color="black">{t({ id: 'button_next_step' })}</StyledButton>
            </StyledButtonWrapper>
          </StyledCol>
        </StyledRow>
      </Form>
    </StyledForm>
  );
};
