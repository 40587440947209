import { ReactComponent as ArrowLeft } from '@assets/icons/icons_arrow-left.svg';
import { ReactComponent as ArrowRight } from '@assets/icons/icons_arrow-right.svg';
import { ReactComponent as CloseIcon } from '@assets/icons/icons_close.svg';
import { ReactComponent as SpinnerIcon } from '@assets/icons/icons_spinner.svg';
import { SimpleButton } from '@components/styled-components/StyledComponents';
import breakpoints from '@lib/common/breakpoints';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import styled from 'styled-components';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Wrapper = styled.div`
  position: fixed;
  z-index: 2022;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  height: calc(100vh - 74px);
`;

const BottomBar = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 80px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${(props) => props.theme.colors.yellow};
  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 15px 40px;
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 15px 20px;
  }
`;

const BottomBarTitle = styled.h1`
  flex: 1;
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.11px;
  color: #000;
  padding-right: 15px;

  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 22px;
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 16px;
  }
`;

const BottomBarControls = styled.div`
  display: flex;
  align-items: center;

  button {
    width: 30px;
    margin-right: 36px;
    &:last-of-type {
      margin-right: 0;
    }

    @media only screen and ${breakpoints.maxWidth.md} {
      width: 20px;
      margin-right: 15px;
    }

    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 16px;
    }
  }
`;

const StyledSpinner = styled(SpinnerIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

interface IFullscreenPDFProps {
  title: string;
  pdf: any;
  className?: string;
  onClose?: () => void;
}

const FullscreenPDF: FC<IFullscreenPDFProps> = (props) => {
  const [pageNumber, setPageNumber] = useState(1); //setting 1 to show fisrt page

  const bottomBarRef = useRef<null>(null);

  useEffect(() => {
    document.body.classList.add('overflow-hidden');
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, []);

  const changePage = (offset: number) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  const bottomBarHeight = (bottomBarRef?.current as any)?.offsetHeight;

  return (
    <Wrapper className={props.className}>
      <Content style={{ height: `calc(100% - ${bottomBarHeight}px)` }}>
        {/* <Spinner animation="grow" color="#ffffff" /> */}
        <StyledSpinner />
        <Document file={props.pdf}>
          <Page pageNumber={pageNumber} />
        </Document>
      </Content>

      <BottomBar ref={bottomBarRef}>
        <BottomBarTitle>{props.title}</BottomBarTitle>
        <BottomBarControls>
          <SimpleButton onClick={previousPage}>
            <ArrowLeft />
          </SimpleButton>
          <SimpleButton onClick={nextPage}>
            <ArrowRight />
          </SimpleButton>
          <SimpleButton onClick={props.onClose}>
            <CloseIcon />
          </SimpleButton>
        </BottomBarControls>
      </BottomBar>
    </Wrapper>
  );
};

export default FullscreenPDF;
