import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';

export interface ProfileDeleteFields {
  email: string;
  password: string;

  reason: string;

  no_longer_use: boolean;
  too_much_effort: boolean;
  dont_want_to_show: boolean;
}

interface UseProfileDeleteFormOptions {
  initialValues?: ProfileDeleteFields;
  onSubmit: (values: ProfileDeleteFields, formikHelpers: FormikHelpers<ProfileDeleteFields>) => Promise<any>;
}

export const useProfileDeleteFormik = (options: UseProfileDeleteFormOptions) => {
  const BranchSchema = Yup.object().shape({
    email: Yup.string().email().required('Email is required'),
    password: Yup.string().required('PLZ is required'),

    // no_longer_use: Yup.boolean().oneOf([true], 'Mein Profil wird nicht mehr verwendet is required'),
    // too_much_effort: Yup.boolean().oneOf([true], 'Zu viel Aufwand is required'),
    // dont_want_to_show: Yup.boolean().oneOf([true], 'Möchte ich nicht angeben is required'),

    reason: Yup.string().required('Reason is required'),
  });

  return useFormik<ProfileDeleteFields>({
    initialValues: {
      email: '',
      password: '',
      no_longer_use: false,
      too_much_effort: false,
      dont_want_to_show: false,
      reason: '',
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: BranchSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type ProfileDeleteFormik = ReturnType<typeof useProfileDeleteFormik>;
