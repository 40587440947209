import styled from 'styled-components';

export const Link = styled.a``;

export const SimpleButton = styled.button`
  background-color: transparent;
  cursor: pointer;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  &:disabled {
    opacity: 0.5;
  }
`;
