import { ReactComponent as ExternalArrow } from '@assets/icons/icons_arrow-external_small.svg';
import { ReactComponent as ArrowIcon } from '@assets/icons/icons_arrow-right.svg';
import { ReactComponent as DownloadIcon } from '@assets/icons/icons_download.svg';
import breakpoints from '@lib/common/breakpoints';
import { downloadFileFromUrl } from '@lib/helpers/downloadFileFromUrl';
import ModuleType from '@lib/interfaces/Modules';
import React, { FC } from 'react';
import Marquee from 'react-fast-marquee';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

const WrapperSmall = styled.div`
  background-color: #000000;
  padding: 18px 15px;
  border: 1px solid #000000;
  &:hover {
    background-color: #ffffff;
    svg path {
      stroke: #000000;
    }
    svg g {
      stroke: #000000;
    }
    p {
      color: #000000 !important;
    }
  }

  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 12px 15px;
  }
`;

const WrapperLarge = styled.div`
  width: 100%;
  padding: 80px 20px 80px 20px;
  background-color: ${(props) => props.theme.colors.violet};
  display: flex;
  justify-content: center;

  &:hover {
    background-color: #ffffff;

    svg path {
      stroke: ${(props) => props.theme.colors.violet};
    }

    p {
      color: ${(props) => props.theme.colors.violet};
    }
  }

  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 48px 20px;
  }

  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 30px 20px;
  }
`;

const Text = styled.p`
  color: #ffffff;
  font-size: 48px;
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  line-height: 1.25;
  font-weight: normal;
  text-align: center;
  margin-left: 15px;
  text-transform: uppercase;
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 25px;
    line-height: 1.25;
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 22px;
    line-height: 1.36;
  }
`;

const NewsTickerLink = styled.a`
  color: #000000;
  font-size: 48px;
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  line-height: 1.25;
  font-weight: normal;
  text-align: center;
  margin-left: 15px;
  text-transform: uppercase;

  &:hover {
    color: #ffffff;
  }

  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 25px;
    line-height: 1.25;
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 22px;
    line-height: 1.36;
  }
`;

const StyledArrow = styled(ExternalArrow)<{ arrowBack?: boolean }>`
  width: 30px;
  height: 30px;
  transform: ${(props) => (props.arrowBack ? 'rotate(-85deg)' : null)};
  path {
    stroke: white;
  }

  @media only screen and ${breakpoints.maxWidth.md} {
    width: 20px;
    height: 20px;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    display: none;
  }
`;

const StyledDownloadIcon = styled(DownloadIcon)`
  width: 30px;
  height: 30px;

  g {
    stroke: white;
  }

  @media only screen and ${breakpoints.maxWidth.md} {
    width: 20px;
    height: 20px;
  }
`;

const ReactLink = styled(RouterLink)`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
`;

const AnchorLink = styled.a`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
`;

const NewsTicker = styled.div`
  display: flex;
  background-color: #ff3d3d;
  padding: 15px 0;
`;

const NewsTickerWrapper = styled.div`
  display: flex;

  p {
    display: flex;
    align-items: center;
    color: #000000;
    margin-right: 25px;
    margin-left: 0;
  }

  svg {
    margin-left: 25px;
  }
`;

interface ILinkButtonProps {
  className?: string;
  data?: {
    arrow_back?: boolean;
    news_ticker?: boolean;
    large_button?: boolean;
    is_download_button?: boolean;
    link?: {
      title?: string;
      url?: string;
      target?: string;
    };
  };
}

const Module00Button: FC<ILinkButtonProps> = (props) => {
  const { data, className } = props;
  const WrapperComponent = data?.large_button ? WrapperLarge : WrapperSmall;

  const handleDownload = (e: any) => {
    e.preventDefault();
    downloadFileFromUrl(data?.link?.url);
  };

  const getUrl = () => {
    const url = data?.link?.url;

    if (!url) {
      return {
        other: false,
        url: '',
      };
    }

    if (!url.includes('vfc')) {
      return {
        other: true,
        url: url,
      };
    }

    const urlArray = url.split('/').filter((x: string) => x);
    const [, , ...rest] = urlArray;
    const slug = rest.join('/');

    return {
      other: false,
      url: slug,
    };
  };

  const renderTickerElements = () => {
    return new Array(4).fill(undefined).map((item, index) => {
      return (
        <Text key={`item-ticker-${index}`}>
          <NewsTickerLink href={data?.link?.url ?? ''} target={data?.link?.target} key={`ticker-${index}`}>
            {data?.link?.title} <ArrowIcon style={{ width: 25 }} />
          </NewsTickerLink>
        </Text>
      );
    });
  };

  const linkUrl = getUrl();
  const isNewsTicker = data?.news_ticker;

  if (isNewsTicker) {
    return (
      <NewsTicker>
        <Marquee gradient={false} direction="right" speed={100}>
          <NewsTickerWrapper>{renderTickerElements()}</NewsTickerWrapper>
        </Marquee>
      </NewsTicker>
    );
  }
  return (
    <WrapperComponent className={className} data-module={ModuleType.MODULE_00}>
      {linkUrl?.other ? (
        <AnchorLink href={linkUrl?.url ?? ''} target={data?.link?.target ?? ''} onClick={data?.is_download_button ? handleDownload : undefined}>
          {data?.is_download_button ? <StyledDownloadIcon /> : <StyledArrow arrowBack={data?.arrow_back} />}
          <Text>{data?.link?.title}</Text>
        </AnchorLink>
      ) : (
        <ReactLink to={linkUrl?.url ?? ''} target={data?.link?.target ?? ''} onClick={data?.is_download_button ? handleDownload : undefined}>
          {data?.is_download_button ? <StyledDownloadIcon /> : <StyledArrow arrowBack={data?.arrow_back} />}
          <Text>{data?.link?.title}</Text>
        </ReactLink>
      )}
    </WrapperComponent>
  );
};

export default Module00Button;
