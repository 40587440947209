// import { ReactComponent as Com } from '@assets/home/com.svg';
// import { ReactComponent as Film } from '@assets/home/film.svg';
// import { ReactComponent as Mission } from '@assets/home/mission.svg';
// import { ReactComponent as Vienna } from '@assets/home/vienna.svg';
import Header from '@components/header/Header';
import breakpoints from '@lib/common/breakpoints';
import { Language, useReactIntlProvider } from '@lib/context/ReactIntlProvider/ReactIntlProvider';
import { useHeaderPosition } from '@lib/hooks/useHeaderPoisition';
import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const Module01Layout = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.violet};
  position: relative;

  @media only screen and ${breakpoints.maxWidth.sm} {
    //padding-top: 10px;
  }

  //@media (max-height: 550px) {
  //  justify-content: flex-start;
  //}
  @media (orientation: landscape) and (max-width: 992px) {
    header {
      margin-bottom: -55px;
    }

    .m01-text-description {
      display: none;
    }

    .m01-text-1 {
      font-size: 54px;
      height: 40px;
    }
    .m01-text-2 {
      font-size: 54px;
      height: 40px;
    }
    .m01-text-3 {
      font-size: 51px;
      height: 37px;
    }

    .m01-text-4 {
      font-size: 51px;
      height: 37px;
    }

    .mission {
      svg {
        margin-right: 10px !important;
      }
    }
  }
`;

interface Module01Props {
  onScrollDown: () => void;
  data?: {
    description?: string;
    video_1?: string;
    video_1_thumbnail?: string;
    video_2?: string;
    video_2_thumbnail?: string;
    video_3?: string;
    video_3_thumbnail?: string;
  };
}

export default function Module01(props: Module01Props) {
  const { activeLanguage } = useReactIntlProvider();
  // const { data } = props;

  const ref = useRef<any>(null);
  // const windowWidth = useWindowWidth();
  const position = useHeaderPosition(ref);

  const [elementsHeights, setElementsHeights] = useState<any>({
    header: null,
  });

  const handleScroll = () => {
    const headerHeight = document.querySelector('header')?.clientHeight;

    setElementsHeights({
      header: headerHeight ?? null,
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onMessageReceivedFromIframe = useCallback((event) => {
    if (event?.data?.type !== 'arrow-clicked') {
      return;
    }
    props.onScrollDown();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.addEventListener('message', onMessageReceivedFromIframe);
    return () => window.removeEventListener('message', onMessageReceivedFromIframe);
  }, [onMessageReceivedFromIframe]); // eslint-disable-line react-hooks/exhaustive-deps

  const iframeSrc = `https://backend.viennafilmcommission.at/m01/${activeLanguage === Language.DE ? 'm01.html' : 'm01-en.html'}`;

  return (
    <Module01Layout data-module="m01">
      <div
        style={{ height: window.innerHeight - (elementsHeights.headerHeight ?? 0) }}
        dangerouslySetInnerHTML={{
          __html: `<iframe className='m001' style='width: 100%; height: 100%; border: 0' src=${iframeSrc} />`,
        }}
      />

      <Header position={position} />
    </Module01Layout>
  );
}
