import breakpoints from '@lib/common/breakpoints';
import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
  display: block;
  width: 100%;
  //max-width: 1224px;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 27px;

  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 0 20px;
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 0 20px;
  }
`;

interface Props {
  className?: string;
  children?: React.ReactNode | React.ReactNodeArray;
}

export const Container = (props: Props) => {
  return <StyledContainer className={props.className ?? ''}>{props.children}</StyledContainer>;
};
