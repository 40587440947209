import Button from '@components/shared/Button';
import { Container } from '@components/shared/Container';
import { RegisterForm } from '@components/user/RegisterForm/RegisterForm';
import breakpoints from '@lib/common/breakpoints';
import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

const StyledRegister = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.yellow};
  padding: 120px 0;
  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 100px 0;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 80px 0;
  }
`;

export const StyledPageHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  @media only screen and ${breakpoints.maxWidth.md} {
    margin-bottom: 50px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    margin-bottom: 50px;
  }
  h1 {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 58px;
    line-height: 1.05em;
    color: ${(props) => props.theme.colors.black};
    margin-bottom: 27px;
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 28px;
      line-height: 1.18em;
      margin-bottom: 24px;
    }

    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 24px;
      line-height: 1.18em;
      margin-bottom: 24px;
    }
  }
  p {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 36px;
    letter-spacing: 0.4px;
    line-height: 1.18em;
    color: ${(props) => props.theme.colors.black};
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 20px;
      letter-spacing: 0.2px;
      line-height: 1.2em;
    }

    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 18px;
      letter-spacing: 0.2px;
      line-height: 1.2em;
    }
  }
`;

const StyledSuccess = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  padding: 120px 150px 120px;
  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 80px 24px 50px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 80px 24px 50px;
  }
`;
const StyledSuccessTitle = styled.h3`
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  font-size: 36px;
  letter-spacing: 0.4px;
  line-height: 1.2em;
  color: ${(props) => props.theme.colors.black};
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 24px;
    letter-spacing: 0.2px;
    margin-right: 0;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 24px;
    letter-spacing: 0.2px;
    margin-right: 0;
  }
`;

const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 80px;
  @media only screen and ${breakpoints.maxWidth.md} {
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
  }
`;

const StyledButton = styled(Button)`
  font-size: 24px;
  padding: 24px 36px;
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 20px;
    padding: 10px 18px;
    margin-left: 0;
    margin-top: 20px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 20px;
    padding: 10px 18px;
    margin-left: 0;
    margin-top: 20px;
  }
`;

interface Props {}

export const SuccessRegister = () => {
  const { formatMessage: t } = useIntl();
  return (
    <>
      <StyledPageHeader>
        <h1>{t({ id: 'user_registration_title1' })}</h1>
        <p>{t({ id: 'user_registration_title2' })}</p>
      </StyledPageHeader>
      <StyledSuccess className="RegisterForm">
        <StyledSuccessTitle>{t({ id: 'user_registration_success' })}</StyledSuccessTitle>
        <StyledButtonWrapper>
          <StyledButton color="black" to="/profile">
            {t({ id: 'back_to_profile' })}
          </StyledButton>
        </StyledButtonWrapper>
      </StyledSuccess>
    </>
  );
};

export const Register = (props: Props) => {
  return (
    <StyledRegister className="Register">
      <Container><RegisterForm /></Container>
    </StyledRegister>
  );
};
