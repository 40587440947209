import { useAuthContext } from '@lib/context/AuthContext/AuthContext';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AppRoute } from './Routes';

export const RestrictedRoute = (props: AppRoute) => {
  const authCtx = useAuthContext();

  if (authCtx.isAuthenticated) {
    return <Redirect to="/" />;
  }

  const Layout = props.layout?.component;

  const hideHeader = props.layout?.hideHeader;

  return Layout ? (
    <Layout hideFooter={props.layout?.hideFooter} hideHeader={hideHeader}>
      <Route {...props} />
    </Layout>
  ) : (
    <React.Fragment>
      <Route {...props} />
    </React.Fragment>
  );
};
