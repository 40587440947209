import { News } from '@api/news';
import SearchResultsHeader from '@components/search/SearchResultsHeader';
import { Container } from '@components/shared/Container';
import Filters, { IFilters } from '@components/shared/Filters';
import LoadMoreButton from '@components/shared/LoadMoreButton';
import NewsListItem from '@components/shared/NewsListItem';
import NoResults from '@components/shared/NoResults';
import breakpoints from '@lib/common/breakpoints';
import sortYears from '@lib/helpers/sortYears';
import useGetNews from '@lib/hooks/queries/useGetNews';
import useLocalStorageFilters from '@lib/hooks/useLocalStorageFilters';
import { ObjectAny } from '@lib/interfaces';
import ModuleType from '@lib/interfaces/Modules';
import React, { FC, useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useIntl } from 'react-intl';
import { Col, Row } from 'react-styled-flexboxgrid';
import styled, { ThemeProvider } from 'styled-components';
import { useDebounce } from 'use-debounce/esm';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding-top: 110px;
  padding-bottom: 110px;
  width: 100%;

  > div {
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

const StyledRow = styled(Row)``;

const StyledCol = styled(Col)`
  margin-bottom: 60px;
  padding: 0 27px;

  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 0 16px;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    padding: 0 5px;
    margin-bottom: 50px;
  }
`;

const ListWrapper = styled.div`
  position: relative;
  margin-left: -27px;
  margin-right: -27px;

  @media only screen and ${breakpoints.maxWidth.md} {
    margin-left: -16px;
    margin-right: -16px;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    margin-left: -5px;
    margin-right: -5px;
  }
`;

interface IModule28Props {
  data?: {
    filters: IFilters;
    title: string;
  };

  searchProps?: {
    searchValue?: string;
    showResultsHeader?: boolean;
  };
}

const getInitialFilters = (key: string, filters?: ObjectAny): ObjectAny | undefined => {
  if (!filters || !filters[key] || typeof filters[key] !== 'object') {
    return undefined;
  }

  return Object.keys(filters[key]['values']).reduce((acc, next) => {
    return {
      ...acc,
      [next as any]: '',
    };
  }, {});
};

const Module28: FC<IModule28Props> = (props) => {
  const { formatMessage } = useIntl();

  const { getLocalStorageFilterByName, setLocalStorageFilter } = useLocalStorageFilters(ModuleType.MODULE_28);

  const [searchValue, setSearchValue] = useState(props.searchProps?.searchValue ?? '');
  const [debouncedSearchValue] = useDebounce(searchValue, 500);

  const [filters, setFilters] = useState<ObjectAny>(
    getLocalStorageFilterByName('filters', {
      years: getInitialFilters('years', props.data?.filters),
    }),
  );

  useEffect(() => {
    setLocalStorageFilter({
      filters,
    });
  }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSearchValue(props.searchProps?.searchValue ?? '');
  }, [props.searchProps?.searchValue]);

  const filtersParams =
    filters && filters.years
      ? Object.values(filters.years)
          ?.filter((x) => !!x)
          .join(',')
      : '';

  const { data: newsData, isFetching, hasNextPage, fetchNextPage } = useGetNews(filtersParams, debouncedSearchValue);

  const theme = useMemo(() => {
    return {
      flexboxgrid: {
        gridSize: 12,
        gutterWidth: 0,
        outerMargin: 0,
        mediaQuery: 'only screen',
        breakpoints: {
          xs: 0,
          sm: 36,
          md: 48,
          lg: 61,
        },
      },
    };
  }, []);

  const allNews = newsData?.pages.map((item) => item.news).flat() ?? [];

  const handleFilterChange = (keyEl: string, el: string) => {
    const isSelected = !!filters?.[keyEl]?.[el];

    const newFilters = filters ? { ...filters[keyEl], [el]: isSelected ? undefined : el } : { [el]: isSelected ? undefined : el };
    setFilters({
      [keyEl]: { ...newFilters },
    });
  };

  const isOnSearchPage = Boolean(props.searchProps);

  const sortedYears = props?.data?.filters?.years?.values ? sortYears(props.data.filters?.years?.values) : {};

  const formattedFilters = {
    ...props.data?.filters,
    years: {
      label: props.data?.filters?.years?.label ?? '',
      values: sortedYears,
    },
  };

  return (
    <>
      <Filters isOnSearch={isOnSearchPage} filters={formattedFilters} selectedFilters={filters} onFiltersChange={handleFilterChange} />
      <Wrapper data-module={ModuleType.MODULE_28}>
        <Container>
          <ListWrapper>
            <InfiniteScroll hasMore={Boolean(hasNextPage)} dataLength={allNews.length} next={fetchNextPage} scrollThreshold={1} loader={null}>
              <ThemeProvider theme={theme}>
                {props.searchProps?.showResultsHeader && allNews?.length ? (
                  <SearchResultsHeader link="news" title={`${formatMessage({ id: 'news' })} (${allNews?.length}) `} />
                ) : null}

                <StyledRow>
                  {(!allNews || allNews.length === 0) && !isFetching ? (
                    <NoResults />
                  ) : (
                    allNews?.map((newsItem: News, index) => {
                      return (
                        <StyledCol key={`m28-news-item-${index}`} xs={6} sm={6} md={6} lg={4}>
                          <NewsListItem
                            ID={index}
                            link={newsItem?.link}
                            post_author={''}
                            post_content={newsItem?.post_content || ''}
                            post_date={newsItem?.post_date || ''}
                            post_name={newsItem?.post_name || ''}
                            post_title={newsItem?.post_title || ''}
                            post_type={newsItem?.post_type || ''}
                            thumbnail={newsItem?.thumbnail || ''}
                          />
                        </StyledCol>
                      );
                    })
                  )}
                </StyledRow>
              </ThemeProvider>
              {isFetching && <LoadMoreButton isLoading />}
            </InfiniteScroll>
          </ListWrapper>
        </Container>
      </Wrapper>
    </>
  );
};

export default Module28;
