import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';

export interface RegisterFields {
  first_name: string;
  last_name: string;
  company: string;
  email: string;
  email_confirmation: string;
  password: string;
  password_confirmation: string;
}

interface UseRegisterFormOptions {
  initialValues?: RegisterFields;
  onSubmit: (values: RegisterFields, formikHelpers: FormikHelpers<RegisterFields>) => Promise<any>;
}

export const useRegisterFormik = (options: UseRegisterFormOptions) => {
  const RegisterSchema = Yup.object().shape({
    first_name: Yup.string().required('Vorname is required'),
    last_name: Yup.string().required('Nachname is required'),
    email: Yup.string().email('Please enter a valid email!').required('Email is required'),
    email_confirmation: Yup.string()
      .required('Email is required')
      .oneOf([Yup.ref('email'), null], 'Email must match'),
    password: Yup.string().min(6, 'Password should be more than 6 letters').required('Password is required'),
    password_confirmation: Yup.string()
      .required('Password is required')
      .oneOf([Yup.ref('password'), null], 'Das Passwort stimmt nicht überein.'),
  });

  return useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      company: '',
      email: '',
      email_confirmation: '',
      password: '',
      password_confirmation: '',
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: RegisterSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type RegisterFormik = ReturnType<typeof useRegisterFormik>;
