import { getPress, GetPressResponse } from '@api/press';
import QueryKeys from '@lib/constants/QueryKeys';
import { AxiosError } from 'axios';
import { useInfiniteQuery } from 'react-query';

export default function useGetPress() {
  return useInfiniteQuery<GetPressResponse, AxiosError>(QueryKeys.press(), ({ pageParam = 1 }) => getPress({ page: pageParam }), {
    getNextPageParam: ({ current_page, total_pages }) => {
      if (current_page === total_pages) {
        return;
      }
      return current_page + 1;
    },
  });
}
