import { useReactIntlProvider } from '@lib/context/ReactIntlProvider/ReactIntlProvider';
import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';

export interface LocationUploadFile {
  url: string;
  id: number;
  title: string;
  copyright: string;
}
export interface LocationUploadVideo {
  url: string;
  thumbnail?: string;
  title: string;
  copyright: string;
}

export interface LocationInfoFields {
  location_name: string;
  location_categories: number[] | any;
  epochs: number;
  location_art: number;
  location_ausstattung: number[] | any;
  motive_description: string;
  main_photo_id?: LocationUploadFile;
  other_photos?: LocationUploadFile[];
  videos?: LocationUploadVideo[];
  company_law_registration: string;

  new_location_category: string;

  company_name: string;
}

interface UseLocationFormOptions {
  initialValues?: LocationInfoFields;
  onSubmit: (values: LocationInfoFields, formikHelpers: FormikHelpers<LocationInfoFields>) => Promise<any>;
}

const errorMessages: any = {
  company_name: {
    en: 'Description text is required',
    de: 'Beschreibungstext fehlt',
  },
};

export const useLocationInfoFormik = (options: UseLocationFormOptions) => {
  const { activeLanguage } = useReactIntlProvider();

  const getErrorMessage = (fieldName: string) => {
    return errorMessages[fieldName][activeLanguage];
  };

  const LocationSchema = Yup.object().shape({
    location_name: Yup.string().required('Name is required'),
    location_categories: Yup.array().min(1, 'Category is required').required('Category is required'),
    motive_description: Yup.string().required('Motif description is required'),
    epochs: Yup.string().required('Epoch is required'),
    location_art: Yup.string().required('Outdoor/Indoor is required'),
    // location_ausstattung: Yup.array().min(1, 'Infrastructure is required').required('Infrastructure is required'),
    company_name: Yup.string().when('company_law_registration', {
      is: (val: string) => val === 'Nein',
      then: Yup.string().required(getErrorMessage('company_name')),
    }),
    company_law_registration: Yup.string().required('Englische Version meiner Texte is required'),
  });

  return useFormik<LocationInfoFields>({
    initialValues: {
      location_name: '',
      location_categories: [],
      new_location_category: '',
      motive_description: '',
      epochs: 0,
      location_art: 0,
      location_ausstattung: [],
      company_law_registration: 'JA',
      company_name: '',
      ...options.initialValues,
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: LocationSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type LocationInfoFormik = ReturnType<typeof useLocationInfoFormik>;
