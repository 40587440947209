import AuthContextProvider from '@lib/context/AuthContext/AuthContextProvider';
import ReactIntlProvider from '@lib/context/ReactIntlProvider/ReactIntlProvider';
import ReactQueryProvider from '@lib/context/ReactQueryProvider/ReactQueryProvider';
import ThemeProvider from '@lib/context/ThemeProvider/ThemeProvider';
import { FC } from 'react';
import { ConfirmationContextProvider } from './ConfirmationContext/ConfirmationContextProvider';
import { UIContextProvider } from './UIContext/UIContextProvider';

const combineProviders = (...components: FC[]): FC => {
  return components.reduce(
    (AccumulatedComponents, CurrentComponent) => {
      return ({ children }) => {
        return (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        );
      };
    },
    ({ children }) => <>{children}</>,
  );
};

/**
 *  Order of the providers is significant
 *  NOTE: If you need to change the order, DO IT CAREFULLY!
 */
const providers = [ReactQueryProvider, ReactIntlProvider, ThemeProvider, UIContextProvider, ConfirmationContextProvider, AuthContextProvider];

const AppContextProviders = combineProviders(...(providers as FC[]));

export default AppContextProviders;
