import { validateFilmingStep2 } from '@api/filming';
import * as API from '@api/general';
import Button from '@components/shared/Button';
import breakpoints from '@lib/common/breakpoints';
import { FilmingFormik } from '@lib/hooks/formik/useFilmingFormik';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
//Components
import { Form } from 'reactstrap';
import styled, { css } from 'styled-components';
import { Input } from '../Input';
import { MultiSelect } from '../MultiSelect';
import { FILMING_STEP } from './FilmingForm';

const StyledForm = styled.div`
  width: 100%;
`;
const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  @media only screen and ${breakpoints.maxWidth.md} {
    flex-direction: column-reverse;
    margin-top: 20px;
    button:first-child {
      margin-bottom: 0 !important;
    }
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    flex-direction: column-reverse;
    margin-top: 20px;
    button:first-child {
      margin-bottom: 0 !important;
    }
  }
`;
const StyledRow = styled.div`
  margin-left: -10px;
  margin-right: -10px;
`;
const StyledCol = styled.div<IColType>`
  display: inline-block;
  width: 100%;
  padding: 0 10px;

  ${({ size }) =>
    size === 4 &&
    css`
      float: left;
      width: 33.333%;
      @media only screen and ${breakpoints.maxWidth.md} {
        width: 100%;
      }
      @media only screen and ${breakpoints.maxWidth.sm} {
        width: 100%;
      }
    `}
`;

const StyledButton = styled(Button)`
  font-size: 24px;
  padding: 24px 36px;
  @media only screen and ${breakpoints.maxWidth.md} {
    width: 100%;
    font-size: 20px;
    padding: 10px 18px;
    margin-bottom: 20px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    width: 100%;
    font-size: 20px;
    padding: 10px 18px;
    margin-bottom: 20px;
  }
`;

interface IColType {
  size?: number;
}

interface Props {
  formik: FilmingFormik;
  setStep: React.Dispatch<React.SetStateAction<FILMING_STEP>>;
  type_of_production: { label: string; value: number }[];
}

const step2Fields = [
  'production_title',
  'type_of_production',
  'scene_description',
  'synopsis',
  'technical_effort',
  'number_of_people_on_crew',
  'number_of_people_cast',
];

export const FilmingProduction = ({ formik, setStep, type_of_production }: Props) => {
  const { formatMessage: t } = useIntl();
  const [file, setFile] = useState(t({ id: 'select_data' }));
  const handleBack = () => setStep(FILMING_STEP.APPLICANT);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    try {
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      setFile(e.target.files[0].name);
      const res = await API.uploadMedia(formData);
      formik.setFieldValue('synopsis', res.id);
      formik.setFieldTouched('synopsis');
    } catch (error) {
      //@ts-ignore
      formik.setFieldError('synopsis', error.response.data.data.message);
    }
  };

  const handleSelectChange = (key: string, value: number[]) => {
    formik.setFieldValue(key, value);
    formik.setFieldTouched(key);
  };

  const handleNext = async () => {
    try {
      const errors = await formik.validateForm(formik.values);

      if (formik.errors.scene_description || formik.errors.technical_effort) {
        return;
      }

      const step2Errors = Object.keys(errors).filter((err) => step2Fields.includes(err));

      const res = await validateFilmingStep2({
        step: '2',
        production_title: formik.values.production_title,
        type_of_production: formik.values.type_of_production.map((el) => el - 1),
        scene_description: formik.values.scene_description,
        synopsis: formik.values.synopsis || null,
        technical_effort: formik.values.technical_effort,
        number_of_people_on_crew: formik.values.number_of_people_on_crew === '0' ? 'zero' : formik.values.number_of_people_on_crew,
        number_of_people_cast: formik.values.number_of_people_cast === '0' ? 'zero' : formik.values.number_of_people_cast,
      });

      if (res.data?.valid && step2Errors?.length === 0) {
        setStep(3);
        formik.setErrors({});
        return;
      }
      await formik.submitForm();
      return;
    } catch (error) {
      //@ts-ignore
      formik.setErrors(error.response.data.data.errors);
    }
  };

  return (
    <StyledForm className="FilmingProduction">
      <Form onSubmit={formik.handleSubmit}>
        <StyledRow>
          <StyledCol>
            <Input
              type="text"
              name="production_title"
              id="production_title"
              label={t({ id: 'production_title' })}
              className="form-control-custom"
              value={formik.values.production_title}
              invalid={!!formik.errors.production_title && formik.touched.production_title}
              onChange={formik.handleChange}
              error={formik.errors.production_title}
            />
          </StyledCol>
          <StyledCol>
            <MultiSelect
              id="type_of_production"
              name="type_of_production"
              label={t({ id: 'type_of_production' })}
              className="form-control-custom"
              options={type_of_production}
              invalid={!!formik.errors.type_of_production && formik.touched.type_of_production}
              error={formik.errors.type_of_production}
              selectedOptions={formik.values.type_of_production}
              onChange={handleSelectChange}
            />
          </StyledCol>
          <StyledCol>
            <Input
              type="textarea"
              name="scene_description"
              id="scene_description"
              maxLength={1000}
              label={t({ id: 'scene_description' })}
              labelInfo={t({ id: 'scene_description' })}
              className="form-control-custom"
              value={formik.values.scene_description}
              invalid={!!formik.errors.scene_description && formik.touched.scene_description}
              onChange={formik.handleChange}
              error={formik.errors.scene_description}
            />
          </StyledCol>
          <StyledCol>
            <Input
              type="file"
              name="synopsis"
              id="synopsis"
              required
              label={t({ id: 'synopsis' })}
              labelInfo={t({ id: 'synopsis' })}
              className="form-control-custom input-image"
              fileText={file}
              invalid={!!formik.errors.synopsis && formik.touched.synopsis}
              onChange={handleFileChange}
              error={formik.errors.synopsis}
            />
          </StyledCol>
          <StyledCol>
            <Input
              type="textarea"
              name="technical_effort"
              id="technical_effort"
              maxLength={1000}
              label={t({ id: 'technical_effort' })}
              className="form-control-custom"
              value={formik.values.technical_effort}
              invalid={!!formik.errors.technical_effort && formik.touched.technical_effort}
              onChange={formik.handleChange}
              error={formik.errors.technical_effort}
            />
          </StyledCol>
          <StyledCol size={4}>
            <Input
              type="text"
              name="number_of_people_on_crew"
              id="number_of_people_on_crew"
              label={t({ id: 'number_of_people_on_crew' })}
              className="form-control-custom"
              value={formik.values.number_of_people_on_crew}
              invalid={!!formik.errors.number_of_people_on_crew && formik.touched.number_of_people_on_crew}
              onChange={formik.handleChange}
              error={formik.errors.number_of_people_on_crew}
            />
          </StyledCol>
          <StyledCol size={4}>
            <Input
              type="text"
              name="number_of_people_cast"
              id="number_of_people_cast"
              label={t({ id: 'number_of_people_cast' })}
              className="form-control-custom"
              value={formik.values.number_of_people_cast}
              invalid={!!formik.errors.number_of_people_cast && formik.touched.number_of_people_cast}
              onChange={formik.handleChange}
              error={formik.errors.number_of_people_cast}
            />
          </StyledCol>

          <StyledCol>
            <StyledButtonWrapper>
              <StyledButton color="black" onClick={handleBack}>
                {t({ id: 'button_back' })}
              </StyledButton>
              <StyledButton disabled={formik.isSubmitting} color="black" onClick={handleNext}>
                {formik.isSubmitting ? t({ id: 'loading' }) : t({ id: 'button_next_step' })}
              </StyledButton>
            </StyledButtonWrapper>
          </StyledCol>
        </StyledRow>
      </Form>
    </StyledForm>
  );
};
