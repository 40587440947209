import breakpoints from '@lib/common/breakpoints';
import React, { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: #FFFFFF;
  border-top: 2px solid #000000;
  border-bottom: 2px solid #000000;

  svg {
    width: 18px;
    height: 18px;
  }
`;

const Text = styled.p`
  color: #000000;
  font-size: 24px;
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  line-height: 1.25;
  font-weight: normal;
  text-align: center;
  margin-left: 15px;
  text-transform: uppercase;
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 25px;
    line-height: 1.25;
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 22px;
    line-height: 1.36;
  }
`;

const StyledButton = styled.span`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  padding: 18px 15px;
  cursor: pointer;

  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 12px 15px;
  }
`;

interface ILinkButtonProps {
  className?: string;
  hideArrow?: boolean;
  onClick: () => void;
  icon: React.ReactElement;
  title: string;
}

const FullWidthOutlineButton: FC<ILinkButtonProps> = (props) => {
  return (
    <Wrapper className={props.className} data-module="modulelink-button">
      <StyledButton onClick={props.onClick}>
        {props.icon}
        <Text>{props.title}</Text>
      </StyledButton>
    </Wrapper>
  );
};

export default FullWidthOutlineButton;
