import { ReactComponent as FacebookIcon } from '@assets/icons/icon-facebook.svg';
import { ReactComponent as LinkedinIcon } from '@assets/icons/icon-linkedin.svg';
import { ReactComponent as MailIcon } from '@assets/icons/icon-mail.svg';
import { ReactComponent as TwitterIcon } from '@assets/icons/icon-twitter.svg';
import { ReactComponent as ShareIcon } from '@assets/icons/icons_share.svg';
import Tooltip from '@components/shared/Tooltip';
import breakpoints from '@lib/common/breakpoints';
import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
`;

const Button = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;

  &:hover {
    svg path {
      stroke: ${(props) => props.theme.colors.mediumRed};
    }
  }
`;

const List = styled.div`
  background-color: ${(props) => props.theme.colors.black};
  border-radius: 2px;
  padding: 8px 10px;
  display: flex;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-left: -10px;
  margin-top: 4px;
  transition: all 0.3s ease-in-out;
  @media only screen and ${breakpoints.maxWidth.lg} {
    left: 10px;
    transform: none;
  }
  @media only screen and ${breakpoints.maxWidth.md} {
    left: 10px;
    transform: none;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    left: 10px;
    transform: none;
  }
  &:before {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    position: absolute;
    top: -3px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
    border-bottom: 3px solid ${(props) => props.theme.colors.black};
    transition: all 0.3s ease-in-out;
    @media only screen and ${breakpoints.maxWidth.lg} {
      left: 25px;
    }
    @media only screen and ${breakpoints.maxWidth.md} {
      left: 25px;
    }
    @media only screen and ${breakpoints.maxWidth.sm} {
      left: 25px;
    }
  }
  a {
    width: 15px;
    height: 15px;
    margin-right: 14px;
    transition: all 0.3s ease-in-out;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      opacity: 0.8;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }
  /*   
  position: absolute;
  right: -20px;
  top: 14px;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;

  p {
    font-size: 16px;
    font-family: GTFlexa-ExtendedMedium, sans-serif;
  }

  li {
    margin-right: -50px;
    cursor: pointer;
    svg {
      width: 25px;
      height: 25px;
    }
  } */
`;

interface IShareButtonProps {
  title?: string;
  url?: string;
  isLoading?: boolean;
  onClick?: () => void;
}

const ShareButton: FC<IShareButtonProps> = (props) => {
  const [opened, setOpened] = useState(false);
  const { formatMessage } = useIntl();
  const url = props.url || window.location.href || '';
  const title = props.title || '';

  const handleClick = () => {
    setOpened(!opened);
  };

  return (
    <Tooltip text={opened ? '' : formatMessage({ id: 'diesen.beitrag.teilen' })}>
      <Wrapper>
        <Button onClick={handleClick}>
          <ShareIcon />
        </Button>
        {opened && (
          <List>
            <a
              href={`https://twitter.com/intent/tweet/?url=${url};&amp;text=${title}`}
              target="_blank"
              rel="noopener nofollow noreferrer"
              onClick={handleClick}
            >
              <TwitterIcon />
            </a>
            <a href={`https://www.facebook.com/sharer.php?u=${url}`} target="_blank" rel="noopener nofollow noreferrer" onClick={handleClick}>
              <FacebookIcon />
            </a>
            <a href={`mailto:?subject=${title}&amp;body=${url}`} target="_blank" rel="noopener nofollow noreferrer" onClick={handleClick}>
              <MailIcon />
            </a>
            <a
              href={`https://www.linkedin.com/shareArticle?mini=true&amp;url=${url}`}
              target="_blank"
              rel="noopener nofollow noreferrer"
              onClick={handleClick}
            >
              <LinkedinIcon />
            </a>
          </List>
        )}
      </Wrapper>
    </Tooltip>
  );
};

export default ShareButton;
