import { ReactComponent as CloseIcon } from '@assets/icons/icons_close.svg';
import { SimpleButton } from '@components/styled-components/StyledComponents';
import breakpoints from '@lib/common/breakpoints';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

const Wrapper = styled.div``;

const SearchForLabel = styled.div`
  margin-bottom: 40px;
  padding: 0 80px;

  h1 {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 58px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.03;
    letter-spacing: normal;
    color: #000;
  }

  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 0 40px;
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    margin-bottom: 20px;
    padding: 0 20px;

    h1 {
      font-size: 20px;
    }
  }
`;

const SearchKeywordWrapper = styled.div`
  width: 100%;
  border-top: 2px solid #000000;
  border-bottom: 2px solid #000000;
  padding: 7px 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  input {
    flex: 1;
    padding-right: 15px;
    font-family: GTFlexa-ExtendedLight, sans-serif;
    font-size: 36px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: 0.4px;
    color: #000;
    border: none;
    &:focus {
      outline: none;
    }
  }

  button {
    width: 30px;
  }

  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 10px 40px;
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 10px 20px;

    input {
      font-size: 20px;
    }

    button {
      width: 20px;
    }
  }
`;

interface ISearchKeywordProps {
  className?: string;
  value?: string;
  onChange?: (e: string) => void;
}

const SearchKeyword: FC<ISearchKeywordProps> = (props) => {
  const { formatMessage } = useIntl();

  const handleOnChange = (e: any) => {
    const value = e.target.value;
    props.onChange?.(value);
  };

  return (
    <Wrapper className={props.className}>
      <SearchForLabel>
        <h1>{formatMessage({ id: 'suchresultate.fur' })}</h1>
      </SearchForLabel>
      <SearchKeywordWrapper>
        <input type="text" value={props.value ?? ''} onChange={handleOnChange} />
        {props.value && (
          <SimpleButton onClick={() => props.onChange?.('')}>
            <CloseIcon />
          </SimpleButton>
        )}
      </SearchKeywordWrapper>
    </Wrapper>
  );
};

export default SearchKeyword;
