import * as API from '@api/locations';
import { Container } from '@components/shared/Container';
import HandleFetchingState from '@components/shared/HandleFetchingState';
import { LocationForm, LOCATION_STEP } from '@components/user/Location/LocationForm';
import { useLocationConfirmationFormik } from '@lib/hooks/formik/useLocationConfirmationFormik';
import { useLocationContactFormik } from '@lib/hooks/formik/useLocationContactFormik';
import { useLocationDataFormik } from '@lib/hooks/formik/useLocationDataFormik';
import { useLocationInfoFormik } from '@lib/hooks/formik/useLocationInfoFormik';
import useGetLocationsEdit from '@lib/hooks/queries/useGetLocationsEdit';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

const StyledEditLocation = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.yellow};
  padding: 120px 0;
`;

export const StyledPageHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 120px;
  h1 {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 58px;
    line-height: 1.05em;
    color: ${(props) => props.theme.colors.black};
    margin-bottom: 27px;
  }
  p {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 36px;
    letter-spacing: 0.4px;
    line-height: 1.18em;
    color: ${(props) => props.theme.colors.black};
  }
`;

interface Props {
  slug: string;
}

export const EditLocation = (props: RouteComponentProps<Props>) => {
  const slug = props.match.params.slug;
  const { data, isLoading, error } = useGetLocationsEdit(slug);

  return (
    <HandleFetchingState loading={isLoading} error={error}>
      <EditLocationWrapper data={data} slug={slug} />
    </HandleFetchingState>
  );
};

const EditLocationWrapper = ({ data, slug }: { data: API.GetLocationEditResponse | undefined; slug: string }) => {
  const [step, setStep] = useState(LOCATION_STEP.LOCATION_INFO);

  const images = data?.location.other_photos?.map((image, index) => {
    return {
      url: image.image.url,
      id: image.image.ID,
      title: image.image_title,
      copyright: image.image_copyright,
    };
  });
  const videos = data?.location.videos?.map((video, index) => {
    return {
      url: video.video_link,
      thumbnail: video.video_thumbnail,
      title: video.video_title,
      copyright: video.video_copyright,
    };
  });

  const locationInfoFormik = useLocationInfoFormik({
    initialValues: {
      location_name: data?.location.title || '',
      location_categories: data?.location.categories.location_categories?.map((el) => el.term_id),
      new_location_category: '',
      motive_description: data?.location.motif_description || data?.location.motive_description || '',
      epochs: data?.location.categories.epochs?.map((el) => el.term_id)[0] || 0,
      location_art: data?.location.categories.location_art?.map((el) => el.term_id)[0] || 0,
      location_ausstattung: data?.location.categories.location_ausstattung?.map((el) => el.term_id),
      main_photo_id: { url: data?.location.thumbnail || '', id: data?.location.main_photo_id_attachment_id || 0, title: '', copyright: '' },
      other_photos: images,
      videos: videos,
      company_law_registration: data?.location.vfc_translate_text ? 'JA' : 'Nein',
      company_name: data?.location.company_name || '',
    },
    onSubmit: async (values, formikHelpers) => {
      try {
        await API.locationsStep1Edit({
          step: '1',
          slug: slug,
          location_name: values.location_name,
          new_location_category: values.new_location_category,
          location_categories: values.location_categories,
          //@ts-ignore
          epochs: values.epochs ? [parseInt(values.epochs)] : 0,
          location_art: values.location_art,
          location_ausstattung: values.location_ausstattung,
          company_name: values.company_name,
          motive_description: values.motive_description,
          main_photo_id: values.main_photo_id?.id || 0,
          other_photos: values.other_photos || [],
          videos: values.videos || [],
          company_law_registration: values.company_law_registration,
        });
        setStep(LOCATION_STEP.LOCATION_DATA);
      } catch (error) {
        //@ts-ignore
        formikHelpers.setErrors(error.response.data.data.errors);
      }
    },
  });

  const locationDataFormik = useLocationDataFormik({
    initialValues: {
      company: data?.location.company || '',
      street: data?.location.street || '',
      postcode: data?.location.postcode || '',
      ort: data?.location.ort || '',
      location_contact_person: data?.location.location_contact_person || '',
      email: data?.location.email || '',
      email_confirm: data?.location.email || '',
      phone: data?.location.phone || '',
    },
    onSubmit: async (values, formikHelpers) => {
      try {
        await API.locationsStep2Edit({
          step: '2',
          slug: slug,
          company: values.company,
          street: values.street,
          postcode: values.postcode,
          ort: values.ort,
          location_contact_person: values.location_contact_person,
          email: values.email,
          phone: values.phone,
        });
        setStep(LOCATION_STEP.FURTHER_CONTACT);
      } catch (error) {
        //@ts-ignore
        formikHelpers.setErrors(error.response.data.data.errors);
      }
    },
  });
  const locationContactFormik = useLocationContactFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      email_confirm: '',
      phone: '',
    },
    onSubmit: async (values, formikHelpers) => {
      try {
        await API.locationsStep3Edit({
          step: '3',
          slug: slug,
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          phone: values.phone,
        });
        setStep(LOCATION_STEP.CONFIRMATION);
      } catch (error) {
        //@ts-ignore
        formikHelpers.setErrors(error.response.data.data.errors);
      }
    },
  });

  const locationConfirmationFormik = useLocationConfirmationFormik({
    initialValues: {
      accept_terms_conditions: !!data?.location.accept_terms_conditions,
      i_wee: data?.location.i_wee || '',
      data_protection: true,
    },
    onSubmit: async (values, formikHelpers) => {
      try {
        await API.locationsStep4Edit({
          step: '4',
          slug: slug,
          accept_terms_conditions: values.accept_terms_conditions ? 1 : 0,
          i_wee: values.i_wee,
        });
        setStep(LOCATION_STEP.THANK_YOU);
      } catch (error) {
        //@ts-ignore
        formikHelpers.setErrors(error.response.data.data.errors);
      }
    },
  });
  return (
    <StyledEditLocation className="EditLocation">
      <Container>
        <LocationForm
          step={step}
          locationInfoFormik={locationInfoFormik}
          locationDataFormik={locationDataFormik}
          locationContactFormik={locationContactFormik}
          locationConfirmationFormik={locationConfirmationFormik}
          setStep={setStep}
        />
      </Container>
    </StyledEditLocation>
  );
};
