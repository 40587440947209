import { Container } from '@components/shared/Container';
import breakpoints from '@lib/common/breakpoints';
import ModuleType from '@lib/interfaces/Modules';
import React, { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<{ backgroundColor?: string }>`
  background-color: ${(props) => props.backgroundColor || props.theme.colors.yellow};
  padding: 100px 15px;
  color: ${(props) => (props.backgroundColor === props.theme.colors.violet ? '#ffffff' : '#000000')} !important;

  p {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 30px;
    line-height: ${(props) => props.theme.helpers.pxToRem(42)};
    letter-spacing: 0.4px;
  }

  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 40px 15px;

    p {
      @media only screen and ${breakpoints.maxWidth.md} {
        font-size: 25px;
        line-height: 1.25;
      }

      @media only screen and ${breakpoints.maxWidth.sm} {
        font-size: 22px;
        line-height: 1.36;
      }
    }
  }
`;

const TextWrapper = styled.div<{ color?: string }>`
  max-width: 1121px;
  width: 100%;
  margin: 0;
  color: ${(props) => (props.color ? props.color : '#000000')} !important;

  h3 {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 52px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.03;
    letter-spacing: normal;
    margin-bottom: 40px;

    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 28px;
      line-height: 1.17;
    }

    @media only screen and ${breakpoints.maxWidth.xs} {
      font-size: 20px;
      line-height: 1.17;
    }
  }
`;

interface IModule18Props {
  data?: {
    title?: string;
    text?: string;
    color?: string;
    text_color?: string;
  };
}

const Module18: FC<IModule18Props> = (props) => {
  const { data } = props;
  return (
    <Wrapper data-module={ModuleType.MODULE_18} backgroundColor={data?.color}>
      <Container>
        <TextWrapper color={data?.text_color}>
          {data?.title && <h3>{data.title}</h3>}
          <p>{data?.text}</p>
        </TextWrapper>
      </Container>
    </Wrapper>
  );
};

export default Module18;
