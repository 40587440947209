import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';

export interface BranchContactSecondaryFields {
  further_person_first_name: string;
  further_person_last_name: string;
  further_person_email: string;
  further_person_email_confirmation: string;
  further_person_phone: string;
  further_person_mobile: string;
}

interface UseBranchFormOptions {
  initialValues?: BranchContactSecondaryFields;
  onSubmit: (values: BranchContactSecondaryFields, formikHelpers: FormikHelpers<BranchContactSecondaryFields>) => Promise<any>;
}

export const useBranchContactSecondaryFormik = (options: UseBranchFormOptions) => {
  const BranchSchema = Yup.object().shape({
    further_person_first_name: Yup.string(),
    further_person_last_name: Yup.string(),
    further_person_email: Yup.string().email('Email should be valid!'),
    further_person_email_confirmation: Yup.string()
      .email('Email should be valid!')
      .oneOf([Yup.ref('further_person_email'), null], 'Email confirm must match'),
    further_person_phone: Yup.string(),
    further_person_mobile: Yup.string(),
  });

  return useFormik({
    initialValues: {
      further_person_first_name: '',
      further_person_last_name: '',
      further_person_email: '',
      further_person_email_confirmation: '',
      further_person_phone: '',
      further_person_mobile: '',
      ...options.initialValues,
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: BranchSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type BranchContactSecondaryFormik = ReturnType<typeof useBranchContactSecondaryFormik>;
