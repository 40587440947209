import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';

export interface BranchCategoryFields {
  production_types: number[] | any;
  new_production_types: string;
  eco_certifications: number[] | any;
}

interface UseBranchFormOptions {
  initialValues?: BranchCategoryFields;
  onSubmit: (values: BranchCategoryFields, formikHelpers: FormikHelpers<BranchCategoryFields>) => Promise<any>;
}

export const useBranchCategoryFormik = (options: UseBranchFormOptions) => {
  const BranchSchema = Yup.object().shape({
    production_types: Yup.array().required('Art der Produktion is required'),
    // eco_certifications: Yup.array().required('Infrastructure is required'),
  });

  return useFormik({
    initialValues: {
      production_types: [],
      new_production_types: '',
      eco_certifications: [],
      ...options.initialValues,
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: BranchSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type BranchCategoryFormik = ReturnType<typeof useBranchCategoryFormik>;
