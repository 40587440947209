import { ReactComponent as MinusIcon } from '@assets/icons/icons_minus.svg';
import { ReactComponent as PlusIcon } from '@assets/icons/icons_plus.svg';
import breakpoints from '@lib/common/breakpoints';
import ModuleType from '@lib/interfaces/Modules';
import { useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
`;
const List = styled.ul`
  margin: 0 0 115px 0;
  padding: 0;
`;

const ListItem = styled.li`
  list-style: none;
  border-bottom: 2px solid #000000;
  &:first-of-type {
    border-top: 2px solid #000000;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  svg {
    margin-left: 10px;
    min-width: 31px;
    height: 31px;
  }
`;

const Title = styled.h1`
  text-transform: uppercase;
  font-family: 'GTFlexa-ExtendedMedium', sans-serif;
  font-size: 24px;
  line-height: ${(props) => props.theme.helpers.pxToRem(30)};
  padding: 40px 0;
  word-break: break-word;

  @media only screen and ${breakpoints.maxWidth.xs} {
    font-size: 16px;
    line-height: 1.17;
  }
`;

const ContentWrapper = styled.div`
  padding-bottom: 20px;
`;

const Content = styled.p`
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  //max-width: 823px;
  font-size: 24px;
  line-height: ${(props) => props.theme.helpers.pxToRem(30)};
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 18px;
    line-height: ${(props) => props.theme.helpers.pxToRem(22)};
    letter-spacing: 0.2px;
  }
`;

interface Module17Props {
  className?: string;
  data?: {
    items?: Array<{ title?: string; description: string; id?: number }>;
  };
}

export default function Module17(props: Module17Props) {
  const [expanded, setExpanded] = useState<number[]>([]);

  const { data } = props;

  const handleOnClick = (id: number) => {
    if (expanded.includes(id)) {
      setExpanded(expanded.filter((x) => x !== id));
      return;
    }

    setExpanded([...expanded, id]);
    return;
  };

  const items = data?.items ?? [];

  return (
    <Wrapper data-module={ModuleType.MODULE_17} className={props.className ?? ''}>
      <List>
        {items
          .map((x, i) => ({ ...x, id: i + 1 }))
          .map((item) => {
            const isExpanded = expanded.includes(item.id);
            return (
              <ListItem key={item.id}>
                <TitleWrapper onClick={() => handleOnClick(item.id)}>
                  <Title>{item.title}</Title>
                  {isExpanded ? <MinusIcon /> : <PlusIcon />}
                </TitleWrapper>
                {isExpanded && (
                  <ContentWrapper>
                    <Content dangerouslySetInnerHTML={{ __html: item.description ?? '' }} />
                  </ContentWrapper>
                )}
              </ListItem>
            );
          })}
      </List>
    </Wrapper>
  );
}
