import * as API from '@api/branch';
import { Container } from '@components/shared/Container';
import { BranchForm, BRANCH_STEP } from '@components/user/Branch/BranchForm';
import breakpoints from '@lib/common/breakpoints';
import { useBranchCategoryFormik } from '@lib/hooks/formik/useBranchCategoryFormik';
import { useBranchContactFormik } from '@lib/hooks/formik/useBranchContactFormik';
import { useBranchContactSecondaryFormik } from '@lib/hooks/formik/useBranchContactSecondaryFormik';
import { useBranchDataFormik } from '@lib/hooks/formik/useBranchDataFormik';
import React, { useState } from 'react';
import styled from 'styled-components';

const StyledCreateBranch = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.violet};
  padding: 120px 0;
  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 100px 0;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 80px 0;
  }
`;

export const StyledPageHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 120px;
  @media only screen and ${breakpoints.maxWidth.md} {
    margin-bottom: 50px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    margin-bottom: 50px;
  }
  h1 {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 58px;
    line-height: 1.05em;
    color: ${(props) => props.theme.colors.white};
    margin-bottom: 27px;
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 28px;
      line-height: 1.18em;
      margin-bottom: 24px;
    }

    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 24px;
      line-height: 1.18em;
      margin-bottom: 24px;
    }
  }
  p {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 36px;
    letter-spacing: 0.4px;
    line-height: 1.18em;
    color: ${(props) => props.theme.colors.white};
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 20px;
      letter-spacing: 0.2px;
      line-height: 1.2em;
    }

    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 18px;
      letter-spacing: 0.2px;
      line-height: 1.2em;
    }
  }
`;

interface Props {}

export const CreateBranch = (props: Props) => {
  const [step, setStep] = useState(BRANCH_STEP.BRANCH_CATEGORY);

  const branchCategoryFormik = useBranchCategoryFormik({
    onSubmit: async (values, formikHelpers) => {
      try {
        const res = await API.branchStep1({
          step: '1',
          production_types: values.production_types,
          new_production_types: values.new_production_types,
          eco_certifications: values.eco_certifications,
        });

        setStep(res.step);
      } catch (error) {
        //@ts-ignore
        formikHelpers.setErrors(error.response.data.data.errors);
      }
    },
  });
  const branchDataFormik = useBranchDataFormik({
    onSubmit: async (values, formikHelpers) => {
      try {
        const res = await API.branchStep2({
          step: '2',
          logo: values.logo,
          company: values.company,
          street: values.street,
          postcode: values.postcode,
          website: values.website,
          email: values.email,
          email_confirmation: values.email_confirmation,
          phone: values.phone,

          company_profile: values.company_profile,
          credentials: values.credentials,
          languages: values.languages,
          vimeo_url: values.vimeo_url,
        });

        setStep(res.step);
      } catch (error) {
        //@ts-ignore
        formikHelpers.setErrors(error.response.data.data.errors);
      }
    },
  });
  const branchContactFormik = useBranchContactFormik({
    onSubmit: async (values, formikHelpers) => {
      try {
        const res = await API.branchStep3({
          step: '3',
          first_name: values.first_name,
          last_name: values.last_name,
          contact_person_email: values.contact_person_email,
          contact_person_email_confirmation: values.contact_person_email_confirmation,
          contact_person_phone: values.contact_person_phone,
          contact_person_mobile: values.contact_person_mobile,
        });

        setStep(res.step);
      } catch (error) {
        //@ts-ignore
        formikHelpers.setErrors(error.response.data.data.errors);
      }
    },
  });
  const branchContactSecondaryFormik = useBranchContactSecondaryFormik({
    onSubmit: async (values, formikHelpers) => {
      try {
        await API.branchStep4({
          step: '4',
          further_person_first_name: values?.further_person_first_name,
          further_person_last_name: values?.further_person_last_name,
          further_person_email: values?.further_person_email,
          further_person_email_confirmation: values?.further_person_email_confirmation,
          further_person_phone: values?.further_person_phone,
          further_person_mobile: values?.further_person_mobile,
        });

        setStep(BRANCH_STEP.THANK_YOU);
      } catch (error) {
        //@ts-ignore
        formikHelpers.setErrors(error.response.data.data.errors);
      }
    },
  });

  return (
    <StyledCreateBranch className="CreateBranch">
      <Container>
        <BranchForm
          step={step}
          branchCategoryFormik={branchCategoryFormik}
          branchDataFormik={branchDataFormik}
          branchContactFormik={branchContactFormik}
          branchContactSecondaryFormik={branchContactSecondaryFormik}
          setStep={setStep}
        />
      </Container>
    </StyledCreateBranch>
  );
};
