import { getNews, GetNewsResponse } from '@api/news';
import QueryKeys from '@lib/constants/QueryKeys';
import { AxiosError } from 'axios';
import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query';

export default function useGetNews(
  year: string = '',
  search: string | undefined = undefined,
  options?: UseInfiniteQueryOptions<GetNewsResponse, AxiosError, any>,
) {
  return useInfiniteQuery<GetNewsResponse, AxiosError>(
    [QueryKeys.news(), year, search],
    ({ pageParam = 1 }) => getNews({ page: pageParam, year: year, search }),
    {
      getNextPageParam: ({ current_page, total_pages }) => {
        if (current_page === total_pages) {
          return;
        }
        return current_page + 1;
      },
      cacheTime: 0,
      ...options,
    },
  );
}
