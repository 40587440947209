import * as API from '@api/filming';
import Button from '@components/shared/Button';
import { Container } from '@components/shared/Container';
import HandleFetchingState from '@components/shared/HandleFetchingState';
import { RequestLocationsForm } from '@components/user/Location/RequestLocationsForm';
import breakpoints from '@lib/common/breakpoints';
import { useRequestLocationsFormik } from '@lib/hooks/formik/useRequestLocationsFormik';
import useGetFilmingPermitForm from '@lib/hooks/queries/useGetFilmingPermitForm';
import useGetLocationsDetails from '@lib/hooks/queries/useGetLocationsDetails';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

const StyledRequestPrivateLocation = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.yellow};
  padding: 120px 0;
  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 100px 0;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 80px 0;
  }
`;

const StyledPageHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  @media only screen and ${breakpoints.maxWidth.md} {
    margin-bottom: 50px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    margin-bottom: 50px;
  }
  h1 {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 58px;
    line-height: 1.05em;
    color: ${(props) => props.theme.colors.black};
    margin-bottom: 27px;
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 28px;
      line-height: 1.18em;
      margin-bottom: 24px;
    }

    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 24px;
      line-height: 1.18em;
      margin-bottom: 24px;
    }
  }
  p {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 36px;
    letter-spacing: 0.4px;
    line-height: 1.18em;
    color: ${(props) => props.theme.colors.black};
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 20px;
      letter-spacing: 0.2px;
      line-height: 1.2em;
    }

    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 18px;
      letter-spacing: 0.2px;
      line-height: 1.2em;
    }
  }
`;

const StyledSuccess = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  padding: 120px 150px 120px;
`;
const StyledSuccessTitle = styled.h3`
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  font-size: 36px;
  letter-spacing: 0.4px;
  line-height: 1.2em;
  color: ${(props) => props.theme.colors.black};
`;

const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 80px;
`;

const StyledButton = styled(Button)`
  font-size: 24px;
  padding: 24px 36px;
`;

interface Props {
  slug: string;
}

export const RequestPrivateLocation = (props: RouteComponentProps<Props>) => {
  const slug = props.match.params.slug;
  const { formatMessage: t } = useIntl();

  const [success, setSuccess] = useState(false);
  const { data, isLoading, error } = useGetFilmingPermitForm();
  const { data: locationData, isLoading: locationLoading, error: locationError } = useGetLocationsDetails(slug);

  const formik = useRequestLocationsFormik({
    initialValues: {
      locations: [],
      company_name: '',
      type_of_production: 0,
      production_title: '',
      name: '',
      phone: '',
      email: '',
      email_confirmation: '',
      comment: '',
      data_protection: false,
      terms: false,
    },
    onSubmit: async (values, formikHelpers) => {
      try {
        await API.privatFilmingPermit({
          location_id: values.locations[0].id,
          company_name: values.company_name,
          type_of_production: data?.data?.type_of_production[values.type_of_production].type || '',
          production_title: values.production_title,
          name: values.name,
          phone: values.phone,
          email: values.email,
          email_confirmation: values.email_confirmation,
          comment: values.comment,
          terms: values.terms,
          data_protection: values.data_protection,
        });
        setSuccess(true);
      } catch (e) {
        //@ts-ignore
        formikHelpers.setErrors(e.response.data.data.errors);
      }
    },
  });

  useEffect(() => {
    if (!locationData) {
      return;
    }
    formik.setFieldValue('locations', [
      {
        id: locationData.id,
        title: locationData.title,
        image: locationData.thumbnails.medium || locationData.thumbnail,
      },
    ]);
  }, [locationData]); //eslint-disable-line react-hooks/exhaustive-deps

  const SuccessRequest = () => {
    return (
      <>
        <StyledPageHeader>
          <h1>{t({ id: 'private_location_title1' })}</h1>
        </StyledPageHeader>
        <StyledSuccess className="RegisterForm">
          <StyledSuccessTitle>{t({ id: 'private_location_success' })}</StyledSuccessTitle>
          <StyledButtonWrapper>
            <StyledButton color="black" to="/">
              {t({ id: 'back_home' })}
            </StyledButton>
          </StyledButtonWrapper>
        </StyledSuccess>
      </>
    );
  };

  return (
    <HandleFetchingState loading={isLoading || locationLoading} error={error || locationError}>
      <StyledRequestPrivateLocation className="RequestPrivateLocation">
        <Container>
          {success ? (
            <SuccessRequest />
          ) : (
            <>
              <StyledPageHeader>
                <h1>{t({ id: 'private_location_title1' })}</h1>
                <p>{t({ id: 'private_location_title2' })}</p>
              </StyledPageHeader>
              <RequestLocationsForm formik={formik} data={data} />
            </>
          )}
        </Container>
      </StyledRequestPrivateLocation>
    </HandleFetchingState>
  );
};
