// organize-imports-ignore
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import AppContextProviders from './lib/context/AppContextProviders';
import { LocationBookmarksContextProvider } from '@lib/context/LocationBookmarksContext/LocationBookmarksContext';
import * as serviceWorker from './serviceWorker';

import GlobalStyle from '@components/styled-components/GlobalStyles';
import './styles/index.scss';

import getLanguageFromLocalStorage from '@lib/helpers/getLanguageFromLocalStorage';

ReactDOM.render(
  <React.StrictMode>
    <Router basename={`/${getLanguageFromLocalStorage()}`}>
      <AppContextProviders>
        <LocationBookmarksContextProvider>
          <App />
          <GlobalStyle />
        </LocationBookmarksContextProvider>
      </AppContextProviders>
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
