import * as API from '@api/user';
import Button from '@components/shared/Button';
import breakpoints from '@lib/common/breakpoints';
import { useProfileDeleteFormik } from '@lib/hooks/formik/useProfileDeleteFormik';
import { useRouter } from '@lib/hooks/useRouter';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Form, FormFeedback } from 'reactstrap';
import styled, { css } from 'styled-components';
import { Checkbox } from '../Checkbox';
import { Input } from '../Input';

const StyledProfileDeleteForm = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  padding: 120px 150px;
  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 100px 20px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 80px 20px;
  }
`;
const StyledFormTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-bottom: 24px;
  @media only screen and ${breakpoints.maxWidth.md} {
    align-items: flex-start;
    flex-direction: column;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    align-items: flex-start;
    flex-direction: column;
  }
  h3 {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 36px;
    letter-spacing: 0.4px;
    line-height: 1.2em;
    color: ${(props) => props.theme.colors.black};
    margin-right: 20px;
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 24px;
      letter-spacing: 0.2px;
      margin-right: 0;
    }
    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 24px;
      letter-spacing: 0.2px;
      margin-right: 0;
    }
  }
  a {
    font-family: 'GTFlexa-StandardMedium';
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 1.24em;
    color: ${(props) => props.theme.colors.black};
    text-decoration: underline;
    margin-bottom: 5px;
    @media only screen and ${breakpoints.maxWidth.md} {
      margin-top: 5px;
    }
    @media only screen and ${breakpoints.maxWidth.sm} {
      margin-top: 5px;
    }
  }
`;
const StyledRow = styled.div`
  margin-left: -16px;
  margin-right: -16px;
`;

interface IColType {
  size?: number;
}
const StyledCol = styled.div<IColType>`
  display: inline-block;
  width: 100%;
  padding: 0 16px;
  ${({ size }) =>
    size === 6 &&
    css`
      float: left;
      width: 50%;
      @media only screen and ${breakpoints.maxWidth.md} {
        width: 100%;
      }
      @media only screen and ${breakpoints.maxWidth.sm} {
        width: 100%;
      }
    `}
`;
const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  @media only screen and ${breakpoints.maxWidth.md} {
    flex-direction: column-reverse;
    margin-top: 20px;
    button:first-child {
      margin-bottom: 0 !important;
    }
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    flex-direction: column-reverse;
    margin-top: 20px;
    button:first-child {
      margin-bottom: 0 !important;
    }
  }
`;
const StyledCancelButton = styled(Button)`
  min-width: 341px;
  font-size: 24px;
  padding: 24px 36px;
  margin-right: 30px;
  @media only screen and ${breakpoints.maxWidth.md} {
    min-width: 100%;
    width: 100%;
    font-size: 20px;
    padding: 10px 18px;
    margin-bottom: 20px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    width: 100%;
    font-size: 20px;
    padding: 10px 18px;
    margin-bottom: 20px;
  }
`;
const StyledButton = styled(Button)`
  font-size: 24px;
  padding: 24px 36px;
  @media only screen and ${breakpoints.maxWidth.md} {
    width: 100%;
    font-size: 20px;
    padding: 10px 18px;
    margin-bottom: 20px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    width: 100%;
    font-size: 20px;
    padding: 10px 18px;
    margin-bottom: 20px;
  }
`;
const StyledFormSeperateTitle = styled.h4`
  font-family: 'GTFlexa-ExtendedMedium', sans-serif;
  font-size: 24px;
  line-height: 1.2em;
  color: ${(props) => props.theme.colors.black};
  margin-top: 30px;
  margin-bottom: 24px;
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 8px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 8px;
  }
`;
const StyledFormFeedback = styled(FormFeedback)`
  font-family: 'GTFlexa-StandardMedium';
  font-size: 16px;
  letter-spacing: 0.2px;
  color: ${(props) => props.theme.colors.red};
  padding-top: 10px;
  text-align: right;
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 14px;
    padding-left: 15px;
    margin-top: -10px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 14px;
    padding-left: 15px;
    margin-top: -10px;
  }
`;

interface Props {
  setDeletedSuccessfully: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ProfileDeleteForm = (props: Props) => {
  const { formatMessage: t } = useIntl();
  const router = useRouter();
  const [error, setError] = useState<string | undefined>(undefined);

  const formik = useProfileDeleteFormik({
    onSubmit: async (values, formikHelpers) => {
      try {
        await API.deleteProfile(values);
        props.setDeletedSuccessfully(true);
        formikHelpers.resetForm();
      } catch (error) {
        //@ts-ignore
        setError(error.response.data.data.message);
      }
    },
  });

  return (
    <StyledProfileDeleteForm className="ProfileDeleteForm">
      <StyledFormTitle>
        <h3>{t({ id: 'login_data' })}</h3>
        <Link to="/profile">{t({ id: 'just_want_to_edit_your_profile' })}</Link>
      </StyledFormTitle>
      <Form onSubmit={formik.handleSubmit}>
        <StyledRow>
          <StyledCol>
            <Input
              type="email"
              name="email"
              id="email"
              label={t({ id: 'email' })}
              className="form-control-custom"
              value={formik.values.email}
              invalid={!!formik.errors?.email && formik.touched?.email}
              onChange={formik.handleChange}
              error={formik.errors.email}
            />
          </StyledCol>
          <StyledCol>
            <Input
              type="password"
              name="password"
              id="password"
              label={t({ id: 'password' })}
              className="form-control-custom"
              value={formik.values.password}
              invalid={!!formik.errors.password && formik.touched.password}
              onChange={formik.handleChange}
              error={formik.errors.password}
            />
          </StyledCol>
          <StyledCol>
            <StyledFormSeperateTitle>{t({ id: 'reason_for_deletion' })}</StyledFormSeperateTitle>
          </StyledCol>
          <StyledCol>
            <Checkbox
              name="no_longer_use"
              id="no_longer_use"
              label={t({ id: 'my_profile_is_no_longer_used' })}
              checked={formik.values.no_longer_use}
              invalid={!!formik.errors.no_longer_use && formik.touched.no_longer_use}
              onChange={formik.handleChange}
              error={formik.errors.no_longer_use}
            />
          </StyledCol>
          <StyledCol>
            <Checkbox
              name="too_much_effort"
              id="too_much_effort"
              label={t({ id: 'too_much_effort' })}
              checked={formik.values.too_much_effort}
              invalid={!!formik.errors.too_much_effort && formik.touched.too_much_effort}
              onChange={formik.handleChange}
              error={formik.errors.too_much_effort}
            />
          </StyledCol>
          <StyledCol>
            <Checkbox
              name="dont_want_to_show"
              id="dont_want_to_show"
              label={t({ id: 'i_don_t_want_to_specify' })}
              checked={formik.values.dont_want_to_show}
              invalid={!!formik.errors.dont_want_to_show && formik.touched.dont_want_to_show}
              onChange={formik.handleChange}
              error={formik.errors.dont_want_to_show}
            />
          </StyledCol>

          <StyledCol>
            <Input
              type="textarea"
              name="reason"
              id="reason"
              label={t({ id: 'other_reason' })}
              className="form-control-custom"
              value={formik.values.reason}
              invalid={!!formik.errors.reason && formik.touched.reason}
              onChange={formik.handleChange}
              error={formik.errors.reason}
            />
          </StyledCol>
          <StyledCol>{error && <StyledFormFeedback>{error}</StyledFormFeedback>}</StyledCol>
          <StyledCol>
            <StyledButtonWrapper>
              <StyledCancelButton onClick={() => router.history.push('/profile')}>{t({ id: 'private_request_button_back' })}</StyledCancelButton>
              <StyledButton disabled={formik.isSubmitting} color="black">
                {formik.isSubmitting ? t({ id: 'loading' }) : t({ id: 'account_delete' })}
              </StyledButton>
            </StyledButtonWrapper>
          </StyledCol>
        </StyledRow>
      </Form>
    </StyledProfileDeleteForm>
  );
};
