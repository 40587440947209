import React, { useContext } from 'react';

export interface UIContextType {
  isMobileMenuOpened?: boolean;
  isSearchOpened?: boolean;
  removeNavigation?: boolean;
  languageOpened?: boolean;
  userDropdownOpened?: boolean;
  kontaktDropdownOpened?: boolean;
  [key: string]: any;
}

const UIContextValues: UIContextType = {};

export const UIContext = React.createContext<UIContextType>(UIContextValues);

export const useUIContext = () => useContext(UIContext);
