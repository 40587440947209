import Button from '@components/shared/Button';
import breakpoints from '@lib/common/breakpoints';
import { BranchContactSecondaryFormik } from '@lib/hooks/formik/useBranchContactSecondaryFormik';
import React from 'react';
import { useIntl } from 'react-intl';
//Components
import { Form } from 'reactstrap';
import styled, { css } from 'styled-components';
import { Input } from '../Input';
import { BRANCH_STEP } from './BranchForm';

const StyledForm = styled.div`
  width: 100%;
`;
const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  @media only screen and ${breakpoints.maxWidth.md} {
    flex-direction: column-reverse;
    margin-top: 20px;
    button:first-child {
      margin-bottom: 0 !important;
    }
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    flex-direction: column-reverse;
    margin-top: 20px;
    button:first-child {
      margin-bottom: 0 !important;
    }
  }
`;
const StyledRow = styled.div`
  margin-left: -15px;
  margin-right: -15px;
`;
const StyledCol = styled.div<IColType>`
  display: inline-block;
  width: 100%;
  padding: 0 15px;

  ${({ size }) =>
    size === 6 &&
    css`
      float: left;
      width: 50%;
      @media only screen and ${breakpoints.maxWidth.md} {
        width: 100%;
      }
      @media only screen and ${breakpoints.maxWidth.sm} {
        width: 100%;
      }
    `}
`;

const StyledButton = styled(Button)`
  font-size: 24px;
  padding: 24px 36px;
  @media only screen and ${breakpoints.maxWidth.md} {
    width: 100%;
    font-size: 20px;
    padding: 10px 18px;
    margin-bottom: 20px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    width: 100%;
    font-size: 20px;
    padding: 10px 18px;
    margin-bottom: 20px;
  }
`;

interface IColType {
  size?: number;
}

interface Props {
  formik: BranchContactSecondaryFormik;
  setStep: React.Dispatch<React.SetStateAction<BRANCH_STEP>>;
}

export const BranchContactSecondary = ({ formik, setStep }: Props) => {
  const { formatMessage: t } = useIntl();

  const handleBack = () => setStep(BRANCH_STEP.CONTACT_PERSON);

  return (
    <StyledForm className="BranchContactSecondary">
      <Form onSubmit={formik.handleSubmit}>
        <StyledRow>
          <StyledCol size={6}>
            <Input
              optional
              type="text"
              name="further_person_first_name"
              id="further_person_first_name"
              label={t({ id: 'first_name' })}
              className="form-control-custom"
              value={formik.values?.further_person_first_name}
              invalid={!!formik.errors?.further_person_first_name && formik.touched?.further_person_first_name}
              onChange={formik.handleChange}
              error={formik.errors?.further_person_first_name}
            />
          </StyledCol>
          <StyledCol size={6}>
            <Input
              optional
              type="text"
              name="further_person_last_name"
              id="further_person_last_name"
              label={t({ id: 'last_name' })}
              className="form-control-custom"
              value={formik.values.further_person_last_name}
              invalid={!!formik.errors.further_person_last_name && formik.touched.further_person_last_name}
              onChange={formik.handleChange}
              error={formik.errors.further_person_last_name}
            />
          </StyledCol>
          <StyledCol>
            <Input
              optional
              type="email"
              name="further_person_email"
              id="further_person_email"
              label={t({ id: 'email' })}
              className="form-control-custom"
              value={formik.values.further_person_email}
              invalid={!!formik.errors.further_person_email && formik.touched.further_person_email}
              onChange={formik.handleChange}
              error={formik.errors.further_person_email}
            />
          </StyledCol>
          <StyledCol>
            <Input
              optional
              type="email"
              name="further_person_email_confirmation"
              id="further_person_email_confirmation"
              label={t({ id: 'email_adresse_wiedeholen' })}
              className="form-control-custom"
              value={formik.values.further_person_email_confirmation}
              invalid={!!formik.errors.further_person_email_confirmation && formik.touched.further_person_email_confirmation}
              onChange={formik.handleChange}
              error={formik.errors.further_person_email_confirmation}
            />
          </StyledCol>
          <StyledCol>
            <Input
              optional
              type="text"
              name="further_person_phone"
              id="further_person_phone"
              label={t({ id: 'phone' })}
              className="form-control-custom"
              value={formik.values.further_person_phone}
              invalid={!!formik.errors.further_person_phone && formik.touched.further_person_phone}
              onChange={formik.handleChange}
              error={formik.errors.further_person_phone}
            />
          </StyledCol>
          <StyledCol>
            <Input
              optional
              type="text"
              name="further_person_mobile"
              id="further_person_mobile"
              label={t({ id: 'mobile' })}
              className="form-control-custom"
              value={formik.values.further_person_mobile}
              invalid={!!formik.errors.further_person_mobile && formik.touched.further_person_mobile}
              onChange={formik.handleChange}
              error={formik.errors.further_person_mobile}
            />
          </StyledCol>
          <StyledCol>
            <StyledButtonWrapper>
              <StyledButton color="black" onClick={handleBack}>
                {t({ id: 'button_back' })}
              </StyledButton>
              <StyledButton disabled={formik.isSubmitting} color="black">
                {formik.isSubmitting ? t({ id: 'loading' }) : t({ id: 'button_next_step' })}
              </StyledButton>
            </StyledButtonWrapper>
          </StyledCol>
        </StyledRow>
      </Form>
    </StyledForm>
  );
};
