import Button from '@components/shared/Button';
import breakpoints from '@lib/common/breakpoints';
import { useAuthContext } from '@lib/context/AuthContext/AuthContext';
import { StyledPageHeader } from '@pages/Register';
import React from 'react';
import { useIntl } from 'react-intl';
import { Form } from 'reactstrap';
import styled, { css } from 'styled-components';
import { useRegisterFormik } from '../../../lib/hooks/formik/useRegisterFormik';
import { Input } from '../Input';

const StyledForm = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  padding: 150px 150px 120px;
  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 80px 24px 50px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 80px 24px 50px;
  }
  form {
    display: -webkit-box;
  }
`;
const StyledFormTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-bottom: 24px;
  @media only screen and ${breakpoints.maxWidth.md} {
    align-items: flex-start;
    flex-direction: column;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    align-items: flex-start;
    flex-direction: column;
  }
  h3 {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 36px;
    letter-spacing: 0.4px;
    line-height: 1.2em;
    color: ${(props) => props.theme.colors.black};
    margin-right: 20px;
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 24px;
      letter-spacing: 0.2px;
      margin-right: 0;
    }
    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 24px;
      letter-spacing: 0.2px;
      margin-right: 0;
    }
  }
  a {
    font-family: 'GTFlexa-StandardMedium';
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 1.24em;
    color: ${(props) => props.theme.colors.black};
    text-decoration: underline;
    margin-bottom: 5px;
    @media only screen and ${breakpoints.maxWidth.md} {
      margin-top: 5px;
    }
    @media only screen and ${breakpoints.maxWidth.sm} {
      margin-top: 5px;
    }
  }
`;
const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  @media only screen and ${breakpoints.maxWidth.md} {
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
  }
`;
const StyledRow = styled.div`
  margin-left: -16px;
  margin-right: -16px;
`;
const StyledCol = styled.div<IColType>`
  display: inline-block;
  float: left;
  width: 100%;
  padding: 0 16px;
  ${({ size }) =>
    size === 6 &&
    css`
      float: left;
      width: 50%;
      @media only screen and ${breakpoints.maxWidth.md} {
        width: 100%;
      }
      @media only screen and ${breakpoints.maxWidth.sm} {
        width: 100%;
      }
    `}
`;

const StyledButton = styled(Button)`
  font-size: 24px;
  padding: 24px 36px;
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 20px;
    padding: 10px 18px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 20px;
    padding: 10px 18px;
  }
`;

interface IColType {
  size?: number;
}

export const RegisterForm = () => {
  const authCtx = useAuthContext();
  const { formatMessage: t } = useIntl();

  const formik = useRegisterFormik({
    onSubmit: async (values, formikHelpers) => {
      try {
        const res = await authCtx.register(values);
        if (res?.response?.data?.data?.errors) {
          formikHelpers.setErrors(res.response.data.data.errors);
        }else{
          formikHelpers.resetForm();
        }
      } catch (error) {}
    },
  });

  return (
    <>
      <StyledPageHeader>
        <h1>{t({ id: 'user_registration_title1' })}</h1>
        <p>{t({ id: 'user_registration_title2' })}</p>
      </StyledPageHeader>
      <StyledForm className="RegisterForm">
        <StyledFormTitle>
          <h3>{t({ id: 'user_registration_form_title' })}</h3>
        </StyledFormTitle>
        <Form onSubmit={formik.handleSubmit}>
          <StyledRow>
            <StyledCol size={6}>
              <Input
                type="text"
                id="first_name"
                name="first_name"
                label={t({ id: 'first_name' })}
                className="form-control-custom"
                value={formik.values.first_name}
                invalid={!!formik.errors.first_name && formik.touched.first_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.errors.first_name}
              />
            </StyledCol>
            <StyledCol size={6}>
              <Input
                type="text"
                id="last_name"
                name="last_name"
                label={t({ id: 'last_name' })}
                className="form-control-custom"
                value={formik.values.last_name}
                invalid={!!formik.errors.last_name && formik.touched.last_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.errors.last_name}
              />
            </StyledCol>
            <StyledCol>
              <Input
                type="text"
                id="company"
                name="company"
                label={t({ id: 'user_company' })}
                optional
                className="form-control-custom"
                value={formik.values.company}
                invalid={!!formik.errors.company && formik.touched.company}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.errors.company}
              />
            </StyledCol>
            <StyledCol>
              <Input
                type="email"
                id="email"
                label={t({ id: 'email' })}
                className="form-control-custom"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                invalid={!!formik.errors.email && formik.touched.email}
                error={formik.errors.email}
              />
            </StyledCol>
            <StyledCol>
              <Input
                type="email"
                id="email_confirmation"
                label={t({ id: 'email_adresse_wiedeholen' })}
                className="form-control-custom"
                value={formik.values.email_confirmation}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                invalid={!!formik.errors.email_confirmation && formik.touched.email_confirmation}
                error={formik.errors.email_confirmation}
              />
            </StyledCol>
            <StyledCol size={6}>
              <Input
                type="password"
                id="password"
                label={t({ id: 'password' })}
                labelInfo={t({ id: 'password' })}
                className="form-control-custom"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                invalid={!!formik.errors.password && formik.touched.password}
                error={formik.errors.password}
              />
            </StyledCol>
            <StyledCol size={6}>
              <Input
                type="password"
                id="password_confirmation"
                label={t({ id: 'password_confirmation' })}
                className="form-control-custom"
                value={formik.values.password_confirmation}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                invalid={!!formik.errors.password_confirmation && formik.touched.password_confirmation}
                error={formik.errors.password_confirmation}
              />
            </StyledCol>
            <StyledCol>
              <StyledButtonWrapper>
                <StyledButton color="black">{authCtx.isLoading ? t({ id: 'loading' }) : t({ id: 'button_next_step' })}</StyledButton>
              </StyledButtonWrapper>
            </StyledCol>
          </StyledRow>
        </Form>
      </StyledForm>
    </>
  );
};
