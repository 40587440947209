import { Language, useReactIntlProvider } from '@lib/context/ReactIntlProvider/ReactIntlProvider';
// @ts-ignore
import de from 'hyphenated-de';
import { ReactNode } from 'react';
// @ts-ignore
import Hyphenated from 'react-hyphen';

interface Props {
  children: ReactNode;
}

const HyphenText = (props: Props) => {
  const { activeLanguage } = useReactIntlProvider();
  const showDe = activeLanguage === Language.DE;
  // return props.children;
  return <Hyphenated language={showDe ? de : undefined}>{props.children}</Hyphenated>;
};

export default HyphenText;
