import { ReactComponent as IconDownload } from '@assets/icons/icons_download.svg';
import Module00Button from '@components/modules/Module00';
import ContentSlider from '@components/shared/ContentSlider';
import HandleFetchingState from '@components/shared/HandleFetchingState';
import breakpoints from '@lib/common/breakpoints';
import { downloadFileFromUrl } from '@lib/helpers/downloadFileFromUrl';
import useGetNewsDetails from '@lib/hooks/queries/useGetNewsDetails';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
`;

const Container = styled.div`
  padding: 80px 140px;
  width: 100%;
  margin: 0 auto;

  @media only screen and ${breakpoints.maxWidth.lg} {
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 100px;
    padding-bottom: 80px;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    padding: 120px 20px 50px;
  }
`;

const H1 = styled.h1<{ color?: string }>`
  font-family: 'GTFlexa-ExtendedMedium', sans-serif;
  font-size: 58px;
  font-weight: normal;
  line-height: 1.03;
  color: ${(props) => props.color};
  margin: 0;

  @media only screen and ${breakpoints.maxWidth.lg} {
    font-size: 32px;
    line-height: 0.88;
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 24px;
    line-height: 1.17;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;

  @media only screen and ${breakpoints.maxWidth.md} {
    margin-bottom: 20px;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    flex-direction: column;
  }
`;

const ContentWrapper = styled.div`
  max-width: 760px;
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  p {
    font-family: 'GTFlexa-StandardLight', sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: 0.5px;
    color: #000;
    margin-bottom: 30px;
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 16px;
    }
    @media only screen and ${breakpoints.maxWidth.xs} {
      font-size: 16px;
    }
  }
`;

const StyledModuleLinkButton = styled(Module00Button)`
  svg {
    transform: rotate(-90deg);
  }
`;

const StyledDownloadItem = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
  &:hover {
    p {
      color: ${(props) => props.theme.colors.red};
    }
    svg g {
      stroke: ${(props) => props.theme.colors.red};
    }
  }
  p {
    font-family: 'GTFlexa-StandardLight', sans-serif;
    font-size: 18px;
    letter-spacing: 0.5px;
    line-height: 1.25em;
    color: ${(props) => props.theme.colors.black};
    margin-right: 15px;
  }
  svg {
    width: 31px;
    height: 31px;
  }
`;

interface INewsDetailsProps {
  newsSlug: string;
}

const NewsDetails: FC<RouteComponentProps<INewsDetailsProps>> = (props) => {
  const { formatMessage: t } = useIntl();
  const { data, isLoading } = useGetNewsDetails(props.match.params.newsSlug);

  const gallery = data?.photo_gallery
    ? data?.photo_gallery?.map((item, index) => {
        return {
          ID: 32 * (index + 1),
          post_title: item.image_title,
          post_type: 'news',
          post_content: item.image_copyright,
          description: item.image_copyright,
          thumbnail: item.image.url,
        };
      })
    : [];
  const downloads = data?.download_files ? data?.download_files : [];

  return (
    <HandleFetchingState loading={isLoading}>
      <Wrapper>
        <Container>
          <ContentWrapper>
            <TitleWrapper>
              <H1>{data?.post_title}</H1>
            </TitleWrapper>

            <TextContent dangerouslySetInnerHTML={{ __html: data?.post_content || '' }} />

            {downloads?.map((download) => {
              return (
                <StyledDownloadItem
                  key={`single-news-download-${download.file.ID}`}
                  onClick={() => downloadFileFromUrl(download.file.url, download.file.filename)}
                >
                  <p>{download?.file_description}</p>
                  <IconDownload />
                </StyledDownloadItem>
              );
            })}
          </ContentWrapper>
        </Container>

        {data?.page && (
          <Module00Button
            data={{
              link: {
                title: data.page?.title,
                url: data.page?.url,
                target: data.page?.target,
              },
            }}
          />
        )}

        {gallery && gallery.length > 0 && <ContentSlider title={data?.photo_gallery_title || ''} hideAllButton fullscreen posts={gallery} />}

        <StyledModuleLinkButton
          data={{
            link: {
              title: t({ id: 'zuruck.zu.allen.news' }),
              url: '/news',
            },
          }}
        />
      </Wrapper>
    </HandleFetchingState>
  );
};

export default NewsDetails;
