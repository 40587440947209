import ContentSlider from '@components/shared/ContentSlider';
import { MyBranches } from '@components/user/Profile/MyBranches';
import { MyLocations } from '@components/user/Profile/MyLocations';
import { ProfileIntro } from '@components/user/Profile/ProfileIntro';
import useGetBranches from '@lib/hooks/queries/useGetBranches';
import useGetMyBranches from '@lib/hooks/queries/useGetMyBranches';
import useGetMyLocations from '@lib/hooks/queries/useGetMyLocations';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

const StyledProfile = styled.div`
  width: 100%;
`;

interface Props {}

export const Profile = (props: RouteComponentProps<Props>) => {
  const myBranches = useGetMyBranches();
  const myLocations = useGetMyLocations();
  const branches = useGetBranches({});


  return (
    <StyledProfile className="Profile">
      <ProfileIntro />
      <MyLocations locations={myLocations.data?.data} />
    
      <MyBranches branches={myBranches.data?.branches} />
      {(!myBranches.data?.branches || myBranches.data?.branches.length === 0) && !branches.isLoading && (
        <ContentSlider
          title="Branchen aus unserer Datenbank."
          hideAllButton
          backgroundColor="#ffffff"
          smallImages
          posts={branches.data?.pages[0].branches}
        />
      )}
    </StyledProfile>
  );
};
