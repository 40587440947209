import { FilmingFields } from '@lib/hooks/formik/useFilmingFormik';
import { get, post } from './axios';

export interface FilmingFormDataResponse {
  data: {
    type_of_production: {
      type: string;
    }[];
    scene_description_helper_text: string;
    technical_effort_helper_text: string;
    location_helper_text: string;
    type_of_adaptation_helper_text: string;
    privacy_text: string;
  };
}
export interface FilmingSubmitResponse {
  data: {
    message?: string;
    errors?: {
      [key: string]: string;
    };
    success?: boolean;
  };
}

export interface FilmingStepResponse {
  data?: {
    valid?: boolean;
    message?: string;
    errors?: {
      [key: string]: string;
    };
  };
  success: boolean;
}

export interface FilmingStep1Input {
  //step1
  step: '1';
  first_name: string;
  last_name: string;
  company_name: string;
  address: string;
  phone: string;
  email: string;
  recording_manager_first_name: string;
  recording_manager_last_name: string;
  recording_manager_phone: string;
  recording_manager_email: string;
}
export interface FilmingStep2Input {
  //step2
  step: '2';
  production_title: string;
  type_of_production: number[] | any;
  scene_description: string;
  synopsis: number | null; //image
  technical_effort: string;
  number_of_people_on_crew: string;
  number_of_people_cast: string;
}
export interface FilmingStep3Input {
  //step3
  step: '3';
  shooting_date: string;
  replacement_date: string;
  time_of_construction: string;
  time_of_dismantling: string;
  start_of_shooting_time: string;
  end_of_rotation_time: string;
}
export interface FilmingStep4Input {
  //step4
  step: '4';
  location: string;
  adaptation_of_location: string;
  type_of_adaption: string;
  owner_was_contacted: string;
  location_contact_person_name: string;
  location_contact_person_phone: string;
  location_contact_person_email: string;
  city_map_screenshot: number | null; //image
  data_protection: boolean;
  date: string;
  name_signature: string;
}

export interface PrivatFilmingPermitInput {
  location_id: number;
  company_name: string;
  type_of_production: string;
  production_title: string;
  name: string;
  phone: string;
  email: string;
  email_confirmation: string;
  comment: string;
  terms: boolean;
  data_protection: boolean;
}
export interface MultiPrivatFilmingPermitInput {
  location_ids: number[];
  company_name: string;
  type_of_production: string;
  production_title: string;
  name: string;
  phone: string;
  email: string;
  email_confirmation: string;
  comment: string;
  terms: boolean;
  data_protection: boolean;
}

export interface PrivateFilmingPermitResponse {
  success: boolean;
  data?: {
    message: string;
  };
}

export interface PrivateFilmingFormDataResponse {
  data?: {
    type_of_production: {
      type: string;
    }[];
    scene_description_helper_text: string;
    technical_effort_helper_text: string;
    location_helper_text: string;
    type_of_adaptation_helper_text: string;
    privacy_text: string;
  };
}

export const validateFilmingStep1 = async (data: FilmingStep1Input) =>
  post<FilmingStep1Input, FilmingStepResponse>({ url: 'v1/filming-permit/validate-fields', data });

export const validateFilmingStep2 = async (data: FilmingStep2Input) =>
  post<FilmingStep2Input, FilmingStepResponse>({ url: 'v1/filming-permit/validate-fields', data });

export const validateFilmingStep3 = async (data: FilmingStep3Input) =>
  post<FilmingStep3Input, FilmingStepResponse>({ url: 'v1/filming-permit/validate-fields', data });

export const validateFilmingStep4 = async (data: FilmingStep4Input) =>
  post<FilmingStep4Input, FilmingStepResponse>({ url: 'v1/filming-permit/validate-fields', data });

export const submitFilming = async (data: FilmingFields) => post<FilmingFields, FilmingSubmitResponse>({ url: 'v1/filming-permit/submit', data });

export const getFilmingFormData = async () => get<undefined, FilmingFormDataResponse>({ url: 'v1/filming-permit/form-data' });

export const privatFilmingPermit = async (data: PrivatFilmingPermitInput) =>
  post<PrivatFilmingPermitInput, PrivateFilmingPermitResponse>({ url: 'v1/private-filming-permit/apply', data });

export const getFilmingPermitFormData = async () => get<undefined, PrivateFilmingFormDataResponse>({ url: 'v1/private-filming-permit/form-data' });

export const multiPrivatFilmingPermit = async (data: MultiPrivatFilmingPermitInput) =>
  post<MultiPrivatFilmingPermitInput, PrivateFilmingPermitResponse>({ url: 'v1/multi-private-filming-permit/apply', data });

export const getMultiPrivatFilmingFormData = async () =>
  get<undefined, PrivateFilmingFormDataResponse>({ url: 'v1/multi-private-filming-permit/form-data' });
