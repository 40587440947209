import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';

export interface LocationContactFields {
  first_name: string;
  last_name: string;
  email: string;
  email_confirm: string;
  phone: string;
}

interface UseLocationFormOptions {
  initialValues?: LocationContactFields;
  onSubmit: (values: LocationContactFields, formikHelpers: FormikHelpers<LocationContactFields>) => Promise<any>;
}

export const useLocationContactFormik = (options: UseLocationFormOptions) => {
  const LocationSchema = Yup.object().shape({
    first_name: Yup.string(),
    last_name: Yup.string(),
    email: Yup.string().email('Email should be valid!'),
    email_confirm: Yup.string()
      .email('Email should be valid!')
      .oneOf([Yup.ref('email'), null], 'Email confirm must match'),
    phone: Yup.string(),
  });

  return useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      email_confirm: '',
      phone: '',
      ...options.initialValues,
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: LocationSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type LocationContactFormik = ReturnType<typeof useLocationContactFormik>;
