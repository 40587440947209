import { Container } from '@components/shared/Container';
import breakpoints from '@lib/common/breakpoints';
import ModuleType from '@lib/interfaces/Modules';
import React, { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.yellow};
  padding-top: 110px;
  padding-bottom: 110px;
  width: 100%;

  @media only screen and ${breakpoints.maxWidth.sm} {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

const InnerWrapper = styled.div`
  h3 {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.25em;
    text-transform: uppercase;
  }

  p {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 58px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.03;
    letter-spacing: normal;
    color: #000000;
    margin-top: 26px;

    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 25px;
      line-height: 1.25;
    }

    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 22px;
      line-height: 1.36;
    }
  }

  button {
    font-size: 22px;
    padding: 13px 29px;
  }
`;

interface IModule20Props {
  data?: {
    title?: string;
    description?: string;
  };
}

const Module20: FC<IModule20Props> = (props) => {
  const { data } = props;
  return (
    <Wrapper data-module={ModuleType.MODULE_20}>
      <Container>
        <InnerWrapper>
          <h3>{data?.title}</h3>
          <p>{data?.description}</p>
        </InnerWrapper>
      </Container>
    </Wrapper>
  );
};

export default Module20;
