import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';

export interface LocationConfirmationFields {
  accept_terms_conditions: boolean;
  i_wee: string;
  data_protection: boolean;
}

interface UseLocationFormOptions {
  initialValues?: LocationConfirmationFields;
  onSubmit: (values: LocationConfirmationFields, formikHelpers: FormikHelpers<LocationConfirmationFields>) => Promise<any>;
}

export const useLocationConfirmationFormik = (options: UseLocationFormOptions) => {
  const LocationSchema = Yup.object().shape({
    accept_terms_conditions: Yup.boolean().oneOf([true], 'Accept terms and conditions is required'),
    i_wee: Yup.string().required('Name is required'),
    data_protection: Yup.boolean().oneOf([true], 'Data protection is required'),
  });

  return useFormik({
    initialValues: {
      accept_terms_conditions: false,
      i_wee: '',
      data_protection: true,
      ...options.initialValues,
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: LocationSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type LocationConfirmationFormik = ReturnType<typeof useLocationConfirmationFormik>;
